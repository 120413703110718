import { styled } from "@nextui-org/react";
import styles from "./ForgotPassword.module.scss"
import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme";
import { URL } from "../../constants";
import { Toaster, toast as toastSonner } from "sonner";
import Input from "../../components/Input/Input";
import toast from "../../components/Toast/ToastTypes"
import { useForm } from "react-hook-form";
import logo from "../../assets/img/white-logo.png"
import { Hidden } from "@mui/material";

import { useTranslation } from "react-i18next";

const StyledForgot = styled("div", {
  [`.${theme} &`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "$primary_300",
    color: "white",
    label: {
      color: "white",
    },
  },
});

const StyledContainerForgot = styled("div", {
  [`.${theme} &`]: {
    minWidth: '350px',
    maxW: "600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "2rem",
    bgColor: 'white',
    padding: '80px',
    borderRadius: '8px',
    color: "$primary_300",
    a: {
      color: "$primary_300",
      display: "flex",
      flexDirection: "column",
    },
  },
});


function ForgotPassword(props) {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('resetToken');
  const uid = queryParams.get('uid');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Nuevo estado para determinar si las contraseñas coinciden
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStatusMessage, setPasswordStatusMessage] = useState("");
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // Ajusta esto según tu regex

  const checkPasswordsMatch = (pass, confirmPass) => {
    if (pass === confirmPass && pass !== "" && confirmPass !== "") {
      setPasswordStatusMessage("Las contraseñas coinciden y son correctas.");
      setPasswordsMatch(true)
    } else if (pass !== confirmPass) {
      setPasswordStatusMessage("Las contraseñas no coinciden");
      setPasswordsMatch(false)
    } else {
      setPasswordStatusMessage(""); // resetear el mensaje si ambos campos están vacíos
    }
  };

  const doForgot = (data) => {
    fetch(URL + "auth/password/reset/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Otros headers si los necesitas, como tokens de autenticación, etc.
      },
      body: JSON.stringify(data)  // Suponiendo que 'data' contiene el email del usuario, por ejemplo: { email: "usuario@example.com" }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
        return response.json();
      })
      .then(data => {
        if (data.detail === 'Password reset e-mail has been sent.') {
          toast("info", "Hemos enviado un correo a tu dirección de correo con las instrucciones para restablecer tu contraseña. Recuerda revisar tu carpeta de spam");
        } else {
          toast("error", data.message || "Error al enviar el correo.");
        }
      })
      .catch(error => {
        toast("error", "Ha ocurrido un error al tratar de comunicarse con el servidor.");
      });
  };

  const Bottom = styled("div", {
    display: 'flex',
    justifyContent: 'space-between',
  })

  const doResetPassword = (data) => {

    data.token = resetToken
    data.uid = uid

    // Lógica para cambiar la contraseña utilizando el resetToken y los campos de contraseña y confirmación.
    // Deberás hacer una solicitud POST similar a la de doForgot pero al endpoint adecuado y con los datos de las contraseñas.
    fetch(URL + "auth/password/reset/confirm/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Otros headers si los necesitas, como tokens de autenticación, etc.
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.detail === "Password has been reset with the new password.") {
          toast("info", "Contraseña reestablecida, redirigiendo ...");
          // Delay the navigation by 2 seconds
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else if (Array.isArray(data) && data.includes("The password is too similar to the first name.")) {
          // Handle the specific error message
          toast("error", "La contraseña es demasiado similar al nombre.");
        } else if (Array.isArray(data) && data.includes("This password is too short. It must contain at least 8 characters.")) {
          // Handle the specific error message
          toast("error", "La contraseña es demasiado corta. Debe contener al menos 8 caracteres.");
        } else if (Array.isArray(data) && data.includes("This password is too common.")) {
          // Handle the specific error message
          toast("error", "La contraseña es demasiado común.");
        } else if (Array.isArray(data) && data.includes("This password is entirely numeric.")) {
          // Handle the specific error message
          toast("error", "La contraseña es completamente numérica.");
        } else {
          // Display the data.detail in the toast
          toast("error", `Error: ${data.new_password2 || "No fue posible cambiar la contraseña verifica por favor."}`);

        }
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordsMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordsMatch(password, e.target.value);
  };

  return (

    <>
      <img src={logo} alt="" className={styles.logo} />
      <Toaster position="top-right" />
      <StyledForgot>
        <StyledContainerForgot>
          <div>
            <h2>Recuperar contraseña</h2>
            <p>
              {resetToken ?
                "Por favor, introduce tu nueva contraseña" :
                "Te enviaremos un correo de recuperación. Recuerda revisar tu spam"}
            </p>
          </div>
          {resetToken ? (
            // Si estamos en modo de restablecimiento de contraseña:
            <>
              <Input
                type={showPassword ? "text" : "password"}
                label="Nueva Contraseña"
                name="new_password1"
                register={register}
                animated={false}
                placeholder="Introduce nueva contraseña"
                required
                autoComplete="off"
                onChange={handlePasswordChange}
              />

              <Input
                type={showPassword ? "text" : "password"}
                label="Confirmar Contraseña"
                name="new_password2"
                register={register}
                animated={false}
                placeholder="Confirma tu contraseña"
                required
                autoComplete="off"
                onChange={handleConfirmPasswordChange}
              />
              <Input
                label="Token de Seguridad"
                name="token"
                animated={false}
                required
                autoComplete="off"
                value={resetToken}
                readonly
              />
              {!passwordsMatch && <p style={{ color: 'red' }}>Las contraseñas no coinciden</p>}

              <Bottom>
                <Link to="/">
                  <Button
                    bgColor="transparent"
                    text={<p>Regresar a Iniciar sesión</p>}
                    textColor="primary_200"
                    startIcon={{ name: "ArrowBack", color: "#5E6EAB" }}
                  />
                </Link>
                <Button
                  handler={handleSubmit(doResetPassword)}
                  bgColor="secondary_300"
                  text="Cambiar contraseña"
                  textColor="white"
                />
              </Bottom>
            </>
          ) : (
            // Si estamos en modo de solicitud de restablecimiento de contraseña:
            <>
              <Input
                label="Correo"
                name="email"
                register={register}
                animated={false}
                placeholder="Ingresar al correo"
                required
              />
              <Bottom>
                <Link to="/">
                  <Button
                    bgColor="transparent"
                    text={<p>Regresar a Iniciar sesión</p>}
                    textColor="primary_200"
                    startIcon={{ name: "ArrowBack", color: "#5E6EAB" }}
                  />
                </Link>
                <Button
                  handler={handleSubmit(doForgot)}
                  bgColor="primary_300"
                  text="Siguiente"
                  textColor="white"
                />
              </Bottom>
            </>

          )}

        </StyledContainerForgot>
      </StyledForgot>
    </>
  );
}

export default ForgotPassword;
