import React, { useEffect, useState } from 'react';
import { styled } from "@nextui-org/react";
import theme from "../../../theme";
import toast from '../../../components/Toast/ToastTypes';
import { useSearchParams } from 'react-router-dom';
import { getQuotation } from '../../../services/quotation.services';
import { useSelector } from 'react-redux';
import { getLoadTypes } from '../../../services/load.services';
import { Accordion, Card, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

// Components
import InfoLiquidation from './InfoLiquidation/InfoLiquidation';
import CostsLiquidation from './CostsLiquidation/CostsLiquidation';
import Costs from './CostsLiquidation/Costs';
import { getStates } from '../../../services/states.services';

const QuoteDetail = ({ service }) => {
    const token = useSelector((state) => state.user.token);
    const [searchParams] = useSearchParams()
    const [activeKey, setActiveKey] = useState(null);
    const handleAccordionClick = (eventKey) => {
        if (activeKey === eventKey) {
            setActiveKey(null);
        } else {
            setActiveKey(eventKey);
        }
    };

    const [detail, setDetail] = useState({})
    const [loadTypes, setLoadTypes] = useState([])
    const [states, setStates] = useState([])
    const [state, setState] = useState('')

    const StyledQuoteDetail = styled("article", {
        // [`.${theme} &`]: {
        //     padding: '3rem 6.5rem',
        //     display: 'flex',
        //     section: {
        //         backgroundColor: '$neutral_50',
        //         width: '50%',
        //         borderRadius: '8px',
        //         padding: '2rem'
        //     },
        //     aside: {
        //         width: '50%',
        //         padding: '2rem 0rem 2rem 5rem'
        //     },
        // }
    });

    const formatDateTime = (date) => {
        return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
    }

    const setData = (data, _loadTypes) => {
        setState(data.state)
        setDetail({
            initial: [
                {
                    name: 'Tipo de cotización',
                    info: data.loads[0].load_type
                },
                {
                    name: 'Fecha y hora',
                    info: formatDateTime(new Date(data.created_at))
                },
                {
                    name: 'Fecha de vencimiento',
                    info: formatDateTime(new Date(data.expiration_date))
                },
                {
                    name: 'Tiempo de Transito',
                    info: "15 dias"
                    /*se debe dar de la cotizacion el tiempo de transito*/
                },
            ],
            commerce: [
                {
                    name: 'Tipo de comercio',
                    info: data.business_type // Change to spanish
                },
                {
                    name: 'Industria',
                    info: data.industry
                },
                {
                    name: 'Términos de negociación',
                    info: data.business_terms
                },
                {
                    name: 'Envío',
                    info: data.shipping.shipping_method // Change to spanish
                },
            ],
            sourceData: [
                {
                    name: 'País de origen',
                    info: data.shipping.origin_country
                },
                {
                    name: 'Dirección',
                    info: data.shipping.pickup_address
                },
                {
                    name: 'Embarque',
                    info: data.shipping.origin_boarding_terminal
                },
                {
                    name: 'Comentario adicional',
                    info: data.shipping.pickup_comments
                },
            ],
            destinationData: [
                {
                    name: 'País de destino',
                    info: data.shipping.destination_country
                },
                {
                    name: 'Dirección',
                    info: data.shipping.delivery_address
                },
                {
                    name: 'Desembarque',
                    info: data.shipping.destination_boarding_terminal
                },
                {
                    name: 'Comentario adicional',
                    info: data.shipping.delivery_comments
                },
            ],
            loadingInformation: data.loads.map(load => {
                return [
                    {
                        name: 'Nombre de la carga',
                        info: load.name
                    },
                    {
                        name: 'Valor de la carga',
                        info: 'U$ ' + data.loads[0].value/* new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(load.value) */
                    },
                    {
                        name: 'Alto',
                        info: load.height + ' ' + load.height_unit
                    },
                    {
                        name: 'Ancho',
                        info: load.width + ' ' + load.width_unit
                    },
                    {
                        name: 'Largo',
                        info: load.long + ' ' + load.long_unit
                    },
                    {
                        name: 'Cantidad',
                        info: load.amount + ' uni'
                    },
                    {
                        name: 'Peso por pieza',
                        info: load.unit_weight + ' ' + load.unit_weight_item
                    },
                    {
                        name: 'Peso total',
                        info: load.total_weight + ' ' + load.total_weight_unit
                    },
                    {
                        name: 'Seguro',
                        info: load.insurance ? 'Si' : 'No'
                    }
                ]
            }).flat(),
            additionalInformation:
                data.loads[0]?.load_type === _loadTypes[0]?.name ?
                    [
                        {
                            name: 'Temperatura',
                            info: data.options.temperature_control ? 'Si' : 'No'
                        },
                        {
                            name: 'Data logger',
                            info: data.options.data_logger ? 'Si' : 'No'
                        },
                        {
                            name: 'Carga remontable',
                            info: data.options.remountable ? 'Si' : 'No'
                        },
                        {
                            name: 'Posición arancelaria',
                            info: data.options.arancelary_position ? 'Si' : 'No'
                        },
                        {
                            name: 'Especificación adicional',
                            info: data.options.additional_specifications
                        },
                    ]
                    : data.loads[0].load_type === _loadTypes[1].name &&
                    [
                        {
                            name: 'Estudio de ruta',
                            info: data.options.route_study ? 'Si' : 'No'
                        },
                        {
                            name: 'Análisis de seguridad',
                            info: data.options.security_analysis ? 'Si' : 'No'
                        },
                        {
                            name: 'Certificación de trincado',
                            info: data.options.lashing_survey ? 'Si' : 'No'
                        },
                        {
                            name: 'Trabajo con comunidades',
                            info: data.options.work_communities ? 'Si' : 'No'
                        },
                        {
                            name: 'Especificación adicional',
                            info: data.options.additional_specifications
                        },
                    ]/* ,
            // Continuación del código anterior
            relevantInformation: [
                {
                    subtitle: 'Información relevante por industria',
                    description: 'Ninguna'
                },
                {
                    subtitle: 'Información relevante por tráfico',
                    description: 'Ninguna'
                },
            ],
            departuresItineraries: [
                'Ninguna'
            ] */
        })
    }

    useEffect(() => {
        getLoadTypes(token)
            .then(async res => {
                const data = await res.json();
                const _loadTypes = data
                setLoadTypes(data)

                if (service && Object.keys(service).length > 0) {
                    setData(service, _loadTypes)
                } else {
                    getQuotation(searchParams.get('radication'), token)
                        .then(async res => {
                            const data = await res.json();
                            setData(data, _loadTypes);
                        })
                }
            })
    }, [service]);

    useEffect(() => {
        if (searchParams.get('created') === 'quotation') {
            setTimeout(() => {
                toast('success', '¡Se ha creado con éxito tu cotización!')
            }, 1000)
        }


        getStates(token)
            .then(res => res.json())
            .then(data => {
                setStates(data)
            })
    }, []);

    return (
        <StyledQuoteDetail>
            {/* <InfoLiquidation detail={detail} radication={searchParams.get('radication')} loadTypes={loadTypes}></InfoLiquidation> */}
            <Costs radication={searchParams.get('radication')} service={service}></Costs>
            {/* {
                searchParams.get('finished') == "Si"?
                <Costs radication={searchParams.get('radication')} service={service}></Costs>:
                <CostsLiquidation detail={detail} radication={searchParams.get('radication')} state={state} loadTypes={loadTypes} states={states}></CostsLiquidation>
            } */}
        </StyledQuoteDetail>
    );
}

export default QuoteDetail;
