import React , {useEffect, useState} from 'react'
import src from '../../../../assets/sendMethods/aerial.jpg'
import { styled, Loading, Modal } from '@nextui-org/react'
import Button from '../../../../components/Button/Button'
import { approveQuotation } from '../../../../services/quotations.services'
import toast from '../../../../components/Toast/ToastTypes';
import { getPreliquidations, getServices } from '../../../../services/preliquidation.services'
import { useSelector } from 'react-redux'

const Info = styled("div",{
    display:'flex',
    flexDirection:'column',
    padding:'20px',
})

const StyledModal = styled(Modal,{
    display:'flex',
    padding :'20px',
    color:'$primary_500 !important',
    background:'$neutral_50',
})

export default function OfferCard({clientChose, data, getData}) {

    const Container = styled("div",{
        dislpay:'flex',
        flexDirection:'column',
        overflow:'hidden',
        background:'white',
        borderRadius:'8px',
        padding:'0px',
        maxWidth:data.suggested?'260px':'250px',
        boxShadow:'$elevation_1',
        transition:'300ms',
        ['.footer']:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            marginTop:'20px',
        },
        ['&:hover']:{
            cursor:'pointer',
            transform:'scale(1.02)',
            boxShadow:'$elevation_2',
        }
    })

    const token = useSelector(state => state.user.token)
    const [preliquidations, setPreliquidations] = useState([])
    const [openModal,setOpenModal] = useState(false)
    const [openRejectModal,setOpenRejectModal] = useState(false)

    const handleApprove = (id) => {
        approveQuotation(id, true).then(()=>{
            setOpenModal(false)
            toast('success', 'Cotizacion aprobada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo aprobar')
        })
    }
    const handleReject = (id) => {
        approveQuotation(id, false).then(()=>{
            setOpenRejectModal(false)
            toast('success', 'Cotizacion rechazada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo rechazar')
        })
    }

    useEffect(()=>{
        Promise.all([ getPreliquidations(token) , getServices(token)]).then(async res => {
            const preliquidationsData = await res[0].json();
            const servicesData = await res[1].json();
            const filteredPrels = preliquidationsData.filter(prel => data.preliquidation_ids.includes(prel.id))
            
            const formattedPrels = filteredPrels.map((prel)=>{
                return {
                    name: servicesData.find(service => service.id === prel.service)?.name,
                    cost: prel.sale_rate.rate_number * prel.unit / prel.sale_rate.trm,
                }
            })
            setPreliquidations(formattedPrels)
        })
        
    },[])
    return (
    <div>
        {data && <Container onClick={() => {setOpenModal(true)}}>
            <img src={src} alt="card-image" />
            <Info>
                <p>Opción {data?.option}</p>
                <h2>{data?.cost}</h2>
                <p>{data.customer_approval && <span style={{color:'green'}}>Ya elegiste esta opción</span>}</p>
                <p>{data.suggested && <span style={{color:'green'}}>Opción recomendada</span>}</p>
                <div>
                    {
                        preliquidations.map(prel=>{
                            return <>
                                <p style={{fontSize:'10px', color:'grey'}}>{prel.name}  ${prel.cost?.toFixed(2)}</p>
                            </>
                        })
                    }
                </div>
                <div className='footer'>
                    {/* <Button handler={() => setOpenRejectModal(true)} startIcon={{name:'CloseModal', color:'white'}} bgColor='delete' size={'sm'} textColor='neutral_50'/> */}
                    <Button disabled={clientChose} text={'Elegir y confirmar'} handler={() => setOpenModal(true)} startIcon={{name:'CheckModal', color:'white'}} bgColor='excel' size={'sm'} textColor='neutral_50'/>
                </div>
            </Info>
        </Container>}

        <StyledModal 
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeButton
            aria-label="modal-title"
            width="fit-content"
        >
            <h3 style={{margin:'20px'}}>Confirma que desea elegir la opcion {data.option}</h3>
            <Modal.Footer>
                <Button text="Cancelar" outline handler={()=>{setOpenModal(false)}}/>
                <Button text="Confirmar" handler={() => handleApprove(data.id)} startIcon={{name:'CheckModal', color:'white'}} bgColor='excel' textColor='neutral_50'/>
            </Modal.Footer>
        </StyledModal>

        <StyledModal 
            open={openRejectModal}
            onClose={() => setOpenRejectModal(false)}
            closeButton
            aria-label="modal-title"
            width="fit-content"
        >
            <h3 style={{margin:'20px'}}>Confirma que desea rechazar la opcion {data.option}</h3>
            <Modal.Footer>
                <Button text="Cancelar" bold outline handler={()=>{setOpenRejectModal(false)}}/>
                <Button text="Confirmar" textColor={'neutral_50'} bold outline bgColor={'delete'} handler={() => handleReject(data.id)}/>
            </Modal.Footer>
        </StyledModal>
    </div>
  )
}
