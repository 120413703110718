import React from "react";
import "./App.css";
import { connect } from "react-redux";
import globalStyles from "./globalStyles";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "sonner";
import { ROLES } from "./constants";
import useUserRoles from "./hooks/useUserRoles";

// ... (import statements)
import Missing from "./pages/Missing/Missing";
import Login from "./pages/Login/Login";
import LoginUrl from "./pages/LoginUrl/LoginUrl";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MainSidebar from "./pages/Layout/MainSidebar/MainSidebar";
import Home from "./pages/Home/Home";
import Navbar from "./pages/Layout/Navbar/Navbar";
import Quotation from "./pages/Quotes/Quotation/Quotation";
import QuoteDetail from "./pages/Quotes/QuotationDetail/QuoteDetail";
import NewQuotation from "./pages/Quotes/NewQuotation/NewQuotation";
import ManageQuotes from "./pages/ManageQuotes/Index";
import ViewCO2 from "./pages/CO2/ViewCO2";
import ValGpt from "./pages/ValGpt/ValGpt";
import Register from "./pages/Register/Register";
import Tools from "./pages/Tools/Tools";
import Services from "./pages/Services/Services";
import History from "./pages/History/History";
import Tracking from "./pages/Tracking/Tracking";
import Support from "./pages/Support/Support";
import Profile from "./pages/Profile/Profile";
import ProfileForms from "./pages/Profile/ProfileForms/ProfileForms";
import Admin from "./pages/Admin/Admin";
import Calculator from "./pages/Calculator/Calculator";
import ServiceChat from "./pages/Services/ServiceDetail/ServiceChat/ServiceChat";
import ServiceLiquidation from "./pages/Services/ServiceDetail/ServiceLiquidation/ServiceLiquidation";
import RoleBasedRoute from "./HOCs/RoleBasedRoute";
import Tariff from './pages/Dian/Tariff'
import AuthVerify from "./HOCs/AuthVerify";
import RenderDocument from "./pages/Profile/Documents/RenderDocument";
import TrackingResults from "./pages/Tracking/TrackingResults";
import OperationsTracking from "./pages/OperationsTracking/OperationsTracking";
import ExportTutorial from "./pages/Tools/ExportTutorial";
import QuotationTutorial from "./pages/Tools/QuotationTutorial";
import ExportToChina from "./pages/Tools/ExportToChina";
import CO2Dashboard from "./pages/CO2Dashboard/CO2Dashboard";
import EditQuotation from "./pages/Quotes/EditQuotation/EditQuotation";
import CO2Persons from "./pages/CO2Persons/CO2Persons";
import Payments from "./pages/CO2History/CO2History";
import Calculate from "./pages/CO2Persons/CalculateCO2/Calculate";
import PreliquidationDetails from "./pages/ManageQuotes/PreLiquidation/PreliquidationDetails";
import Co2Tutorial from "./pages/Tools/Co2Tutorial";
import ServiceTutorial from "./pages/Tools/ServiceTutorial";

import { Co2 } from "@mui/icons-material";
import QuoteDetails from "./pages/ManageQuotes/quoteDetails";

function App({ loggedIn, token }) {

  const userRoles = useUserRoles()
  globalStyles(); // Global styles are being invoked here.
  if (!loggedIn) {
    return (
      // ... (Login routes)
      <>
        <Routes>
          <Route exact element={<Login />} path="/"></Route>
          <Route exact element={<LoginUrl />} path="/login"></Route>
          <Route element={<Register />} path="/register/:invitation?"></Route>
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Toaster position="top-right" />
      </>
    );
  }

  return (
    <div style={{ display: "flex", backgroundColor: "#F3F5FF" }}>
      <AuthVerify />
      <MainSidebar userRoles={userRoles}></MainSidebar>
      {
        userRoles.length > 0 &&
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            width: "calc(100% - 250px)",
            minHeight: "100vh",
          }}
        >
          <Navbar token={token} />
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Tools />} path="/tools" />
            <Route element={<ExportTutorial />} path="/tools/export" />
            <Route element={<QuotationTutorial />} path="/tools/quote" />
            <Route element={<ExportToChina />} path="/tools/export-to-china" />
            <Route element={<Co2Tutorial />} path="/tools/co2-tutorial" />
            <Route element={<ServiceTutorial />} path="/tools/service-tutorial" />
            <Route element={<QuotationTutorial />} path="/tools/quote" />
            <Route element={<Calculator />} path="/calculator" />
            <Route element={<Tariff />} path="/tariff" />
            <Route element={<Profile token={token} />} path="/profile" />
            <Route element={<RenderDocument token={token} />} path="/profile/document" />
            <Route element={<ProfileForms />} path="/profile/forms" />

            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User]} userRoles={userRoles} />}>
              <Route element={<Quotation />} path="/quotation-requests" />
              <Route element={<QuoteDetail />} path="/quotation/detail" />
              <Route element={<NewQuotation />} path="/new-quotation" />
              <Route element={<EditQuotation />} path="/quotation/edit" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Guest, ROLES.Supplier]} userRoles={userRoles} />}>
              <Route element={<Services />} path="/services" />
              <Route element={<ServiceChat />} path="/services/detail/chat" />
              <Route element={<ServiceLiquidation />} path="/services/detail/liquidation" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<ViewCO2 />} path="/co2" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<CO2Dashboard />} path="/co2/Dash" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<CO2Persons />} path="/co2/persons" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<Calculate />} path="/co2/persons/calculate" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<Payments />} path="/co2/history" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]} userRoles={userRoles} />}>
              <Route element={<ValGpt />} path="/ValGpt" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor]} userRoles={userRoles} />}>
              <Route element={<History />} path="/history" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor]} userRoles={userRoles} />}>
              <Route element={<Tracking />} path="/tracking" />
              <Route element={<TrackingResults />} path="trackingresults" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Advisor]} userRoles={userRoles} />}>
              <Route element={<Support />} path="/support" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin, ROLES.Advisor]} userRoles={userRoles} />}>
              <Route element={<Admin />} path="/backstage" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin]} userRoles={userRoles} />}>
              <Route element={<ManageQuotes />} path="/manageQuotes" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin]} userRoles={userRoles} />}>
              <Route element={<QuoteDetails />} path="/manageQuotes/quotation-details/" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin]} userRoles={userRoles} />}>
              <Route element={<PreliquidationDetails />} path="/manageQuotes/preliquidation-details/" />
            </Route>
            <Route element={<RoleBasedRoute allowedRoles={[ROLES.Admin]} userRoles={userRoles} />}>
              <Route element={<OperationsTracking />} path="/operations/tracking" />
            </Route>

            <Route path="*" element={<Missing />} />
          </Routes>
        </div>
      }
      <Toaster position="top-right" duration={5000} />
    </div>
  );
}

const mapState = ({ user: { fetching, loggedIn, token } }) => {
  return {
    fetching,
    loggedIn,
    token,
  };
};

export default connect(mapState)(App);
