import React, { useEffect } from 'react'
import { styled } from '@nextui-org/react';
import { Modal } from '@nextui-org/react';
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect';
import CreatableInputSelect from '../../../components/Select/CreatableSelect';
import InputFileCard from '../../../components/InputFileCard/InputFileCard'
import { Radio } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { useForm, Controller } from 'react-hook-form'
import { toBase64 } from '../../../services/base64.services';
import { sendEmail, getOperations, getEmails, getSubject } from '../../../services/operations.services';
import { confirmacionDeArribo, notificacionLiberacion } from './mailTemplates';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from '../../../components/Toast/ToastTypes';

const StyledContainer = styled("form", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '30px',
    gap: '15px',
    borderRadius: '8px',
    padding: '40px',
})

const StyledFormModal = styled(Modal, {
    padding: '50px',
    color: '$primary_500 !important',
    textAlign: 'left',
    width: '1000px',
})

export default function MailForm({ selectedOperation }) {

    const { control, handleSubmit, setValue, reset,getValues,  watch } = useForm({
        defaultValues: {
            print: 'active-registration',
            receiver_type: 'client',
            language: 'ES',
        }
    });

    const [body, setBody] = useState('')
    const [radication, setRadication] = useState()
    const [emails, setEmails] = useState()
    const [selectedEmails, setSelectedEmails] = useState()

    const token = useSelector(state => state.user.token)

    const handleFile = async (e, name) => {
        if (e?.target?.files.length > 0) {
            const file = await toBase64(e.target.files[0])
            setValue(name, file)
        } else {
            setValue(name, '')
        }
    }

    const onSubmit = (formData) => {
        let submitData = formData;
        submitData.body = body;
        submitData.radication = radication;

        submitData.to = selectedEmails.map(mail => { return mail.value })
        submitData.cc = formData.cc?.map(e => { return e.value })
        submitData.bcc = formData.bcc?.map(e => { return e.value })

        if (submitData.cc?.length < 1) { submitData.cc = undefined }
        if (submitData.bcc?.length < 1) { submitData.bcc = undefined }

        sendEmail(submitData, token).then(async res => {
            reset({})
            setSelectedEmails("")
            setBody("")
            toast('success', 'Correo enviado')
        }).catch(() => toast('error', 'Ha habido un error'))
    }
    useEffect(() => {
        getOperations({ token, id: selectedOperation }).then(async res => {
            const operation = await res.json();
            setRadication(operation.radication)

            getSubject(token, operation.radication).then(async res => {
                const subject = await res.json()
                setValue("subject", subject.subject)
            })

            getEmails(token, radication).then(async res => {
                const emailsData = await res.json()
                const recipients = []
                emailsData.forEach(email => {
                    if (!recipients.includes(email.recipient)) {
                        recipients.push(email.recipient)
                    }
                })
                setEmails(recipients)
                setSelectedEmails(recipients.map(recipient => {
                    return {
                        label: recipient,
                        value: recipient,
                    }
                }))
            })
        })
    }, [selectedOperation])

    return (
        <StyledContainer onSubmit={handleSubmit(onSubmit)}>
            <div style={{ 'gridColumn': 'span 2', display: 'flex', justifyContent: 'space-around' }}>
                <div>
                    <label>Que desea imprimir :</label>
                    <Controller
                        name="print"
                        control={control}
                        render={({ field }) => (
                            <Radio.Group {...field} size="sm" orientation="horizontal" defaultValue='active-registration'>
                                <Radio value="active-registration"><label style={{ color: '#292E68' }}>Registro activo</label></Radio>
                                <Radio value="consolidated"><label style={{ color: '#292E68' }}>Todos consolidado</label></Radio>
                            </Radio.Group>
                        )}
                    />
                </div>
                {/* <div>
                    <label>Seleccionar destinatario:</label>
                    <Controller
                        name="receiver_type"
                        control={control}
                        render={({ field }) => (
                            <Radio.Group {...field} size="sm" orientation="horizontal" defaultValue='client'>
                                <Radio value="client"><label style={{ color: '#292E68' }}>Cliente</label></Radio>
                                <Radio value="agent"><label style={{ color: '#292E68' }}>Agente</label></Radio>
                                <Radio value="other"><label style={{ color: '#292E68' }}>Tercero</label></Radio>
                            </Radio.Group>
                        )}
                    />
                </div>
                <div>
                    <label>Idioma del reporte:</label>
                    <Controller
                        name="language"
                        control={control}
                        render={({ field }) => (
                            <Radio.Group {...field} size="sm" orientation="horizontal" defaultValue='ES'>
                                <Radio value="ES"><label style={{ color: '#292E68' }}>Español</label></Radio>
                                <Radio value="EN"><label style={{ color: '#292E68' }}>Ingles</label></Radio>
                            </Radio.Group>
                        )}
                    />
                </div> */}
            </div>
            <div style={{ 'gridColumn': 'span 2', display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Destinatarios :</label>
                <Controller
                    name="to"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            value={selectedEmails}
                            onChange={e => setSelectedEmails(e)}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Copia correo :</label>
                <Controller
                    name="cc"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Copia oculta :</label>
                <Controller
                    name="bcc"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Asunto :</label>
                <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                        <Input {...field} />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px' }}><b>Seleccione la plantilla :</b></label>
                <InputSelect options={[
                    { label: 'Ninguno', value: '' },
                    { label: 'Confirmacion de arribo', value: confirmacionDeArribo },
                    { label: 'Confirmacion liberacion', value: notificacionLiberacion },
                ]}
                    disableClear={true}
                    onChange={e => setBody(e.value)}
                />
            </div>
            <div style={{ 'gridColumn': 'span 2' }}>
                <TextEditor state={body} setState={setBody} />
            </div>
            <div style={{ 'gridColumn': 'span 2', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <label style={{ fontWeight: 'bold' }}>Adjuntos :</label>
                <InputFileCard name="attached" label='Sube o arrastra' onChange={handleFile} startIcon={{ name: 'Upload', color: '#FFF' }} width={'100%'} aspectRatio={'6/1'} />
                <Button 
                disabled={!selectedOperation ||  !getValues('subject') || getValues('selectedEmails')?.length<1}
                text='Enviar correo'
                type="submit"
                bold
                bgColor={'primary_300'}
                textColor='neutral_50' />
            </div>
            {!selectedOperation && <label>No hay una operacion seleccionada(!)</label>}
            {/* <StyledFormModal
            width="70%"
            aria-label="modal-title"
            open={openPreview}
            onClose={() => setOpenPreview(false)}
        >
            <div dangerouslySetInnerHTML={{ __html: watch()?.template?.value }} />
        </StyledFormModal> */}
        </StyledContainer>
    )
}
