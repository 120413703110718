import React, { useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';
import theme from '../../../theme';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { logOutAction } from "../../../store/sessionDuck";
import { ROLES } from '../../../constants';

import { getMessaging, getToken, onMessage } from "firebase/messaging";
// Componets
import Icon from '../../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

function MainSidebar({ logOutAction, userRoles }) {

    const { collapseSidebar, collapsed } = useProSidebar();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const radication = searchParams.get('radication');

    const { t } = useTranslation("Sidebar")
    const token = useSelector(state => state.user.token)

    useEffect(() => {
        // console.log('token :',token)
        // console.log('userRoles :',userRoles)
        if (!token) {
            logOutAction()
        }
    }, [token])

    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }

    return (
        <Sidebar rootStyles={{
            display: "flex",
            maxWidth: 250,
            borderWidth: "0rem",
            [`.${sidebarClasses.container}`]: {
                padding: !collapsed ? '1rem 2rem' : "1rem 1rem",
                backgroundColor: "white",
                height: "100vh",
                width: collapsed ? 80 : 250,
                borderRadius: '0px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                top: '0',
                left: '0',
                transition: 'width 0.3s'
            },
            [`.headerLogo`]: {
                display: "flex",
                flex: '0 0 auto',
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
                marginBottom: "2rem",
                marginTop: '1rem',
                [`.logo`]: {
                    display: collapsed ? 'none' : "inherit"
                },
                [`.collapseButton`]: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                    boxShadow: "0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12)",
                    borderRadius: "2rem",
                    padding: "8px",
                    width: "24px",
                    height: "24px",
                    transform: collapsed ? "rotate(180deg)" : "initial",
                    cursor: "pointer"
                }
            }
        }}>
            <div className='headerLogo'>
                <img src="/logo_sidebar.svg" className='logo' alt="" />
                <div className="collapseButton" onClick={() => collapseSidebar()} >
                    <img src="/sidebar_collapse_arrow.svg" alt="" width="6px" height="11px" />
                </div>
            </div>

            {
                userRoles.length > 0 &&
                <>
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: active ? '#FFFFFF' : '#292E68',
                                    backgroundColor: active ? '#3D509A' : undefined,
                                    borderRadius: "8px",
                                    ":hover": {
                                        background: active ? '#3D509A' : "#F3F5FF"
                                    },
                                    padding: 7
                                };
                            if (level === 1)
                                return {
                                    color: active ? '#FFFFFF' : '#292E68',
                                    backgroundColor: active ? '#EB9D3A' : undefined,
                                    borderRadius: "8px",
                                    ":hover": {
                                        background: active ? '#EB9D3A' : "#F3F5FF"
                                    },
                                    padding: 7
                                };
                        }
                    }} style={{ flex: '1 1 0%', marginBottom: '32px' }}>
                        <MenuItem
                            icon={
                                <Icon name="Diagram" color={location.pathname == "/" ? "white" : theme.colors.primary_500} />
                            }
                            active={location.pathname == "/"}
                            component={<Link to="/" />}
                        >
                            {t('home')}
                        </MenuItem>
                        {
                            havePermissions([ROLES.Admin, ROLES.User]) &&
                            <SubMenu
                                label={t('quotes')}
                                icon={<Icon name="Cotizaciones" color={location.pathname == "/quotation" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/quotation" && havePermissions([ROLES.Admin])}
                                component={<Link to="/quotation-requests" />}
                                rootStyles={{
                                    'ul': {
                                        backgroundColor: '#F3F5FF',
                                        borderRadius: '0px 0px 8px 8px',
                                    },
                                    'li': {
                                        marginTop: '0.625rem',
                                        marginBottom: '0rem',
                                    },
                                    '.ps-submenu-expand-icon': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {
                                    havePermissions([ROLES.Admin, ROLES.Advisor]) &&
                                    <MenuItem
                                        icon={<Icon name="Manage" />}
                                        active={location.pathname == "/manageQuotes"}
                                        component={<Link to="/manageQuotes" />}
                                    >
                                        {t("liquidation")}
                                    </MenuItem>
                                }
                            </SubMenu>
                        }
                        {
                            havePermissions([ROLES.Admin]) &&
                            <SubMenu
                                label={t('operations')}
                                icon={<Icon name="Operations" size='50px' />}
                                // active={location.pathname == "/quotation" && havePermissions([ROLES.Admin])}
                                // component={<Link to="/quotation" />}
                                rootStyles={{
                                    'ul': {
                                        backgroundColor: '#F3F5FF',
                                        borderRadius: '0px 0px 8px 8px',
                                    },
                                    'li': {
                                        marginTop: '0.625rem',
                                        marginBottom: '0rem',
                                    },
                                    '.ps-submenu-expand-icon': {
                                        display: 'none'
                                    }
                                }}
                            >
                                <MenuItem
                                    icon={<Icon name="Tracking" size="30px" />}
                                    active={location.pathname == "/operations/tracking"}
                                    component={<Link to="/operations/tracking" />}
                                >
                                    {t("operationsTracking")}
                                </MenuItem>
                            </SubMenu>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Guest, ROLES.Supplier]) &&
                            <SubMenu
                                label={t('services')}
                                icon={<Icon name="Servicios" color={location.pathname == "/services" || location.pathname.startsWith('/services/detail') ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/services" || location.pathname.startsWith('/services/detail')}
                                component={<Link to="/services" />}
                                open={location.pathname.startsWith('/services/detail')}
                                rootStyles={{
                                    'ul': {
                                        backgroundColor: '#F3F5FF',
                                        borderRadius: '0px 0px 8px 8px',
                                    },
                                    'li': {
                                        marginTop: '0.625rem',
                                        marginBottom: '0rem',
                                    },
                                    '.ps-submenu-expand-icon': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {!collapsed &&
                                    <MenuItem
                                        icon={<Icon name="Mail" color={location.pathname == "/services/detail/chat" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/services/detail/chat/"}
                                        component={<Link to={`/services/detail/chat/?radication=${radication}&tab=chat`} />}
                                    >
                                        Chat
                                    </MenuItem>}
                                {!collapsed && havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor]) &&
                                    <MenuItem
                                        icon={<Icon name="File" color={location.pathname == "/services/detail/liquidation" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/services/detail/liquidation/"}
                                        component={<Link to={`/services/detail/liquidation/?radication=${radication}`} />}
                                    >
                                        {t("liquidation")}
                                    </MenuItem>}
                            </SubMenu>
                        }

                        {
                            //    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor]) &&
                            //  <MenuItem
                            //    icon={<Icon name="4pl" color={location.pathname == "/4pl" ? "white" : theme.colors.primary_500} />}
                            //     active={location.pathname == "/4pl"}
                            //     component={<Link to="/4pl" />}
                            //  >
                            //</Menu>      4PL
                            //   </MenuItem>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.Advisor, ROLES.User]) &&
                            <MenuItem
                                icon={<Icon name="Technology" color={location.pathname == "/ValGpt" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/ValGpt"}
                                component={<Link to="/ValGpt" />}
                            >
                                GPT
                            </MenuItem>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]) &&
                            <SubMenu
                                rootStyles={{
                                    ['.ps-menu-label']: {
                                        marginTop: '5px'
                                    }
                                }}
                                label='CO2'
                                icon={<Icon name="Tree" color={theme.colors.primary_500} />}
                            // active={location.pathname == "/co2"}
                            >
                                {
                                    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]) &&
                                    <MenuItem
                                        rootStyles={{
                                            ['.ps-menu-label']: {
                                                marginTop: '5px'
                                            }
                                        }}
                                        icon={<Icon name="Calculator" color={location.pathname == "/co2" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/co2"}
                                        component={<Link to="/co2" />}
                                    >
                                        <span>Calculadora</span>
                                    </MenuItem>
                                }

                                {
                                    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]) &&
                                    <MenuItem
                                        rootStyles={{
                                            ['.ps-menu-label']: {
                                                marginTop: '5px'
                                            }
                                        }}
                                        icon={<Icon name="Diagram" color={location.pathname == "/co2/Dash" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/co2/Dash"}
                                        component={<Link to="/co2/Dash" />}
                                    >
                                        <span>Dashboard</span>
                                    </MenuItem>
                                }
                                {
                                    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]) &&
                                    <MenuItem
                                        rootStyles={{
                                            ['.ps-menu-label']: {
                                                marginTop: '5px'
                                            }
                                        }}
                                        icon={<Icon name="TreePerson" color={location.pathname == "/co2/persons" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/co2/persons"}
                                        component={<Link to="/co2/persons" />}
                                    >
                                        <span>Personal</span>
                                    </MenuItem>
                                }
                                {
                                    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor, ROLES.Co2]) &&
                                    <MenuItem
                                        rootStyles={{
                                            ['.ps-menu-label']: {
                                                marginTop: '5px'
                                            }
                                        }}
                                        icon={<Icon name="Historial" color={location.pathname == "/co2/history" ? "white" : theme.colors.primary_500} />}
                                        active={location.pathname == "/co2/history"}
                                        component={<Link to="/co2/history" />}
                                    >
                                        <span>Historial</span>
                                    </MenuItem>
                                }
                            </SubMenu>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.Advisor]) &&
                            <MenuItem
                                icon={<Icon name="Rastreo" color={location.pathname == "/tracking" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/tracking"}
                                component={<Link to="/tracking" />}
                            >
                                {t("tracking")}
                            </MenuItem>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor]) &&
                            <MenuItem
                                icon={<Icon name="Historial" color={location.pathname == "/history" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/history"}
                                component={<Link to="/history" />}
                            >
                                {t("history")}
                            </MenuItem>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor]) &&
                            <MenuItem
                                icon={<Icon name="Soporte" color={location.pathname == "/support" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/support"}
                                component={<Link to="/support" />}
                            >
                                {t("support")}
                            </MenuItem>
                        }
                        {
                            havePermissions([ROLES.Admin, ROLES.Advisor]) &&
                            <MenuItem
                                icon={<Icon name="Dashboard" color={location.pathname == "/backstage" ? "white" : theme.colors.primary_500} />}
                                active={location.pathname == "/backstage"}
                                component={<Link to="/backstage/?tab=dashboard" />}
                            >
                                {t("management")}
                            </MenuItem>
                        }
                    </Menu>

                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    borderRadius: "0px 0px 8px 8px",
                                    padding: 7
                                };
                        }
                    }} style={{ borderTop: 'solid 1px #DFE2E6' }}>
                        <MenuItem
                            icon={<Icon name="Exit" />}
                            active={location.pathname == "/"}
                            component={<Link to="/" />}
                            onClick={logOutAction}
                        >
                            {t("log-out")}
                        </MenuItem>
                    </Menu>


                </>


            }

        </Sidebar>
    );
}

const mapState = ({ }) => {
    return {};
};

export default connect(mapState, { logOutAction })(MainSidebar);