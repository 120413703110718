

import { useState } from 'react';
import { styled } from '@nextui-org/react';
import PreLiquidationTable from './PreLiquidationTable';
import Button from '../../../components/Button/Button';


import PreliquidationFormModal from './PreliquidationFormModal';
import PreliquidationCharacteristicsModal from './PreliquidationCharacteristicsModal';
import PriceListModal from './PriceListModal';
import { getQuoteData } from '../../../services/quotations.services';
import { getPreliquidations, getProviders, getServiceGroups, getServices } from '../../../services/preliquidation.services';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function PreLiquidation({ currentQuote }) {

    const [openModalParticipants, setOpenModalParticipants] = useState(false)
    const [openCharacteristicsModal, setOpenCharacteristicsModal] = useState(false)
    const [openPriceListModal, setOpenPriceListModal] = useState(false)

    const [currentQuoteData, setCurrentQuoteData] = useState('')
    const [selectedPreliquidation, setSelectedPreliquidation] = useState('')
    const [tableData, setTableData] = useState([])

    const token = useSelector(state => state.user.token)

    const StyledDiv = styled('div', {
        bgColor: '$neutral_50',
        borderRadius: '8px',
        padding: '15px'
    })

    useEffect(() => {
        if (!openModalParticipants) {
            getPreliquidations(token).then(async res => {

                const asyncTasks = [getProviders(token), getServiceGroups(token), getServices(token), getQuoteData(token, currentQuote)]
                Promise.all(asyncTasks).then(async responses => {
                    const providersData = await responses[0].json();
                    const serviceGroupsData = await responses[1].json();
                    const servicesData = await responses[2].json();
                    const currentQuoteData = await responses[3].json();

                    setCurrentQuoteData(currentQuoteData)
                    const data = await res.json();
                    const formatted = data?.map((prel) => {
                        const profit = prel.unit * (prel.sale_rate?.rate_number / prel.sale_rate?.trm - prel.purchase_rate?.rate_number / prel.purchase_rate?.trm)

                        return {
                            id: prel.id,
                            provider: providersData.find(provider => provider.id === prel.provider)?.name,
                            service_group: serviceGroupsData.find(group => group.id === prel.service_group)?.name,
                            service: servicesData.find(service => service.id === prel.service)?.name,
                            unit: prel.unit,
                            sale_price: prel.sale_rate?.rate_number,
                            sale_currency: prel.sale_rate?.currency,
                            sale_trm: prel.sale_rate?.trm,
                            tax: prel.sale_rate?.tax,
                            purchase_price: prel.purchase_rate?.rate_number,
                            purchase_currency: prel.purchase_rate?.currency,
                            purchase_trm: prel.purchase_rate?.trm,
                            profit: +profit.toFixed(2),
                            // tableProfit:<p style={{color:
                            //     profit>350 ? '#228B22' : profit >50 ? '#FFC300 ' : '#CD5C5C'
                            // }}>{profit}</p>
                        }
                    })
                    if (currentQuote) {
                        const filter = formatted.filter(prel => currentQuoteData.preliquidation_ids?.includes(prel.id))
                        setTableData(filter)
                    } else {
                        setTableData([])
                    }
                })
            })
        }
    }, [openModalParticipants, currentQuote])
    return (
        <StyledDiv>
            <div style={{ display: 'flex', gap: '15px', margin: '10px' }}>
                <Button text='Agregar servicio' disabled={!currentQuote} bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { setSelectedPreliquidation(''); setOpenModalParticipants(true) }} />
                <Button text='Caracteristicas' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenCharacteristicsModal(true)} />
                <Button text='Lista de precios' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenPriceListModal(true)} />
            </div>
            <div style={{ margin: '20px' }}>
                {!currentQuoteData && <p> "Seleccione una cotizacion de la tabla ↑" </p>}
            </div>
            <PreLiquidationTable
                tableData={tableData}
                currentQuote={currentQuote}
                radication={currentQuoteData.radication}
                setSelectedPreliquidation={setSelectedPreliquidation}
                setOpenModalParticipants={setOpenModalParticipants}
            />
            <PreliquidationFormModal
                radication={currentQuoteData.radication}
                currentQuote={currentQuote}
                selectedPreliquidation={selectedPreliquidation}
                setSelectedPreliquidation={setSelectedPreliquidation}
                open={openModalParticipants}
                setOpen={setOpenModalParticipants}
            />
            <PreliquidationCharacteristicsModal
                open={openCharacteristicsModal}
                setOpen={setOpenCharacteristicsModal}
            />
            <PriceListModal
                open={openPriceListModal}
                setOpen={setOpenPriceListModal}
            />
        </StyledDiv>
    )
}
