import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import PageTitle from '../../components/PageTitle/PageTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getUserAuth } from '../../services/auth.services'
import { useNavigate, useSearchParams } from 'react-router-dom';


import ManageView from './ManageView/ManageView';

import { useTranslation } from "react-i18next";


const StyledSupport = styled('div', {
    [`.${theme} &`]: {
        position: 'relative',
        padding: '3rem 2rem',
        ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
            fontFamily: 'inherit',
            color: '$primary_300',
            textTransform: 'none'
        },
        ['.Mui-selected']: {
            fontWeight: '600'
        },
        ['.MuiTabs-indicator']: {
            backgroundColor: '$primary_300'
        }
    }
})

const Support = ({ token }) => {

    const { t } = useTranslation("Support");

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const tabs = [
        {
            value: 'all',
            label: 'Todos los tickets'
        },
        {
            value: 'open',
            label: 'Tickets Abiertos'
        },
        {
            value: 'resolved',
            label: 'Tickets Resueltos'
        },
        {
            value: 'questions',
            label: 'Preguntas'
        }
    ]
    const [tabId, setTabId] = useState(tabs.findIndex(tab => tab.value === searchParams.get('tab')));
    const [tabValue, setTabValue] = useState('all')
    const [user, setUser] = useState({})

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        navigate('/support/?tab=' + newValue)
    };

    const handleChangeIndex = (index) => {
        setTabId(index);
    };

    useEffect(() => {
        getUserAuth(token).then(({ user }) => {
            setUser(user)
        })
    }, []);

    return (
        <StyledSupport>
            <PageTitle>
                {t("title")}
            </PageTitle>
            <br></br>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="Support"
            >
                {
                    tabs.map((tab, i) =>
                        <Tab key={i} value={tab.value} label={tab.label} />
                    )
                }
            </Tabs>
            <ManageView selectedSection={tabValue} />
        </StyledSupport>
    );
}

export default Support;
