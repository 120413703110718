
import { useState } from 'react';
import { styled } from '@nextui-org/react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import TrackingResults from './TrackingResults'
import TrackingInstructions from './TrackingInstructions'
import { getTrackingDetails } from '../../services/tracking.services';
import { useSelector } from 'react-redux';
import toast from '../../components/Toast/ToastTypes';
//Libreria Traduccion
import { useTranslation } from "react-i18next";

const StyledServices = styled('article', {
    padding: '3rem 2rem',
    display: "flex",
    height: "100%",
    flexDirection: "column",
    ['.header']: {
        display: 'flex',
        gap: '20px',
        width: '860px',
    },
    ['.container']: {
        padding: '15px',
    },
});

export default function Tracking() {
    const token = useSelector(state => state.user.token)

    const [input, setInput] = useState('');
    const [showResults, setShowResults] = useState('')
    const [trackingData, setTrackingData] = useState(null)

    const handleChange = (e) => setInput(e.target.value);

    const handleClick = () => {
        getTrackingDetails(input, token).then(async res => {
            const data = await res.json()
            setShowResults(true)
            setTrackingData(data)
        }).catch(err => {
            setShowResults(false)
            toast('error', 'ha ocurrido un error, verifique que el número de radicado sea valido')
        })
    };

    return (
        <div>
            <StyledServices>
                <p>Ingresa el número de radicado</p>
                <div className="header">
                    <Input placeholder='Ejemplo: #123456' startIcon={{ name: 'Search' }} onChange={handleChange} fullWidth />
                    <Button text='Rastreo' bold bgColor={'primary_400'} textColor={'neutral_50'} handler={() => handleClick()} />
                    {showResults && <Button text='<Volver' bold bgColor={'primary_400'} textColor={'neutral_50'} handler={() => { setShowResults(''); setTrackingData(null) }} />}
                </div>
                {
                    showResults ? <TrackingResults data={trackingData.shipments[0]} /> : <TrackingInstructions />
                }
            </StyledServices>
        </div>
    )
}
