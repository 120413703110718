import { URL } from "../constants"

export const calculateCO2 = async (data) => {
    const response = await fetch(`${URL}compensation/co2/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            data: data
        })
    })
    return response.json()
}

export const getShippingsCo2 = async (token) => {
    const response = await fetch(`${URL}shipping-co2/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getCO2Stats = async (token) => {
    const response = await fetch(`${URL}shippingstats/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getEmissions = async (token, radication) => {
    const response = await fetch(`${URL}co2_radication/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const calculateCO2byPerson = async (token, data) => {
    const response = await fetch(`${URL}calculateco2personal/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            data: data
        })
    })
    return response;
}
export const getCompanyCo2 = async (token, user) => {
    const response = await fetch(`${URL}co2PerUser/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getUserCo2 = async (token, user) => {
    const response = await fetch(`${URL}co2-peruser/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}