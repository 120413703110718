import React, { useEffect, useState } from 'react'
import ComposedBarChart from '../../../components/Graphics/ComposedChart/ComposedChart';
import DashboardHorizontalBarChart from '../../../components/Graphics/HorizontalChart/DashboardHorizontalChart';
import SimplePieChart from '../../../components/Graphics/SimplePieChart/SimplePieChart';
import { getCommercialKpis } from '../../../services/kpis.services';

import Button from '../../../components/Button/Button';
import { usePDF } from 'react-to-pdf';
import { useProSidebar } from 'react-pro-sidebar';
import { styled } from "@nextui-org/react";
import Card from './Card';
import { useSelector } from 'react-redux';

const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '40px',
    gridTemplateRows: '160px 500px 450px',
})
const MainChartContainer = styled("div", {
    maxH: '500px',
    paddingLeft: '40px',
    backgroundColor: '$neutral_50',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    width: '100%',
    gridColumn: 'span 5',
    fontSize: '12px',
})
const SimpleChartContainer = styled("div", {
    maxH: '500px',
    fontSize: '12px',
    backgroundColor: '$neutral_50',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    gridColumn: 'span 3',
})

export default function Dashboard() {

    const { toPDF, targetRef } = usePDF({filename: 'admin_dashboard.pdf'});

    const { collapseSidebar, collapsed } = useProSidebar();
    const token = useSelector(state => state.user.token)

    const [loadingPdf, setLoadingPdf] = useState(false)
    const [commercialKpis, setCommercialKpis] = useState({})

    useEffect(() => {
        if (!collapsed) {
            collapseSidebar()
        }
        getCommercialKpis(token).then(async res => {
            const kpis = await res.json();
            setCommercialKpis(kpis)
        })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);
    
    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }
    return (
        <div>
        <div style={{margin:'20px'}}>
            <Button text={loadingPdf?'Cargando...':'Descargar pdf'} disabled={loadingPdf} handler={() => downloadPDF()} outline/>
        </div>
        <StyledContainer ref={targetRef}>
            <div style={{ 'gridColumn': 'span 2' }}><Card title='Cotizaciones' number={commercialKpis.total_quotations?.toFixed(2)} color='#fbc644' icon='icon' aditional='Total de cotizaciones realizadas' /></div>
            <div style={{ 'gridColumn': 'span 2' }}><Card title='$ Profit' number={commercialKpis.total_profit?.toFixed(2)} color='#fd7c5c' icon='icon' aditional='Profit total obtenido de cotizaciones' /></div>
            <div style={{ 'gridColumn': 'span 2' }}><Card title='$ Promedio' number={(commercialKpis.total_profit / commercialKpis.total_quotations)?.toFixed(2)} color='#dc3546' icon='icon' aditional='Profit promedio por cotizacion' /></div>
            <div style={{ 'gridColumn': 'span 2' }}><Card title='Usuario ' number={commercialKpis.user_quotations?.toFixed(2)} color='#6981ec' icon='icon' aditional='Cotizaciones realizadas por el usuario' /></div>

            <SimpleChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>Cotizaciones por proveedor</p>
                <DashboardHorizontalBarChart width={400} height={400} color={'#fd7c5c'} dataKey={"Cantidad de cotizaciones"} data={
                    commercialKpis.quotations_per_provider && Object.keys(commercialKpis.quotations_per_provider).map(key => {
                        return {
                            name: key,
                            "Cantidad de cotizaciones": commercialKpis.quotations_per_provider[key],
                        }
                    })
                } />
            </SimpleChartContainer>
            <MainChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>Cotizaciones por servicio</p>
                <ComposedBarChart barDataKey={"Cantidad de cotizaciones"} data={
                    commercialKpis.quotations_per_service && commercialKpis.avg_profit_per_provider && Object.keys(commercialKpis.quotations_per_service).map(key => {
                        return {
                            name: key,
                            "Cantidad de cotizaciones": commercialKpis.quotations_per_service[key],
                        }
                    })
                }
                />
            </MainChartContainer>
            <SimpleChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>Profit por proveedor</p>
                <SimplePieChart dataKey={"Profit"} color={'#dc3546'} data={
                    commercialKpis.avg_profit_per_provider && Object.keys(commercialKpis.avg_profit_per_provider).map(key => {
                        return {
                            name: key,
                            "Profit": commercialKpis.avg_profit_per_provider[key],
                        }
                    })
                } />
            </SimpleChartContainer>
            <MainChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>Profit por servicio</p>
                <DashboardHorizontalBarChart dataKey={"Profit"} data={
                    commercialKpis.avg_profit_per_service && Object.keys(commercialKpis.avg_profit_per_service).map(key => {
                        return {
                            name: key,
                            "Profit": commercialKpis.avg_profit_per_service[key]
                        }
                    })
                } />
            </MainChartContainer>
        </StyledContainer>
        </div>
    )
}
