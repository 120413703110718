import React, { useEffect, useState } from 'react';
import { getOperationStatuses, getSubStatuses, getSubStatusesOperations } from '../../../services/operations.services';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Table from '../../../components/Table/Table';

export default function TrackingLogs({ currentOperation, updateLogs }) {
    const token = useSelector(state => state.user.token);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const asyncTasks = [getSubStatuses(token), getSubStatusesOperations(token), getOperationStatuses(token)];
        Promise.all(asyncTasks).then(async res => {
            const subStatusesData = await res[0].json();
            const relations = await res[1].json();
            const operationStatusesData = await res[2].json();

            const currentRelations = relations.filter(relation => relation.operation === currentOperation);

            const logData = currentRelations.map((relation, index) => {
                const currentSubStatus = subStatusesData.find(sub => sub.id === relation.operation_sub_status);
                const bgColor = index % 2 === 0 ? '#f2f2f2' : '#ffffff'; // Alternar colores

                return {
                    id: relation.id,
                    subStatus: currentSubStatus?.name,
                    status: operationStatusesData.find(status => status.id === currentSubStatus?.operation_status)?.name,
                    notes: relation?.notes,
                    date: relation?.date ? format(new Date(relation.date), 'dd/MM/yyyy : HH:mm') : '',
                    bgColor: bgColor, // Nuevo campo para el color de fondo
                };
            });

            logData.sort((a, b) => (new Date(b.date) - new Date(a.date)));

            setItems(logData);
        });
    }, [currentOperation, updateLogs]);

    const columns = [
        { name: 'Estado', uid: 'status', type: 'text' },
        { name: 'Subestado', uid: 'subStatus', type: 'text' },
        { name: 'Fecha', uid: 'date', type: 'text' },
        { name: 'Notas', uid: 'notes', type: 'text' },
    ];

    return (
        <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>Registro de estado de la operación</p>
            {items.length ? (
                <Table columns={columns} items={items} style={{ width: 'auto', margin: 'auto' }}>
                    {(row, index) => (
                        <tr key={row.id} style={{ background: row.bgColor }}>
                            {columns.map(column => (
                                <td key={column.uid}>{row[column.uid]}</td>
                            ))}
                        </tr>
                    )}
                </Table>
            ) : (
                <p>Aun no hay registros de esta operación o no hay una seleccionada</p>
            )}
        </div>
    );
}
