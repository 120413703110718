import format from "date-fns/format"

export const example = `
<p><strong>PLANTILLA DE EJEMPLO CONDICIONES DE LA OFERTA</strong></p><p>&nbsp;</p>
<p>A continuaci&oacute;n, se detallan las condiciones de la oferta de env&iacute;o:</p>
<ol>
    <li>Descripci&oacute;n del Env&iacute;o: [Descripci&oacute;n detallada del paquete, peso, dimensiones, contenido, etc.].</li>
    <li>Origen: [Origen del env&iacute;o].</li><li>Destino: [Destino del env&iacute;o].</li>
    <li>Fecha de Recogida: [Fecha de Recogida programada].</li>
    <li>Fecha de Entrega Estimada: [Fecha estimada de entrega].</li>
    <li>Costo Total: $[Costo Total del env&iacute;o], que incluye tarifas de env&iacute;o, impuestos y otros cargos.</li>
    <li>M&eacute;todo de Env&iacute;o: [Especificar el m&eacute;todo de env&iacute;o, como terrestre, a&eacute;reo, mar&iacute;timo, etc.].</li>
    <li>Seguro: [Especificar si el env&iacute;o incluye seguro y la cobertura].</li>
    <li>Condiciones Especiales: [Cualquier condici&oacute;n especial o instrucci&oacute;n relacionada con el env&iacute;o].</li>
</ol>`

export default function CreateExample(data){
    return `
    <p><strong>DETALLES DE LA OFERTA DE ENVÍO</strong></p>
    <p>&nbsp;</p>
    <p>Detalles de la oferta de envío con radicación ${data?.radication}:</p>
    <ul>
        <li><strong>Tipo de Negocio:</strong> ${data?.business_type}</li>
        <li><strong>Industria:</strong> ${data?.industry}</li>
        <li><strong>Términos Comerciales:</strong> ${data?.business_terms}</li>
    </ul>

    <h3>Datos de Envío</h3>
    <ul>
        <li><strong>Origen:</strong> ${data?.shipping?.origin_boarding_terminal}</li>
        <li><strong>Destino:</strong> ${data?.shipping?.destination_boarding_terminal}</li>
        <li><strong>Costo Total:</strong> $${data?.cost || 'No especificado'}</li>
        <li><strong>Método de Envío:</strong> ${data?.options?.additional_specifications}</li>
        <li><strong>Seguro:</strong> ${data?.options?.shipping_insurance === 0 ? 'No incluido' : 'Incluido'} (${data?.options?.shipping_insurance} de cobertura)</li>
        <li><strong>Condiciones Especiales:</strong> ${data?.observations}</li>
    </ul>
    <h3>Opciones Adicionales</h3>
    <ul>
        <li><strong>Control de Temperatura:</strong> ${data?.options?.temperature_control === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Mercancías Peligrosas:</strong> ${data?.options?.dangerous === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Registrador de Datos:</strong> ${data?.options?.data_logger === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Remontable:</strong> ${data?.options?.remountable === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Posición Arancelaria:</strong> ${data?.options?.arancelary_position === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Estudio de Ruta:</strong> ${data?.options?.route_study === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Análisis de Seguridad:</strong> ${data?.options?.security_analysis === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Encuesta de Sujeción:</strong> ${data?.options?.lashing_survey === 0 ? 'No incluido' : 'Incluido'}</li>
        <li><strong>Comunidades de Trabajo:</strong> ${data?.options?.work_communities === 0 ? 'No incluido' : 'Incluido'}</li>
    </ul>
    `
}