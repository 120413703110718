import React, { useState, useEffect } from 'react'
import { styled } from '@nextui-org/react'
import { usePDF } from 'react-to-pdf'
import DatesRangePickerModal from '../../components/DateRangePicker/DateRangePicker'
import format from 'date-fns/format'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import ColoredPieChart from '../../components/Graphics/ColoredPieChart'
import Icon from '../../components/Icon/Icon'
import { useNavigate } from 'react-router-dom'

const colors = ['#89ac76', '#fd7c5c', '#6981ec']

const Header = styled("div", {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    ['button']: {
        margin: '15px',
    }
})

const MainContainer = styled("div", {
    padding: '50px',
    display: 'grid',
    gap: '40px',
    gridTemplateColumns: 'repeat(5, 1fr)'
})

const MainChart = styled("div", {
    gridColumn: 'span 3',
    gridRow: 'span 2',
    justifyContent: 'space-around',
    height: '450px',
    padding: '40px',
    background: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    color: '$neutral_300',
    ['.container']: {
        display: 'flex',
        height: '350px',
    },
    ['.info']: {
        paddingTop: '40px',
        minWidth: '220px',
        display: 'flex',
        fontSize: '16px',
        gap: '20px',
        flexDirection: 'column',
        ['span']: {
            margin: '10px',
        },
        ['.detail']: {
            fontSize: '12px',
            color: '$neutral_250',
        },
    },
})
const Secondary = styled("div", {
    color: 'white',
    padding: '10px',
    paddingTop: '20px',
    gridColumn: 'span 2',
    // color:'$neutral_400',
    borderRadius: '8px',
    background: '#94ba7f',
    boxShadow: '2px 2px 5px white',
    ['div']: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
    },
    ['.number']: {
        fontSize: '60px',
        margin: '10px',
    }
})
const mockData = [
    {
        "type": "Car",
        "distance_km": 50,
        "co2_emissions": 10
    },
    {
        "type": "Bus",
        "distance_km": 200,
        "co2_emissions": 15
    },
    {
        "type": "Hotel",
        "duration_nights": 3,
        "co2_emissions": 30
    }
]

export default function CO2Persons() {
    const navigate = useNavigate()
    const { toPDF, targetRef } = usePDF({filename: 'admin_dashboard.pdf'});

    const [activitiesData, setActivitiesData] = useState([])
    const [open, setOpen] = useState(false)
    const [dates, setDates] = useState({})
    const [loadingPdf, setLoadingPdf] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setActivitiesData(mockData)
        }, 300)
    }, [])

    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }

    return (
        <div ref={targetRef}>
            <Header>
                <div style={{ position: 'relative', padding: '20px' }}>
                    <div onClick={() => setOpen(true)} style={{ display: 'flex', gap: '20px', width: 'fit-content' }}>
                        {dates?.startDate ? <Input value={format(dates.startDate, "dd/MM/yyyy")} /> : <Input value="" placeholder={"Desde..."} />}
                        {dates?.endDate ? <Input value={format(dates.endDate, "dd/MM/yyyy")} /> : <Input value="" placeholder={"Hasta..."} />}
                    </div>
                    <DatesRangePickerModal setDates={setDates} open={open} setOpen={setOpen} />
                </div>
                <div style={{display:'flex'}}>
                    <Button handler={()=>downloadPDF()} text={loadingPdf?'Cargando...':'Descargar'} endIcon={{ name: 'Download' }} outline disabled={loadingPdf}/>
                    <Button handler={() => navigate('./calculate')} text={"Calcular emisiones de CO2"} outline bold endIcon={{ name: 'Tree' }} />
                </div>
                
            </Header>

            <MainContainer>
                <MainChart>
                    <h3>Emisiones por actividad</h3>
                    <div className='container'>
                        <ColoredPieChart colors={colors} radius={120} data={activitiesData?.map(activity => {
                            return {
                                name: [activity.type],
                                value: activity.co2_emissions,
                            }
                        })} />
                        <div className='info'>
                            <div>
                                <Icon name="Car" size='33px' color={colors[0]} />
                                <span><strong>Auto : 10kg</strong></span>
                                <p className='detail'>distancia recorrida : 300km</p>
                            </div>
                            <div>
                                <Icon name="Bus" size='33px' color={colors[1]} />
                                <span><strong>Bus : </strong>15kg</span>
                                <p className='detail'>distancia recorrida : 350km</p>
                            </div>
                            <div>
                                <Icon name="Hotel" size='33px' color={colors[2]} />
                                <span><strong>Hotel : </strong>30kg</span>
                                <p className='detail'>estadia : 3 noches, Personas: 3</p>
                            </div>
                        </div>
                    </div>
                </MainChart>
                <Secondary>
                    <h3>Emisiones totales de CO2</h3>
                    {dates.startDate && <p>{`Emisiones totales generadas desde ${format(dates?.startDate, "dd/MM/yyyy")} hasta ${format(dates?.endDate, "dd/MM/yyyy")}`}</p>}
                    <div>
                        <h1 className='number'>55.00kg</h1>
                        <Icon name='GreenEnergy' size='60px' color='white' />
                    </div>
                </Secondary>
                <Secondary>
                    <h3>Emisiones compensadas </h3>
                    {dates.startDate && <p>{`Emisiones totales compensadas desde ${format(dates?.startDate, "dd/MM/yyyy")} hasta ${format(dates?.endDate, "dd/MM/yyyy")}`}</p>}
                    <div>
                        <h1 className='number'>55.00kg</h1>
                        <Icon name='GreenEnergy' size='60px' color='white' />
                    </div>
                </Secondary>
            </MainContainer>
        </div>
    )
}
