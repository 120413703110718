import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { getUserCo2 } from '../../../services/CO2.services'
// Components
import Table from '../../../components/Table/Table';
import { getCountries } from '../../../services/countries.services';

const mockData = []

const TableCO2Payments = () => {

    const [items, setItems] = useState([])
    const user = useSelector(state => state.user)
    const columns = [
        { name: '', uid: 'actions', type: 'action' },
        { name: 'ID', uid: 'calculation_id', type: 'text' },
        { name: 'Origen', uid: 'departure', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'País Estadía', uid: 'country', type: 'text' },
        { name: 'Fecha', uid: 'created_at', type: 'text' },
        { name: 'Tipo Cálculo', uid: 'type', type: 'text' },
        { name: 'Combustible', uid: 'fuel', type: 'text' },
        { name: 'Pasajeros', uid: 'passengers', type: 'text' },
        { name: 'CO2', uid: 'kg_amount', type: 'text' },
        { name: 'Distancia', uid: 'distance', type: 'text' },
        { name: 'Peso', uid: 'weight', type: 'text' },
        { name: 'Unidad Peso', uid: 'weight_unit', type: 'text' },
        { name: 'Personas Estadía', uid: 'guests', type: 'text' },
        { name: 'Noches Estadía', uid: 'nights', type: 'text' },
        { name: 'Viaje Regreso', uid: 'return_trip', type: 'text' },
        { name: 'Especificaciones', uid: 'specification', type: 'text' },
        { name: 'Tipo Vehículo', uid: 'vehicle_class', type: 'text' },
        { name: 'Estado', uid: 'status', type: 'text' },
        { name: 'Método Cálculo', uid: 'calculation_method', type: 'text' },
        { name: 'Tipo Carga', uid: 'type_of_cargo', type: 'text' },
        { name: 'Costo Transporte', uid: 'transportation_cost', type: 'text' },
        { name: 'Fecha Compensación', uid: 'compensation_date', type: 'text' }
    ]


    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (item) => console.log('item :', item)
        },
        {
            name: 'Compensar',
            icon: 'View',
            handle: (item) => console.log('item :', item)
        },
    ]

    useEffect(() => {

        getUserCo2(user.token, user.idUser).then(async (res) => {
            const items = await res.json();

            let countries = await (await getCountries(user.token)).json()
            console.log(countries)
            const formattedItems = items.map((item) => {
                const formattedDate = new Date(item.created_at).toLocaleDateString();

                let formattedType;
                switch (item.type) {
                    case 'ship_freight':
                        formattedType = 'Marítimo';
                        break;
                    case 'air_freight':
                        formattedType = 'Aéreo';
                        break;
                    case 'road_freight':
                        formattedType = 'Terrestre';
                        break;
                    default:
                        formattedType = item.type;
                }

                const country = countries.find(c => c.iso === item.country);
                const countryName = country ? country.name : item.country;

                return {
                    ...item,
                    created_at: formattedDate,
                    type: formattedType,
                    country: countryName,
                };
            });

            const invertedItems = formattedItems.reverse();

            setItems(invertedItems);
        });



    }, [user])

    return (
        <div className='h-100'>
            <Table columns={columns} items={items} actions={actions} />
        </div>
    );
}

export default TableCO2Payments;