import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useFieldArray, useForm } from 'react-hook-form';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';

const Beneficiaries = ({ setBeneficiaries }) => {
    const nameForm = 'beneficiaries'
    const { register, control, getValues } = useForm({
        defaultValues: {
            [nameForm]: [{
                company: '',
                phone: '',
                contact: ''
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: nameForm
    });

    const StyledBeneficiaries = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            },
            ['main section.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setBeneficiaries(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledBeneficiaries>
            <PageTitle>
                Personas o entidades beneficiarias
            </PageTitle>

            <aside>
                Apellidos y nombres de las personas o entidades beneficiarias de las operaciones de comercio exterior.
            </aside>

            <main>
                {
                    fields.map((field, i) =>
                        <div key={field.id}>
                            <h5>Beneficiaria No. {i + 1}</h5>
                            <section className='form'>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.company`} label='Empresa' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.phone`} label='Télefono' placeholder='Introducir' type='number' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.contact`} label='Contacto' placeholder='Introducir' required></Input>

                                <div className='d-flex align-items-end'>
                                    {
                                        i < fields.length - 1 ?
                                            <Button handler={() => { remove(i); saveData() }} text='Eliminar' outline></Button>
                                            :
                                            <Button handler={() => { append(); saveData() }} text='Agregar otro' startIcon={{ name: 'Plus', size: '16' }} outline></Button>
                                    }
                                </div>
                            </section>
                        </div>
                    )
                }
            </main>
        </StyledBeneficiaries>
    );
}

export default Beneficiaries;
