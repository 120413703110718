import React, { useEffect, useState } from 'react'
import { Modal, styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useForm, Controller } from 'react-hook-form'
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getClients, getStatuses, getReasons, getSubTypes, getSolutions, getOffices, getQuoteLanguages, postQuotation } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';

const StyledFormModal = styled(Modal, {
    overflowY: 'auto',
    padding: '1.5rem',
    color: '$primary_500',
    ['h3']: {
        color: '$primary_500'
    }
})
const Grid = styled('form', {
    paddingBottom: '5rem',
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(2, 1fr)',
})

const formatDataArray = (dataArray) => {
    const formated = dataArray.map(data => {
        return {
            label: data?.name,
            value: data?.value || data?.id,
        }
    })
    return formated
}
const formatData = (data) => {
    return {
        label: data?.name,
        value: data?.value || data?.id,
    }
}

export default function CreateVersionModal({ open, setOpen, currentQuoteData, token }) {

    const { handleSubmit, control, reset, watch, formState: { errors } } = useForm();

    const [selectOptions, setSelectOptions] = useState({
        subtypes: [],
        solutions: [],
        offices: [],
        clients: [],
        statuses: [],
        terms: [],
        reasons: [],
        languages: [],
    })

    useEffect(() => {
        const asyncTasks = [getSubTypes(token), getSolutions(token), getOffices(token), getClients(token), getStatuses(token), getBusinessTerms(token), getReasons(token), getQuoteLanguages(token)]
        Promise.all(asyncTasks).then(async responses => {
            const subtypesData = await responses[0].json()
            const solutionsData = await responses[1].json()
            const officesData = await responses[2].json()
            const clientsData = await responses[3].json()
            const statusesData = await responses[4].json()
            const termsData = await responses[5].json()
            const reasonsData = await responses[6].json()
            const languagesData = await responses[7].json()

            setSelectOptions({
                subtypes: formatDataArray(subtypesData),
                solutions: formatDataArray(solutionsData),
                offices: formatDataArray(officesData),
                clients: formatDataArray(clientsData),
                statuses: formatDataArray(statusesData),
                terms: formatDataArray(termsData),
                reasons: formatDataArray(reasonsData),
                languages: formatDataArray(languagesData),
            });
            if (currentQuoteData.shipping){
                reset({
                    subtype: formatData(subtypesData.find(subtype => subtype.id === currentQuoteData.subtype)),
                    solution: formatData(solutionsData.find(solution => solution.id === currentQuoteData.service)),
                    office: formatData(officesData.find(office => office.id === currentQuoteData.office)),
                    client: formatData(clientsData.find(client => client.id === currentQuoteData.client)),
                    origin_terminal: currentQuoteData.shipping.origin_boarding_terminal,
                    destination_terminal: currentQuoteData.shipping.destination_boarding_terminal,
                    terms: formatData(termsData.find(term => term.id == currentQuoteData.incoterm)),
                    status: formatData(statusesData.find(status => status.id === currentQuoteData.status)),
                    reason: formatData(reasonsData.find(reason => reason.id === currentQuoteData.reason)),
                    language: formatData(languagesData.find(language => language.id === currentQuoteData.language)),
                    observations: currentQuoteData.observations,
                })
            }
        })
    }, [currentQuoteData])
    const onSubmit = (data) => {
        const submitData = {
            radication: currentQuoteData.radication,
            origin_terminal: data.origin_terminal,
            destination_terminal: data.destination_terminal,
            observations: data.observations,
            terms: data.terms.label,
            incoterm: data.terms.value,
            client: data.client?.value,
            service: data.solution?.value,
            subtype: data.subtype?.value,
            office: data.office?.value,
            language: data.language?.value,
            status: data.status?.value,
            reason: data.reason?.value,
        }
        postQuotation(submitData).then(() => {
            toast("success", "Version creada con exito")
            setOpen(false)
        }).catch(err => { console.log('err :', err); toast("error", "Error, no pudo crearse la version") })
    };

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Crear version de cotizacion <b>{currentQuoteData.radication}</b></h3>
            </Modal.Header>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid>
                        <Controller
                            name="subtype"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Subtipo" {...field} options={selectOptions?.subtypes} />
                                    {errors.subtype && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.subtype.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="solution"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Servicio" {...field} options={selectOptions?.solutions} />
                                    {errors.solution && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.solution.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="office"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Oficina" {...field} options={selectOptions?.offices} />
                                    {errors.office && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.office.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="client"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Clientes" {...field} options={selectOptions?.clients} />
                                    {errors.client && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.client.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="origin_terminal"
                            control={control}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PlacesAutocomplete
                                        {...field}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    {...field}
                                                    label='Origen'
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    // value={watch('origin_terminal')|| ''}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {errors.origin_terminal && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.origin_terminal.message}</span>}
                                </div>
                            )}
                            rules={{ required: "Este campo es obligatorio" }}
                        />
                        <Controller
                            name="destination_terminal"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PlacesAutocomplete
                                        {...field}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    {...field}
                                                    label='Destino'
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    // value={watch('destination_terminal')|| ''}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {errors.destination_terminal && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.destination_terminal.message}</span>}
                                </div>
                            )}
                        />

                        <Controller
                            name="status"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Estado" {...field} options={selectOptions?.statuses} />
                                    {errors.status && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.status.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="reason"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Motivo" {...field} options={selectOptions?.reason} />
                                    {errors.reason && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.reason.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="terms"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Incoterms" {...field} options={selectOptions?.terms} />
                                    {errors.terms && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.terms.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="language"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputSelect label="Idioma de la cotizacion" {...field} options={selectOptions?.languages} />
                                    {errors.language && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.language.message}</span>}
                                </div>
                            )}
                        />
                        <div style={{ gridColumn: 'span 2' }}>
                            <Controller
                                name="observations"
                                control={control}
                                rules={{ required: "Este campo es obligatorio" }}
                                render={({ field }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Input label="Observaciones" {...field} fullWidth />
                                        {errors.observations && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.observations.message}</span>}
                                    </div>
                                )}
                            />
                        </div>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={'xl'} text='Crear versión' bold bgColor={'primary_300'} type='submit' textColor='neutral_50' />
                </Modal.Footer>
            </form>
        </StyledFormModal>
    );
}
