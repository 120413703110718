import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSearchParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';

//Libreria Traduccion
import { useTranslation } from "react-i18next";

const StyledCard = styled("div", {
    backgroundColor: '$neutral_50',
    padding: '40px',
    borderRadius: '8px',
    display: 'flex !important',
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    ['.text']: {
        width: '48%',
    },
    h1: {
        width: 'fit-content',
        color: '$secondary_300',
        fontSize: '36px',
        fontWeight: '$semibold'
    },
    h3: {
        fontSize: '20px',
        fontWeight: '$semibold',
    }
});

const StepCard = ({ imgSrc, index, title, description }) => (
    <div className='container'>
        <StyledCard>
            <img src={imgSrc} alt={"paso" + (index)} />
            <div className='text'>
                <h1>0{index}</h1>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </StyledCard>
    </div>
);

const TrackingInstructions = () => {

    const [searchParams] = useSearchParams();
    const [filterSearch, setFilterSearch] = useState('');

    const handleChange = (e) => setFilterSearch(e.target.value);

    useEffect(() => {
        setTimeout(() => {
            let message;
        }, 1000);
    }, [searchParams]);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const steps = [
        {
            id: 1,
            title: 'Realiza seguimientos de tu servicio',
            description: 'Desde aquí podrás localizar tu servicio activo en cualquier momento del día, tan solo con el número de radicado.',
            imgSrc: '/Tracking1.png',
        },
        {
            id: 2,
            title: 'Conoce la trayectoria de tu servicio',
            description: 'Con el número de radicado de tu servicio podrás conocer cuál es su punto de origen y destino.',
            imgSrc: '/Tracking2.png',
        },
        {
            id: 3,
            title: 'Revisa el estado de tu servicio',
            description: 'Puedes seguir el estado tu carga con fecha y hora desde recogida hasta entrega final.',
            imgSrc: '/Tracking3.png',
        }
    ];

    return (
        <div>
            <div style={{ margin: '50px' }}>
                <PageTitle>Conoce cómo realizar un rastreo de forma sencilla</PageTitle>
            </div>
            <Slider {...sliderSettings}>
                {
                    steps.map((step) => (
                        <StepCard
                            key={step.id}
                            imgSrc={step.imgSrc}
                            index={step.id}   // Si todavía necesitas el índice, puedes usar el id. De lo contrario, elimina esta línea.
                            title={step.title}
                            description={step.description}
                        />
                    ))
                }
            </Slider>
        </div>
    );
};

export default TrackingInstructions;
