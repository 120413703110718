
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { styled, Loading } from "@nextui-org/react"
import Input from "../../components/Input/Input"
import InputSelect from "../../components/Select/inputSelect"
import Button from "../../components/Button/Button"
import toast from "../../components/Toast/ToastTypes"
import DatePickerModal from "../../components/DatePicker/DatePicker"
import format from "date-fns/format"
import { getQuotation } from "../../services/quotation.services"

import { useForm, Controller } from 'react-hook-form'

import { postOperation, getOperationTypes, getStatuses, getOperationStatuses, getAgents, getCoordinators } from "../../services/operations.services"
import { getUsers } from "../../services/user.services"
import { getClients, getQuotationByRadication } from "../../services/quotations.services"
import DisplayData from "../ManageQuotes/QuoteForm/DisplayData"
import PendingTable from "./PendingTable"

const StyledContainer = styled("div", {
    display: 'grid',
    marginTop: '30px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '30px',
})

const FormContainer = styled("form", {
    gridColumn: 'span 3',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    padding: '50px',
    boxShadow: "$elevation_1",
    gap: '30px',
    flexDirection: 'column',
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
})
const InputContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    gridColumn:'span 2',
    ['span']: {
        color: '$delete',
        fontSize: '12px'
    }
})
const RFQDataContainer = styled("div", {
    overflowY: 'scroll',
    maxH: '540px',
    gridColumn: 'span 2',
    gridRow: 'span 2',
    background: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '50px',
})

export default function CreateOperation({ updateTable, setUpdateTable }) {
    const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm();
    const token = useSelector(state => state.user.token);
    console.log('token :', token)
    const [showPending, setShowPending] = useState(false)

    const [radication, setRadication] = useState()
    const [RFQ, setRFQ] = useState()

    const [openETD, setOpenETD] = useState(false)
    const [openETA, setOpenETA] = useState(false)

    const [ETD, setETD] = useState(new Date());
    const [ETA, setETA] = useState(new Date());

    const [users, setUsers] = useState([]);
    const [operationsData, setOperationsData] = useState({});


    useEffect(() => {
        if (radication) {
            setRFQ()
            const asyncTasks = [
                getOperationTypes(token),
                getUsers(token),
                getClients(token),
                getAgents(token),
                getStatuses(token),
                getOperationStatuses(token),
                getCoordinators(token),
                getQuotation(radication, token),
                getQuotationByRadication(token, radication)
            ];
            Promise.all(asyncTasks).then(async responses => {
                const operationTypesData = await responses[0].json();
                const usersData = await responses[1].json();
                const clientsData = await responses[2].json();
                const agentsData = await responses[3].json();
                const statusesData = await responses[4].json();
                const operationStatusesData = await responses[5].json();
                const coordinatorsData = await responses[6].json();
                const RFQData = await responses[7].json();
                const currentQuotation = await responses[8].json();
                const currentClient = currentQuotation.client
                console.log("cliente",currentClient)
                setRFQ(RFQData)
                const client = RFQData.users.find(user=>user.role[0]==='Usuario')
                setValue("client", {
                    label:`${client.name} ${client.last_name} - ${client.email}`,
                    value:client.id,
                })
                setUsers(usersData);
                setOperationsData({
                    types: operationTypesData,
                    statuses: statusesData,
                    operation_statuses: operationStatusesData,
                    clients: usersData,
                    agents: agentsData,
                    coordinators: coordinatorsData,
                    currentClient: currentClient
                });
            }).catch(err => console.log('ERROR:', err));
        }
    }, [radication]);

    const onSubmit = (formData) => {
        const submitData = {
            ETD: ETD,
            ETA: ETA,
            operation_type: formData.operation_type?.value,
            status: formData.status?.value,
            operation_status: formData.operation_status?.value,
            client: formData.client?.value,
            agent: formData.agent?.value,
            coordinator: formData.coordinator?.value,
            radication,
        }
        postOperation(submitData).then(async res => {
            const response = await res.json();
            setUpdateTable(updateTable + 1)
            toast("success", "Operacion creada con exito")
        }).catch(err => toast("error", "No se ha podido crear la operacion"))
    }

    return (
        <div>
            <Button text={showPending ? 'Ocultar operaciones pendientes' : 'Ver operaciones pendientes'} bold bgColor={'primary_300'} textColor='neutral_50' handler={() => {
                showPending ? setShowPending(false) : setShowPending(true)
            }} />
            {
                showPending && <PendingTable setRadication={setRadication} setShowPending={setShowPending} />
            }
            <StyledContainer>
                {radication && <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <DatePickerModal open={openETD} setOpen={setOpenETD} date={ETD} setDate={setETD} />
                    <DatePickerModal open={openETA} setOpen={setOpenETA} date={ETA} setDate={setETA} />
                    <div onClick={() => { setOpenETD(true) }}>
                        <Input value={format(ETD, "dd/MM/yyyy")} label='ETD' />
                    </div>
                    <div onClick={() => { setOpenETA(true) }}>
                        <Input value={format(ETA, "dd/MM/yyyy")} label='ETA' />
                    </div>
                    <InputContainer>
                        <Controller
                            name="operation_type"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Tipo de operacion"
                                    options={operationsData.types?.map(type => ({
                                        label: type.name,
                                        value: type.id
                                    }))}
                                />
                            )}
                        />
                        {errors.operation_type && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.operation_type.message}</span>}
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            name="status"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Estado"
                                    options={operationsData.statuses?.map(status => ({
                                        label: status.name,
                                        value: status.id
                                    }))}
                                />
                            )}
                        />
                        {errors.status && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.status.message}</span>}
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            name="operation_status"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Estado de operacion"
                                    options={operationsData.operation_statuses?.map(status => ({
                                        label: status.name,
                                        value: status.id
                                    }))}
                                />
                            )}
                        />
                        {errors.operation_status && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.operation_status.message}</span>}
                    </InputContainer>
                    <InputContainer style={{gridColumn:'span 4'}}>
                        <Controller
                            name="client"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Cliente"
                                    options={operationsData.clients?.map(client => ({
                                        label:`${client.name} ${client.last_name} - ${client.email}`,
                                        value:client.id,
                                    }))}
                                    defaultValue={operationsData.currentClient}
                                />
                            )}
                        />
                        {errors.client && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.client.message}</span>}
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            name="agent"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Agente"
                                    options={operationsData.agents?.map(agent => ({
                                        label: agent.name,
                                        value: agent.id
                                    }))}
                                />
                            )}
                        />
                        {errors.agent && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.agent.message}</span>}
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            name="coordinator"
                            control={control}
                            rules={{ required: "Este campo es obligatorio" }}
                            render={({ field }) => (
                                <InputSelect
                                    {...field}
                                    label="Coordinador"
                                    options={operationsData.coordinators?.map(coordinator => ({
                                        label: users.find(user => user.id === coordinator.user)?.name,
                                        value: coordinator.id
                                    }))}
                                />
                            )}
                        />
                        {errors.coordinator && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.coordinator.message}</span>}
                    </InputContainer>
                    <Button text="Crear operacion" disabled={Object.keys(errors).length || !radication || !RFQ} bold bgColor={'primary_300'} textColor='neutral_50' type="submit" />
                </FormContainer>}
                <RFQDataContainer>
                    {
                        !radication ?
                            <div>
                                <h3>Selecciona una cotizacion</h3>
                                <p>Presiona "Nueva operacion" y selecciona una cotizacion de la tabla para empezar a operar</p>
                            </div> :
                            RFQ ?
                                <DisplayData data={RFQ} radication={radication} /> :
                                <Loading />
                    }
                </RFQDataContainer>
            </StyledContainer>
        </div>
    );
}