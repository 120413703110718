import React, { useState } from 'react'
import { styled } from '@nextui-org/react';
import Table from '../../../components/Table/Table';
import { getOperations, getActivities, postLogEntries, editOperationActivity, setSubState} from '../../../services/operations.services';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Modal } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes'
import AddActivityModal from './AddActivityModal'
import TrackingCards from './TrackingCards';
import TrackingModal from './TrackingModal';
import TrackingLogs from './TrackingLogs';

const StyledContainer = styled("div", {
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '10px'
})

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    minWidth: '400px',
    padding: '20px',
})

const columns = [
    { name: 'Acciones', uid: 'actions', type: 'action' },
    { name: 'Eventos', uid: 'activity', type: 'text' },
    { name: 'Fecha confirmada', uid: 'confirmed_date', type: 'text' },
    { name: 'Fecha estimada', uid: 'estimated_date', type: 'text' },
    // { name: 'Operacion', uid: 'operation', type: 'text' },
]

const convertDate = date => {
    const dateParts = date.split('/');
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

export default function Events({ selectedOperation,setSelectedOperation, selectedOperationData }) {

    const token = useSelector(state => state.user.token)
    const user = useSelector(state => state.user)

    const [items, setItems] = useState([])
    const [currentOperationData, setCurrentOperationData] = useState({})
    const [openLogModal, setOpenLogModal] = useState(false)
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState('')
    const [logEntry, setLogEntry] = useState('')

    const [openAddActivityModal, setOpenAddActivityModal] = useState(false)
    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    const [updateLogs, setUpdateLogs] = useState(0)

    function formatDate(inputDate) {
        const date = new Date(inputDate);

        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}/${month}/${year}`;
    }

    function handleDelete() {

        const editedOperation = currentOperationData;
        const newActivities = editedOperation.activities.filter(activity => activity.activity !== selectedActivity.id)
        editedOperation.activities = newActivities;

        editOperationActivity({ data: editedOperation, id: currentOperationData.id }).then(() => {
            setOpenConfirmationModal(false)
            toast("success", "Actividad borrada")
        }).catch(() => toast("error", "Error, no se pudo borrar la actividad"))
    }

    function handleSubmitLogEntry() {
        const entryData = {
            text_field: logEntry,
            operation: selectedOperation,
            activity: selectedActivity.id,
            recipient: user.idUser,
        }
        postLogEntries(entryData).then(res => {
            toast("success", "Entrada agregada con exito")
        }).catch(err => console.log('err :', err))
    }

    const actions = [
        {
            name: 'Agregar log',
            icon: 'Plus',
            handle: (item) => {
                setOpenLogModal(true);
                setSelectedActivity(item)
            }
        },
        {
            name: 'Modificar actividad',
            icon: 'EditSquare',
            handle: (item) => {
                setSelectedActivity(item)
                setOpenAddActivityModal(true)
            }
        },
        {
            name: 'Borrar actividad',
            icon: 'Trash',
            handle: (item) => {
                setSelectedActivity(item)
                setOpenConfirmationModal(true)
            }
        },
    ]

    useEffect(() => {
        Promise.all([getOperations({ token, id: selectedOperation }), getActivities(token)]).then(async responses => {
            const operationData = await responses[0].json();
            if (!Array.isArray(operationData)){
                setCurrentOperationData(operationData);
            }
            const activitiesData = await responses[1].json();
            setActivities(activitiesData)
            const tableData = operationData.activities?.map((operationActivity) => {
                return {
                    id: operationActivity.activity,
                    activity: activitiesData.find(activity => activity.id === operationActivity.activity)?.name,
                    confirmed_date: formatDate(operationActivity.confirmed_date),
                    estimated_date: formatDate(operationActivity.estimated_date),
                    operation: operationActivity.operation,
                }
            })
            const sortedData = tableData?.sort((a, b) => {
                const dateA = convertDate(a.confirmed_date);
                const dateB = convertDate(b.confirmed_date);
                return new Date(dateB) - new Date(dateA);
            });
            setItems(sortedData)
        })
    }, [selectedOperation, openAddActivityModal, openConfirmationModal])

    return (
        <StyledContainer>
            <p style={{ margin: '10px', padding: '10px', borderBottom: '1px solid grey' }}>Registro activo : <b>{currentOperationData.radication || 'Seleccione una operacion'}</b></p>
            <div style={{display:'flex', gap:'15px'}}>
                {/* <Button text='Agregar actividad' disabled={!selectedOperation} bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { setOpenAddActivityModal(true) }}/> */}
                <Button text='Asignar numero de rastreo' disabled={!selectedOperation} bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { setOpenTrackingModal(true)}} />
            </div>
            {/* {
                items?.length > 0 ?
                    <Table columns={columns} items={items} actions={actions} /> :
                    <p style={{ margin: '20px' }} >No hay actividades en la operacion seleccionada</p>
            } */}

            <TrackingModal open={openTrackingModal} setOpen={setOpenTrackingModal} radication={currentOperationData.radication}/>

            <StyledModal
                closeButton
                aria-label="modal-title"
                open={openLogModal}
                onClose={() => { setOpenLogModal(false); setSelectedActivity('') }}
                width="fit-content"
            >
                <Modal.Body>
                    <p>Agregar entrada de registro a:</p>
                    <p> {selectedActivity.name} (id : {selectedActivity.id})</p>
                    <textarea label={"Log entry:"} onChange={(e) => setLogEntry(e.target.value)} style={{ border: "1px solid #292E68", borderRadius: "8px" }} />
                    <Button text='Agregar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { handleSubmitLogEntry() }} />
                </Modal.Body>
            </StyledModal>
            <AddActivityModal
                activities={activities}
                currentOperationData={currentOperationData}
                operationId={selectedOperation}
                openAddActivityModal={openAddActivityModal}
                setOpenAddActivityModal={setOpenAddActivityModal}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity}
            />

            <StyledModal
                aria-label="modal-title"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la actividad seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenConfirmationModal(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledModal>
            <TrackingCards setUpdateLogs={setUpdateLogs} currentOperationData={currentOperationData} setSelectedOperation={setSelectedOperation}/>
            <div style={{marginTop:'20px'}}>
                <TrackingLogs currentOperation={selectedOperation} updateLogs={updateLogs}/>
            </div>
        </StyledContainer>
    )
}
