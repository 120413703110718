import React, { useState, useEffect } from 'react'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import { Loading, Modal, Tooltip } from '@nextui-org/react';
import { styled } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import Icon from '../../../components/Icon/Icon';
import { getQuoteData, getSubTypes, getSolutions, getOffices, getClients, getQuoteLanguages, getStatuses, getReasons, postQuotation, deleteQuotation, editQuotation, finishQuotation } from '../../../services/quotations.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getUsers } from '../../../services/user.services';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import toast from '../../../components/Toast/ToastTypes';
import { getQuotation } from '../../../services/quotation.services';
import DisplayData from './DisplayData';
import FinishQuotationModal from './FinishQuotationModal';
import { ROLES } from '../../../constants';

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500 !important',
})

const FormContainer = styled("fieldset", {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '30px'
})

const InputsContainer = styled("div", {
    position: 'relative',
    backgroundColor: '$neutral_50',
    gridColumn: 'span 3',
    display: 'grid',
    maxHeight: '600px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '30px',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    ['.autocomplete-dropdown-container-top, .autocomplete-dropdown-container-bottom']: {
        position: 'absolute',
        zIndex: '1',
        width: '100%',
        ['.suggestion']: {
            border: 'solid 1px $neutral_100',
            padding: '0.4rem',
            fontSize: '0.9rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        ['.suggestion:first-child']: {
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
        },
        ['.suggestion:last-child']: {
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px'
        },
    },
    ['.autocomplete-dropdown-container-top']: {
        bottom: '100%'
    }
})

const RFQDataContainer = styled("div", {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    background: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '50px',
})

export default function QuoteForm({ currentQuote, setCurrentQuote, items, setItems }) {

    const [searchParams] = useSearchParams()
    const [subtypes, setSubtypes] = useState([])
    const [services, setServices] = useState([])
    const [offices, setOffices] = useState([])
    const [incoterms, setIncoterms] = useState([])
    const [clients, setClients] = useState([])
    const [status, setStatus] = useState([])
    const [quoteLanguages, setQuoteLanguages] = useState([])

    const [reasons, setReasons] = useState([])
    const [quoteData, setQuoteData] = useState({})

    const [selectedService, setSelectedService] = useState({})

    const [disableForm, setDisableForm] = useState(true)

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    const token = useSelector(state => state.user.token)

    const navigate = useNavigate()

    const { control, handleSubmit, watch, getValues, setValue, reset, formState: { errors } } = useForm();

    const [filteredServices, setFilteredServices] = useState([]);
    const [RFQ, setRFQ] = useState()
    const [radication, setRadication] = useState('')
    
    useEffect(() => {
        const radication = searchParams.get("radication")
        if (radication) {
            setRadication(searchParams.get("radication"))
        }
    }, [])

    useEffect(() => {
        if (subtypes.length > 0) {
            const selectedSubtype = watch("subtype");
            const filteredServices = services?.filter(service => service.subtype.includes(selectedSubtype?.value));
            setFilteredServices(filteredServices);
        }
    }, [subtypes, services, watch("subtype")])

    const currentSubtype = watch("subtype")
    useEffect(() => {
        if (subtypes.length > 0) {
            const filteredServices = services?.filter(service => service.subtype.includes(currentSubtype?.value));
            setSelectedService('')
            setFilteredServices(filteredServices);
        }
    }, [currentSubtype]);

    useEffect(() => {
        const asyncTasks = [
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getBusinessTerms(token),
            getUsers(token),
            getQuoteLanguages(token),
            getStatuses(token),
            getReasons(token),
        ];
        if (radication) {
            asyncTasks.push(getQuotation(radication, token))
        }
        Promise.all(asyncTasks)
            .then(async responses => {
                const subtypesData = await responses[0].json(); setSubtypes(subtypesData);
                const servicesData = await responses[1].json(); setServices(servicesData)
                const officesData = await responses[2].json(); setOffices(officesData)
                const incotermsData = await responses[3].json(); setIncoterms(incotermsData)
                const clientsData = await responses[4].json();
                const quoteLanguagesData = await responses[5].json(); setQuoteLanguages(quoteLanguagesData)
                const statusesData = await responses[6].json(); setStatus(statusesData)
                const reasonsData = await responses[7].json(); setReasons(reasonsData)
                
                setClients(clientsData.filter(user => [ROLES.User, ROLES.Client, ROLES.Admin].includes(user.role_id)))

                if (radication) {
                    const RFQData = await responses[8].json(); setRFQ(RFQData)
                    if (currentQuote) {
                        reset({
                            subtype: formatData(subtypesData.find(e => e.id === quoteData.subtype)),
                            service: formatData(servicesData.find(e => e.id === quoteData.service)),
                            office: formatData(officesData.find(e => e.id === quoteData.office)),
                            client: formatData(clientsData.find(e => e.id === quoteData.client)),
                            origin: quoteData?.shipping?.origin_boarding_terminal,
                            destination: quoteData?.shipping?.destination_boarding_terminal,
                            reason: formatData(reasonsData.find(e => e.id === quoteData.reason)),
                            incoterm: formatData(incotermsData.find(e => e.id === quoteData.incoterm)),
                            language: formatData(quoteLanguagesData.find(e => e.id === quoteData.language)),
                            status: formatData(statusesData.find(e => e.id === quoteData.status)),
                            notes: quoteData.observations,
                        });
                    }
                    if (RFQData) {
                        console.log('RFQData :',RFQData)
                        setValue("origin", RFQData.shipping.origin_boarding_terminal)
                        setValue("destination", RFQData.shipping.destination_boarding_terminal)
                        setValue("office", formatData(officesData[1]))
                        const client = RFQData.users.find(user=>user.role[0]==='Usuario')
                        console.log('client :',client)
                        setValue("client", {
                            label:`${client.name} ${client.last_name} - ${client.email}`,
                            value:client.id,
                        })
                        const incoterm = formatData(incotermsData.find(e => e.name === RFQData.business_terms))
                        setValue("incoterm", incoterm)
                        if (!currentQuote){
                            setDisableForm(false)
                        }
                    }
                }
            })
            .catch(error => console.error('Error:', error));
    }, [radication]);
    console.log('FORM :',watch())
    useEffect(() => {
        if (currentQuote) {
            getQuoteData(token, currentQuote).then(async res => {
                const data = await res.json();
                setDisableForm(true)
                setQuoteData(data);
                setRadication(data.radication)
                navigate('/manageQuotes/')
            })
        }
    }, [currentQuote]);

    const formatData = (data) => {
        return {
            label: data?.name,
            value: data?.value || data?.id,
        }
    }

    const onSubmit = () => {
        const formData = watch()
        const submitData = {
            origin_terminal: formData.origin,
            destination_terminal: formData.destination,
            observations: formData.notes,
            client: formData.client?.value,
            service: selectedService.value,
            subtype: formData.subtype?.value,
            office: formData.office?.value,
            language: formData.language?.value,
            incoterm: formData.incoterm?.value,
            status: formData.status?.value,
            reason: formData.reason?.value,
            terms: 'Condiciones de la oferta',
            radication,
        }

        if (currentQuote && !disableForm) {
            editQuotation(submitData, currentQuote).then(res => {
                toast("success", "Cotizacion modificada")
            }).catch(err => { toast("error", "No se pudo modificar la cotizacion") })
        } else {
            postQuotation(submitData).then(res => {
                let updatedItems = items
                let aux = {}
                aux.id = res.id
                aux.subtype = subtypes.find(e => e.id === submitData.subtype)?.name
                aux.service = services.find(e => e.id === submitData.service)?.name
                aux.office = offices.find(e => e.id === submitData.office)?.name
                aux.client = clients.find(e => e.id === submitData.client)?.name + clients.find(e => e.id === submitData.client)?.last_name
                aux.origin = submitData.origin
                aux.destination = submitData.destination
                aux.reason = reasons.find(e => e.id === submitData.reason)?.name
                aux.incoterm = incoterms.find(e => e.id === submitData.incoterm)?.name
                aux.language = quoteLanguages.find(e => e.id === submitData.quoteLanguage)?.name
                aux.status = status.find(e => e.id === submitData.status)?.name
                aux.notes = submitData.notes

                updatedItems.push(aux)
                setCurrentQuote({
                    id:aux.id,
                    radication:aux.radication,
                })
                setDisableForm(true)
                setItems([...updatedItems]);
                toast("success", "Cotizacion creada con exito")
            }).catch(err => toast("error", "No se pudo crear la cotizacion"))
        }
    };

    const handleDelete = () => {
        if (currentQuote) {
            deleteQuotation(currentQuote).then((res) => {
                setItems(items.filter(item => item.id !== currentQuote))
                setDeleteConfirmation(false)
                setCurrentQuote('')
                toast("success", "Cotizacion eliminada")
            }).catch((err) => {
                setDeleteConfirmation(false)
                toast("error", "No se pudo eliminar la cotizacion")
            })
        }
    }


    const [openFinishModal, setOpenFinishModal] = useState(false)

    const CustomInputSelect = React.forwardRef((props, ref) => (
        <InputSelect ref={ref} {...props} />
    ));

    const CustomInput = React.forwardRef((props, ref) => (
        <Input ref={ref} {...props} />
    ));

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '15px', margin: '20px' }}>
                    <Button text='Nueva cotización' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => {
                        navigate('/quotation-requests/?liquidate=true')
                    }} />
                    <Button text='Modificar' disabled={!currentQuote} bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { currentQuote && setDisableForm(false) }} />
                    <Button text='Guardar' disabled={Object.keys(errors).length} bold bgColor={'primary_300'} textColor='neutral_50' type="submit" />
                    <Button text='Borrar' disabled={!currentQuote} bold bgColor={'delete'} textColor='neutral_50' handler={() => setDeleteConfirmation(true)} />
                </div>
                <Tooltip content="Finaliza la liquidacion y la comparte con el cliente para su aprobación">
                    <Button text='Finalizar liquidación'
                        bold
                        bgColor={'primary_300'}
                        textColor='neutral_50'
                        disabled={!currentQuote}
                        handler={()=>setOpenFinishModal(true)}
                    />
                </Tooltip>
            </div>
            {radication && <form onSubmit={handleSubmit(onSubmit)} errors={errors}>
                <FormContainer disabled={disableForm}>
                    <InputsContainer>
                        <div>
                            <Controller
                                name="subtype"
                                defaultValue={undefined}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label="Subtipo * :" {...field}
                                        isDisabled={disableForm}
                                        // onChange={() => setSelectedService('')}        
                                        options={subtypes?.map(subtype => {
                                            return {
                                                label: subtype.name,
                                                value: subtype.id,
                                            }
                                        })}
                                    />
                                )}
                            />
                            {errors.subtype && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.subtype.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="service"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Servicio *' {...field}
                                        isDisabled={disableForm}
                                        value={selectedService}
                                        onChange={(e) => { setSelectedService(e); setValue('service', e) }}
                                        options={filteredServices?.map(service => {
                                            return {
                                                label: service.name,
                                                value: service.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.service && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.service.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="office"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Oficina *' {...field}
                                        isDisabled={disableForm}
                                        options={offices?.map(office => {
                                            return {
                                                label: office.name,
                                                value: office.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.office && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.office.message}</span>}
                        </div>
                        <div style={{gridColumn:'span 2'}}>
                            <Controller
                                name="client"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Cliente *' {...field}
                                        isDisabled={disableForm}
                                        options={clients.map(client => {
                                            return {
                                                label: `${client.name} ${client.last_name} - ${client.email}`,
                                                value: client.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.client && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.client.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="incoterm"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Incoterm' {...field}
                                        isDisabled={disableForm}
                                        options={incoterms?.map(incoterm => {
                                            return {
                                                label: incoterm.name,
                                                value: incoterm.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.incoterm && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.incoterm.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="origin"
                                control={control}
                                render={({ field }) => (
                                    <PlacesAutocomplete
                                        {...field}
                                    // searchOptions={{
                                    //     componentRestrictions: { country: destinationCountryISO }
                                    // }}
                                    // onSelect={(e)=> {console.log('onSelect :',e)}}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    isDisabled={disableForm}
                                                    label='Origen'
                                                    name={`origin`}
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    value={watch('origin') || ''}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.origin && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.origin.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="destination"
                                control={control}
                                render={({ field }) => (
                                    <PlacesAutocomplete
                                        {...field}
                                    // searchOptions={{
                                    //     componentRestrictions: { country: destinationCountryISO }
                                    // }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    isDisabled={disableForm}
                                                    label='Destino'
                                                    value={watch('destination') || ''}
                                                    name={`destination`}
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.destination && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.destination.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={{label:'En proceso', value:2}}
                                render={({ field }) => (
                                    <CustomInputSelect label='Estado *' {...field}
                                        isDisabled={disableForm}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        options={status.map(state => {
                                            return {
                                                label: state.name,
                                                value: state.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.status && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.status.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="reason"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Motivo' {...field} isDisabled={disableForm}
                                        options={reasons.map(reason => {
                                            return {
                                                label: reason.name,
                                                value: reason.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.reason && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.reason.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="language"
                                defaultValue={{label:'Español', value:1}}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Idioma' {...field}
                                        isDisabled={disableForm}
                                        options={quoteLanguages.map(language => {
                                            return {
                                                label: language.name,
                                                value: language.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.subtype && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.subtype.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="travel_time"
                                defaultValue={{label:'Un dia', value: 1}}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({field }) => (
                                    <CustomInputSelect label="Tiempo en transito *" {...field}
                                        isDisabled={disableForm}       
                                        options={[
                                            {label:'Un dia', value: 1},
                                            {label:'Una semana', value: 7},
                                            {label:'15 dias',value: 15},
                                            {label:'30 dias',value: 30},
                                            {label:'abierto',value: 1},
                                        ]}
                                    />
                                )}
                            />
                            {
                            watch('travel_time')?.label == 'abierto' &&
                                <Input label="Días *" type="number" onChange={(e)=>{setValue("travel_time.value",e.target.value)}} isDisabled={disableForm}/>
                            }
                            {errors.travel_time && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.travel_time.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="expiration"
                                defaultValue={{label:'Un dia', value: 1}}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label="Vencimiento * " {...field}
                                        isDisabled={disableForm}      
                                        options={[
                                            {label:'Un dia', value: 1},
                                            {label:'Una semana', value: 7},
                                            {label:'15 dias',value: 15},
                                            {label:'30 dias',value: 30},
                                            {label:'abierto',value: 0},
                                        ]}
                                    />
                                )}
                            />
                            {
                            watch('expiration')?.label=='abierto' &&
                            <Input label="Dias *" type="number" onChange={(e)=>{setValue("expiration.value",e.target.value)}} isDisabled={disableForm}/>
                            }
                            {errors.expiration && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.expiration.message}</span>}
                        </div>
                        <div style={{ 'gridColumn': 'span 2' }}>
                            {!currentQuote && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Controller
                                    name="notes"
                                    rules={{ required: "Este campo es obligatorio" }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input label='Observaciones *' {...field} />
                                    )}

                                />
                                {errors.notes && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.notes.message}</span>}
                            </div>}
                        </div>

                    </InputsContainer>
                    <RFQDataContainer>
                        {
                            !radication ?
                                <div>
                                    <h3>Selecciona una cotizacion</h3>
                                    <p>Selecciona una cotizacion de la tabla o presiona "Nueva cotizacion" para empezar a operar</p>
                                </div> :
                                RFQ ?
                                    <DisplayData data={RFQ} radication={radication} /> :
                                    <Loading />
                        }
                    </RFQDataContainer>
                    <InputsContainer>
                        <Controller
                            name="load-type"
                            control={control}
                            render={({ field }) => (
                                <Input label='Tipo de carga ' {...field} />
                            )}
                        />
                        <Controller
                            name="Tariff heading"
                            control={control}
                            render={({ field }) => (
                                <Input label='Partida arancelaria' {...field} />
                            )}
                        />
                        <Controller
                            name="Transit-days"
                            control={control}
                            render={({ field }) => (
                                <Input label='Dias de transito' {...field} />
                            )}
                        />
                        <Button text='Guardar cotizacion' disabled={Object.keys(errors).length} bold bgColor={'primary_300'} textColor='neutral_50' type="submit" />
                    </InputsContainer>
                </FormContainer>
            </form>}
            <FinishQuotationModal quoteData={quoteData} open={openFinishModal} setOpen={setOpenFinishModal} />
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la cotizacion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDeleteConfirmation(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' disabled={!currentQuote} handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </div>
    )
}