import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { URL, bgColorStates, textColorStates } from '../../../constants';
import { useSelector } from "react-redux";
import { Loading } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { getQuotations } from '../../../services/quotation.services';

// Components
import Table from '../../../components/Table/Table';

import { useTranslation } from "react-i18next";
import { getOperations } from '../../../services/operations.services';
import { getSubStatusesOperations, getSubStatuses } from '../../../services/operations.services';

const TableServices = ({ filterType, filterSearch }) => {
    const navigate = useNavigate()
    const token = useSelector((state) => state.user.token);

    const [data,setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])

    console.log('services :',data)

    const downloadQuotation = (item) => {
        //log('download')
    }

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Carga', uid: 'load', type: 'text' },
        { name: 'Fecha', uid: 'date', type: 'date' },
        { name: 'Hora', uid: 'hour', type: 'hour' },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Asesor', uid: 'advisor', type: 'text' },
        { name: 'Estado', uid: 'subStatus', type: 'text' }
    ]

    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (item) => navigate(`/services/detail/chat/?radication=${item.idRadication}&tab=chat`)
        },
        {
            name: 'Descargar',
            icon: 'Download',
            handle: downloadQuotation
        }
    ]

    const getListAdvisors = (users) => {
        let list = [];
        users.forEach(user => { if (user.role[0] === 'Asesores') { list.push(` ${user.name} ${user.last_name}`) } })
        return list.toLocaleString()
    }

    useEffect(()=>{
        setLoading(true)
        const asyncTasks = [getQuotations(token), getOperations({token}), getSubStatuses(token), getSubStatusesOperations(token)]
        Promise.all(asyncTasks).then(async res=>{
            const servicesData = await res[0].json();
            const operationsData = await res[1].json();
            const subStatusesData = await res[2].json();
            const subStatusesOperationsData= await res[3].json();

            const tableData = servicesData?.services.map(service => {
                const operation = operationsData.find(op => op.radication === service.radication)
                let lastSubStatus = 'Pending'
                if (operation){
                    const subStatuses = subStatusesOperationsData.filter(sub => sub.operation === operation.id)
                    const sortedsubStatuses = subStatuses.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    const lastSubStatusId = sortedsubStatuses[0].id;
                    lastSubStatus = subStatusesData.find(sub => sub.id === lastSubStatusId)?.name
                }
                return {
                    ...service,
                    subStatus:lastSubStatus
                }
            })
            setData(tableData)
            setLoading(false)
        })
    },[])

    useEffect(() => {
        if (!loading) {
            let dataTable;
            if (data?.length > 0) {
                dataTable = data
                    .filter(service => {
                        if (service.state !== 'Cotizado' && filterType === "Seleccionar")
                            return true;
                        else if (service.state !== 'Cotizado' && filterType === "Carga general")
                            return service.loads[0].load_type === 'Carga General'
                        else if (service.state !== 'Cotizado' && filterType === "Carga proyecto")
                            return service.loads[0].load_type === 'Carga Proyecto'
                    })
                    .map(service => ({
                        id: service.id,
                        idRadication: service.radication,
                        load: service.loads.length > 0 ? service.loads[0].load_type : '',
                        date: new Date(service.created_at),
                        origin: service.shipping.origin_country,
                        destination: service.shipping.destination_country,
                        advisor: getListAdvisors(service.users),
                        subStatus:service.subStatus,
                        // state: {
                        //     name: service.state,
                        //     bgColor: bgColorStates[service.state],
                        //     textColor: textColorStates[service.state]
                        // }
                    }));
                if (filterSearch) {
                    dataTable = dataTable?.filter(service => {
                        let isIncluded = false
                        Object.keys(service).map(key => {
                            if (service[key].toString().toLowerCase().includes(filterSearch.toLowerCase())) {
                                isIncluded = true
                            }
                        })
                        return isIncluded;
                    })
                }
            } else {
                dataTable = data
            }
            console.log("dios mio jesus :",dataTable)
            setItems(dataTable);
        }
    }, [loading, filterType, filterSearch]);
    console.log("items :",items)
    return (
        <div className='h-100'>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items.length > 0 ?
                        <Table columns={columns} items={items} actions={actions} searchBar={false}></Table>
                        :
                        <div className='d-flex justify-content-center'>No hay servicios aprobados</div>
            }
        </div>
    );
}

export default TableServices;
