import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import ViewsHandler from '../../components/ViewsHandler/ViewsHandler';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getUserAuth, logout } from '../../services/auth.services'
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components
import Information from './Information/Information'
import Documents from './Documents/Documents';
import Payments from './Payments/Payments';
import Referrals from './Payments/Payments';
import Settings from './Settings';
//constants
import { ROLES } from '../../constants';
import { useSelector } from 'react-redux';

const StyledProfile = styled('div', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
            fontFamily: 'inherit',
            color: '$primary_300',
            textTransform: 'none'
        },
        ['.Mui-selected']: {
            fontWeight: '600'
        },
        ['.MuiTabs-indicator']: {
            backgroundColor: '$primary_300'
        }
    }
})

const Profile = ({ token }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const userRoles = useSelector(state=>state.user.roles)
    const [tabs ,setTabs] = useState([])
    const [tabId, setTabId] = useState(tabs.findIndex(tab => tab.value === searchParams.get('tab')));
    const [tabValue, setTabValue] = useState(searchParams.get('tab'))
    const [user, setUser] = useState({})

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        navigate('/profile/?tab=' + newValue)
    };

    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }

    useEffect(() => {
        getUserAuth(token).then((res) => {
            console.log('res :',res)
            if (res.user) {
                setUser(res.user)
            } 
        }).catch((err) => console.log("err :", err))
        if (havePermissions([1,4,5,7,9,10,11,12])){
            setTabs([
                {value: 'information',label: 'Información'},
                {value: 'documents',label: 'Documentos'},
                {value: 'payments',label: 'Pagos'},
                {value: 'referal',label: 'Referidos'},
                {value: 'config',label: 'Ajustes'}
            ])
        } else {
            setTabs([
                {value: 'information',label: 'Información'},
                {value: 'payments',label: 'Pagos'},
                {value: 'referal',label: 'Referidos'},
                {value: 'config',label: 'Ajustes'}
            ])
        }
    }, []);

    return (
        <StyledProfile>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="profile"
            >
                {
                    tabs.map((tab, i) =>
                        <Tab key={i} value={tab.value} label={tab.label} />
                    )
                }
            </Tabs>

            <ViewsHandler
                activeView={tabId}
            >
                <Information user={user}></Information>
                <Documents user={user}></Documents>
                <Payments user={user}></Payments>
                <Referrals user={user}></Referrals>
                <Settings user={user}></Settings>

            </ViewsHandler>
        </StyledProfile>
    );
}

export default Profile;