import React, { useState, useEffect, useCallback, useRef } from "react";
import { styled, Loading } from "@nextui-org/react";
import theme from "../../theme";
import PageTitle from "../../components/PageTitle/PageTitle";
import useDraggableScroll from 'use-draggable-scroll';
import styles from "./Home.module.scss"
import { useFetch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { URL } from "../../constants";
import { useTranslation } from "react-i18next";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { useNavigate } from 'react-router-dom'
import { usePDF } from "react-to-pdf";
import { logOutAction } from "../../store/sessionDuck";

// Components
import CardTop from "../../components/CardTop/CardTop";
import Card from "../../components/Cards/Card/Card";
import CO2 from "../../components/Graphics/CO2/CO2"
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import SimpleBarChart from "../../components/Graphics/ActivitiesBarChart/BarChart";
import HorizontalBarChart from "../../components/Graphics/HorizontalChart/HorizontalChart";
import GeneralTable from "./Tables/GeneralTable";
import PieRechart from "../../components/Graphics/PieChart/PieChart";
import HomeConfig from "../../components/HomeConfig/HomeConfig";
import DatesRangePickerModal from "../../components/DateRangePicker/DateRangePicker";
import { Modal } from "@mui/material";

//services
import { getBusinessTermsByUser, getIncotermsByUser } from "../../services/businessTerms.services";
import { GetShipmentsByCountryOrigin } from "../../services/countries.services";
import { GetShipmentsByCountryDestination } from "../../services/countries.services";
import { getShipmentsByShippingMethod, getShippingAnalysisByMethod, getUserShippingMethodRatio, getShippingMethodRatio } from "../../services/shippingMethods.services";
import { getWeightByCountry, getWeightTrendByUser, getWeightTrendByShippingMethod } from "../../services/weight.services";

//User Tracking 
import Tracker from '@openreplay/tracker/cjs';
import { getUserAuth } from '../../services/auth.services';
import { use } from "i18next";

try {
  const tracker = new Tracker({
    projectKey: "QUQx75VyCHiMfj9WpnB4",
  });

  // Additional initialization or tracking logic
} catch (error) {
  console.error('Error initializing OpenReplay tracker:', error.message);

  // Check if the error is related to doNotTrack or incognito mode
  if (error.message.includes('doNotTrack') || error.message.includes('incognito')) {
    // Provide user-friendly guidance on how to resolve the issue
    console.warn('Please disable doNotTrack or use a regular browsing window for full tracking functionality.');
  } else {
    // Handle other errors or display a generic error message
    console.error('An unexpected error occurred:', error);
  }
}
import * as Sentry from "@sentry/react";
import { getStatisticsSplit } from "../../services/home.services";

Sentry.init({
  dsn: "https://82588c9fa13ffce8c0d8360e890a60a0@o4506419815645184.ingest.sentry.io/4506419817480192",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});
const container = document.getElementById('app');


const defaultData = {
  kilosMoved: 0,
  kilosMovedPercentage: 0,
  cubicMeters: 0,
  cubicMetersPercentage: 0,
  teus: 0,
  totalTeusPercentage: 0,
  quoteCount: 0,
  quoteCountPercentage: 0,
  servicesCount: 0,
  servicesCountPercentage: 0,
  totalCost: 0,
  totalCostPercentage: 0,
  totalDim: 0,
  dimPercentage: 0,
  origins: {
    air: {},
    ocean: {},
    ground: {},
  },
  destinations: {
    air: {},
    ocean: {},
    ground: {},
  },
  originsOperation: {
    air: {},
    ocean: {},
    ground: {},
  },
  destinationsOperation: {
    air: {},
    ocean: {},
    ground: {},
  },
};

const StyledHome = styled("section", {
  [`.${theme} &`]: {
    backgroundColor: "$primary_50",
    height: "100%",
    padding: "3rem 2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem"
  },
  ['.title']: {
    display: 'flex',
    marginBottom: '2rem',
  },
  ['.vignette']: {
    width: '20px',
    backgroundColor: '$secondary_300',
    marginRight: '1rem',
    borderRadius: '10px'
  },
  ['h2']: {
    marginBottom: '0px'
  },
  ['.dateFilter div']: {
    display: 'flex'
  },
  ['.dateFilter span']: {
    fontWeight: '600',
    color: "$primary_500"
  },
  ['.dateFilter > div']: {
    gap: '1rem'
  }
});

const StyledCardDeck = styled("div", {
  [`.${theme} &`]: {
    display: "flex",
    gap: "1rem",
    overflowX: "scroll",
    width: "100%",
    paddingBottom: "4px"
  },
});


function Home() {



  const navigate = useNavigate()
  const [user, setUser] = useState({
    name: '',
    lastName: ''
  })
  const { t } = useTranslation("Home");

  const token = useSelector((state) => state.user.token);
  const ref = useRef("div");
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" })
  const [dates, setDates] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  })
  const [data, setData] = useState(null);
  const { data: fetchedData, loading, error } = useFetch(URL + 'home/statistics/', { Authorization: "Token " + token });
  const preparedData = { ...defaultData, ...fetchedData };
  const [configOpen, setConfigOpen] = useState(false)
  const [openDates, setOpenDates] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)

  const { toPDF, targetRef } = usePDF({ filename: 'admin_dashboard.pdf' });

  const [kpis, setKpis] = useState({})
  //console.log('kpis :',kpis)
  useEffect(() => {
    getUserAuth(token)
      .then(({ user }) => {
        const userAuth = {
          email: user.email,
        };

        setUser(userAuth);

        tracker.start({
          userID: userAuth.email,
          metadata: {
            plan: "free",
            rol: "Asesor"
          }
        });
        if (user.first_name === 'guest') {
          navigate('/services')
        }
      })
      .catch((error) => {
        // Aquí puedes manejar el error. Por ejemplo, puedes hacer logout.
        logOutAction()
        // Y también puedes mostrar un mensaje de error.
        console.error('Hubo un error al obtener la autenticación del usuario:', error);
      });
    const updateKpis = async () => {
      try {
        const kpiPromises = [
          getBusinessTermsByUser(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getIncotermsByUser(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),

          GetShipmentsByCountryOrigin(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          GetShipmentsByCountryDestination(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),

          getShippingAnalysisByMethod(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),

          getShipmentsByShippingMethod(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getUserShippingMethodRatio(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getShippingMethodRatio(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),

          getWeightTrendByUser(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getWeightTrendByShippingMethod(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getStatisticsSplit(token)
        ];

        const kpiResponses = await Promise.all(kpiPromises);

        const updatedKpis = {};
        await Promise.all(
          kpiResponses.map(async (res, index) => {
            const key = ['businessTermsByUser', 'incotermsByUser', 'shipmentsByCountryOrigin', 'shipmentsByCountryDestination', 'shippingAnalysisByMethod', 'shipmentsByShippingMethod', 'userShippingMethodRatio', 'shippingMethodRatio', 'weightTrendByUser', 'weightTrendByShippingMethod', 'statistics'][index];
            updatedKpis[key] = await res.json();
          })
        );
        setKpis(updatedKpis);
      } catch (error) {
        console.error(`Error fetching data: ${error.message}`);
      }
    };
    updateKpis();
  }, [dates]);
  //console.log("kpis :", kpis)

  if (loading) {
    return <div><Loading></Loading></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  const formatNumber = (value) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 });
      return `${formattedValue}K`;
    } else {
      return value?.toString();
    }
  };

  try {
    const mockCards = [
      // ... cards using kpis.statistics data
    ];
  } catch (error) {
    console.error("Error fetching statistics:", error);
    const mockCards = [
      // ... cards with error messages or loading indicators
    ];
  }
  const mockCards = [
    {
      icon: "Cube",
      value: formatNumber(kpis?.statistics?.kilosMoved?.[0]?.toFixed(2) ?? 0),
      text: "Kilos movidos",
      percentage: `${formatNumber(kpis?.statistics?.kilosMoved?.[1]?.toFixed(2) ?? 0)}%`
    },
    {
      icon: "Expand",
      value: formatNumber(kpis?.statistics?.cubicMeters?.[0].toFixed(2) ?? 0),
      text: "Metros cúbicos",
      percentage: `${formatNumber(kpis?.statistics?.cubicMeters?.[1].toFixed(2) ?? 0)}%`
    },
    {
      icon: "Terrestre",
      value: formatNumber(kpis?.statistics?.teus?.[0].toFixed(2) ?? 0),
      text: "TEUS",
      percentage: `${formatNumber(kpis?.statistics?.teus?.[1].toFixed(2) ?? 0)}%`
    },
    {
      icon: "Cotizaciones",
      value: formatNumber(kpis?.statistics?.quoteCount?.[0] ?? 0),
      text: "Cotizaciones",
      percentage: `${formatNumber(kpis?.statistics?.quoteCount?.[1].toFixed(2) ?? 0)}%`
    },
    {
      icon: "Servicios",
      value: formatNumber(kpis?.statistics?.servicesCount?.[0] ?? 0),
      text: "Servicios",
      percentage: `${formatNumber(kpis?.statistics?.servicesCount?.[1].toFixed(2) ?? 0)}%`
    },
    {
      icon: "MoneyBag",
      value: formatNumber(kpis?.statistics?.totalCost?.[0].toFixed(2) ?? 0),
      text: "Gastos",
      percentage: `${formatNumber(kpis?.statistics?.totalCost?.[1].toFixed(2) ?? 0)}%`
    },
    {
      icon: "Maritimo",
      value: formatNumber(data?.totalDim ?? preparedData.totalDim) ?? 0,
      text: "DIM",
      percentage: `${data?.dimPercentage?.toFixed(2) ?? preparedData.dimPercentage ?? 0}%`
    },
  ];


  const mockTopCardsAir = {
    origenes: Object.values(data?.origins?.air ?? preparedData.origins.air),
    destinos: Object.values(data?.destinations?.air ?? preparedData.destinations.air),
  };

  const mockTopCardsOcean = {
    origenes: Object.values(data?.origins?.ocean ?? preparedData.origins.ocean),
    destinos: Object.values(data?.destinations?.ocean ?? preparedData.destinations.ocean),
  };

  const mockTopCardsGround = {
    origenes: Object.values(data?.origins?.ground ?? preparedData.origins.ground),
    destinos: Object.values(data?.destinations?.ground ?? preparedData.destinations.ground),
  };

  const mockTopCards = {
    origenes: Object.values(data?.origins?.operation ?? preparedData.originsOperation.air),
    destinos: Object.values(data?.destinations?.operation ?? preparedData.destinationsOperation.air),
  }


  function formatCostStatistics(data) {
    if (!data || typeof data !== 'object') {
      console.error('Invalid data object');
      return; // or handle the error in an appropriate way
    }
    let resultArray = [];
    let allDates = new Set();
    Object.keys(data).forEach(category => {
      Object.keys(data[category]).forEach(date => {
        allDates.add(date);
      });
    });
    allDates.forEach(date => {
      let newObj = {
        name: date,
        Aéreo: data.air[date] || 0,
        Marítimo: data.ocean[date] || 0,
        Terrestre: data.ground[date] || 0
      };
      resultArray.push(newObj);
    });
    return resultArray;
  }

  function downloadPDF() {
    setLoadingPdf(true)
    toPDF().then(() => {
      setLoadingPdf(false)
    }).catch((err) => {
      console.log("error al descargar pdf :", err)
      setLoadingPdf(false)
    })
  }

  return (
    <StyledHome ref={targetRef}>
      <PageTitle>
        {t("title")}
      </PageTitle>
      <div className={styles.dateclass}>
        <div className="dateFilter">
          <span>{t("dates")}</span>
          <div onClick={() => { setOpenDates(true) }} style={{ display: 'flex', gap: '20px', width: 'fit-content' }}>
            <Input label={'desde'} value={format(dates.startDate, "dd/MM/yyyy")} />
            <Input label={'hasta'} value={format(dates.endDate, "dd/MM/yyyy")} />
          </div>
          <DatesRangePickerModal aria-labelledby="modal-date-range" setDates={setDates} open={openDates} setOpen={setOpenDates} />
        </div>
        <div className={styles.btnContainer}>
          <Button handler={() => downloadPDF()} text={loadingPdf ? 'Cargando...' : t('download-button')} endIcon={{ name: 'Download' }} outline disabled={loadingPdf} />
          <Button text={t('config')} endIcon={{ name: 'Setting' }} outline handler={() => { configOpen ? setConfigOpen(false) : setConfigOpen(true) }}></Button>
          {/* {
            configOpen?<HomeConfig setConfigOpen={setConfigOpen}/>:null
          } */}
          <Modal
            open={configOpen}
            onClose={() => setConfigOpen(false)}
            aria-labelledby="modal-config"
          >
            <HomeConfig setConfigOpen={setConfigOpen} />
          </Modal>
        </div>
      </div>

      <StyledCardDeck className={styles.customTrack} ref={ref} onMouseDown={onMouseDown} >
        {
          mockCards.map((card =>
            <Card key={card.text} {...card} />
          ))
        }
      </StyledCardDeck>
      <div className={styles.charts}>
        {/* <ActivitiesChart width={'75%'} data={data ?? fetchedData}></ActivitiesChart> */}
        <SimpleBarChart data={kpis.statistics && formatCostStatistics(kpis.statistics.totalCostType)} title={'Costos por metodo de envio'} icon={'MoneyBag'} />
        <CO2 width={'25%'} kpis={kpis} configOpen={configOpen} openDates={openDates}></CO2>
      </div>
      <div className={styles.cardDeck}>
        {/* <GeneralTable /> */}

        <PieRechart title='Incoterms' icon='Send' data={kpis.incotermsByUser && Object.keys(kpis?.incotermsByUser).map(key => {
          return { name: key, value: kpis.incotermsByUser[key] }
        })} />

        {/* <SimpleLineChart title={t("shipping-trend")} icon='send' data={[]} /> */}

        <PieRechart title={t("shipments-by-method")} icon='Send' data={kpis.shippingAnalysisByMethod && Object.keys(kpis?.shippingAnalysisByMethod).map(key => {
          return { name: key, value: kpis.shippingAnalysisByMethod[key] }
        })} />

        <PieRechart title={t('shipments-by-country-origin')} icon='Volumen' data={kpis.shipmentsByCountryOrigin && Object.keys(kpis.shipmentsByCountryOrigin).map(key => {
          return { name: key, value: kpis.shipmentsByCountryOrigin[key] }
        })} />

        <PieRechart title={t('shipments-by-country-destination')} icon='Volumen' data={kpis.shipmentsByCountryDestination && Object.keys(kpis.shipmentsByCountryDestination).map(key => {
          return { name: key, value: kpis.shipmentsByCountryDestination[key] }
        })} />

        {/* <PieRechart title={t('business-terms')} icon='Send' data={kpis.businessTermsByUser && kpis.businessTermsByUser?.map(e => {
          return { name: e.term, value: e.count }
        })} /> */}

        <HorizontalBarChart title={t('shipments-by-method')} icon='Volumen' data={kpis.shipmentsByShippingMethod && Object.keys(kpis.shipmentsByShippingMethod).map(key => {
          return { name: key, cantidad: kpis.shipmentsByShippingMethod[key] }
        })} />

        <HorizontalBarChart title={t('weight-trend-by-shipping-method')} icon='Volumen' data={kpis.weightTrendByShippingMethod && Object.keys(kpis.weightTrendByShippingMethod).map(key => {
          return { name: key, cantidad: kpis.weightTrendByShippingMethod[key] }
        })} />

        <CardTop title={t("top-air")} objects={mockTopCardsAir} icon="Aereo" />
        <CardTop title={t("top-ocean")} objects={mockTopCardsOcean} icon="Maritimo" />
        <CardTop title={t("transit-in-operation")} objects={mockTopCards} />
        <CardTop title={t("top-land")} objects={mockTopCardsGround} icon="Terrestre" />
      </div>
    </StyledHome>
  );
}

export default Home;
