import React, { useState } from "react";
import logo from "../../../../assets/img/val-big.png";
import { styled } from "@nextui-org/react";
import { usePDF } from "react-to-pdf";
import Button from "../../../../components/Button/Button";

const Container = styled("div", {
  background: "white",
  borderRadius: "8px",
  padding: "30px",
  minWidth:'1000px',
  [".space-between"]: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "200px",
  },
  ["div>p"]: {
    margin: "8px 0",
    fontSize: "13px",
  },
  [">p"]: {
    margin: "4px 0",
    fontSize: "13px",
  },
  ["table"]: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    ["th"]: {
      border: "1px solid black",
      margin: "0",
      padding: "0",
    },
    ["td"]: {
      border: "1px solid black",
      margin: "0",
      padding: "5px",
    },
  },
});
const SectionTitle = styled("div", {
  background: "$primary_300",
  color: "white",
  textAlign: "center",
  margin: "20px 0",
});

export default function Val170({data}) {
  console.log("data :", data);
  const { toPDF, targetRef } = usePDF({ filename: "Val170.pdf" });
  const [loadingPdf, setLoadingPdf] = useState(false);

  function downloadPDF() {
    setLoadingPdf(true);
    toPDF()
      .then(() => {
        setLoadingPdf(false);
      })
      .catch((err) => {
        console.log("error al descargar pdf :", err);
        setLoadingPdf(false);
      });
  }

  return (
    <>
      <div style={{ margin: "20px" }}>
        <Button
          text={loadingPdf ? "Cargando..." : "Descargar pdf"}
          disabled={loadingPdf}
          handler={() => downloadPDF()}
          outline
        />
      </div>
      <Container ref={targetRef}>
        <div className="space-between">
          <p>Registro Conocimiento del Cliente y Proveedor - Circular 170</p>
          <img src={logo} alt="" height={50} />
        </div>
        <div className="space-between">
          <p>Nuevo: {data.fechaConstitucion}</p>
          <p>Actualizacion: {data.fechaConstitucion}</p>
          <p>Fecha: {data.fechaConstitucion}</p>
        </div>
        <SectionTitle>1. INFORMACIÓN GENERAL</SectionTitle>
        <div className="space-between">
          <div>
            <p>Razon social: {data.razonSocial}</p>
            <p>Tipo y numero de documento: {data.documento}</p>
            <p>Dirección: {data.informacion_general}</p>
            <p>Teléfono: {data.telefono}</p>
            <p>Celular/FAX: {data.celularOFax}</p>
            <p>Pagina web: {data.paginaWeb}</p>
          </div>
          <div>
            <p>Ciudad: {data.ciudad}</p>
            <p>Municipio: {data.municipio}</p>
            <p>Correo: {data.correo}</p>
            <p>Pagina web: {data.paginaWeb}</p>
          </div>
        </div>
        <p>Nombre del representante legal</p>
        <div className='space-between'>
          <p>Antigüedad en el Mercado: {data.antiguedadEnMercado}</p>
          <p>Fecha de constitución: {data.fechaConstitucion}</p>
        </div>
        <p>Cuenta con un Sistema de Control y Seguridad (BASC-ISO 28000):</p>
        <p>Cuenta con un Sistema de Calidad: </p>
        <p>Si no cuenta con ningún sistema de gestión. ¿Con qué controles de seguridad cuenta la empresa?</p>
        <SectionTitle>2. INFORMACIÓN CONTABLE Y TRIBUTARIA</SectionTitle>
        <p>CIUU: {data.ciuu}</p>
        <p>Describa su actividad económica: {data.actividadEconomica}</p>
        <div className='space-between'>
          <div>
            <p>%de retención de ICA: {data.retencionICA}</p>
            <p>Régimen común: {data.regimenComun}</p>
            <p>Régimen simplificado: {data.regimenSimplificado}</p>
          </div>
          <div>
            <p>%de retención de la fuente: {data.retencionFuente}</p>
            <p>Gran contribuyente(si/no): {data.granContribuyente}</p>
            <p>Auto- retenedor(si/no): {data.autoRetenedor}</p>
          </div>
        </div>
        <div className='space-between'>
          <p>Total activos: {data.totalActivos}</p>
          <p>Total pasivos: {data.totalPasivos}</p>
          <p>Total patrimonios: {data.totalPatrimonios}</p>
        </div>
        <p>Origen de los recursos:</p>
        <p>Correo electrónico para facturación electrónica:</p>
        <SectionTitle>3. INFORMACION SOCIETARIA (Si se trata de Sociedad Anónima no se requiere esta información)</SectionTitle>
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre de los Socios</th>
              <th>Nit/CC</th>
              <th>% Accionario</th>
            </tr>
          </thead>
          <tbody>
            {data.socios.map((socio, index) => (
              <tr key={index}>
                <td>{socio}</td>
                <td>Nit/CC {index + 1}</td>
                <td>% Accionario {index + 1}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <SectionTitle>REPRESENTACIÓN LEGAL</SectionTitle>
        <div className="space-between">
          <p>Nombre:</p>
          <p>Cedula:</p>
          <p>Cargo:</p>
        </div>
        <div className="space-between">
          <p>Nombre:</p>
          <p>Cedula:</p>
          <p>Cargo:</p>
        </div>
        <SectionTitle>REFERENCIA BANCARIA</SectionTitle>
        <div className="space-between">
          <p>Entidad: {data.referencias_bancarias}</p>
          <p>Numero;</p>
        </div>
        <div className="space-between">
          <p>Sucursal:</p>
          <p>Teléfono:</p>
        </div>
        <SectionTitle>REFERENCIA COMERCIAL</SectionTitle>
        <div className="space-between">
          <div>
            <p>Empresa:</p>
            <p>Contacto:</p>
            <p>Empresa:</p>
            <p>Contacto:</p>
          </div>
          <div>
            <p>Teléfono:</p>
            <p>Dirección:</p>
            <p>Teléfono:</p>
            <p>Dirección:</p>
          </div>
        </div>
        <SectionTitle>
          7.APELLIDOS Y NOMBRES DE PERSONAS O ENTIDADES BENEFICIARIAS DE LAS
          OPERACIONES DE COMERCIO EXTERIOR
        </SectionTitle>
        <div className="space-between">
          <div>
            <p>Empresa:</p>
            <p>Empresa:</p>
            <p>Empresa:</p>
          </div>
          <div>
            <p>Contacto:</p>
            <p>Contacto:</p>
            <p>Contacto:</p>
          </div>
          <div>
            <p>Teléfono:</p>
            <p>Teléfono:</p>
            <p>Teléfono:</p>
          </div>
        </div>
        <SectionTitle>8. CONTACTO - PROCESO COMERCIO EXTERIOR</SectionTitle>
        <div className="space-between">
          <p>Nombre:</p>
          <p>Email:</p>
          <p>Teléfono:</p>
        </div>
        <SectionTitle>9. CONTACTO - CUENTAS POR PAGAR</SectionTitle>
        <div className="space-between">
          <p>Nombre:</p>
          <p>Email:</p>
          <p>Teléfono:</p>
        </div>
        <SectionTitle>10. AUTORIZACIÓN DE MANEJO DE DATOS</SectionTitle>
        <p style={{ fontSize: "9px" }}>
          <strong>Nota 1:</strong> La presente Circular se solicita con el
          propósito de dar cumplimiento a lo señalado en la Circular externa
          0170 de 2002 expedida por la Dirección de impuestos y aduana
          nacionales. <strong>Nota 2:</strong> Manifiesto bajo juramento que la
          información en este Formulario de Conocimiento de Cliente - Circular
          170 es verídica, al igual que los documentos anexos.{" "}
          <strong>Nota 3:</strong> Por medio del presente documento autorizo de
          manera expresa e irrevocable a VAL OPERADOR LOGISTICO o a quien
          represente sus derechos u ostenten en el futuro a cualquier título o
          la calidad de acreedor, a consultar, solicitar, suministrar, reportar,
          procesar, divulgar, y mantener actualizada toda la información que se
          refiera a mi comportamiento crediticio, financiero y comercial a
          cualquier base de datos y o a las centrales de información financiero
          y/o riesgos o a quien represente sus derechos. Usted podrá en
          cualquier momento, ejercer su derecho de conocer, actualizar,
          rectificar, solicitar, ser informado, revocar y/o solicitar la
          supresión de sus datos, así como acceder a sus datos personales que
          han sido objeto de tratamiento, en Cajica: San roque km 7 vía Cajica-
          chia Oficina 240 de la torre 1 Oficial de cumplimiento VAL OPERADOR
          LOGISTICO , se compromete a no utilizar sus datos para un uso distinto
          a aquel por el que fueron recaudados, de acuerdo con la ley.{" "}
          <strong>Nota 4:</strong> Declaro que los recursos de la compañía
          provienen de actividades licitas y por lo tanto no provienen de
          ninguna actividad ilícita de las contempladas en el código penal
          colombiano o en cualquier norma que lo modifique o adicione.
          Igualmente declaro que la compañía no efectúa transacciones destinadas
          a tales actividades o a favor de personas relacionadas con las mismas.
          Eximo a la empresa solicitante de toda responsabilidad que se derive
          por la información errónea, falsa, inexacta que yo hubiera
          proporcionado en este documento o de la violación del mismo. En
          constancia de haber leído, entendido y aceptado lo anterior, firmo el
          presente documento.
        </p>

        <div className="space-between">
          <div>
            <strong>Firma representante legal</strong>
            <p>Nombre:</p>
          </div>
          <div>
            <strong>Firma representante legal</strong>
            <p>Nombre:</p>
          </div>
        </div>
      </Container>
    </>
  );
}
