import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useForm, Controller } from 'react-hook-form';
import { getCiiu } from '../../../../services/ciiu.services';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import InputSelect from '../../../../components/Select/inputSelect';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { useSelector } from 'react-redux';

const Accountant = ({ setAccountant }) => {
    const binaryOption = [
        {
            value: 1,
            name: 'Sí'
        },
        {
            value: 0,
            name: 'No'
        }
    ]
    const token = useSelector(state => state.user.token)
    const [ciiu, setCiiu] = useState([])
    const nameForm = 'accountant'
    const { register, control, getValues } = useForm({
        defaultValues: {
            [nameForm]: {
                ciiu: '',
                economicActivity: '',
                icaWithholding: '',
                sourceWithholding: '',
                totalAssets: '',
                totalLiabilities: '',
                totalNetWorth: '',
                sourceFunds: '',
                billingEmail: '',
                generalTaxRegime: binaryOption[1].value,
                simplifiedTaxRegime: binaryOption[1].value
            }
        }
    });

    const StyledAccountant = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                },
            },
        }
    })

    const saveData = () => {
        setAccountant(getValues()[nameForm])
        // console.log('values :',getValues()[nameForm])
    }

    useEffect(() => {
        saveData()

        getCiiu(token)
            .then(res => res.json())
            .then(data => {
                const _data = data.map((e) => {
                    return {
                        label: e.codigo + '-' + e.descripcion,
                        value: e.codigo,
                    }
                });
                const filtered = _data.filter(e => e.value)
                setCiiu(filtered)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <StyledAccountant>
            <PageTitle>
                Información contable y tributario
            </PageTitle>

            <aside>

            </aside>

            <main className='form'>
                <Controller
                    control={control}
                    name={`${nameForm}.ciiu`}
                    render={({ field: { onChange, value } }) => (
                        // <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.ciiu`} value={value} label='CIIU (Actividades Económicas)' options={ciiu} maxWidth={'500px'} required></Select>
                        <InputSelect options={ciiu} onChange={(value) => { onChange(value.value); saveData(value.value); }} label='CIIU (Actividades Económicas)' />

                    )}
                ></Controller>
                <Input register={register} onChange={saveData} name={`${nameForm}.economicActivity`} label='Describe actividad económica' placeholder='Introducir' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.icaWithholding`} label='Retención ICA' placeholder='% Introducir' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.sourceWithholding`} label='Retención en la fuente' placeholder='% Introducir' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.totalAssets`} label='Total de activos' placeholder='$ Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.totalLiabilities`} label='Total de pasivos' placeholder='$ Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.totalNetWorth`} label='Total de patrimonios' placeholder='Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.sourceFunds`} label='Origen de los recursos' placeholder='Introducir' required></Input>
                <div className='colspan-2 input'>
                    <Input register={register} onChange={saveData} name={`${nameForm}.billingEmail`} label='Correo de facturación electrónica' placeholder='Introducir correo' required></Input>
                </div>

                <Controller
                    control={control}
                    name={`${nameForm}.generalTaxRegime`}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.generalTaxRegime`} value={value} label='¿Régimen común?' options={binaryOption} required></RadioGroup>
                    )}
                ></Controller>
                <div></div>
                <Controller
                    control={control}
                    name={`${nameForm}.simplifiedTaxRegime`}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.simplifiedTaxRegime`} value={value} label='¿Régimen simplificado?' options={binaryOption} required></RadioGroup>
                    )}
                ></Controller>
            </main>
        </StyledAccountant>
    );
}

export default Accountant;
