import { styled } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import theme from "../../../theme";
import * as echarts from 'echarts';
import 'echarts-liquidfill';
import {getCompanyCo2} from '../../../services/CO2.services'
// Components
import Dropdown from "../../Dropdown/Dropdown";
import { useTranslation } from "react-i18next";

function CO2({ token, width, kpis, configOpen, openDates }) {
    const StyledCO2 = styled("div", {
        [`.${theme} &`]: {
            backgroundColor: "$neutral_50",
            width: `${width}`,
            borderRadius: '8px',
            position: 'relative',
        },
        ['.header']: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
        },
        ['.content']: {
            width: '100%',
            height: '100%',
            padding: "2rem",
            display: 'flex',
            flexDirection: 'column'
        },
        ['.graphicContent']: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        ['.graphic']: {
            width: '100%',
            position: 'relative',
            "&::after": {
                content: '',
                display: 'block',
                paddingBottom: '100%',
            },
        },
        ['.CO2Text']: {
            position: 'absolute',
            width: '100%',
            top: '32%',
            textAlign: 'center',
            zIndex: '1',
            fontWeight: '800',
            color: '$primary_400',
            fontSize: '1.7rem',
        },
        ['#CO2Graphic, #CO2Graphic div, #CO2Graphic div canvas']: {
            width: '100% !important',
            height: '100% !important',
        },
        ['.title']: {
            color: '$primary_300',
            fontWeight: '600',
            marginBottom: '0rem'
        },
        ['.nextui-dropdown-button']: {
            height: 'fit-content'
        },
        ['.nextui-button-text']: {
            height: '1.5rem',
            color: '$primary_300',
        }
    });

    const { t } = useTranslation("Home");
    const user = useSelector(state => state.user)

    useEffect(() => {
        const chart = echarts.init(document.getElementById("CO2Graphic"));
        getCompanyCo2(user.token,user.idUser).then(async res=>{
            let co2=await res.json()
            let tons=co2.sum
            chart.setOption({
                series: [{
                    type: 'liquidFill',
                    data: [tons,0.5,0.3],
                    color: ['#C1E9CA', '#A7E0B4', '#67CA7D'],
                    center: ['50%', '50%'],
                    radius: '99%',
                    amplitude: '5%',
                    silent: true,
                    itemStyle: {
                        shadowBlur: 0,
                    },
                    outline: {
                        borderDistance: 0,
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: '#67CA7D',
                            shadowBlur: 0,
                        }
                    },
                    backgroundStyle: {
                        color: 'white'
                    },
                    label: {
                        fontSize: 20,
                        color: '#292E68',
                        insideColor: '#292E68',
                        formatter: function (data) {
                            return `{a|Co2}\n{b|${data.value} Kg}`;
                        },
                        rich: {
                            a: {
                                fontFamily: 'Poppins',
                                fontSize: 20,
                            },
                            b: {
                                fontFamily: 'Poppins',
                                fontSize: 35,
                            },
                        }
                    }
                }]
            });
            setTimeout(() => {
                chart.resize()
            }, 1000)
            window.addEventListener('resize', chart.resize);
        })
        
    }, [t("carbon-footprint"), kpis, configOpen, user, openDates]);

    return (
        <StyledCO2 className="theme">
            <div className="content">
                <div className="header">
                    <span className="title">{t("carbon-footprint")}</span>
                    {/* <Dropdown options={[{ key: 'año', name: 'Año' }, { key: 'mes', name: 'Mes' }, { key: 'día', name: 'Día' }]}></Dropdown> */}
                </div>
                <div className="graphicContent">
                    <div className="graphic">
                        {/* <div className="CO2Text"><span>Co2</span></div> */}
                        <div id="CO2Graphic"></div>
                    </div>
                </div>
            </div>
        </StyledCO2>
    );
}

export default CO2;

