import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@nextui-org/react';
// Components
import TableCO2Payments from './TableCO2History/TableCO2History';


const StyledServices = styled('article', {
    padding: '3rem 2rem',
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: '1.5rem',
    ['.header']: {
        display: 'flex',
        gap: '1.5rem'
    },
})

const Payments = () => {
    const [searchParams] = useSearchParams()
    const [filterType, setFilterType] = useState("Seleccionar");
    const [filterSearch, setFilterSearch] = useState('')

    const [options, setoptions] = useState([
        { key: "Seleccionar", name: "Seleccionar" },
        { key: "Carga general", name: "Carga general" },
        { key: "Carga proyecto", name: "Carga proyecto" }
    ]);

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterSearch(value)
    }

    return (
        <StyledServices>
            <TableCO2Payments />
            {/* <div className="header">
                <Input placeholder='Buscar' startIcon={{ name: 'Search' }} onChange={handleChange} fullWidth></Input>
                <Button text={'Descargar'} endIcon={{ name: 'Download' }} outline bold></Button>
            </div>

            <div className="flex-1 overflow-h">
                <TableServices filterType={filterType} filterSearch={filterSearch}></TableServices>
            </div> */}
        </StyledServices>
    );
}

export default Payments;