import React from 'react'
import { styled, Image } from '@nextui-org/react'
import PageTitle from '../../components/PageTitle/PageTitle'
import ship from '../../assets/sendMethods/maritime.jpg'
import Card3 from '../../components/Cards/Card3/Card3'
import video from '../../assets/tools/video.jpg'
import YoutubeEmbed from '../../components/Youtube/EmbedYoutube'
import TutorialsData from './TutorialsData'

const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '40px',
    // gridTemplateRows: '160px 500px 450px',
})
const StyledMain = styled("div", {
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    gridColumn: 'span 3',
    padding: '50px',
    margin: '20px',
    ['img']: {
        margin: '40px 0',
        borderRadius: '8px',
    }
})
const OtherTutorials = styled("div", {
    backgroundColor: '$neutral_50',
    padding: '50px 20px',
    margin: '20px 0',
})

const tutorials = TutorialsData;


export default function QuotationTutorial() {
    return (
        <StyledContainer>
            <StyledMain>
                <PageTitle>¿Cómo crear una cotización?</PageTitle>
                <YoutubeEmbed embedId="u31qwQUeGuM" />
                <h3>Pasos para generar una cotización</h3>
                <p>
                    Aprende cómo crear una cotización de manera sencilla y efectiva con nuestros consejos y guía detallada. En el video adjunto, te mostraremos paso a paso el proceso para generar una cotización precisa y adaptada a tus necesidades.
                </p>
                <p>
                    Además, abordaremos aspectos clave, como la consideración de las especificaciones del container, para garantizar que tu cotización sea transparente y cumpla con todos los requisitos. No te pierdas esta valiosa información que te facilitará la creación de cotizaciones exitosas.
                </p>
            </StyledMain>
            <OtherTutorials>
                <PageTitle>Explorar mas</PageTitle>
                {
                    tutorials.map(((tutorial, i) => {
                        return <Card3 key={i} title={tutorial.title} image={tutorial.image} to={tutorial.url} iconVideo />
                    }))
                }
            </OtherTutorials>
        </StyledContainer>
    )
}
