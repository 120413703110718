import React from 'react'
import Select from 'react-select'
import theme from '../../theme';
import { styled } from '@nextui-org/react';
import makeAnimated from 'react-select/animated';

const StyledLabel = styled('div', {
    [`.${theme} &`]: {
        color: '$primary_500',
        fontSize: '0.875rem',
        paddingLeft: '0.25rem',
        marginBottom: '0.55rem',
        ['span']: {
            color: '#ff676c'
        }
    }
})

export default function InputSelect({ options, onChange, label, value, isDisabled, disableClear, defaultValue, isMulti }) {
    // console.log('options :',options)
    const colourStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (styles) => ({
            ...styles,
            border: '1px solid ' + theme.colors.primary_300,
            borderRadius: '8px',
            margin: 0,
            padding: 0,
            height: '40px',
            // minWidth:'300px',
            boxShadow: 'none',
            outline: 'none',
            [':hover']: {
                borderColor: 'grey',
            }
        }),
    };

    const animatedComponents = makeAnimated();

    return (
        <div>
            {label &&
                <StyledLabel>
                    <label>{label}</label>
                </StyledLabel>
            }
            <Select
                value={value}
                isMulti={isMulti}
                isClearable={disableClear ? false : true}
                options={options}
                styles={colourStyles}
                components={animatedComponents}
                onChange={onChange}
                isDisabled={isDisabled}
                defaultValue={defaultValue || ''}
                menuPosition="fixed"
            />
        </div>
    )
}