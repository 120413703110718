import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../theme";
import { Step, StepLabel, Stepper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import camelCaseToSnakeCase from "../../../services/case.services";
import { getQuotation, sendQuotation, setServiceUser } from "../../../services/quotation.services";
import { useSelector } from "react-redux";
import toast from "../../../components/Toast/ToastTypes";
import { getLoadTypes } from "../../../services/load.services";
import { getBusinessTerms } from "../../../services/businessTerms.services";
import { getCountries } from '../../../services/countries.services';
import { getShippingMethods } from "../../../services/shippingMethods.services";
import { getIndustries } from "../../../services/industries.services";
import { FormProvider, useForm } from 'react-hook-form';
import { useFetch } from "../../../hooks/useFetch";
//Components
import ViewsHandler from "../../../components/ViewsHandler/ViewsHandler"
import Button from "../../../components/Button/Button";
import Liquidation from "./Liquidation/Liquidation";
import Commerce from "./Commerce/Commerce";
import Negotiation from "./Negotiation/Negotation";
import Send from "./Send/Send";
import Load from "./Load/Load";
import Additional from "./Additional/Additional";
import Documents from "./Documents/Documents";

//Constants
import { liquidationFormSteps } from "../../../constants";
import { URL } from "../../../constants";

const StyledQuotation = styled('div', {
  [`.${theme} &`]: {
    padding: '3rem 2rem',
    minHeigh: '1000px',
  }
})

const StyledStep = styled(Step, {
  [`.${theme} &`]: {
    ['.MuiStepLabel-root']: {
      position: 'relative',
      zIndex: '1',
      ['.MuiStepLabel-iconContainer']: {
        backgroundColor: '$primary_50',
        ['svg']: {
          borderRadius: '1rem',
          color: '$primary_300',
          ['circle']: {
            color: '$primary_300',
          },
          ['text']: {
            fill: '$neutral_50'
          }
        }
      },
      ['.MuiStepLabel-iconContainer.Mui-disabled']: {
        backgroundColor: '$primary_50',
        ['svg']: {
          border: 'solid 1px $primary_300',
          borderRadius: '1rem',
          ['circle']: {
            color: '$primary_50',
          },
          ['text']: {
            fill: '$primary_300'
          }
        }
      },
      ['.MuiStepLabel-label, .MuiStepIcon-text']: {
        fontFamily: 'inherit'
      },
      ['.MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active']: {
        color: '$primary_300'
      },
      ['.MuiStepLabel-label.Mui-disabled']: {
        color: '$primary_100'
      }
    },
    ['.MuiStepConnector-root']: {
      zIndex: '0',
      left: '-50%',
      right: '50%',
      ['span']: {
        border: 'solid 1.5px $primary_300',
      }
    }
  }
})

const StyledFooterContainer = styled('div', {
  [`.${theme} &`]: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    bottom: '0',
    width: '100%',
    height: '75px',
    ['.footer']: {
      backgroundColor: '$neutral_50',
      width: '90%',
      borderRadius: '8px 8px 0px 0px',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '$elevation_1',
      ['.moveSteps']: {
        display: 'flex',
        gap: '1rem'
      }
    }
  }
})

function NewQuotation({ }) {
  const navigate = useNavigate()
  const [radication,setRadication] = useState()

  const user = useSelector((state) => state.user);
  const token = user.token;
  const [searchParams] = useSearchParams()

  const [activeStep, setActiveStep] = useState(0);
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [disabledSave, setDisabledSave] = useState(false)

  const [industries, setIndustries] = useState([])
  const [loadTypes, setLoadTypes] = useState([]);
  const [businessTerms, setBusinessTerms] = useState([]);
  const [commerceTypes, setCommerceTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);

  const [steps, setSteps] = useState(liquidationFormSteps.General);
  const [liquidation, setLiquidation] = useState({});
  const [commerce, setCommerce] = useState({});
  const [negotiation, setNegotiation] = useState({});
  const [send, setSend] = useState({});
  const [load, setLoad] = useState({});
  const [documents,setDocuments] = useState()
  const [client, setClient] = useState()
  

  const methods = useForm({defaultValues:{
    commerce:{
      commerceType: "import",
      shippingMethod: {
        value: 1,
        text: "Aéreo",
        icon: "Aereo"
      }
    },
    load:{
      0:{
        insurance:false,
        longUnit:'cm',
        widthUnit:'cm',
        heightUnit:'cm',
      }
    },
    negotiation:{
      businessTerm: 1,
    },
    additional:{
      imo:false,
      // permissions:false,
      temperatureControl: false,
      dataLogger: false,
      remountable: false,
      arancelaryPosition: false,
      routeStudy: false,
      securityAnalysis: false,
      lashingSurvey: false,
      workCommunities: false,
      additionalSpecifications: '',
    }
  }});

  const headers = {
    "Content-Type": "application/json",
    'Authorization': 'Token ' + token
  }
  const { data } = useFetch(URL + 'user/', headers);

  const handleBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  const handleNext = () => { 
      setActiveStep((preActiveStep) => preActiveStep + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  const save = async () => {
    const submitData = camelCaseToSnakeCase(methods.getValues())
    
    const oldSubmitData = {
      "liquidation": {"load_type":submitData.liquidation.load_type.value},
      "commerce": {
          "commerce_type": submitData.commerce.commerce_type,
          "industry": 1,
          "another_industry":"",
      },
      "negotiation": submitData.negotiation,
      "send": submitData.send,
      "load": submitData.load,
      "additional": submitData.additional,
      "documents": {},
    };
    if (!oldSubmitData.send.delivery_address) {oldSubmitData.send.delivery_address='Ninguno'}
    if (!oldSubmitData.send.pick_up_address) {oldSubmitData.send.pick_up_address='Ninguno'}
    if (!oldSubmitData.send.pick_up_comments) {oldSubmitData.send.pick_up_comments='Ninguno'}
    if (!oldSubmitData.send.delivery_comments) {oldSubmitData.send.delivery_comments='Ninguno'}
    if (!oldSubmitData.send.origin_boarding_terminal) {oldSubmitData.send.origin_boarding_terminal='Ninguno'}
    if (!oldSubmitData.send.destination_boarding_terminal) {oldSubmitData.send.destination_boarding_terminal='Ninguno'}
    if (!oldSubmitData.additional.purchase_order) {oldSubmitData.additional.purchase_order='Ninguna'}

    oldSubmitData.send.shipping_method = submitData.commerce.shipping_method.value,
    oldSubmitData.send.origin_city = 'origin_city',
    oldSubmitData.send.destination_city = 'destination_city',
  
    sendQuotation(oldSubmitData, token)
      .then(async res => {
        const data = await res.json()
        if (res.ok) {
          if (client) {
            getQuotation(data.radication_number, token).then( async res => {
              const serviceData = await res.json();
              setServiceUser(token, client.value, serviceData.id).then(res=>{
                console.log("res :",res)
              }).catch(err=>{
                console.log("err :",err)
              })
            })
          }
          navigate(`/quotation/detail/?radication=${data.radication_number}&created=quotation`)
        } else {
          Object.keys(data.errors).forEach(input => {
            toast('error', data.errors[input]);
          });
        }
    })
  }
  //console.log("Form :",methods.watch())
  useEffect(() => {
    setCommerceTypes([
      {value: 'import',name: 'Importación'},
      {value: 'export',name: 'Exportación'},
      {value: 'custom',name: 'Aduanero'},
      {value: 'triangular',name: 'Triangular'},
    ])

    const radication = searchParams.get('radication');
    setRadication(radication)
    const asyncTasks = [getLoadTypes(token),getBusinessTerms(token),getCountries(token),getShippingMethods(token),getIndustries(token)]
    if (radication){
      asyncTasks.push(getQuotation(radication,token))
    }
    Promise.all(asyncTasks).then(async res => {
      const loadTypesData = await res[0].json(); 
      const businessTermsData = await res[1].json();
      const countriesData = await res[2].json();
      const shippingMethodsData = await res[3].json();
      const industriesData = await res[4].json();

      setLoadTypes(loadTypesData)
      setBusinessTerms(businessTermsData)
      setCountries(countriesData)
      setShippingMethods(shippingMethodsData)
      setIndustries(industriesData)
      
      if (radication){
        const quoteData = await res[5].json();
        const formattedQuoteData = {
          "load": {
              "0": {
                  "loadName": "carga",
                  "loadValue": quoteData.loads[0].value.toString(),
                  "long": quoteData.loads[0].long.toString(),
                  "width": quoteData.loads[0].width.toString(),
                  "height": quoteData.loads[0].height.toString(),
                  "amount": quoteData.loads[0].amount.toString(),
                  "unitWeight": quoteData.loads[0].unit_weight.toString(),
                  "totalWeight": quoteData.loads[0].total_weight,
                  "insurance": quoteData.loads[0].insurance === 1
              }
          },
          "negotiation": {
              "businessTerm": businessTermsData.find(term => term.name === quoteData.business_terms)?.id
          },
          "additional": {
              "packaging": false,
              "imo": false,
              "temperatureControl": quoteData.options.temperature_control === 1,
              "dataLogger": quoteData.options.data_logger === 1,
              "remountable": quoteData.options.remountable === 1,
              "arancelaryPosition": quoteData.options.arancelary_position === 1,
              "routeStudy": quoteData.options.route_study === 1,
              "securityAnalysis": quoteData.options.security_analysis === 1,
              "lashingSurvey": quoteData.options.lashing_survey === 1,
              "workCommunities": quoteData.options.work_communities === 1,
              "additionalSpecifications": quoteData.additional_specifications,
              "purchase_order": quoteData?.purchase_order
          },
          "liquidation": {
              "loadType": quoteData.loads[0].load_type
          },
          "commerce": {
              "commerceType": quoteData.business_type,
              "shippingMethod": {
                  "value": 1,
                  "text": "Aéreo",
                  "icon": "Aereo"
              }
          },
          "send": {
              "originCountry": countriesData.find(country=>country.name === quoteData.shipping.origin_country)?.id,
              "pickUpAddress": quoteData.shipping.pickup_address,
              "pickUpComments": quoteData.shipping.pickup_comments,
              "originBoardingTerminal": quoteData.shipping.origin_boarding_terminal,
              "destinationBoardingTerminal": quoteData.shipping.destination_boarding_terminal,
              "destinationCountry": countriesData.find(country=>country.name === quoteData.shipping.destination_country)?.id,
              "deliveryAddress": quoteData.shipping.delivery_address,
              "deliveryComments": quoteData.shipping.delivery_comments
          }
      };        
      methods.reset(formattedQuoteData)
      }
    })
    
  }, []);

  return (
    <div>
      <StyledQuotation>
        <Stepper activeStep={activeStep} alternativeLabel>
          {
            steps.map((step, i) => (
              <StyledStep key={i}>
                <StepLabel>{step.name}</StepLabel>
              </StyledStep>
            ))
          }
        </Stepper>
        <FormProvider {...methods}>
          <ViewsHandler
            activeView={activeStep}
          >
              <Liquidation  setDisabledContinue={setDisabledContinue} loadTypes={loadTypes}></Liquidation>
              <Commerce shippingMethods={shippingMethods} setDisabledContinue={setDisabledContinue} commerceTypes={commerceTypes} loadTypes={loadTypes}></Commerce>
              <Negotiation setDisabledContinue={setDisabledContinue} negotiation={negotiation} commerceType={commerce?.commerceType} businessTerms={businessTerms}></Negotiation>
              <Send loadTypes={loadTypes} countries={countries} shippingMethods={shippingMethods} setDisabledContinue={setDisabledContinue}></Send>
              <Load setDisabledContinue={setDisabledContinue} activeStep={activeStep} load={load} setLoad={setLoad} loadType={liquidation?.loadType} shippingMethod={send?.shippingMethod} loadTypes={loadTypes} shippingMethods={shippingMethods}></Load>
              <Additional client={client} setClient={setClient} loadTypes={loadTypes} setDisabledSave={setDisabledSave}></Additional>
              <Documents setDocuments={setDocuments}></Documents>
          </ViewsHandler>
        </FormProvider>
      </StyledQuotation>

      <StyledFooterContainer>
        <div className='footer'>
          <Button handler={() => navigate('/quotation')} text='Salir' bgColor='primary_50' startIcon={{ name: 'ArrowLeft', size: '13' }} outline bold> </Button>
          <div className='moveSteps'>
            <Button handler={handleBack} text='Atrás' bgColor='primary_50' disabled={activeStep === 0} outline bold></Button>
            {
              activeStep === steps.length - 1 ?
                radication? <Button disabled={disabledContinue} text='Guardar cambios' bgColor='primary_400' textColor='neutral_50' handl bold
                  handler={()=> toast("warn","Aun no implementado")}
                />:
                <Button handler={save} disabled={disabledSave} text='Guardar' bgColor='primary_400' textColor='neutral_50' bold></Button>
                :
                <Button handler={handleNext} text='Continuar' bgColor='primary_400' disabled={disabledContinue} textColor='neutral_50' bold></Button>
            }
          </div>
        </div>
      </StyledFooterContainer>
    </div>
  );
}
export default NewQuotation;
