import React, { useEffect, useState } from 'react';
import { styled, Modal } from "@nextui-org/react";
import Button from '../../components/Button/Button';
import { format } from "date-fns";
import Input from '../../components/Input/Input';
import ColoredPieChart from '../../components/Graphics/ColoredPieChart';
import ComposedBarChart from '../../components/Graphics/ComposedChart/ComposedChart';
import SmallTable from './SmallTable';
import DateRangePickerModal from '../../components/DateRangePicker/DateRangePicker';
import { getCO2Stats } from '../../services/CO2.services';
import { useSelector } from 'react-redux';
import { usePDF } from 'react-to-pdf';

const StyledContainer = styled("div", {
    padding: '50px',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: '20px',
    rowGap: '40px',
    gridTemplateRows: '340px 420px',
    ['.title']: {
        width: 'fit-content',
        color: 'grey',
        margin: 'auto',
        marginTop: '15px',
    },
    ['.total']: {
        width: 'fit-content',
        fontWeight: 'bold',
        color: 'grey',
        margin: 'auto',
        marginTop: '5px',
    }
});
const WideDiv = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    background: '$neutral_50',
    gridColumn: 'span 6',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
});
const Pies = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    maxHeight: '250px',
});
const Legend = styled("div", {
    gridColumn: 'span 4',
    display: 'flex',
    width: 'fit-content',
    margin: 'auto'
});
const SmallChart = styled("div", {
    margin: 'auto',
    height: '200px',
    minWidth: '200px',
});
const TableContainer = styled("div", {
    gridColumn: 'span 2',
    backgroundColor: '$neutral_50',
    height: '420px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
});
const BigChart = styled("div", {
    gridColumn: 'span 4',
    background: '$neutral_50',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
});

const colors = ['#fbc644', '#fd7c5c', '#dc3546', '#6981ec', '#89ac76','#9F33FF'];

export default function CO2Dashboard() {
    const token = useSelector(state => state.user.token);
    const { toPDF, targetRef } = usePDF({ filename: 'admin_dashboard.pdf' });

    const [open, setOpen] = useState(false);
    const [dates, setDates] = useState({});
    const [data, setData] = useState([]);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const getChartTitle = (index) => {
        const labels=['CO2 Emitido', 'Toneladas Movidas', 'Kilometros']
        return labels[index];
    };

    const getTotal = (subData) => {
        return subData.reduce((total, item) => total + item.value, 0);
    };

    const getData = () => {
        const asyncTasks = [getCO2Stats(token)];
        Promise.all(asyncTasks).then(async responses => {
            const shippingsData = await responses[0].json();
            console.log(shippingsData);

            const formatShippingsData = [];
            const keysco2 = Object.keys(shippingsData.co2);
            keysco2.forEach(key => {
                formatShippingsData.push({
                    name: key,
                    value: shippingsData.co2[key]
                });
            });

            const formatShippingsData2 = [];
            const keystons = Object.keys(shippingsData.tons);
            keystons.forEach(key => {
                formatShippingsData2.push({
                    name: key,
                    value: shippingsData.tons[key]
                });
            });

            const formatShippingsData3 = [];
            const keysdistance = Object.keys(shippingsData.distance);
            keysdistance.forEach(key => {
                formatShippingsData3.push({
                    name: key,
                    value: shippingsData.distance[key]
                });
            });

            setData([formatShippingsData, formatShippingsData2, formatShippingsData3]);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    function downloadPDF() {
        setLoadingPdf(true);
        toPDF().then(() => {
            setLoadingPdf(false);
        }).catch((err) => {
            console.log("error al descargar pdf :", err);
            setLoadingPdf(false);
        });
    }

    return (
        <div ref={targetRef}>
            <div style={{ position: 'relative', padding: '20px 50px', display: 'flex', justifyContent: 'space-between' }}>
                <div onClick={() => setOpen(true)} style={{ display: 'flex', gap: '20px', width: 'fit-content' }}>
                    {dates?.startDate ? <Input value={format(dates.startDate, "dd/MM/yyyy")} /> : <Input value="" placeholder={"Desde..."} />}
                    {dates?.endDate ? <Input value={format(dates.endDate, "dd/MM/yyyy")} /> : <Input value="" placeholder={"Hasta..."} />}
                </div>
                <Button handler={() => downloadPDF()} text={loadingPdf ? 'Cargando...' : 'Descargar'} endIcon={{ name: 'Download' }} outline disabled={loadingPdf} />
                <DateRangePickerModal setDates={setDates} open={open} setOpen={setOpen} />
            </div>
            <StyledContainer>
                <WideDiv>
                    <Pies>
                        {data.map((subData, index) => (
                            <SmallChart key={index}>
                                <p className='title'>{getChartTitle(index)}</p>
                                <p className='total'>Total : {getTotal(subData)}</p>
                                <ColoredPieChart data={subData} colors={colors} radius={60} />
                            </SmallChart>
                        ))}
                    </Pies>
                    <Legend>
                        {data[0]?.map((item, i) => (
                            <div style={{ display: 'flex', margin: 'auto', marginTop: '20px', width: '100px' }} key={i}>
                                <div style={{ background: colors[i], width: '20px', height: '15px', borderRadius: '2px', margin: '5px' }} />
                                <span style={{ color: colors[i] }}>{item.name}</span>
                            </div>
                        ))}
                    </Legend>
                </WideDiv>
                <BigChart>
                    <p className='title' >CO2 por metodo</p>
                    <ComposedBarChart barDataKey={"CO2"} lineDataKey={"TL equivalente CO2"} data={data[0]} />
                </BigChart>
                <TableContainer>
                    <SmallTable />
                </TableContainer>
            </StyledContainer>
        </div>
    );
}
