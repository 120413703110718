export const mockTableProject = [
    {
        textTitle: "Atributos especiales",
        iconf2: { name: "Verify", color: "#FFF" }, f2c1: " Mercancias peligrosas",
        iconf3: { name: "Verify", color: "#FFF" }, f3c1: " Temperatura sensible",
        iconf4: { name: "Verify", color: "#FFF" }, f4c1: " No apilables",
        iconf5: { name: "Verify", color: "#FFF" }, f5c1: " Mercancias peligrosas",
    },
    {
        textTitle: "Contenedor de 20 pies OPEN TOP",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "6.058 mm", f2c3: "5.900 mm", f2c4: "",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.345 mm", f3c4: "2.335 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.400 mm", f4c4: "2.290 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "29,35", f5c4: "",
    },
    {
        textTitle: "Contenedor de 40 pies OPEN TOP",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "12.192 mm", f2c3: "11.575 mm", f2c4: "",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.285 mm", f3c4: "2.280 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.250 mm", f4c4: "2.200 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "58,70 m3", f5c4: "",
    },
    {
        textTitle: "Contenedor de 20 pies FLAT RACK",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "6.058 mm", f2c3: "5.900 mm", f2c4: "5.700 mm",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.400 mm", f3c4: "2.250 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.285 mm", f4c4: "2.285 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "28,35 m3", f5c4: "",
    },
    {
        textTitle: "Contenedor de 40 pies FLAT RACK",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "12.192 mm", f2c3: "12.150 mm", f2c4: "11.835 mm",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.400 mm", f3c4: "2.230 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.035 mm", f4c4: "2.035 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "58,70 m3", f5c4: "",
    },
]

export const mockTableGeneral = [
    {
        textTitle: "Atributos especiales",
        iconf2: { name: "Verify", color: "#FFF" }, f2c1: " Mercancias peligrosas",
        iconf3: { name: "Verify", color: "#FFF" }, f3c1: " Temperatura sensible",
        iconf4: { name: "Verify", color: "#FFF" }, f4c1: " No apilables",
        iconf5: { name: "Verify", color: "#FFF" }, f5c1: " Mercancias peligrosas",
    },
    {
        textTitle: "Contenedor de 20 pies",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "6.058 mm", f2c3: "5.900 mm", f2c4: "",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.345 mm", f3c4: "2.335 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.400 mm", f4c4: "2.290 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "29,35", f5c4: "",
    },
    {
        textTitle: "Contenedor de 40 pies",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "12.192 mm", f2c3: "12.030 mm", f2c4: "",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.345 mm", f3c4: "2.335 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.591 mm", f4c3: "2.400 mm", f4c4: "2.290 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "67,70 m3", f5c4: "",
    },
    {
        textTitle: "Contenedor de 40 pies HIGH CUBE",
        f1c1: "Medidas", f1c2: " Externas", f1c3: "Internas", f1c4: "Puertas abiertas",
        iconf2: { name: "Largo" }, f2c1: " Largo", f2c2: "12.192 mm", f2c3: "12.030 mm", f2c4: "",
        iconf3: { name: "Ancho" }, f3c1: " Ancho", f3c2: "2.438 mm", f3c3: "2.400 mm", f3c4: "2.335 mm",
        iconf4: { name: "Alto" }, f4c1: " Alto", f4c2: "2.896 mm", f4c3: "2.710 mm", f4c4: "2.595 mm",
        iconf5: { name: "Volumen" }, f5c1: " Volumen", f5c2: "", f5c3: "76,50 m3", f5c4: "",
    },

]