import React from 'react'
import { Tooltip, styled } from '@nextui-org/react';

const Container = styled("div",{
    fontSize:'14px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
})

export default function DisplayData({ data, radication }) {
    let totalWeight = 0;
    data?.loads?.forEach( load => {
        totalWeight += load.total_weight
    })

    function calculateCBM(length, length_unit, width, width_unit, height, height_unit) {
        if (!length_unit){length_unit='cm'}
        if (!width_unit){width_unit='cm'}
        if (!height_unit){height_unit='cm'}
        const conversionFactors = {
            cm: 0.01,
            m: 1,
            in: 0.0254
        };

        const lengthInMeters = length * conversionFactors[length_unit];
        const widthInMeters = width * conversionFactors[width_unit];
        const heightInMeters = height * conversionFactors[height_unit];

        const cbm = lengthInMeters * widthInMeters * heightInMeters;
        //RETORNA EN METROS CUBICOS
        return cbm;
    }

    function formatCBM(cubicMeters){
        if (cubicMeters < 0.09999999){
            return `${(cubicMeters*100000).toFixed(2)} cm³`
        } else {
            return cubicMeters.toFixed(2) + 'm³'
        }
    }

    let totalCBM = 0;

    data?.loads?.forEach(load => {
        totalCBM += calculateCBM(load.long,load.long_unit,load.width,load.width_unit,load.height,load.height_unit)
    })
    return (
        !data.error && <Container>
            <h3>{radication}</h3>
            <strong>General</strong>
            <span>Tipo de operacion : {data.business_type}</span>
            <span>Industria : {data.industry}</span>
            <span>Incoterm: {data.business_terms}</span>
            <strong>Envio</strong>
            <span>Metodo de envio: {data.shipping?.shipping_method}</span>
            <span>Origen : {`${data.shipping?.pickup_address} ,${data.shipping?.origin_boarding_terminal}, ${data.shipping?.origin_country}`}</span>
            <span>Destino : {`${data.shipping?.delivery_address} ,${data.shipping?.destination_boarding_terminal}, ${data.shipping?.destination_country}`}</span>

            <strong>Cargas :</strong>
            <span>Peso total : {totalWeight} Kg</span>
            <span>CBM total : {formatCBM(totalCBM)}</span>
            {
                data.shipping?.shipping_method==="air" && <span>Peso cargable aereo : {(totalCBM * 166.67).toFixed(3)}</span>
            }
            {
                data.shipping?.shipping_method==="ocean" && <span>Toneladas totales : {totalWeight/1000}</span>
            }
            <div style={{display:'flex', flexWrap:'wrap', gap:'20px', margin:'10px'}}>
                {
                    data.loads.map((load, index) => {
                        return (
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <strong>Carga  {index + 1} :</strong>
                                <span>{`Nombre : ${load.name}`}</span>
                                <span>{`Valor : ${load.value} USD`}</span>
                                <span>{`Cantidad : ${load.amount}`}</span>
                                <span>{`Medidas : ${load.long}${load.long_unit} x ${load.width}${load.width_unit} x ${load.height}${load.height_unit}`}</span>
                                <span>{`cmb : ${formatCBM(calculateCBM(load.long,load.long_unit,load.width,load.width_unit,load.height,load.height_unit))}`}</span>
                                <Tooltip content="peso x cantidad" color={"primary"}>
                                    <span>{`Peso total : ${load.total_weight} ${load.total_weight_unit}`}</span>
                                </Tooltip>
                            </div>
                        )
                    })
                }
            </div>
            <strong>Adicional : </strong>
            <span>Requiere control de temperatura : {data.options.temperature_control === 1 ? "Si" : "No"}</span>
            <span>Requiere Data logger : {data.options.data_logger === 1 ? "Si" : "No"}</span>
            <span>La carga es remontable : {data.options.remountable === 1 ? "Si" : "No"}</span>
            <span>Requiere posicion arancelaria : {data.options.arancelary_position === 1 ? "Si" : "No"}</span>
            <span>Especificaciones adicionales : {data.options.additional_specifications || "Ninguno"}</span>
        </Container>
    )
}
