import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { useSearchParams } from 'react-router-dom';
import toast from '../../components/Toast/ToastTypes';
import { debounce } from 'lodash';
// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import TableServices from './TableServices/TableServices';

import { useTranslation } from "react-i18next";

const StyledServices = styled('article', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            display: 'flex',
            gap: '1.5rem'
        },
    }
})

const Services = () => {
    const [searchParams] = useSearchParams()
    const [filterType, setFilterType] = useState("Seleccionar");
    const [filterSearch, setFilterSearch] = useState('')

    const [options, setoptions] = useState([
        { key: "Seleccionar", name: "Seleccionar" },
        { key: "Carga general", name: "Carga general" },
        { key: "Carga proyecto", name: "Carga proyecto" }
    ]);

    useEffect(() => {
        setTimeout(() => {
            let message;

            if (searchParams.get('created') === 'liquidation') {
                message = '¡Se ha aprobado con éxito tu liquidación!'
                toast('success', message)
            } else if (searchParams.get('created') === 'pre-liquidation') {
                const title = 'Tu pre-liquidación ha sido aprobada con éxito'
                message = <>Recuerda que en las próximas <b>24 horas</b> recibiras tu liquidación final.</>
                toast('success', message, title)
            }
        }, 1000)
    }, []);

    const handleChange = (value) => {
        setFilterSearch(value)
    }
    const debouncedSearch = debounce((value) => {
        handleChange(value)
    }, 300);

    return (
        <StyledServices>
            <PageTitle>
                Servicios
            </PageTitle>
            <div className="header">
                <Input placeholder='Buscar' startIcon={{ name: 'Search' }} onChange={(e) => { debouncedSearch(e.target.value) }} fullWidth></Input>
                <Dropdown options={options} onChange={data => setFilterType(data[0])} outline></Dropdown>
                <Button text={'Descargar'} endIcon={{ name: 'Download' }} outline bold></Button>
            </div>
            <div className="flex-1 overflow-h">
                <TableServices filterType={filterType} filterSearch={filterSearch}></TableServices>
            </div>
        </StyledServices>
    );
}

export default Services;
