import Icon from '../../Icon/Icon';
import theme from '../../../theme';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { styled } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

export default function HorizontalBarChart({ title, icon, data }) {

    const StyledDiv = styled("div", {
        backgroundColor: "$neutral_50",
        minHeight: '20rem',
        borderRadius: "8px",
        display: "flex",
        flexDirection: 'column',
    })
    const TitleIconContainer = styled("div", {
        display: 'flex',
        gap: '10px',
        margin: '30px',
        width: '100%',
    })
    const Title = styled("p", {
        color: "$primary_300",
        fontWeight: '800',
    })
    const noData = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #CCC",
        height: "232px",
        padding: "10px",
    };

    const { t } = useTranslation("Home")

    return (
        <StyledDiv>
            <TitleIconContainer>
                <Icon name={icon} color={theme.colors.secondary_200} />
                <Title>{title}</Title>
            </TitleIconContainer>
            {data?.length === 0 ? (
                <div style={noData}>
                    <p>{t('no-data')}</p>
                </div>
            ) : (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        layout="vertical" // establece el diseño en vertical
                        width={500}
                        height={200}
                        data={data}
                        margin={{
                            top: 0,
                            right: 30,
                            left: 140,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="cantidad" stackId="a" fill="#EEAE5B" barSize={30} />
                    </BarChart>
                </ResponsiveContainer>)}
        </StyledDiv>
    );
}