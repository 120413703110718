import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from "react-redux";
import { getUsers } from "../../../../services/user.services";
import useUserRoles from "../../../../hooks/useUserRoles";
//Componentes

import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import Textarea from "../../../../components/Textarea/Textarea";
import InputSelect from "../../../../components/Select/inputSelect";
import Input from "../../../../components/Input/Input";

import { ROLES } from "../../../../constants";

const StyledAdditional = styled("div", {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['aside']: {
            color: "$primary_200"
        },
        ['main']: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
        }
    }
});

const binaryOptions = [
    {value: true, name: "Sí"},
    {value: false,name: "No"}
]

const nameForm = 'additional'

function Additional({client, setClient, loadTypes, setDisabledSave }) {
    const user = useSelector(state => state.user)

    const [users, setUsers] = useState([])

    const userRoles = useUserRoles()

    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }

    useEffect(()=>{
        getUsers(user.token).then(async res => {
            const data = await res.json();
            setUsers(data)
        }).catch( err => console.log('err :',err))
    },[])

    const { register, control, getValues, watch } = useFormContext({
        defaultValues: {
            [nameForm]: {
                packaging:binaryOptions[1].value,
                // permissions:binaryOptions[1].value,
                temperatureControl: binaryOptions[1].value,
                dataLogger: binaryOptions[1].value,
                remountable: binaryOptions[1].value,
                arancelaryPosition: binaryOptions[1].value,
                routeStudy: binaryOptions[1].value,
                securityAnalysis: binaryOptions[1].value,
                lashingSurvey: binaryOptions[1].value,
                workCommunities: binaryOptions[1].value,
                additionalSpecifications: '',
            }
        }
    });

    useEffect(() => {
        //VALIDACIONES
        const additional = getValues('additional')
        if (
            (additional.packaging && !additional.whichPackaging) ||
            (additional.permissions && !additional.whichPermissions) ||
            watch(`${nameForm}.additionalSpecifications`).length > 244
        ){
            setDisabledSave(true)
        } else {
            setDisabledSave(false)
        }
    }, [watch()]);

    const loadType = getValues('liquidation.loadType')?.value
    
    return (
        <StyledAdditional>
            <PageTitle>
                Información adicional
            </PageTitle>
            
            {havePermissions([ROLES.Admin, ROLES.Advisor]) && 
            <InputSelect
                label={'Selecciona a nombre de que usuario se creara la cotizacion'}
                value={client}
                onChange={(e) => setClient(e)}
                options={users.filter(user=>user.role_id=='4').map(user => {
                    return {
                        label:`${user.email} - ${user.name} ${user.last_name}`,
                        value:user.id,
                    }
            })}/>}

            <Controller
                control={control}
                name={`${nameForm}.purchaseOrder`}
                render={({field}) => (
                    <Input label="Numero de orden de compra" {...field}/>
                )}
            ></Controller>

            {
                loadType === loadTypes[0]?.id ?
                    <aside>Necesitamos información adicional de tu carga general.</aside>
                    : loadType === loadTypes[1]?.id &&
                    <aside>Necesitamos información adicional de tu carga proyecto.</aside>
            }

            <main>
                {
                    loadType === loadTypes[0]?.id ?
                        <>  
                            {/* <Controller
                                control={control}
                                name={`${nameForm}.packaging`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.packaging`} value={value} label='1 - ¿Requieres un embalaje en especial?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller> */}
                            {watch(`${nameForm}.packaging`) && <Input label={"Cual?"} placeholder={'Embalaje para mercancías peligrosas'}/>}
                            {
                                watch(`${nameForm}.packaging`) && 
                                <Controller
                                    control={control}
                                    name={`${nameForm}.whichPackaging`}
                                    render={({ field }) => (
                                        <Input label={"Cual?"} placeholder={'Embalaje para mercancías peligrosas'} {...field}/>
                                    )}
                                />
                            }

                            {/* Campo solo visual : */}
                            {/* <RadioGroup label='1 - ¿La carga requiere algún tipo de permiso o autorización especial de IMO?' options={binaryOptions}></RadioGroup> */}
                            {/* Campo funcional : */}
                            <Controller
                                control={control}
                                name={`${nameForm}.imo`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.permissions`} value={value} label='1 - ¿La carga requiere algún tipo de permiso o autorización especial de IMO?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            {watch(`${nameForm}.imo`) && <Input label={"Cual?"} placeholder={'Permiso Especial de Navegación'}/>}

                            {/* {
                                watch(`${nameForm}.imo`) && 
                                <Controller
                                    control={control}
                                    name={`${nameForm}.whichPermissions`}
                                    render={({ field }) => (
                                        <Input label={"Cual?"} placeholder={'Permiso Especial de Navegación'} {...field}/>
                                    )}
                                />
                            } */}
                            <Controller
                                control={control}
                                name={`${nameForm}.temperatureControl`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.temperatureControl`} value={value} label='1 - ¿Requieres control de temperatura?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.dataLogger`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.dataLogger`} value={value} label='2 - ¿Requieres de data logger?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.remountable`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.remountable`} value={value} label='3 - ¿La carga es remontable?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.arancelaryPosition`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.arancelaryPosition`} value={value} label='4 - ¿Requieres posición arancelaria?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>
                        </>
                        : loadType === loadTypes[1]?.id &&
                        <>
                            <Controller
                                control={control}
                                name={`${nameForm}.routeStudy`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.routeStudy`} value={value} label='1 - ¿Requiere estudio de ruta?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.securityAnalysis`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.securityAnalysis`} value={value} label='2 - ¿Requiere análisis de seguridad?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.lashingSurvey`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value)}} name={`${nameForm}.lashingSurvey`} value={value} label='3 - ¿Requiere certificación de trincado o suveyor?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.workCommunities`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value)}} name={`${nameForm}.workCommunities`} value={value} label='4 - ¿Requiere trabajo con comunidades?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>
                        </>
                }

                    <Controller
                        control={control}
                        name={`${nameForm}.additionalSpecifications`}
                        render={({ field  }) => (
                            <Textarea {...field} label='5 - Especificaciones adicionales' placeholder='Ejemplo: Contenido inflamable...'/>
                        )}
                    ></Controller>
                    {watch(`${nameForm}.additionalSpecifications`).length > 244 &&
                        <p style={{color:'red', fontSize:'12px'}}>Maximo de caracteres superado</p>
                    }
                {/* SI cotizar otro medio step 7 sino terminar. 
                Para cotizar en otro medio validar el medio anterior y solicitar puerto o aeropuerto segun aplique.
                */}
                {/*    <Controller
                    control={control}
                    name={`${nameForm}.duplicate`}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value); saveData(); }} name={`${nameForm}.duplicate`} value={value} label='5 - ¿Cotizar en otro medio?' options={binaryOptions} required></RadioGroup>
                    )}
                ></Controller> */}
            </main>

        </StyledAdditional>

    );

}
export default Additional;