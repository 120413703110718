import { URL } from "../constants"

export const getUsers = async (token) => {
    const response = await fetch(`${URL}user/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const sendUser = async (data, token) => {
    const response = await fetch(`${URL}user/corporation/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const getProfileDocuments = async (token) => {
    const response = await fetch(`${URL}profile/corporation-document/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}