import React from 'react'
import { Table, styled } from '@nextui-org/react'

export default function SmallTable() {

    const columns = [
        { name: 'Empresa', uid: 'location', type: 'text' },
        { name: 'CO2', uid: 'co2', type: 'text' },
    ]

    const data = [
        { location: 'PowerWave Energy', co2: 1401, id: 1 },
        { location: 'Elite Steelworks', co2: 1131, id: 2 },
        { location: 'Precition Plastics', co2: 840, id: 3 },
        { location: 'Global Fabrication Solutions', co2: 577, id: 4 },
        { location: 'Techmaster Electronics', co2: 287, id: 5 },
        { location: 'Innovateware INC', co2: 270, id: 6 },
    ]

    const StyledTable = styled(Table, {
        maxHeight: '420px',
        fontSize: '10px',
        ['td']: {
            borderBottom: '1px solid grey',
        }
    })
    return (
        <div>
            <StyledTable>
                <Table.Header columns={columns}>
                    {
                        (column) => <Table.Column key={column.uid}>{column.name}</Table.Column>
                    }
                </Table.Header>
                <Table.Body items={data}>
                    {
                        (item) => {
                            return <Table.Row>
                                {
                                    (columnKey) => <Table.Cell>{item[columnKey]}</Table.Cell>
                                }
                            </Table.Row>
                        }
                    }
                </Table.Body>
            </StyledTable>
        </div>
    )
}
