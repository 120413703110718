export function validateAllLoad(values) {  //Para habilitar o deshabilitar el boton Continuar    
    let isValid = []
    Object.keys(values.load).forEach(key => {
        if (values.load[key].loadName &&
            values.load[key].loadValue &&
            Number(values.load[key].loadValue) > 0 &&
            values.load[key].amount &&
            Number(values.load[key].amount) > 0 &&
            values.load[key].insurance !== undefined &&
            values.load[key].totalWeight &&
            Number(values.load[key].totalWeight) > 0
            // &&
            // values.load[key].width && values.load[key].widthUnit &&
            // values.load[key].long && values.load[key].longUnit &&
            // values.load[key].amount &&
            // values.load[key].unitWeight &&
            // values.load[key].amount
        ) {
            isValid.push(true);
        } else {
            isValid.push(false)
        }
    })
    return !isValid.includes(false)
}

export function validateCurrent(values, expandedForm) {   //Para habilitar o deshabilitar el boton Añadir

    const currentForm = values.load[expandedForm];
    if (currentForm &&
        currentForm.loadName &&
        currentForm.loadValue &&
        Number(currentForm.loadValue) > 0 &&
        currentForm.amount &&
        Number(currentForm.amount) > 0 &&
        currentForm.insurance !== undefined &&
        currentForm.totalWeight &&
        Number(currentForm.totalWeight) > 0 &&
        // currentForm.width && currentForm.widthUnit &&
        // currentForm.long && currentForm.longUnit &&
        // currentForm.amount && 
        // currentForm.unitWeight &&
        // currentForm.amount &&
        expandedForm + 1 === Object.keys(values.load).length //Estamos parados en el ultimo form
    ) {
        return true
    }
    return false
}

export function calculateTotalTons(values) {
    let allLoadsWeight = 0;

    Object.keys(values.load).forEach(key => {
        let totalWeight = values.load[key].totalWeight;
        let weightUnit = values.load[key].totalWeightUnit;

        switch (weightUnit) {
            case 'kg':
                totalWeight = totalWeight / 1000;
                break;
            case 'lb':
                totalWeight = totalWeight / 2205;
                break;
            default:
                totalWeight = totalWeight / 1000;
        }

        allLoadsWeight = allLoadsWeight + totalWeight;
    });

    return allLoadsWeight;
}

export function calculateCBM(values) {
    let allLoadsCBM = 0;
    console.log("values :", values);

    Object.keys(values.load).forEach(key => {
        let length = values.load[key].long;
        let length_unit = values.load[key].longUnit;
        let width = values.load[key].width;
        let width_unit = values.load[key].widthUnit;
        let height = values.load[key].height;
        let height_unit = values.load[key].heightUnit;

        if (!length_unit) { length_unit = 'cm'; }
        if (!width_unit) { width_unit = 'cm'; }
        if (!height_unit) { height_unit = 'cm'; }

        const conversionFactors = {
            cm: 0.01,
            m: 1,
            in: 0.0254
        };

        const lengthInMeters = length * conversionFactors[length_unit];
        const widthInMeters = width * conversionFactors[width_unit];
        const heightInMeters = height * conversionFactors[height_unit];
        const cbm = lengthInMeters * widthInMeters * heightInMeters;
        allLoadsCBM = allLoadsCBM + cbm;
    });

    return allLoadsCBM;
}