import React from 'react';
import Pdf from '../../../../../assets/Files/pdf.png';
import { styled } from '@nextui-org/react';
import theme from '../../../../../theme';
import format from 'date-fns/format';
import { Avatar } from '@nextui-org/react';
import { useTranslation } from "react-i18next";

const StyledBubbleOther = styled("div", {
    [`.${theme} &`]: {
        backgroundColor: "$secondary_50",
        padding: "1rem 2rem 1rem 2rem",
        width: "75%",
        maxW: "566px",
        alignSelf: "start",
        borderRadius: "8px",
        color: '$primary_300',
        display: 'flex',
        gap: '1rem',
        ".message": {
            flex: '1'
        },
        ".time": {
            display: "flex",
            alignItems: "end",
        },
        ".time p": {
            fontSize: '0.875rem'
        }
    }
})

const StyledBubble = styled("div", {
    [`.${theme} &`]: {
        backgroundColor: "$neutral_50",
        padding: "1rem 2rem 1rem 2rem",
        width: "75%",
        maxWidth: "566px",
        alignSelf: "end",
        borderRadius: "8px",
        color: '$primary_300',
        display: 'flex',
        gap: '1rem',
        ".message": {
            flex: '1'
        },
        ".time": {
            display: "flex",
            alignItems: "end",
        },
        ".time p": {
            fontSize: '0.875rem'
        }
    }
})

function Bubble({ isUser = false, message, name, time, type }) {
    function formatDate(date) {
        const inputDate = new Date(date)
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0)
        if (inputDate >= today) {
            return "Hoy"
        } else if (inputDate >= yesterday) {
            return "Ayer"
        } else {
            const day = String(inputDate.getDate()).padStart(2, '0');
            const month = String(inputDate.getMonth() + 1).padStart(2, '0');
            const year = inputDate.getFullYear();
            return `${day}/${month}/${year}`;
        }
    }

    function formatDate(date) {
        var regex = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2}) UTC\+(\d{4})$/;
        var match = date.match(regex);
        if (match) {
            return "Hoy"
        } else {
            const inputDate = new Date(date)
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            const yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0)
            if (inputDate >= today) {
                return "Hoy"
            } else if (inputDate >= yesterday) {
                return "Ayer"
            } else {
                const day = String(inputDate.getDate()).padStart(2, '0');
                const month = String(inputDate.getMonth() + 1).padStart(2, '0');
                const year = inputDate.getFullYear();
                return `${day}/${month}/${year}`;
            }
        }
    }

    const formatTime = (date) => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
    }

    const renderMessage = () => {
        if (type === 'document') {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={Pdf} alt="" style={{ paddingRight: '10px', width: '3rem', height: '3rem' }} />
                    <p>{message}</p>
                </div>
            );
        }
        return <p>{message}</p>;
    }

    const StyledBubbleType = isUser ? StyledBubble : StyledBubbleOther;
    const username = name.split(' ')
    console.log('Username :', username)
    return (
        <StyledBubbleType>
            <Avatar text={`${username[0][0].toUpperCase()}${username[1][0]?.toUpperCase()}`} size='sm'></Avatar>
            <div className='message'>
                <h4>{name}</h4>
                {renderMessage()}
            </div>
            <div className='time'>
                {time && <p> {formatDate(time)}-{formatTime(new Date(time))}</p>}
            </div>
        </StyledBubbleType>
    );
}

export default Bubble;