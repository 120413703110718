import React from 'react'
import { Modal, styled, Checkbox, Radio } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import { finishQuotation } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500 !important',
})

export default function FinishQuotationModal({ open, setOpen, quoteData }) {
    const handleFinish = () => {
        finishQuotation(quoteData.id).then(()=>{
            toast("success","Cotizacion finalizada")
            setOpen(false)
        }).catch(err => console.log("ERR :",err))
    }

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Body>
            <h3><b>{quoteData.radication}</b></h3>
                <p>Desea finalizar la liquidación de radicado {quoteData.radication}?</p>
                <p>La cotización se enviara al cliente para su aprobación</p>
            </Modal.Body>
            <Modal.Footer>
                <Button text='Cancelar'
                    bold
                    outline
                    handler={()=>setOpen(false)}
                />
                <Button text='Confirmar'
                    bold
                    bgColor={'primary_300'}
                    textColor='neutral_50'
                    disabled={!quoteData.id}
                    handler={()=>handleFinish()}
                />
            </Modal.Footer>
        </StyledFormModal>
    );
}
