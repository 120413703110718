import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { URL, bgColorStates, textColorStates } from '../../../constants';
import { useSelector } from "react-redux";
import { Loading, Modal, styled } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import DataModal from '../../../components/Modal/DataModal/DataModal';
import ItemModal from '../../../components/Modal/ItemModal/ItemModal';
import { deleteService } from '../../../services/quotation.services';
// Components
import Table from '../../../components/Table/Table';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes';
//
const TableServices = () => {
    const navigate = useNavigate()
    const token = useSelector((state) => state.user.token);
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + token
    }
    const [modalStyle, setModalStyle] = useState({ display: "none" });
    const [modal, setModal] = useState(null);
    const { data, loading } = useFetch(`${URL}service/all/`, headers);
    const roles = useFetch(`${URL}roles/`, headers);
    const states = useFetch(`${URL}states/`, headers);
    const asesores = useFetch(`${URL}user/rol/5/`, headers);
    const [items, setItems] = useState([])
    const [advisor, setAdvisor] = useState()
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    const [currentService, setCurrentService] = useState()

    const style = {
        display: "block",
        backgroundColor: "rgba(0, 0, 0, .7)",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        zIndex: "300"
    }

    const StyledModal = styled(Modal, {
        color: '$primary_500 !important',
        minWidth: '400px',
        padding: '20px',
    })

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Carga', uid: 'load', type: 'text' },
        { name: 'Fecha', uid: 'date', type: 'date' },
        { name: 'Hora', uid: 'hour', type: 'hour' },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Asesor', uid: 'advisor', type: 'text' },
        { name: 'Orden de compra', uid: 'purchaseOrder', type: 'text' },
        { name: 'Costo', uid: 'cost', type: 'text' },
        { name: 'Estado', uid: 'state', type: 'badge' },
    ]

    const handleDeleteService = () => {
        //     deleteService(token, currentService.radication).then(()=>{
        //         toast("success", "El servicio a sido eliminado")
        //     }).catch(() => {
        //         toast('error','No se ha podido eliminar el servicio')
        //     })
        toast('warn', 'Aun no esta disponible')
    }

    const closeModal = () => {
        setModal(null);
        setModalStyle({ display: "none" });
    };

    const advisorModal = userData => {
        let advisors = userData.advisor.trim();
        if (advisors != '') {
            const advisorsList = advisors.split(',')
            const containAdvisor = asesores.data.filter(advisor => !advisorsList.includes(advisor.first_name))
            advisors = containAdvisor?.length === 0 ? null : containAdvisor
        }
        else advisors = asesores.data

        setModalStyle(style);
        setModal(
            <DataModal
                data={advisors}
                titleText="Seleccione el nuevo asesor"
                buttonText="Asignar"
                close={closeModal}
                id={userData.id}
                endpoint="services/add-advisor/"
                methodType="POST"
                headers={headers}
            />
        )
    }
    const stateModal = serviceData => {
        const serviceState = states.data.filter(state => {
            return (serviceData.state.name == 'Aprobado' && state.name != 'Cotizado' && state.name != 'Aprobado') ||
                (serviceData.state.name != 'Aprobado' && state.name != serviceData.state.name);
        });

        setModalStyle(style);
        setModal(
            <DataModal
                data={serviceState}
                titleText="Seleccione el nuevo estado"
                buttonText="Cambiar"
                close={closeModal}
                id={serviceData.id}
                endpoint="services/changeState/"
                methodType="PUT"
                headers={headers}
            />
        )
    }
    const deleteAdvisorModal = serviceData => {
        setModalStyle(style);
        setModal(
            <DataModal
                data={advisor[serviceData.id]?.length != 0 ? advisor[serviceData.id] : null}
                titleText="Eliminar asesor"
                buttonText="Eliminar"
                contentText="¿Esta seguro que desea eliminar el asesor?"
                close={closeModal}
                id={serviceData.id}
                endpoint="services/delete-advisor/"
                methodType="DELETE"
                headers={headers}
            />
        );
    };

    const costModal = serviceData => {
        setModalStyle(style);
        setModal(
            <ItemModal
                titleText="Asignar costos"
                buttonText="Asignar"
                close={closeModal}
                id={serviceData.id}
                endpoint="service/assign-costs/"
                methodType="POST"
                headers={headers}
            />
        )
    };

    const actions = [
        {
            name: 'Ver Servicio',
            icon: 'View',
            handle: (service) => {
                navigate(`/services/detail/chat/?radication=${service.idRadication}&tab=chat`)
            }
        },
        {
            name: 'Cambiar estado',
            icon: 'Edit',
            type: 'button',
            handle: stateModal
        },
        {
            name: 'Asignar Asesor',
            icon: 'UserAdd',
            type: 'button',
            handle: advisorModal
        },
        {
            name: 'Eliminar Asesor',
            icon: 'Trash',
            type: 'button',
            handle: deleteAdvisorModal
        },
        {
            name: 'Asignar costos',
            icon: 'MoneyBag',
            type: 'button',
            handle: costModal
        },
        {
            name: 'Eliminar servicio',
            icon: 'Trash',
            type: 'button',
            handle: (service) => { setCurrentService(service), setOpenConfirmationModal(true) }
        }
    ];

    const getListAdvisors = (users) => {
        let list = [];
        users.forEach(user => { if (user.role[0] === 'Asesores') { list.push(` ${user.name} ${user.last_name}`) } })
        return list.toLocaleString()
    }

    useEffect(() => {
        if (!loading) {
            let dataTable;
            let serviceAdvisors = {};
            if (data.length > 0) {
                dataTable = data.map((service) => (
                    {
                        id: service.id,
                        idRadication: service.radication,
                        date: new Date(service.created_at),
                        load:
                            service.loads.length > 0
                                ? service.loads[0].load_type
                                : "",
                        origin: service.shipping.origin_country,
                        destination: service.shipping.destination_country,
                        advisor: getListAdvisors(service.users),
                        state: {
                            name: service.state,
                            bgColor: bgColorStates[service.state],
                            textColor: textColorStates[service.state]
                        }
                    }));
                data.map(service => {
                    const array = [];
                    service.users.map(user => {
                        array.push({
                            id:
                                user.id,
                            name:
                                `${user.name} ${user.last_name}`,
                        });
                    });
                    serviceAdvisors[service.id] = array;
                });
                setAdvisor(serviceAdvisors);
            }
            setItems(dataTable);
        }
    }, [loading]);

    return (
        <div className='h-100'>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items?.length > 0 ?
                        <Table columns={columns} items={items} actions={actions}></Table>
                        :
                        <div className='d-flex justify-content-center'>No hay servicios aprobados</div>
            }
            <div style={modalStyle}>{modal}</div>
            <StyledModal
                aria-label="modal-title"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar el servicio seleccionado</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenConfirmationModal(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDeleteService()} />
                    </div>
                </Modal.Body>
            </StyledModal>
        </div>

    );
}

export default TableServices;