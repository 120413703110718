import React, { useEffect, useState } from 'react'
import { URL } from '../../../constants'
import { Loading, Modal, styled } from '@nextui-org/react'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal'
import Select from 'react-select';
import Button from '../../../components/Button/Button'
import Table from '../../../components/Table/Table'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toast from '../../../components/Toast/ToastTypes'
import { getSubTypes } from '../../../services/quotations.services'
import { getUsers } from '../../../services/user.services'
import theme from '../../../theme'

const colourStyles = {
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  control: (styles) => ({
    ...styles,
    border: '1px solid ' + theme.colors.primary_300,
    borderRadius: '8px',
    margin: '30px',
    padding: 0,
    minHeight: '40px',
    width: '300px',
    boxShadow: 'none',
    outline: 'none',
    [':hover']: {
      borderColor: 'grey',
    }
  }),
};

const StyledFormModal = styled(Modal, {
  padding: '1.5rem',
  color: '$primary_500 !important',
  ['input']: {
    minWidth: '200px !important',
  },
  ['form']: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  ['button']: {
    margin: '28px',
  }
})

export default function ManageModal({ open, setOpen, title, inputs, endpoint }) {

  const token = useSelector(state => state.user.token)
  const { control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      email: '',
      subtype: '',
    }
  })
  const [data, setData] = useState()
  const [columns, setColumns] = useState()
  const [subtypes, setSubtypes] = useState([])
  const [submitModifies, setSubmitModifies] = useState(false)
  const [users, setUsers] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedDeleteData, setSelectedDeleteData] = useState()

  async function fetchData(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud. Código de estado: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error al obtener los datos:', error.message);
      throw error;
    }
  }
  async function postData(url, data) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud POST. Código de estado: ${response.status}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error al realizar la solicitud POST:', error.message);
      throw error;
    }
  }
  async function putData(url, data) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud PUT. Código de estado: ${response.status}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error al realizar la solicitud PUT:', error.message);
      throw error;
    }
  }
  async function deleteData(url) {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud DELETE. Código de estado: ${response.status}`);
      }
      return response;
    } catch (error) {
      console.error('Error al realizar la solicitud DELETE:', error.message);
      throw error;
    }
  }
  function handleDelete(url) {
    deleteData(url).then(res => {
      toast("success", "Eliminado con exito")
      getData();
    }).catch(err => {
      toast("error", "Error, no se pudo eliminar")
    })
  }

  const actions = [
    {
      name: 'Eliminar',
      icon: 'Trash',
      type: 'button',
      handle: (e) => {
        setSelectedDeleteData({
          ...e,
          url: URL + endpoint + e.id + '/',
        })
        setOpenDeleteModal(true)
      }
    },
    {
      name: 'Modificar',
      icon: 'Edit',
      type: 'button',
      handle: (e) => {
        setSubmitModifies(true)
        if (e.id) { setValue("id", e.id) }
        if (e.name) { setValue("name", e.name) }
        if (e.email) { setValue("email", e.email) }
        if (e.subtype) {
          const selectedSubtypes = e.subtype.split('-')
          const selectedSubtypesIds = selectedSubtypes.map(selectedSubtype => {
            return subtypes.find(subtype => subtype.name == selectedSubtype)?.id
          })
          setValue("subtype", selectedSubtypesIds)
        }
      }
    }
  ];

  // console.log('FORMVALUES:',watch())

  async function fetchSubTypes(token, res) {
    const subtypeRes = await getSubTypes(token);
    const subtypesData = await subtypeRes.json();
    setSubtypes(subtypesData);
    const formattedData = res.map(item => {
      let subtypesString = '';
      item.subtype.forEach((subtypeId, index) => {
        let separador = '-';
        if (item.subtype.length === index + 1) { separador = '' }
        subtypesString += subtypesData.find(subtype => subtype.id === subtypeId)?.name + separador;
      });
      return {
        ...item,
        subtype: subtypesString,
      };
    });
    setData(formattedData);
  }

  async function fetchUsers(token, res) {
    const usersRes = await getUsers(token);
    const usersData = await usersRes.json();
    //console.log(usersData)
    const filteredData = usersData.filter((user) =>
      user.name !== 'guest'
    )
   // console.log('filteredData :', filteredData)
    setUsers(filteredData);
    const formattedData = res.map(item => {
      const user = filteredData.find(user => user.id === item.user)?.email;
      return {
        ...item,
        user,
      };
    });
    setData(formattedData);
  }

  function getData() {
    fetchData(URL + endpoint).then(async res => {
      if (!res.userprofile) {
        setData(res);
        let tableColumns = Object.keys(res[0]).map(e => {
          return { name: e, uid: e, type: 'text' }
        });
        tableColumns.push({ name: 'Acciones', uid: 'actions', type: 'action' });
        setColumns(tableColumns);

        if (title == "Servicios" || title == "Services") {
          fetchSubTypes(token, res).catch(err => console.log(err));
        }

        if (title == "Coordinadores" || title == "Coordinators") {
          fetchUsers(token, res).catch(err => console.log(err));
        }
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    getData();
    return () => {
      setSubmitModifies(false)
      reset({})
      setData()
      setColumns()
      setSubtypes([])
      setUsers([])
    };
  }, [endpoint])

  const onSubmit = (data) => {
    if (submitModifies) {
      putData(`${URL + endpoint + data.id}/`, data).then(res => {
        toast("success", "Modificado con exito")
        getData();
      }).catch(err => {
        toast("error", "Error, no se pudo modificar")
      })
    } else {
      postData(URL + endpoint, data).then(res => {
        toast("success", "Añadido con exito")
        getData();
      }).catch(err => {
        toast("error", "Error, no se pudo añadir")
      })
    }
  }

  return (
    <StyledFormModal
      aria-label="modal-title"
      open={open}
      onClose={() => setOpen(false)}
      width="fit-content"
    >
      <Modal.Header justify='space-between'>
        <h3>{title}</h3>
      </Modal.Header>
      {
        !data || !columns ? <Loading size='xl' /> :
          <Modal.Body justify='space-between'>
            <form onSubmit={handleSubmit(onSubmit)} errors={errors}>
              {
                inputs.map((input, index) => {
                  switch (input) {
                    case 'name':
                      return (
                        <div key={index}>
                          <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                              <Input label='Nombre' {...field} />
                            )}
                            rules={{ required: "Este campo es obligatorio" }}
                          />
                        </div>
                      );
                    case 'email':
                      return (
                        <div key={index}>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <Input label='Email' {...field} />
                            )}
                            rules={{ required: "Este campo es obligatorio" }}
                          />
                        </div>
                      );
                    case 'service_group':
                      return (
                        <div key={index}>
                          <Controller
                            name="subtype"
                            control={control}
                            render={() => (
                              <Select
                                isMulti={true}
                                styles={colourStyles}
                                value={watch('subtype') && watch('subtype')?.map(subtypeId => {
                                  return {
                                    label: subtypes.find(subtype => subtype.id === subtypeId)?.name,
                                    value: subtypeId,
                                  }
                                })}
                                onChange={inputValue => setValue('subtype', inputValue.map(e => (e.value)))}
                                options={subtypes?.map(subtype => {
                                  return {
                                    label: subtype.name,
                                    value: subtype.id,
                                  }
                                })}
                              />
                            )}
                            rules={{ required: "Este campo es obligatorio" }}
                          />
                        </div>
                      );
                    case 'user':
                      return (
                        <div key={index}>
                          <Controller
                            name="user"
                            control={control}
                            render={() => (
                              <InputSelect onChange={(e) => setValue("user", e?.value)} key={index} label="Usuarios" options={users?.map(user => {
                                return {
                                  label: user?.email,
                                  value: user?.id,
                                }
                              })} />
                            )}
                            rules={{ required: "Este campo es obligatorio" }}
                          />
                        </div>
                      );

                  }
                })
              }
              {submitModifies ?
                <div>
                  <Button text="Modificar" type="submit" outline />
                  <Button text="Cancelar" handler={() => { setSubmitModifies(false); reset({}) }} outline />
                </div> :
                <Button text="Añadir" type="submit" outline />
              }
            </form>
            {columns && <Table columns={columns} items={data} excel={false} searchBar={false} actions={actions} />}
          </Modal.Body>
      }
      <DeleteConfirmationModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        text={`Confirma que desea eliminar : ${selectedDeleteData?.name}`}
        handleDelete={() => handleDelete(selectedDeleteData?.url)}
      />
    </StyledFormModal>
  )
}
