import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

export default function SimplePieChart({ data, dataKey, color, radius = 100 }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          dataKey={dataKey}
          isAnimationActive={true}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={radius}
          fill={color}
          label
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
