import { styled } from "@nextui-org/react";
import React from "react";
import theme from "../../../theme";
import styles from "./Card.module.scss";
import Icon from "../../Icon/Icon";

function Card({ value, text, percentage, icon }) {
  const StyledCard = styled("div", {
    [`.${theme} &`]: {
      backgroundColor: "$neutral_50",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "164px",
      color: "$primary_100",
      h2: {
        color: "$primary_300",
      },
      span: {
        color: "$primary_300",
      },
      padding: "1rem 0",
    },
    [`.fl`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5rem",
    },
  });

  const Circle = styled("div", {
    [`.${theme} &`]: {
      backgroundColor: "$primary_50",
      borderRadius: "2rem",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <StyledCard className="theme">
      <Circle>
        <Icon name={icon} />
        {/* <img src="/Programming Module by Streamlinehq.svg" alt="" /> */}
      </Circle>
      <h2>{value}</h2>
      <p>{text}</p>
      <div className="fl">
        <span>{percentage}</span>
      </div>
    </StyledCard>
  );
}

export default Card;
