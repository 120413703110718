import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useForm, Controller } from 'react-hook-form';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';

const General = ({ setGeneral }) => {
    const documentTypes = [
        {
            value: 'RFC',
            name: 'RFC'
        },
        {
            value: 'NIT',
            name: 'NIT'
        },
        {
            value: 'C.C.',
            name: 'Cédula de ciudadania'
        }
    ]
    const marketAge = [
        {
            value: '0 - 1',
            name: '0 - 1'
        },
        {
            value: '1 - 3',
            name: '1 - 3'
        },
        {
            value: '3 - 5',
            name: '3 - 5'
        },
        {
            value: '5 - 10',
            name: '5 - 10'
        },
        {
            value: '10+',
            name: '10+'
        }
    ]
    const binaryOption = [
        {
            value: 1,
            name: 'Sí'
        },
        {
            value: 0,
            name: 'No'
        }
    ]
    const nameForm = 'general'
    const { register, control, getValues } = useForm({
        defaultValues: {
            [nameForm]: {
                corporateName: '',
                documentType: '',
                documentNumber: '',
                city: '',
                municipality: '',
                phone: '',
                cellPhoneFax: '',
                webSite: '',
                nameLegalRepresentative: '',
                marketAge: '',
                dateFormation: '',
                controlSecuritySystem: binaryOption[1].value,
                qualitySystem: binaryOption[1].value
            }
        }
    });

    const StyledGeneral = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setGeneral(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledGeneral>
            <PageTitle>
                Información General
            </PageTitle>

            <aside>

            </aside>

            <main className='form'>
                <div className='colspan-2 input'>
                    <Input register={register} onChange={saveData} name={`${nameForm}.corporateName`} label='Razón social' placeholder='Introducir razón' required></Input>
                </div>
                <Controller
                    control={control}
                    name={`${nameForm}.documentType`}
                    render={({ field: { onChange, value } }) => (
                        <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.documentType`} value={value} label='Tipo de documento' options={documentTypes} required></Select>
                    )}
                ></Controller>
                <Input register={register} onChange={saveData} name={`${nameForm}.documentNumber`} label='No. de documento' placeholder='Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.city`} label='Ciudad' placeholder='Introducir' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.municipality`} label='Municipio' placeholder='Introducir' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.phone`} label='Télefono' placeholder='Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.cellPhoneFax`} label='Celular / Fax' placeholder='Introducir' type='number' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.webSite`} label='Página web' placeholder='https://' required></Input>
                <Input register={register} onChange={saveData} name={`${nameForm}.nameLegalRepresentative`} label='Nombre de representante legal' placeholder='Introducir nombre' required></Input>
                <Controller
                    control={control}
                    name={`${nameForm}.marketAge`}
                    render={({ field: { onChange, value } }) => (
                        <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.marketAge`} value={value} label='Antigüedad del mercado' options={marketAge} required></Select>
                    )}
                ></Controller>
                <Input register={register} onChange={saveData} name={`${nameForm}.dateFormation`} label='Fecha de constitución' type='date' placeholder='Introducir' required></Input>

                <Controller
                    control={control}
                    name={`${nameForm}.controlSecuritySystem`}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.controlSecuritySystem`} value={value} label='¿Cuenta con sistema de control y seguridad?' options={binaryOption} required></RadioGroup>
                    )}
                ></Controller>
                <div></div>
                <Controller
                    control={control}
                    name={`${nameForm}.qualitySystem`}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.qualitySystem`} value={value} label='¿Cuenta con sistema de calidad?' options={binaryOption} required></RadioGroup>
                    )}
                ></Controller>
            </main>
        </StyledGeneral>
    );
}

export default General;
