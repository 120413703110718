import React, { useEffect } from 'react'
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector } from 'react-redux';
import ViewsHandler from '../../components/ViewsHandler/ViewsHandler';
import { styled } from '@nextui-org/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tracker from '@openreplay/tracker/cjs';
import Dashboard from './dashboard/Dashboard'
import ServicesTable from './servicesTable/ServicesTable';
import UsersTable from './usersTable/UsersTable';
import { getUserAuth } from '../../services/auth.services';
import Manage from './Manage/Manage';
import ProvidersTable from './providersTable/ProvidersTable';

const tracker = new Tracker({
    projectKey: "QUQx75VyCHiMfj9WpnB4",
});


const StyledContainer = styled('div', {
    ['header']: {
        backgroundColor: '$neutral_50',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.title']: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
        fontFamily: 'inherit',
        color: '$primary_300',
        textTransform: 'none'
    },
    ['.Mui-selected']: {
        fontWeight: '600',
        color: '$primary_300 !important'
    },
    ['.MuiTabs-indicator']: {
        backgroundColor: '$secondary_300'
    }
})

const StyledViewsContainer = styled("div", {
    padding: '30px',
})

const tabs = [
    {
        id: 'dashboard-tab',
        value: 'dashboard',
        label: 'Dashboard',
    },
    {
        id: 'services-tab',
        value: 'services',
        label: 'Servicios',
    },
    {
        id: 'users-tab',
        value: 'users',
        label: 'Usuarios',
    },
    {
        id: 'providers-tab',
        value: 'providers',
        label: 'Proveedores',
    },
    {
        id: 'manage-tab',
        value: 'manage',
        label: 'Administrar',
    }
]


export default function Admin() {
    const token = useSelector((state) => state.user.token);
    const [searchParams] = useSearchParams()
    const [tabId, setTabId] = useState(0);
    const [tabValue, setTabValue] = useState(searchParams.get('tab'))
    const navigate = useNavigate()
    const [user, setUser] = useState({
        name: '',
        lastName: ''
    })
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        // navigate(`/services/detail/chat/?radication=${radication}&tab=${newValue}`)
        navigate(`/backstage/?tab=${newValue}`)
    };
    useEffect(() => {
        setTabValue(tabValue)
        setTabId(tabs.findIndex(tab => tab.value === tabValue))
    }, [])
    return (
        <StyledContainer>
            <header>
                {/* <div className='title'>
                <PageTitle>
                    Administrar
                </PageTitle>
            </div> */}
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    {
                        tabs.map((tab, i) =>
                            <Tab key={i} value={tab.value} label={tab.label} />
                        )
                    }
                </Tabs>
            </header>
            <StyledViewsContainer>
                <ViewsHandler
                    activeView={tabId}
                >
                    <Dashboard />
                    <ServicesTable />
                    <UsersTable />
                    <ProvidersTable />
                    <Manage />
                </ViewsHandler>
            </StyledViewsContainer>
        </StyledContainer>
    )
}
