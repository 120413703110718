import { URL } from "../constants"

export const sendMessage = async (token, message) => {
    const response = await fetch(`${URL}send/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: token,
            message: message
        })
    })
    return response.json()
}

export const deleteParticipant = async (data) => {
    const response = await fetch(`${URL}services/delete-advisor/`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
    return response;
}