import React from 'react'
import { styled } from "@nextui-org/react";
import Icon from '../../../components/Icon/Icon';

const IconContainer = styled("div", {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.3);',
  width: '70px',
  height: '55px',
  borderRadius: '8px',
})

export default function Card({ color, title, number, aditional }) {

  const StyledContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: color,
    color: 'white',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    height: '160px',

    transition: '300ms',
    ['&:hover']: {
      transform: 'translateY(-6px)'
    }
  })


  return (
    <StyledContainer>
      <div style={{ display: 'flex', gap: '20px' }}>
        <IconContainer>
          <Icon name='weight' color='white' size='38px' />
        </IconContainer>
        <div>
          <p>{title}</p>
          <h1>{number}</h1>
        </div>
      </div>
      <p style={{ fontSize: '12px', wrap: 'no-wrap' }}>{aditional}</p>
    </StyledContainer>
  )
}
