import { styled } from '@nextui-org/react';
import { getLogEntries, postLogEntries, editLogEntry, deleteLogEntry } from '../../services/operations.services';
import { getUsers } from '../../services/user.services';
import { getActivities, getOperations } from '../../services/operations.services';
import TextEditor from '../../components/TextEditor/TextEditor'
import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input';
import InputSelect from '../../components/Select/inputSelect'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from '../../components/Toast/ToastTypes';


const StyledDiv = styled('div', {
    bgColor: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '30px',
})

const StyledCard = styled('div', {
    borderRadius: '8px',
    background: '$neutral_100',
    margin: '10px',
    padding: '20px',
    transition: '200ms',
    ['&:hover']: {
        cursor: 'pointer',
        background: '$neutral_200',
    }
})

const CardContent = styled("div", {
    position: 'relative',
    overflow: 'hidden',
    // background:'blue',
    maxHeight: '150px',
    ['.card-header']: {
        display: 'flex',
        width: '100%',
        margin: '5px',
        padding: '5px',
        justifyContent: 'space-between',
        borderBottom: '1px solid grey'
    }
    // ['>p']:{
    //     position:'absolute',
    //     right:'10px',
    // }, 
})

export default function TrackingNotes({ selectedOperation, currentActivity }) {
    const token = useSelector(state => state.user.token)
    const userId = useSelector(state => state.user.idUser)

    const [note, setNote] = useState('')
    const [logEntries, setLogEntries] = useState([])
    const [selectedEntry, setSelectedEntry] = useState()
    const [selectedActivity, setSelectedActivity] = useState(currentActivity)
    const [allActivities, setAllActivities] = useState([])
    const [operationActivities, setOperationActivities] = useState([])
    const [radication, setRadication] = useState('')
    const [readOnly, setReadOnly] = useState(true)
    const [showTextEditor, setShowTextEditor] = useState(false)
    const [users, setUsers] = useState([])

    function formatDate(isoDate) {
        const date = new Date(isoDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }


    useEffect(() => {
        const asyncTasks = [getOperations({ token, id: selectedOperation }), getActivities(token), getLogEntries(token), getUsers(token)];
        setNote('')
        Promise.all(asyncTasks)
            .then(async responses => {
                const operationsData = await responses[0].json();
                const activitiesData = await responses[1].json();
                const logEntriesData = await responses[2].json();
                const usersData = await responses[3].json();
                setUsers(usersData)
                if (!selectedActivity) {
                    const filteredLogEntries = logEntriesData.filter(entry => entry.operation === selectedOperation);
                    setLogEntries(filteredLogEntries);
                } else {
                    const filteredLogEntries = logEntriesData.filter(entry => entry.operation === selectedOperation && entry.activity === selectedActivity);
                    setLogEntries(filteredLogEntries);
                }
                setRadication(operationsData.radication)
                setAllActivities(activitiesData);
                setOperationActivities(operationsData.activities);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
    }, [selectedOperation, selectedActivity]);


    useEffect(() => {
        setSelectedActivity('')
        setSelectedEntry('')
    }, [selectedOperation])

    useEffect(() => {
        if (selectedEntry) {
            setNote(selectedEntry.text_field)
        }
    }, [selectedEntry])

    const updateLogEntries = () => {
        getLogEntries(token)
            .then((response) => response.json())
            .then((logEntriesData) => {
                const filteredLogEntries = logEntriesData.filter((entry) => entry.operation === selectedOperation);
                setLogEntries(filteredLogEntries);
            })
            .catch((error) => {
                console.error('Error al obtener las entradas de registro:', error);
            });
    };

    const handleNew = () => {
        setShowTextEditor(true)
        setSelectedEntry('')
        setNote('')
        setReadOnly(false)
    }

    const handleDelete = () => {
        deleteLogEntry(selectedEntry.id).then(() => {
            setNote('')
            setSelectedEntry('')
            setReadOnly(false)
            toast('success', 'Entry eliminada')
            updateLogEntries()
        })
    }
    const handleSubmit = () => {
        if (selectedEntry) {
            editLogEntry({ text_field: note }, selectedEntry.id).then(res => {
                toast('success', 'Entrada actualizada')
                updateLogEntries()
            }).catch(err => console.log('err .', err))
        } else {
            const submitData = {
                text_field: note,
                operation: selectedOperation,
                activity: selectedActivity,
                recipient: userId,
            }
            postLogEntries(submitData).then(res => {
                toast('success', 'Entrada creada con exito')
                updateLogEntries()
                setSelectedEntry(res.id)
            }).catch(err => console.log('err .', err))
        }
    }

    return (
        <StyledDiv>
            {
                selectedOperation ?
                    <div>
                        <div style={{ display: 'flex', gap: '15px', margin: '10px' }}>
                            <Button text='Nuevo' bold disabled={!selectedOperation} bgColor={'primary_300'} textColor='neutral_50' handler={() => handleNew()} />
                            <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' disabled={!selectedEntry || !readOnly} handler={() => setReadOnly(false)} />
                            <Button text='Guardar' bold bgColor={'primary_300'} textColor='neutral_50' disabled={readOnly} handler={() => handleSubmit()} />
                            <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDelete()} />
                        </div>
                        <div style={{ display: 'flex', gap: '35px', margin: '10px' }}>
                            <InputSelect label="Actividad o evento"
                                onChange={(e) => setSelectedActivity(e?.value || '')}
                                options={operationActivities?.map(operationActivity => {
                                    return {
                                        label: allActivities.find(activity => activity.id === operationActivity.activity)?.name,
                                        value: operationActivity.activity,
                                    }
                                })}
                            />
                            <Input label="fecha" type="date" />
                            <Input label="hora" type="time" />
                        </div>
                        <p style={{ margin: '15px' }}>Operacion seleccionada : <b>{radication}</b></p>
                        {/* <p style={{margin:'15px'}}> Actividad seleccionada : {selectedActivity || 'ninguna'}</p> */}
                        {/* <p style={{margin:'15px'}}> Entrada seleccionada : {selectedEntry?.id || 'ninguna'}</p> */}
                    </div>
                    : <p><b>Selecciona una operación de la tabla ↑</b></p>
            }

            {showTextEditor && <TextEditor state={note} setState={setNote} readOnly={readOnly} />}

            {
                logEntries.sort((a, b) => new Date(b.date_time) - new Date(a.date_time))?.map(entry => {
                    return (
                        <StyledCard onClick={() => { setSelectedActivity(entry.activity); setSelectedEntry(entry); setReadOnly(true); setShowTextEditor(true); window.scrollTo({ top: 200, behavior: 'smooth' }) }}>
                            <CardContent>
                                <div className='card-header'>
                                    <span>{users.find(user => user.id === entry.recipient)?.name} {users.find(user => user.id === entry.recipient)?.last_name}</span>
                                    <p>{formatDate(entry.date_time)}</p>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: entry.text_field }} />
                            </CardContent>
                        </StyledCard>
                    )
                })
            }
        </StyledDiv>
    )
}