import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, styled, Loading } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes';
import InputFileCard from '../../../components/InputFileCard/InputFileCard';
import { toBase64 } from '../../../services/base64.services';
import DisplayData from '../QuoteForm/DisplayData';

import { useForm, Controller } from 'react-hook-form'
import { getPreliquidations, getServiceGroups, getServices, getServiceVariations, getProviderTypes, getProviders, getAppliesPer, getRateTypes, getRates, getSaleRates, getPurchaseRates, postLiquidation, editLiquidation, deleteLiquidation } from '../../../services/preliquidation.services';
import { getQuotation } from '../../../services/quotation.services';

const RFQDataContainer = styled("div", {
    background: '$neutral_200',
    borderRadius: '8px',
    padding: '30px',
    overflowY: 'scroll',
})

const StyledFormModal = styled(Modal, {
    display: 'flex',
    // width: '165vh',
    padding: '30px',
    color: '$primary_500 !important',
})
const StyledFieldset = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '50px',
})
const InputRow = styled("div", {
    width: '100%',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'start',
    gap: '20px',
    ['input']: {
        width: '100%',
    }
})
const InputContainer = styled("div", {
    maxWidth: '300px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ['span']: {
        color: '$delete',
        fontSize: '12px'
    }
})
const SmallInputContainer = styled("div", {
    width: '260px',
    display: 'flex',
    flexDirection: 'column',
    ['span']: {
        color: '$delete',
        fontSize: '12px'
    }
})
const Line = styled("div", {
    width: '90%',
    height: '1px',
    background: 'grey',
    margin: '30px 10px'
})

const TotalsContainer = styled("div", {
    display: 'flex',
    minHeight: '70px',
    marginTop: '20px',
    justifyContent: 'space-around',
    gap: '20px',
    ['div']: {
        width: '100%',
        backgroundColor: '$primary_300',
        color: '$neutral_50',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '$elevation_1'
    }
})

export default function PreliquidationFormModal({ open, setOpen, currentQuote, radication, selectedPreliquidation, setSelectedPreliquidation }) {

    const token = useSelector(state => state.user.token)
    const userId = useSelector(state => state.user.idUser)

    const { handleSubmit, control, watch, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            'applies': '',
            'notes': '',
            'provider': '',
            'purchase-currency': '',
            'purchase-min': '',
            'purchase-rate-type': '',
            'purchase-trm': '',
            'sale-currency': '',
            "sale-price": '',
            "sale-trm": '',
            "service": '',
            "taxes": '',
            "unit": '',
        }
    });

    const [disableForm, setDisableForm] = useState(false)

    const [RFQ, setRFQ] = useState()

    const [serviceGroup, setServiceGroup] = useState([])
    const [services, setServices] = useState([])
    const [serviceVariations, setServiceVariations] = useState([])
    const [providerTypes, setProviderTypes] = useState([])
    const [providers, setProviders] = useState([])
    const [appliesPer, setAppliesPer] = useState([])
    const [rateTypes, setRateTypes] = useState([])
    const [rates, setRates] = useState([])
    const [purchaseRates, setPurchaseRates] = useState([])
    const [salesRates, setSalesRates] = useState([])

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [selectedService, setSelectedService] = useState(null);
    const formatData = (data) => {
        return {
            label: data?.name || data?.unit || data?.type,
            value: data?.value || data?.id,
        }
    }

    useEffect(() => {
        const asyncTasks = [
            getServiceGroups(token),
            getServices(token),
            getServiceVariations(token),
            getProviderTypes(token),
            getProviders(token),
            getAppliesPer(token),
            getRateTypes(token),
            getRates(token),
            getSaleRates(token),
            getPurchaseRates(token),
        ];
        if (radication) {
            asyncTasks.push(getQuotation(radication, token))
        }
        Promise.all(asyncTasks)
            .then(async responses => {
                const serviceGroupsData = await responses[0].json(); setServiceGroup(serviceGroupsData);
                const servicesData = await responses[1].json(); setServices(servicesData)
                const serviceVariationsData = await responses[2].json(); setServiceVariations(serviceVariationsData)
                const providerTypesData = await responses[3].json(); setProviderTypes(providerTypesData)
                const providersData = await responses[4].json(); setProviders(providersData)
                const appliesPerData = await responses[5].json(); setAppliesPer(appliesPerData)
                const rateTypesData = await responses[6].json(); setRateTypes(rateTypesData)
                const ratesData = await responses[7].json(); setRates(ratesData)
                const saleRatesData = await responses[8].json(); setSalesRates(saleRatesData)
                const purchaseRatesData = await responses[9].json(); setPurchaseRates(purchaseRatesData)
                if (radication) {
                    const RFQData = await responses[10].json(); setRFQ(RFQData)
                }
                if (selectedPreliquidation) {
                    setDisableForm(true)
                    getPreliquidations(token, selectedPreliquidation).then(async res => {
                        let data = await res.json()
                        const fillForm = {
                            "applies": formatData(appliesPerData.find(e => e.id === data.applies_per)),
                            "notes": data.notes || '',
                            "provider": formatData(providersData.find(provider => provider.id === data.provider)),
                            "provider-type": formatData(providerTypesData.find(providerType => providerType.id === data.provider_type)),
                            "purchase-currency": { label: data.purchase_rate.currency, value: data.purchase_rate.currency },
                            "purchase-min": data.purchase_rate.minimum_rate,
                            "purchase-price": data.purchase_rate.rate_number,
                            "purchase-rate-type": formatData(rateTypesData.find(rateType => rateType.id === data.purchase_rate.rate_type)),
                            "purchase-trm": data.purchase_rate.trm,
                            "sale-currency": { label: data.sale_rate.currency, value: data.sale_rate.currency },
                            "sale-min": data.sale_rate.minimum_rate,
                            "sale-price": data.sale_rate.rate_number,
                            "sale-rate-type": formatData(rateTypesData.find(rateType => rateType.id === data.sale_rate.rate_type)),
                            "sale-trm": data.sale_rate.trm,
                            "unit": data.unit,
                            "service": formatData(servicesData.find(service => service.id === data.service)),
                            "service-group": formatData(serviceGroupsData.find(serviceGroup => serviceGroup.id === data.service_group)),
                            "taxes": { label: data.sale_rate.tax, value: data.sale_rate.tax },
                            "variant": undefined
                        }
                        reset(fillForm)
                    })
                }

            })
            .catch(error => console.error('Error:', error));
    }, []);

    const onSubmit = (data) => {
        setSubmitting(true)
        const submitData = {
            sale_rate: {
                rate_number: data['sale-price'],
                minimum_rate: data['sale-min'],
                currency: data['sale-currency']?.value,
                trm: data['sale-trm'],
                tax: data.taxes?.value,
                // rate_type: { type : data['sale-rate-type'].value },
                rate_type: data['sale-rate-type']?.value
            },
            purchase_rate: {
                rate_number: data['purchase-price'],
                minimum_rate: data['purchase-min'],
                currency: data['purchase-currency']?.value,
                trm: data['purchase-trm'],
                // rate_type: {type : data['purchase-rate-type']?.value},
                rate_type: data['purchase-rate-type']?.value,
            },
            quotation_ids: [currentQuote],
            unit: data.unit,
            user: userId,
            service_group: data['service-group']?.value,
            service: data.service?.value,
            service_variation: data.variant?.value,

            provider_type: data['provider-type']?.value,
            provider: data.provider?.value,

            applies_per: data.applies?.value,

            notes: data.notes,
            provider_rate_file: data['provider-rate'],
        }

        if (selectedPreliquidation) {
            editLiquidation(submitData, selectedPreliquidation).then(res => {
                toast('success', 'Servicio modificado con exito')
                setDisableForm(true);
                setSubmitting(false)
            }).catch(err => { toast('error', 'No se pudo modificar el servicio'); setSubmitting(false) })
        } else {
            postLiquidation(submitData).then(res => {
                toast('success', 'Servicio agregado con exito')
                reset({
                    "applies": { label: '', value: '' },
                    "notes": '',
                    "provider": { label: '', value: '' },
                    "purchase-currency": { label: '', value: '' },
                    "purchase-min": '',
                    "purchase-price": '',
                    "purchase-rate-type": { label: '', value: '' },
                    "purchase-trm": '',
                    "sale-currency": { label: '', value: '' },
                    "sale-min": '',
                    "sale-price": '',
                    "sale-rate-type": { label: '', value: '' },
                    "sale-trm": '',
                    "unit": '',
                    "service": { label: '', value: '' },
                    "service-group": { label: '', value: '' },
                    "taxes": { label: '', value: '' },
                    "variant": undefined,
                })
                setSubmitting(false)
            }).catch(err => { toast('error', 'No se pudo agregar el servicio'); setSubmitting(false) })
        }
    };

    const handleFile = async (e, name) => {
        if (e?.target?.files.length > 0) {
            const file = await toBase64(e.target.files[0])
            setValue(name, file)
        } else {
            setValue(name, '')
        }
    }

    const handleDelete = () => {
        deleteLiquidation(selectedPreliquidation).then(res => {
            toast('success', 'Servicio borrado con exito')
            setOpen(false)
        }).catch(err => { console.log('ERROR :', err); toast('error', 'No se pudo borrar el servicio') })
    }

    let saleTotal = 0
    let purchaseTotal = 0
    if (watch('sale-rate-type')?.value === 1) {
        // %
        saleTotal = watch('sale-price') * watch('unit') / 100
        purchaseTotal = watch('purchase-price') * watch('unit') / 100
    } else {
        //valor
        saleTotal = watch('sale-price') * watch('unit') / watch('sale-trm')
        purchaseTotal = watch('purchase-price') * watch('unit') / watch('purchase-trm')
    }

    const profit = saleTotal - purchaseTotal
    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Formulario de preliquidación de servicios</h3>
            </Modal.Header>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div style={{ display: 'flex', gap: '15px', margin: '15px' }}>
                        <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDisableForm(false)} disabled={!disableForm} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' disabled={!selectedPreliquidation} handler={() => setDeleteConfirmation(true)} />
                    </div>
                    <StyledFieldset disabled={disableForm}>
                        <div>
                            <InputRow>
                                <InputContainer>
                                    <Controller
                                        name="service"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio", }}
                                        render={({ field }) => (
                                            <InputSelect
                                                label="Servicio :"
                                                {...field}
                                                isDisabled={disableForm}
                                                options={services?.map((service) => ({
                                                    label: service.name,
                                                    value: service.id,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    setSelectedService(selectedOption.value);
                                                   // console.log("op" + selectedOption.value);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.service && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.service.message}</span>}
                                </InputContainer>
                                <InputContainer>
                                    <Controller
                                        name="provider"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect
                                                label="Proveedor :"
                                                {...field}
                                                isDisabled={disableForm}
                                                options={providers
                                                    ?.filter((provider) =>
                                                        selectedService ? provider.services.includes(selectedService) : true
                                                    )
                                                    .map((provider) => ({
                                                        label: provider.name,
                                                        value: provider.id,
                                                    }))}
                                            />
                                        )}
                                    />
                                    {errors.provider && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.provider.message}</span>}
                                </InputContainer>
                                {/* <InputContainer>
                                <Controller
                                    name="variant"
                                    control={control}
                                    rules={{ required: "Este campo es obligatorio" }}
                                    render={({ field }) => (
                                        <InputSelect label="Variacion :" {...field}
                                            isDisabled={disableForm}
                                            options={serviceVariations?.filter(variation => variation.service === watch('service')?.value).map(serviceVariation => {
                                                return {
                                                    label: serviceVariation.name,
                                                    value: serviceVariation.id
                                                }
                                            })}
                                        />
                                    )}
                                />
                                {errors.variant && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.variant.message}</span>}
                            </InputContainer> */}
                                <InputRow>
                                </InputRow>
                            </InputRow>
                            <Line />
                            <InputRow>
                                <InputContainer>
                                    <Controller
                                        name="applies"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Aplica por :" {...field}
                                                isDisabled={disableForm}
                                                options={appliesPer?.map(appliesPer => {
                                                    return {
                                                        label: appliesPer.name,
                                                        value: appliesPer.id
                                                    }
                                                })}
                                            />
                                        )}
                                    />
                                    {errors.applies && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.applies.message}</span>}
                                </InputContainer>
                                <SmallInputContainer>
                                    <Controller
                                        name="unit"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 1, message: "El valor mínimo permitido es 1" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="Unidad :" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['unit'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['unit'].message}</span>}
                                </SmallInputContainer>
                            </InputRow>
                            <InputRow>
                                <SmallInputContainer>
                                    <Controller
                                        name="sale-price"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 1, message: "El valor mínimo permitido es 1" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="Tarifa de venta:" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['sale-price'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['sale-price'].message}</span>}
                                </SmallInputContainer>
                                <InputContainer>
                                    <Controller
                                        name="sale-rate-type"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Tipo de tarifa :" {...field}
                                                isDisabled={disableForm}
                                                options={rateTypes?.map(rateType => {
                                                    return {
                                                        label: rateType.type,
                                                        value: rateType.id
                                                    }
                                                })}
                                            />
                                        )}
                                    />
                                    {errors['sale-rate-type'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['sale-rate-type'].message}</span>}
                                </InputContainer>
                                <SmallInputContainer>
                                    <Controller
                                        name="sale-min"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 0, message: "El valor mínimo permitido es 0" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="Minima :" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['sale-min'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['sale-min'].message}</span>}
                                </SmallInputContainer>
                            </InputRow>
                            <InputRow>
                                <InputContainer>
                                    <Controller
                                        name="sale-currency"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Moneda :" {...field}
                                                isDisabled={disableForm}
                                                options={[
                                                    { label: "COP", value: "COP" },
                                                    { label: "EUR", value: "EUR" },
                                                    { label: "USD", value: "USD" },
                                                ]}
                                            />
                                        )}
                                    />
                                    {errors['sale-currency'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['sale-currency'].message}</span>}
                                </InputContainer>
                                <SmallInputContainer>
                                    <Controller
                                        name="sale-trm"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <Input label="TRM :" {...field} />
                                        )}
                                    />
                                    {errors['sale-trm'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['sale-trm'].message}</span>}
                                </SmallInputContainer>
                                <InputContainer>
                                    <Controller
                                        name="taxes"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Impuesto :" {...field}
                                                isDisabled={disableForm}
                                                options={[
                                                    { label: 0, value: 0 },
                                                    { label: 19, value: 19 }
                                                ]}
                                            />
                                        )}
                                    />
                                    {errors.taxes && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.taxes.message}</span>}
                                </InputContainer>
                            </InputRow>
                            {
                                (watch('unit') || watch('sale-price')) &&
                                <p style={{ color: '#FF6B6B' }}>Tarifa {watch('unit')}  x {watch('sale-price')} {watch('sale-currency.label')} Min {watch('sale-min')}</p>
                            }
                            <InputRow>
                                <InputContainer>
                                    <Controller
                                        name="notes"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <Input label="Notas para el cliente" fullWidth {...field} />
                                        )}
                                    />
                                    {errors.notes && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.notes.message}</span>}
                                </InputContainer>
                            </InputRow>
                            <Line />
                            <InputRow>
                                <SmallInputContainer>
                                    <Controller
                                        name="purchase-price"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 1, message: "El valor mínimo permitido es 1" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="Tarifa de compra:" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['purchase-price'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['purchase-price'].message}</span>}
                                </SmallInputContainer>
                                <InputContainer>
                                    <Controller
                                        name="purchase-rate-type"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Tipo de tarifa :" {...field}
                                                isDisabled={disableForm}
                                                options={rateTypes?.map(rateType => {
                                                    return {
                                                        label: rateType.type,
                                                        value: rateType.id
                                                    }
                                                })}
                                            />
                                        )}
                                    />
                                    {errors['purchase-rate-type'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['purchase-rate-type'].message}</span>}
                                </InputContainer>
                                <SmallInputContainer>
                                    <Controller
                                        name="purchase-min"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 0, message: "El valor mínimo permitido es 0" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="Minima :" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['purchase-min'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['purchase-min'].message}</span>}
                                </SmallInputContainer>
                            </InputRow>
                            <InputRow>
                                <InputContainer>
                                    <Controller
                                        name="purchase-currency"
                                        control={control}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        render={({ field }) => (
                                            <InputSelect label="Moneda :" type={'number'} {...field}
                                                isDisabled={disableForm}
                                                options={[
                                                    { label: "COP", value: "COP" },
                                                    { label: "EUR", value: "EUR" },
                                                    { label: "USD", value: "USD" },
                                                ]}
                                            />
                                        )}
                                    />
                                    {errors['purchase-currency'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['purchase-currency'].message}</span>}
                                </InputContainer>
                                <SmallInputContainer>
                                    <Controller
                                        name="purchase-trm"
                                        control={control}
                                        rules={{
                                            required: "Este campo es obligatorio",
                                            min: { value: 0.1, message: "El valor mínimo permitido es 0.1" }
                                        }}
                                        render={({ field }) => (
                                            <Input label="TRM :" type={'number'} {...field} />
                                        )}
                                    />
                                    {errors['purchase-trm'] && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors['purchase-trm'].message}</span>}
                                </SmallInputContainer>
                            </InputRow>
                            <p style={{ margin: '20px' }}>Adjuntar las tarifas del proveedor</p>
                            <InputFileCard label='Sube o arrastra' name='provider-rate' onChange={handleFile} startIcon={{ name: 'Upload', color: '#FFF' }} width={'100%'} aspectRatio={'4/1'} />
                        </div>
                        <RFQDataContainer>
                            {
                                !radication ?
                                    <div>
                                        <h3>Selecciona una cotizacion</h3>
                                        <p>No hay cotizacion seleccionada</p>
                                    </div> :
                                    RFQ ?
                                        <DisplayData data={RFQ} radication={radication} /> :
                                        <Loading />
                            }
                        </RFQDataContainer>
                    </StyledFieldset>
                    <TotalsContainer>
                        <div>
                            <p>Venta estimada </p>
                            {!isNaN(saleTotal) && saleTotal !== Infinity && <h3>{saleTotal.toFixed(2)} USD</h3>}
                        </div>
                        <div>
                            <p>Compra estimada </p>
                            {!isNaN(purchaseTotal) && purchaseTotal !== Infinity && <h3>{purchaseTotal.toFixed(2)} USD</h3>}
                        </div>
                        <div>
                            <p>Profit </p>
                            {!isNaN(profit) && profit !== Infinity && <h3>{profit.toFixed(2)} USD</h3>}
                        </div>
                    </TotalsContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={submitting ? 'Guardando...' : 'Guardar cambios'}
                        type="submit"
                        disabled={Object.keys(errors).length || submitting}
                        bold
                        bgColor={'primary_300'}
                        textColor='neutral_50'
                    />
                </Modal.Footer>
            </form>
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la preliquidacion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDeleteConfirmation(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' disabled={!selectedPreliquidation} handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </StyledFormModal>
    );
}
