import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewsHandler from '../../components/ViewsHandler/ViewsHandler';
import { Collapse } from '@nextui-org/react';
import { styled } from '@nextui-org/react';
import OperationsTable from './OperationsTable';
import Events from './Events/Events';
import TrackingNotes from './Notes';
import Notificate from './Notificate/Notificate';
import CreateOperation from './CreateOperation';
import Instructions from './Instructions/Instructions';

import { getOperations, getOperationTypes, getStatuses, getOperationStatuses, getAgents, getCoordinators } from '../../services/operations.services'
import { getClients } from '../../services/quotations.services';
import { getQuotations } from '../../services/quotation.services';
import { getUsers } from '../../services/user.services'
import OperationsDashboard from './OperationsDashboard';

const StyledContainer = styled('div', {
    marginBottom: '50px',
    ['header']: {
        backgroundColor: '$neutral_50',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.title']: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
        fontFamily: 'inherit',
        color: '$primary_300',
        textTransform: 'none'
    },
    ['.Mui-selected']: {
        fontWeight: '600',
        color: '$primary_300 !important'
    },
    ['.MuiTabs-indicator']: {
        backgroundColor: '$secondary_300'
    }
})

const tabs = [
    {
        id: 'create_operation',
        value: 'create_operation',
        label: 'Crear operacion',
    },
    {
        id: 'events',
        value: 'events',
        label: 'Seguimiento a la operacion',
    },
    {
        id: 'notes',
        value: 'notes',
        label: 'Bitacora',
    },
    {
        id: 'notificate',
        value: 'notificate',
        label: 'Notificar',
    },
    {
        id: 'instructions',
        value: 'instructions',
        label: 'Instrucciones',
    },
    {
        id: 'dashboard',
        value: 'dashboard',
        label: 'Dashboard',
    },
]

const StyledCollapse = styled(Collapse, {
    [".nextui-collapse-title"]: {
        color: '$primary_300',
    }
})

export default function OperationsTracking() {

    const token = useSelector((state) => state.user.token);
    const [tabValue, setTabValue] = useState('create_operation')
    const [tabId, setTabId] = useState(0);

    const [tableItems, setTableItems] = useState([])
    const [updateTable, setUpdateTable] = useState(1)
    const [selectedOperation, setSelectedOperation] = useState('')
    const [operationsData, setOperationData] = useState({})

    const [showTable, setShowTable] = useState(false)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
    };

    function formatDate(dateString) {
        const originalDate = new Date(dateString);
        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1;
        const year = originalDate.getFullYear();

        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');

        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

        return formattedDate;
    }


    useEffect(() => {
        setShowTable(true)
        const asyncTasks = [getOperations({ token }), getOperationTypes(token), getUsers(token), getAgents(token), getStatuses(token), getOperationStatuses(token), getCoordinators(token), getClients(token),getQuotations(token)]
        Promise.all(asyncTasks).then(async responses => {
            const operationsData = await responses[0].json()
            const operationTypesData = await responses[1].json()
            const usersData = await responses[2].json()
            const agentsData = await responses[3].json()
            const statusesData = await responses[4].json()
            const operationStatusesData = await responses[5].json()
            const coordinatorsData = await responses[6].json()
            const clientsData = await responses[7].json()
            const quotationsData = await responses[8].json()

            setOperationData(operationsData)
            console.log('operationsData :',operationsData)
            console.log('quotationsData :',quotationsData)

            const tableData = operationsData.map((operation, i) => {
                const incoterm = quotationsData.services.find(quote =>( quote.radication === operation.radication))?.business_terms
                const shippingData = quotationsData.services.find(quote =>( quote.radication === operation.radication))?.shipping
                console.log('quoteData :',shippingData)
                return {
                    id: operation.id,
                    radication: operation.radication,
                    operation_type_id: operation.operation_type,
                    operation_type: operationTypesData.find(type => type.id === operation.operation_type)?.name,
                    etd: formatDate(operation.ETD),
                    eta: formatDate(operation.ETA),
                    origin: `${shippingData?.origin_boarding_terminal}, ${shippingData?.origin_country}`,
                    destination:`${shippingData?.destination_boarding_terminal}, ${shippingData?.destination_country}`,
                    incoterm,
                    coordinator: usersData.find(user => user.id === coordinatorsData.find(coor => coor.id === operation.coordinator)?.user)?.name + ' ' + usersData.find(user => user.id === coordinatorsData.find(coor => coor.id === operation.coordinator)?.user)?.last_name,
                    client: clientsData.find(client => client.id === operation.client)?.name,
                    agent: agentsData.find(agent => agent.id === operation.agent)?.name,
                    status: statusesData.find(status => status.id === operation.status)?.name,
                    operation_status: operationStatusesData.find(OS => OS.id === operation.operation_status)?.name,
                }
            });
            setTableItems(tableData)
        }).catch(err => console.log('ERROR :', err))
    }, [updateTable])

    return (
        <StyledContainer>
            <header>
                <StyledCollapse title='Ver operaciones en curso' expanded={showTable} onClick={() => setShowTable(true)}>
                    <OperationsTable items={tableItems} setSelectedOperation={setSelectedOperation} setShowTable={setShowTable} updateTable={updateTable} setUpdateTable={setUpdateTable} />
                </StyledCollapse>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    {
                        tabs.map((tab, i) =>
                            <Tab key={i} value={tab.value} label={tab.label} />
                        )
                    }
                </Tabs>
            </header>
            <div style={{ padding: '30px' }}>
                <ViewsHandler
                    activeView={tabId}
                >
                    <CreateOperation updateTable={updateTable} setUpdateTable={setUpdateTable} />
                    <Events selectedOperation={selectedOperation} setSelectedOperation={setSelectedOperation}/>
                    <TrackingNotes selectedOperation={selectedOperation} operationsData={operationsData} />
                    <Notificate selectedOperation={selectedOperation} />
                    <Instructions />
                    <OperationsDashboard/>
                </ViewsHandler>
            </div>
        </StyledContainer>
    )
}