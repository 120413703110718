import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useFieldArray, useForm, Controller } from 'react-hook-form';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import Button from '../../../../components/Button/Button';

const Partnership = ({ setPartnerships }) => {
    const documentTypes = [
        {
            value: 'RFC',
            name: 'RFC'
        },
        {
            value: 'NIT',
            name: 'NIT'
        },
        {
            value: 'C.C.',
            name: 'Cédula de ciudadania'
        }
    ]
    const nameForm = 'partnership'
    const { register, control, getValues } = useForm({
        defaultValues: {
            [nameForm]: [{
                name: '',
                lastName: '',
                documentType: '',
                documentNumber: '',
                totalShares: ''
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: nameForm
    });

    const StyledPartnership = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            },
            ['main section.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setPartnerships(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledPartnership>
            <PageTitle>
                Información societaria
            </PageTitle>

            <aside>
                Sí se trata de sociedad anónima no se requiere esta información, de lo contrario agregar los socios con acciones superiores al 10%
            </aside>

            <main>
                {
                    fields.map((field, i) =>
                        <div key={field.id}>
                            <h5>Socio No. {i + 1}</h5>
                            <section className='form'>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.name`} label='Nombre de socio' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.lastName`} label='Apellido de socio' placeholder='Introducir' required></Input>
                                <Controller
                                    control={control}
                                    name={`${nameForm}.${i}.documentType`}
                                    render={({ field: { onChange, value } }) => (
                                        <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.${i}.documentType`} value={value} label='Tipo de documento' options={documentTypes} required></Select>
                                    )}
                                ></Controller>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.documentNumber`} label='No. de documento' placeholder='Introducir' type='number' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.totalShares`} label='Total acciones' placeholder='% Introducir' type='number' required></Input>

                                <div className='d-flex align-items-end'>
                                    {
                                        i < fields.length - 1 ?
                                            <Button handler={() => { remove(i); saveData() }} text='Eliminar' outline></Button>
                                            :
                                            <Button handler={() => { append(); saveData() }} text='Agregar otro' startIcon={{ name: 'Plus', size: '16' }} outline></Button>
                                    }
                                </div>
                            </section>
                        </div>
                    )
                }
            </main>
        </StyledPartnership>
    );
}

export default Partnership;
