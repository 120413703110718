import React, { useEffect, useState } from 'react';
import { Loading, styled } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getOffers } from '../../../../services/quotations.services';

// Components
import Button from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { getPreliquidations } from '../../../../services/preliquidation.services';
import OfferCard from './OfferCard';
import { getQuotation } from '../../../../services/quotation.services';

const Container = styled ("div",{
    display:'flex',
    flexDirection:'column',
    background:'$neutral_100',
    alignItems:'center',
    gap:'20px',
    width:'90%',
    margin:'3rem auto',
    padding:'20px',
    borderRadius:'8px',
    ['.labels']:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        gap:'10px',
        
    },
    ['.info']:{
        display:'flex',
        marginLeft:'30px',
        gap:'10px',
        flexDirection:'column',
        justifyContent:'space-between',
    }
})

const InfoCard = styled("div", {
    padding:'20px',
    borderRadius:'8px',
    background:'white',
    // minWidth:'500px',
    ['.title']:{
        margin:'20px',
    },
    ['.card_body']:{
        display:'flex',
        justifyContent:'space-between',
    }
})

const DataContainer = styled("div",{
    display:'grid',
    gridTemplateColumns:'repeat(2, 1fr)',
    gap:'20px',
})
const LoadCards = styled("div",{
    gridColumn:'span 2',
    display:'flex',
    gap:'15px',
    ['.load_card']:{
        border:'1px solid $primary_300',
        borderRadius:'8px',
        padding:'10px',
    }
})

export default function Costs ({ radication, service }){
    const token = useSelector((state) => state.user.token);

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [offers, setOffers] = useState(false)
    const [items , setItems] = useState([])
    const [clientChose, setClientChose] = useState(false)
    const [RFQData, setRFQData] = useState({})

    function getData(){
        setLoading(true)
        const asyncTasks = [getOffers(token,radication), getPreliquidations(token), getQuotation(radication, token)]
        Promise.all(asyncTasks).then(async res => {
            const offersData =  res[0]
            const preliquidationsData = await res[1].json();
            const rfqData = await res[2].json();
            setRFQData(rfqData)
            if (offersData?.length > 0){
                setOffers(offersData)
                console.log('offersData :',offersData)
                const formattedData = offersData.map((offer,i) => {
                    if (offer.customer_approval){
                        setClientChose(true)
                    }
                    let cost = 0;
                    offer.preliquidation_ids.map(id => {
                        const prel = preliquidationsData.find(p => p.id===id);
                        const prelCost = prel.sale_rate.rate_number * prel.unit / prel.sale_rate.trm
                        cost +=prelCost
                    })
                    return {
                        ...offer,
                        option: i+1,
                        cost:'$' + cost.toFixed(2),
                    }
                })
                setItems(formattedData)
            }

            setLoading(false)
        })
    }

    useEffect(()=>{
        getData()
    },[])

    // useEffect(()=>{
    //     if (clientChose){
    //         navigate(`/services/detail/chat/?radication=${RFQData.radication}&tab=chat`)
    //     }
    // },[clientChose])

    return (
        <Container>
            <PageTitle>
                {`Pre - Liquidación #${radication}`}
            </PageTitle>
            {clientChose && <Button text="Ir a detalles del servicio" handler={()=>navigate(`/services/detail/chat/?radication=${RFQData.radication}&tab=chat`)}/>}
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',gap:'40px'}}>
            {loading?<Loading size='xl'/>:
            <div>
                <p style={{margin:'20px'}}>{items.length>0?
                'La cotizacion esta lista para su aprobacion, a continuacion se presentan las opciones disponibles.':
                'Pronto nuestro equipo atenderá su solicitud y se pondrá en contacto con usted para brindarle más información y ayudarle en lo que necesite.'
                }</p>
                <div style={{display:'flex', justifyContent:'center', flexDirection:'row',flexWrap:'wrap',gap:'40px'}}>
                    {items.map((item)=>{
                        return <OfferCard clientChose={clientChose} data={item} getData={getData}/>
                    })}
                </div>
            </div>
            }
            </div>
            {RFQData.shipping && RFQData.options && 
            <DataContainer>
                <InfoCard>
                    <p className='title'>- Informacion general</p>
                    <div className='card_body'>
                        <div className='labels'>
                            <b>Radicado </b>
                            <b>Tipo de Negocio </b>
                            <b>Método de envio</b>
                            <b>Incoterm</b>
                        </div>
                        <div className='info'>
                            <p>{RFQData.radication}</p>
                            <p>{RFQData.business_type}</p>
                            <p>{RFQData.shipping.shipping_method}</p>
                            <p>{RFQData.business_terms}</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard>
                    <p className='title'>- Información adicional</p>
                    <div className='card_body'>
                        <div className='labels'>
                            <b>Seguro de Envío </b>
                            <b>Control de Temperatura </b>
                            <b>Peligroso </b>
                            <b>Registro de datos </b>
                            <b>Desmontable </b>
                            <b>Posicion arancelaria </b>
                            <b>Estudio de ruta </b>
                            <b>Analisis de seguridad </b>
                            <b>Notas </b>
                        </div>
                        <div className='info'>
                            <p>{RFQData.options.shipping_insurance === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.temperature_control === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.dangerous === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.data_logger === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.remountable === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.arancelary_position === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.route_study === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.security_analysis === 1? "Si" : "No"}</p>
                            <p>{RFQData.options.additional_specifications || 'Ninguna'}</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard>
                    <p className='title'>- Informacion de origen</p>
                    <div className='card_body'>
                        <div className='labels'>
                            <b>País </b>
                            <b>Embarque </b>
                            <b>Dirección </b>
                        </div>
                        <div className='info'>
                            <p>{RFQData.shipping.origin_country}</p>
                            <p>{RFQData.shipping.origin_boarding_terminal}</p>
                            <p>{RFQData.shipping.pickup_address}</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard>
                    <p className='title'> - Informacion de destino</p>
                    <div className='card_body'>
                        <div className='labels'>
                            <b>País </b>
                            <b>Embarque </b>
                            <b>Dirección </b>
                        </div>
                        <div className='info'>
                            <p>{RFQData.shipping.destination_country}</p>
                            <p>{RFQData.shipping.destination_boarding_terminal}</p>
                            <p>{RFQData.shipping.delivery_address}</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard style={{gridColumn:'span 2'}}>
                    <p className='title'>- Información de Cargas</p>
                    <LoadCards>
                        {RFQData.loads.map((load, index) => (
                            <div key={index} className='load_card'>
                                <p>Tipo de Carga: {load.load_type}</p>
                                <p>Nombre: {load.name}</p>
                                <p>Valor: {load.value}</p>
                                <p>Dimensiones: {load.long} {load.long_unit} x {load.width} {load.width_unit} x {load.height} {load.height_unit}</p>
                                <p>Peso Total: {load.total_weight} {load.total_weight_unit}</p>
                            </div>
                        ))}
                    </LoadCards>
                </InfoCard>
            </DataContainer>
            }
        </Container>
    );
}
