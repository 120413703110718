import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useSearchParams } from 'react-router-dom';
import { getQuotation } from '../../../../services/quotation.services';
import { useSelector } from 'react-redux';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import QuoteDetail from '../../../Quotes/QuotationDetail/QuoteDetail';

import { useTranslation } from "react-i18next";


const StyledServiceLiquidation = styled('div', {
    [`.${theme} &`]: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ['header']: {
            backgroundColor: '$neutral_50',
            padding: '1rem 2rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }
})

const ServiceLiquidation = () => {
    const token = useSelector((state) => state.user.token);
    const [searchParams] = useSearchParams()
    const radication = searchParams.get('radication')
    const [service, setService] = useState({})

    useEffect(() => {
        getQuotation(radication, token)
            .then(async res => {
                const data = await res.json();
                setService(data)
            })
    }, []);

    return (
        <StyledServiceLiquidation>
            <header>
                <PageTitle>
                    Servicio # {radication}
                </PageTitle>
                <p><b>Estado: </b>{service?.state}</p>
            </header>

            <QuoteDetail service={service} />
        </StyledServiceLiquidation>
    );
}

export default ServiceLiquidation;
