import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/Table'
import { styled } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

const columns = [
  { name: 'Acciones', uid: 'actions', type: 'action' },
  { name: 'Proveedor', uid: 'provider', type: 'text' },
  { name: 'Grupo de servicio', uid: 'service_group', type: 'text' },
  { name: 'Servicio', uid: 'service', type: 'text' },
  { name: 'Und', uid: 'unit', type: 'text' },
  { name: 'Venta', uid: 'sale_price', type: 'text' },
  { name: 'Moneda venta', uid: 'sale_currency', type: 'text' },
  { name: 'trm venta', uid: 'sale_trm', type: 'text' },
  { name: '% Impuesto', uid: 'tax', type: 'text' },
  { name: 'Compra', uid: 'purchase_price', type: 'text' },
  { name: 'Moneda compra', uid: 'purchase_currency', type: 'text' },
  { name: 'trm compra', uid: 'purchase_trm', type: 'text' },
  { name: 'Profit (USD)', uid: 'profit', type: 'text' },
];

const TotalsContainer = styled("div", {
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'space-around',
  gap: '20px',
  ['div']: {
    width: '100%',
    backgroundColor: '$primary_300',
    color: '$neutral_50',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '$elevation_1'
  }
})

export default function PreLiquidationTable({ tableData, currentQuote,radication, setSelectedPreliquidation, setOpenModalParticipants }) {

  const [items, setItems] = useState(tableData);
  const navigate = useNavigate()

  const actions = [
    {
      name: 'Seleccionar',
      icon: 'View',
      handle: (item) => {
        setSelectedPreliquidation(item.id)
        setOpenModalParticipants(true)
      }
    },
    {
      name: 'Detalles',
      icon: 'View',
      handle: (item) => {
        navigate(`/manageQuotes/preliquidation-details/?preliquidation=${item.id}&radication=${radication}`)
      }
    },
  ]
  let salesTotal = 0;
  let purchaseTotal = 0;
  let profit = 0;

  tableData.forEach(item => {
    salesTotal += (item.sale_price / item.sale_trm) * item.unit
    purchaseTotal += (item.purchase_price / item.purchase_trm) * item.unit
    profit += item.profit
  });

  // useEffect(()=>{
  //   if (currentQuote){
  //     const filteredData = tableData.filter(item => item.operation === currentQuote)
  //     setItems(filteredData)
  //   } else {setItems(tableData)}
  // },[currentQuote])
  
  return (
    <div style={{ backgroundColor: '#EBEDF0', padding: '20px', borderRadius: '8px' }}>
      {
        currentQuote? items.length > 0 ? <Table columns={columns} items={items} actions={actions} /> : <p>No hay preliquidaciones en la cotizacion seleccionada</p> : <p>Selecciona una cotizacion para utilizar este modulo</p>
      }
      {
        items.length > 0 && <TotalsContainer>
          <div>
            <p>Total de ventas</p>
            <h3>{salesTotal.toFixed(2)} USD</h3>
          </div>
          <div>
            <p>Total de compras</p>
            <h3>{purchaseTotal.toFixed(2)} USD</h3>
          </div>
          <div>
            <p>Profit</p>
            <h3 style={{color:
                profit>350 ? 'white' : profit >50 ? '#FFC300 ' : '#ff2222'
            }}>{profit.toFixed(2)} USD
            </h3>  
          </div>
        </TotalsContainer>
      }
    </div>
  )
}
