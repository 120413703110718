import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import { Avatar, styled } from "@nextui-org/react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserAuth } from '../../../services/auth.services';
import 'moment/locale/es';
import { useTranslation } from "react-i18next";
// Components
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Button/Button";
import toast from "../../../components/Toast/ToastTypes";

function Navbar({ token }) {
    const { t } = useTranslation("Tools");
    const location = useLocation()
    const navigate = useNavigate()
    const [user, setUser] = useState({
        name: '',
        lastName: ''
    })
    const { i18n } = useTranslation();

    const StyledActions = styled('div', {
        [`.${theme} &`]: {
            position: "relative",
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            cursor: 'pointer',
            paddingRight: '15px',
            marginRight: '10px',
            height: "50px"
        },
        ['.number']: {
            position: "absolute",
            top: "0",
            right: "0",
            backgroundColor: "#FF0000",
            color: "#FFF",
            borderRadius: "50%",
            padding: "0px 4px",
            fontSize: "12px"
        }
    })
    const NavContainer = styled("div", {
        [`.${theme} &`]: {
            backgroundColor: "$neutral_50",
            height: "72px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: '2rem',
            position: 'sticky',
            top: '0px',
            right: '0px',
            zIndex: '201'
        },
        ['.tools']: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            textDecoration: `${location.pathname === '/tools' ? 'underline' : 'none'}`,
            color: `${location.pathname === '/tools' ? '$secondary_300' : 'inherent'}`
        },
        ['.notificaciones']: {
            display: 'flex',
            alignItems: 'center',
            marginRight: "20px",
            textDecoration: "none",
        },
        ['.profile']: {
            gap: '1rem',
            display: 'flex',
            alignItems: 'center',
            color: '$neutral_50'
        }
    });

    useEffect(() => {
        getUserAuth(token)
            .then(({ user }) => {
                const userAuth = {
                    name: user.first_name,
                    lastName: user.last_name
                }
                setUser(userAuth)
            });
    }, []);
    return (
        <NavContainer>
            <div style={{ display: "flex" }}>
                <Link to="/tools" className="tools">
                    <Icon name="Herramientas" color={location.pathname === '/tools' ? '#EB9D3A' : undefined}></Icon>
                    {t("title")}
                </Link>
            </div>

            <div className="d-flex align-items-center">
                <div style={{ marginRight: "20px", display: "flex", gap: "20px" }}>
                    <Button
                        disabled={i18n.language === "es"}
                        handler={() => { i18n.changeLanguage("es") }}
                        size={"sm"}
                        text={'Es '}
                        endIcon={{ name: 'Language', size: '20' }}
                        textColor={'primary_300'}
                        bgColor={'neutral_100'}
                    />
                    <Button
                        disabled={i18n.language === "en_US"}
                        handler={() => { i18n.changeLanguage("en_US") }}
                        size={"sm"}
                        text={'En '}
                        endIcon={{ name: 'Language', size: '20' }}
                        textColor={'primary_300'}
                        bgColor={'neutral_100'}
                    />
                </div>
                <div className="profile">
                    <Avatar text={`${user?.name[0]?.toUpperCase()}${user?.lastName[0]?.toUpperCase()}`} size='md'></Avatar>
                    <Link to="/profile/?tab=information" style={{
                        textDecoration: "none",
                    }}>{user.name + ' ' + user.lastName}</Link>
                </div>
            </div>
        </NavContainer>
    )
}

export default Navbar;