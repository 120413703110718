import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';


export default function ComposedBarChart({ title, icon, barDataKey, lineDataKey, data, height, width }) {

  const filteredData = data?.filter(e => e[barDataKey] !== 0)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={width || 500}
        height={height || 400}
        data={filteredData}
        margin={{
          top: 20,
          right: 20,
          bottom: 90,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={barDataKey} barSize={20} fill="#6981ec" />
        <Line type={lineDataKey} dataKey={lineDataKey} stroke="#ff7300" />
      </ComposedChart>
    </ResponsiveContainer>
  );

}
