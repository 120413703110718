import React  from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@nextui-org/react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Icon from '../../Icon/Icon';
import theme from '../../../theme';

const TitleIconContainer = styled("div", {
  display: 'flex',
  gap: '10px',
  margin: '30px',
  width: '100%',
})
const Title = styled("p", {
  color: "$primary_300",
  fontWeight: '800',
})
const Column = styled("div", {
  display: 'flex',
  width:'100%',
  flexDirection: 'column',
})

export default function SimpleBarChart({ data, title, icon }) {

  const { t } = useTranslation("Home")

  const mock = [
    {
      name: 'ene',
      Aéreo: 4000,
      Marítimo: 2400,
      Terrestre: 2400,
    },
    {
      name: 'feb',
      Aéreo: 3000,
      Marítimo: 1398,
      Terrestre: 2210,
    }
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Column>
        <TitleIconContainer>
            <Icon name={icon} color={theme.colors.secondary_200} />
            <Title>{title}</Title>
        </TitleIconContainer>
        <BarChart
          width={900}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 40,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={t('air')} fill="#F7D7AE" />
          <Bar dataKey={t('ocean')} fill="#EEAE5B" />
          <Bar dataKey={t('land')} fill="#3D509A" />
        </BarChart>
      </Column>
    </ResponsiveContainer>
  );
}
