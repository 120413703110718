import React from 'react'
import { styled } from '@nextui-org/react';
import theme from '../../../theme';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';

import { useTranslation } from "react-i18next";

export default function Ticket({ id, subject, date, detail }) {

    const Card = styled("div", {
        display: "flex",
        flexDirection: "column",
        bgColor: "$neutral_50",
        borderRadius: "8px",
        padding: "23px",
    })
    const StyledTag = styled("div", {
        [`.${theme} &`]: {
            background: "$secondary_2_500",
            margin: '8px',
            width: 17,
            height: 17,
            borderRadius: "8px"
        }
    })
    const Header = styled("div", {
        display: 'flex',
        justifyContent: 'space-between',
        div: {
            display: 'flex',
        },
        ['span']: {
            color: '$primary_200'
        }
    })
    const Label = styled("span", {
        whiteSpace: 'nowrap',
        fontWeight: 600,
    })
    const Container = styled("div", {
        margin: "10px",
        display: 'flex',
        h4: {
            margin: '2px 10px'
        }
    })
    const Bottom = styled("div", {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0 0 0'
    })
    return (
        <Card>
            <Header>
                <div>
                    <StyledTag />
                    <h3>Ticket # {id}</h3>
                </div>
                <span>{date}</span>
            </Header>
            <Container>
                <Label>Asunto : </Label>
                <span>{subject}</span>
            </Container>
            <Container>
                <Label>Detalle : </Label>
                <span>{detail}</span>
            </Container>
            <Bottom>
                <Container>
                    <h4>4</h4>
                    <Icon name={'Clip'} />
                    <h4>5</h4>
                    <Icon name={'Mail'} />
                </Container>
                <Button text='Ingresar al chat' bgColor={'transparent'} bold />
            </Bottom>
        </Card>
    )
}
