import React from 'react';
import { Loading } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
// Components
import Table from '../../components/Table/Table';

export default function QuotationsTable({ items, setShowTable, setCurrentQuote, loading }) {

    const navigate = useNavigate()
    // const token = useSelector(state=>state.user.token)
    // const { data, loading } = useFetch(URL + 'quotations/', { 'Authorization': 'Token ' + token });
    // console.log('Quotations :', data)
    // const [items, setItems] = useState([])

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID', uid: 'id', type: 'id' },
        { name: 'Cotización', uid: 'radication', type: 'text' },
        { name: 'Cliente', uid: 'client', type: 'text' },
        { name: 'Servicio', uid: 'service', type: 'text' },
        { name: 'Subtipo', uid: 'subtype', type: 'text' },
        { name: 'Oficina', uid: 'office', type: 'text' },
        { name: 'Razon', uid: 'reason', type: 'text' },
        { name: 'Nro de versiones', uid: 'versions', type: 'textCenter' },
        { name: 'Costo', uid: 'cost', type: 'text' },
        { name: 'Profit', uid: 'profit', type: 'text' },
        { name: 'Estado', uid: 'status', type: 'text' },
    ]

    const actions = [
        {
            name: 'Seleccionar',
            icon: 'View',
            handle: (item) => {
                setCurrentQuote({
                    id:item.id,
                    radication:item.radication,
                })
                setShowTable(false)
            }
        },
        {
            name: 'Ver detalles',
            icon: 'View',
            handle: (item) => {
                navigate(`/manageQuotes/quotation-details/?id=${item.id}&radication=${item.radication}`)
            },
        }
    ]

    return (
        <div className='h-100' style={{ background: '#EBEDF0', padding: '20px', borderRadius: '8px' }}>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items?.length > 0 ?
                        <Table columns={columns} items={items} actions={actions}></Table>
                        :
                        <div className='d-flex justify-content-center'>No hay preliquidaciones</div>
            }
        </div>
    );
}