import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Loading } from '@nextui-org/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getQuotations } from '../../../../services/quotation.services'
import { getMovements } from '../../../../services/quotations.services';
import toast from '../../../../components/Toast/ToastTypes'
import format from 'date-fns/format';
import { debounce } from 'lodash';
// Components
import Table from '../../../../components/Table/Table';
import { getPreliquidations } from '../../../../services/preliquidation.services';
import { getOffers, getQuoteData } from '../../../../services/quotations.services';

import { ROLES } from '../../../../constants';

const TableQuotation = ({ filterType, filterSearch }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const user = useSelector((state) => state.user);
    const token = user.token;
    const [services, setServices] = useState([])
    const [items, setItems] = useState([])
    const [movements, setMovements] = useState()

    const tableStyle = {
        width: '100%',
    };

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text', style: { width: 'auto' } },
        { name: 'Carga', uid: 'load', type: 'text', style: { width: 'auto' } },
        { name: 'Fecha', uid: 'date', type: 'date' },
        { name: 'Estado/Movimientos', uid: 'movements', type: 'stepper', style: { width: 'auto' } },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Costos', uid: 'value', type: 'text', style: { width: 'auto' }  },
        { name: 'Listo para aprobar', uid: 'ready', type: 'text' },
    ];
    useEffect(() => {
        if (searchParams.get('liquidate')) {
            toast("info", "Selecciona desde la tabla la cotizacion que deseas liquidar")
        }
        if (searchParams.get('operation')) {
            toast("info", "Selecciona desde la tabla la cotizacion con la que deseas iniciar una operacion")
        }
    }, [])

    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (item) => navigate(`/quotation/detail/?radication=${item.idRadication}&finished=${item.ready}`)
        },
        {
            name: 'Descargar',
            icon: 'Download',
            handle: () => { toast("warn", "descargar inhabilitado :S") }
        },
        {
            name: 'Editar',
            icon: 'Edit',
            handle: (item) => { navigate(`/new-quotation/?radication=${item.idRadication}`) }
        },
    ]
    /*si el rol es admin o asesor mostrar el menu de liquidar mas adelante se deben agregar los otros roles*/
    //console.log('user.roles :', user.roles)
    ///console.log('aver :', user.roles.includes(1))
    if (user.roles.includes(1) || user.roles.includes(5)) {
        actions.push({
            name: 'Liquidar',
            icon: 'Cotizaciones',
            handle: (item) => { navigate(`/manageQuotes/?radication=${item.idRadication}&id=${item.id}`) }
        });
    }


    useEffect(() => {
        const asyncTasks = [getQuotations(token), getMovements(token), getPreliquidations(token)]
        Promise.all(asyncTasks).then(async res => {
            const RFQData = await res[0].json();
            const movementsData = await res[1].json();
            const preliquidationsData = await res[2].json();

            setMovements(movementsData);

            const offersData = await Promise.all(
                RFQData.services.map(async (service) => {
                    return await getOffers(token, service.radication)
                })
            )
            const allOffers = offersData.filter(offers => offers.length > 0)
            allOffers.forEach(offers => {
                const matchingService = RFQData.services.find(service => service.radication === offers[0].radication)
                const matchingServiceIndex = RFQData.services.indexOf(matchingService)

                const costs = offers.map(offer => {
                    let cost = 0;
                    offer.preliquidation_ids.forEach(id => {
                        const preliquidation = preliquidationsData.find(prel => {
                            return prel.id === id
                        })
                        const prelCost = preliquidation.sale_rate.rate_number * preliquidation.unit / preliquidation.sale_rate.trm
                        cost += prelCost
                    })
                    return cost;
                })
                RFQData.services[matchingServiceIndex].costs = costs;
            })
            setServices(RFQData.services);

        }).catch(err => console.log('error :', err))
    }, [])

    useEffect(() => {
        if (services?.length > 0) {
            let dataTable;
            if (services?.length > 0 && movements) {
                dataTable = services
                    .filter(service => {
                        if (service.state === 'Cotizado' && filterType === "Seleccionar")
                            return true;
                        else if (service.state === 'Cotizado' && filterType === "Carga general")
                            return service.loads[0].load_type === 'Carga General'
                        else if (service.state === 'Cotizado' && filterType === "Carga proyecto")
                            return service.loads[0].load_type === 'Carga Proyecto'
                    })
                    .map(service => {
                        let values;
                        if (service.costs) {
                            values = ''
                            service.costs.forEach((cost, i) => {
                                const separate = i + 1 === service.costs.length ? '' : '/'
                                values += '$' + cost.toFixed(2) + separate
                            })
                        }
                        return ({
                            id: service.id.toString(),
                            idRadication: service.radication,
                            load: service.loads && service.loads.length > 0 ? service.loads[0].load_type : null, date: new Date(service.created_at),
                            expiration: format(new Date(service.expiration_date), 'dd/MM/yyyy'),
                            origin: service.shipping.origin_country,
                            destination: service.shipping.destination_country,
                            value: values || 'No asignado',
                            movements: movements.find(movement => movement.radication === service.radication),
                            ready: service.costs ? "Si" : "No",
                        });
                    });
            } else {
                dataTable = services
            }
            if (filterSearch) {
                dataTable = dataTable.filter(quotation => {
                    let isIncluded = false
                    Object.keys(quotation).map(key => {
                        if (typeof (quotation[key]) === 'string' && quotation[key].toLowerCase().includes(filterSearch.toLowerCase())) {
                            isIncluded = true
                        }
                    })
                    return isIncluded;
                })
            }
            setItems(dataTable);
        }
    }, [services, movements, filterType, filterSearch]);

    return (
        <div className="h-100">
            {services?.length < 1 ? (
                <div className="d-flex justify-content-center">
                    <Loading></Loading>
                </div>
            ) : items.length > 0 ? (
                <Table columns={columns} items={items} actions={actions} searchBar={false} style={tableStyle}></Table>
            ) : (
                <div className="d-flex justify-content-center">No hay cotizaciones</div>
            )}
        </div>
    );
};

export default TableQuotation;
