import React, { useState } from 'react';
import { styled } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import PageTitle from '../../../components/PageTitle/PageTitle';
import ManageModal from './ManageModal';
import { URL } from '../../../constants';

const Container = styled("div", {
    background: '$neutral_50',
    margin: '20px',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    ['h2']: {
        color: '$neutral_400',
        fontWeight: '$semibold',
        margin: '15px',
        fontSize: '20px',
    },
    ['button']: {
        background: '$primary_200 !important',
        borderRadius: '8px',
        height: '150px',
        width: '150px',
        color: '$neutral_50 !important',
        transition: '300ms',
        ['&:hover']: {
            background: '$primary_300 !important',
        }
    }
});

const quotationButtons = [
    { text: 'Clientes', endpoint: 'quotation/clients/', inputs: ['name', 'email'] },
    { text: 'Subtipos', endpoint: 'quotation/subtypes/', inputs: ['name'] },
    { text: 'Servicios', endpoint: 'quotation/solutions/', inputs: ['name', 'service_group'] },
    { text: 'Oficinas', endpoint: 'quotation/offices/', inputs: ['name'] },
    { text: 'Estados', endpoint: 'quotation/statuses/', inputs: ['name'] },
    { text: 'Motivos', endpoint: 'quotation/reasons/', inputs: ['name'] },
]
const operationsButtons = [
    { text: 'Tipos de operacion', endpoint: 'operations/operation_types/', inputs: ['name'] },
    { text: 'Estados', endpoint: 'operations/statuses/', inputs: ['name'] },
    { text: 'Estados de operacion', endpoint: 'operations/operation_statuses/', inputs: ['name'] },
    { text: 'Subestados de operacion', endpoint: 'operations/operation_sub_statuses/', inputs: ['name'] },
    { text: 'Coordinadores', endpoint: 'operations/coordinators/', inputs: ['user'] },
]

export default function Manage() {
    const [openModal, setOpenModal] = useState(false);
    const [title, setTitle] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [inputs, setInputs] = useState([]);

    const handleButtonClick = (button) => {
        setTitle(button.text);
        setEndpoint(button.endpoint);
        setInputs(button.inputs);
        setOpenModal(true);
    };

    return (
        <Container>
            <PageTitle>Administrar</PageTitle>
            <h2>Cotizacion :</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {
                    quotationButtons.map((button, index) => {
                        return <Button key={index} text={button.text} handler={() => handleButtonClick(button)} />
                    })
                }
            </div>
            <h2>Operaciones :</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {
                    operationsButtons.map((button, index) => {
                        return <Button key={index} text={button.text} handler={() => handleButtonClick(button)} />
                        // return <button key={index} onClick={()=> handleButtonClick(button)}>{button.text}</button>
                    })
                }
            </div>
            <ManageModal
                open={openModal}
                setOpen={setOpenModal}
                title={title}
                inputs={inputs}
                endpoint={endpoint}
            />
        </Container>
    );
}