import React, { useEffect, useState } from 'react'
import { styled,Tooltip,Loading } from '@nextui-org/react';
import Button from '../../../components/Button/Button'
import VersionsTable from './VersionsTable';
import { useSelector } from 'react-redux';
import { getClients, getStatuses, getReasons, getSubTypes, getSolutions, getOffices, getQuoteData } from '../../../services/quotations.services';
import { getPreliquidations } from '../../../services/preliquidation.services';
import CreateVersionModal from './CreateVersionModal';
import FinishQuotationModal from '../QuoteForm/FinishQuotationModal';

import { getBusinessTerms } from '../../../services/businessTerms.services';
const StyledDiv = styled('div', {
    bgColor: '$neutral_50',
    borderRadius: '8px',
    padding: '30px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
})

export default function VersionControl({ currentQuote, setCurrentQuote }) {

    const token = useSelector(state => state.user.token)
    const [loading, setLoading] = useState(false)

    const [tableData, setTableData] = useState([])
    const [currentQuoteData, setCurrentQuoteData] = useState({})
    const [openFormModal, setOpenFormModal] = useState(false)
    const [openFinishModal, setOpenFinishModal] = useState(false)

    function getData(){
        setLoading(true)
        const asyncTasks = [getQuoteData(token), getClients(token), getStatuses(token), getReasons(token), getSubTypes(token), getSolutions(token), getOffices(token), getQuoteData(token, currentQuote.id),getBusinessTerms(token), getPreliquidations(token)]
        Promise.all(asyncTasks).then(async responses => {
            const quotesData = await responses[0].json()
            const clientsData = await responses[1].json()
            const statusesData = await responses[2].json()
            const reasonsData = await responses[3].json()
            const subtypesData = await responses[4].json()
            const servicesData = await responses[5].json()
            const officesData = await responses[6].json()
            const currentQuoteData = await responses[7].json()
            const incotermsData = await responses[8].json()
            const preliquidationsData = await responses[9].json();

            setCurrentQuoteData(currentQuoteData)
            
            const versions = quotesData?.filter((quote => quote.radication === currentQuoteData.radication))

            const tableData = versions.map((version => {
                let totalCost = 0;
                let totalProfit = 0
                version.preliquidation_ids.forEach(id => {
                    const prel = preliquidationsData.find(prel => prel.id === id)
                    totalCost += prel.sale_rate.rate_number * prel.unit / prel.sale_rate.trm
                    totalProfit += prel.unit * (prel.sale_rate?.rate_number / prel.sale_rate?.trm - prel.purchase_rate?.rate_number / prel.purchase_rate?.trm)
                })

                return {
                    id: version.id,
                    subtype: subtypesData.find(subtype => subtype.id === version.subtype)?.name,
                    service: servicesData.find(service => service.id === version.service)?.name,
                    reason: reasonsData.find(reason => reason.id === version.reason)?.name,
                    origin: version.shipping.origin_boarding_terminal,
                    destination: version.shipping.destination_boarding_terminal,
                    incoterms: incotermsData.find(incoterm => incoterm.id === version.incoterm)?.name,
                    office: officesData.find(office => office.id === version.office)?.name,
                    client: clientsData.find(client => client.id === version.client)?.name,
                    // status: statusesData.find(status => status.id === version.status)?.name,
                    cost:'$ '+totalCost.toFixed(2),
                    profit:'$ '+totalProfit.toFixed(2),
                    status:version.approve?'Finalizada':'En proceso',
                    observations: version.observations,
                }
            }))
            setTableData(tableData)
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [currentQuote,openFormModal,openFinishModal])

    return (
        <div>
            <div style={{ display: 'flex', gap: '15px', margin: '25px' }}>
                <Button text='Crear nueva versión' bold bgColor={'primary_300'} textColor='neutral_50' disabled={!currentQuote} handler={() => setOpenFormModal(true)} />
                <Button text='Borrar versión' bold bgColor={'delete'} textColor='neutral_50' />
                <Tooltip content="Finaliza la liquidación y la comparte con el cliente para su aprobación">
                    <Button text='Finalizar versión'
                        bold
                        bgColor={'primary_300'}
                        textColor='neutral_50'
                        disabled={!currentQuote}
                        handler={()=>setOpenFinishModal(true)}
                    />
                </Tooltip>
                <FinishQuotationModal quoteData={currentQuoteData} open={openFinishModal} setOpen={setOpenFinishModal} />
            </div>
            <StyledDiv>
                {
                    currentQuote ? loading?<div style={{margin:'100px'}}><Loading size='lg'/></div>: <VersionsTable data={tableData} currentQuote={currentQuote} setCurrentQuote={setCurrentQuote}/> : <p>Seleccione una cotización con multiples versiones</p>
                }
            </StyledDiv>
            <CreateVersionModal currentQuoteData={currentQuoteData} open={openFormModal} setOpen={setOpenFormModal} token={token} />
        </div>
    )
}
