import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { useSearchParams } from 'react-router-dom';
// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import TableServices from './TableServices/TableServices';

// traducciones
import { useTranslation } from "react-i18next";

const StyledServices = styled('article', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            display: 'flex',
            gap: '1.5rem'
        },
    }
})

const History = () => {
    const { t } = useTranslation("History");
    const [searchParams] = useSearchParams()
    const [filterType, setFilterType] = useState("Seleccionar");
    const [filterSearch, setFilterSearch] = useState('')

    const [options, setoptions] = useState([
        { key: "Seleccionar", name: "Seleccionar" },
        { key: "Carga general", name: "Carga general" },
        { key: "Carga proyecto", name: "Carga proyecto" }
    ]);

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterSearch(value)
    }
    return (
        <StyledServices>
            <PageTitle>
                {t("title")}
            </PageTitle>

            <div className="header">
                <Input placeholder={t("search")} startIcon={{ name: 'Search' }} onChange={handleChange} fullWidth></Input>
                <Dropdown options={options} onChange={data => setFilterType(data[0])} outline></Dropdown>
                <Button text={t("Download")} endIcon={{ name: 'Download' }} outline bold></Button>
            </div>

            <div className="flex-1 overflow-h">
                <TableServices filterType={filterType} filterSearch={filterSearch}></TableServices>
            </div>
        </StyledServices>
    );
}

export default History;
