import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import PageTitle from '../../../../../components/PageTitle/PageTitle'
import NeedlePieChart from '../../../../../components/Graphics/NeedlePieChart/NeedlePieChart'
import { getLatLng } from '../../../../../services/map.services'
import { getEmissions } from '../../../../../services/CO2.services'
import { useSelector } from 'react-redux'

const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(5 , 1fr)',
    margin: '20px',
    padding: '40px',
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    boxShadow: "1px 1px 7px #3D509A",
    gap: '20px',
})

const GeneralInfo = styled("div", {
    fontSize: '13px',
    gridColumn: 'span 2',
    display: 'flex',
    flexDirection: 'column',
})
const InfoContainer = styled("div", {
    display: 'flex',
    gap: '30px',
    marginTop: '20px',
    ['div']: {
        display: 'flex',
        flexDirection: 'column',
    },
    ['span']: {
        margin: '5px',
    }
})
const MapContainer = styled("div", {
    gridColumn: 'span 3',
    gridRow: 'span 2',
})
const GraphContainer = styled("div", {
    gridColumn: 'span 2',
    display: 'flex',
    padding: '30px',
    flexDirection: 'column',
    alignItems: 'center',
})

const InitMap = (originCoords, destinationCoords) => {
    const mapOptions = {
        zoom: 2,
        center: { lat: (originCoords.lat + destinationCoords.lat) / 2, lng: (originCoords.lng + destinationCoords.lng) / 2 }
    };
    // Nuevo mapa
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);

    new google.maps.Marker({
        position: originCoords,
        map: map,
        label: 'Origen'
    });

    new google.maps.Marker({
        position: destinationCoords,
        map: map,
        label: 'Destino'
    });
};


export default function ServiceDetails({ service }) {

    const token = useSelector(state => state.user.token)

    const [emissions, setEmissions] = useState(0)
    const [emissionDistance, setEmissionDistance] = useState(0)

    console.log('service :', service)
    useEffect(() => {
        if (Object.keys(service).length > 0) {
            const asyncTasks = [getLatLng(service.shipping.origin_boarding_terminal), getLatLng(service.shipping.destination_boarding_terminal)]
            Promise.all(asyncTasks).then(async responses => {
                const originCoordsData = responses[0].location
                const destinationCoordsData = responses[1].location
                InitMap(originCoordsData, destinationCoordsData)
            })
        }
    }, [service])

    useEffect(() => {
        getEmissions(token, service.radication).then(async res => {
            const emissionsData = await res.json();
            const distanceInMiles = emissionsData?.distance;
            const distanceInKilometers = distanceInMiles * 1.60934; // Conversión de millas a kilómetros
            setEmissions(emissionsData?.co2);
            setEmissionDistance(distanceInKilometers);
        })
    }, [])

    let totalWeight = 0;
    service.loads.forEach((load)=>{
        totalWeight+=load.total_weight
    })

    return (
        <StyledContainer>
            <GeneralInfo>
                <PageTitle>Servicio</PageTitle>
                {service && <InfoContainer>
                    <div>
                        <span><strong>{`Terminos: `}</strong>{service?.business_terms}</span>
                        <span><strong>{`Tipo de operación: `}</strong>{service?.business_type}</span>
                        <span><strong>{`Tipo de carga: `}</strong>{service?.loads[0]?.load_type}</span>
                        <span><strong>{`Peso de Cargas: `}</strong>{service?.loads.map((load, i)=>{
                            return <p style={{fontSize:'13px'}}>{`${i+1}-${load.name}:${load.total_weight} ${load.total_weight_unit}`}</p>
                        })}</span>
                    </div>
                    <div>
                        <span><strong>{`Origen: `}</strong>{service?.shipping?.origin_boarding_terminal}</span>
                        <span><strong>{`Destino: `}</strong>{service?.shipping?.destination_boarding_terminal}</span>
                        <span><strong>{`Metodo de envio: `}</strong>{service?.shipping?.shipping_method}</span>
                        <span><strong>{`Distancia recorrida: `}</strong>{emissionDistance.toFixed(2)} kms</span>
                    </div>

                </InfoContainer>}
            </GeneralInfo>
            <MapContainer>
                <div id={'map'} className='w-100 h-100 border-rounded'></div>
            </MapContainer>
            <GraphContainer>
                <h3 style={{ width: 'fit-content' }}>{`Emision CO2 : ${emissions}`} kg</h3>
                <NeedlePieChart value={emissions} />
            </GraphContainer>
        </StyledContainer>
    )
}
