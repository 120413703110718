import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../../theme';
import { useSelector } from 'react-redux';
import { downloadDocument, getDocuments, handleDownloadDocument } from '../../../../../services/documents.services';
import PdfIcon from '../../../../../assets/Files/pdf.png';
import DocIcon from '../../../../../assets/Files/word.png';
import JpegIcon from '../../../../../assets/Files/jpg.png';
import PngIcon from '../../../../../assets/Files/png.png';
import XlsIcon from '../../../../../assets/Files/excel.png';
import toast from '../../../../../components/Toast/ToastTypes';

import { useTranslation } from "react-i18next";

const StyledAttachments = styled('div', {
    [`.${theme} &`]: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.container']: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
            gridGap: '1rem',
            ['.card']: {
                cursor: 'pointer',
                borderRadius: '8px',
                border: '1px solid $secondary_300',
                backgroundColor: '$neutral_50',
                ['.file']: {
                    padding: '1.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ['img']: {
                        width: '50px'
                    }
                },
                ['.title']: {
                    backgroundColor: '$secondary_50',
                    alignSelf: 'end',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    borderRadius: '0px 0px 8px 8px',
                    padding: '0.5rem 1rem',
                    fontSize: '0.9rem',
                    ['span']: {
                        width: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    },
                    ['span.type']: {
                        color: '$secondary_300'
                    }
                }
            }
        }
    }
})

const Attachments = ({ radication }) => {
    const token = useSelector((state) => state.user.token);
    const [documents, setDocuments] = useState([])
    const download = (document) => {
        downloadDocument(document.route, token)
            .then(res => {
                !res.ok && toast('error', 'Hubo un error al intentar descargar el documento')
                return res.json()
            })
            .then(data => {
                handleDownloadDocument(data.document, document.name)
            })
    }

    useEffect(() => {
        getDocuments(radication, token)
            .then(async res => {
                const data = await res.json()
                res.ok && setDocuments(data)
            })
    }, []);

    function getFileIconAndType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        let IconComponent;
        let fileType;

        switch (extension) {
            case 'pdf':
                IconComponent = PdfIcon;
                fileType = 'PDF';
                break;
            case 'doc':
            case 'docx':
                IconComponent = DocIcon;
                fileType = 'DOC';
                break;
            case 'jpeg':
            case 'jpg':
                IconComponent = JpegIcon;
                fileType = 'JPEG';
                break;
            case 'png':
                IconComponent = PngIcon;
                fileType = 'PNG';
                break;
            case 'xls':
            case 'xlsx':
                IconComponent = XlsIcon;
                fileType = 'XLS';
                break;
            default:
                IconComponent = PdfIcon; // Usa el icono de PDF como predeterminado
                fileType = 'FILE';
        }
        return { IconComponent, fileType };
    }

    return (

        <StyledAttachments>
            <h5>Total de archivos adjuntos: {documents.length}</h5>
            <div className='container'>
                {
                    documents.map((document, i) => {
                        const { IconComponent, fileType } = getFileIconAndType(document.name);
                        return (
                            <div key={i} className='card' onClick={() => download(document)}>
                                <div className='file'>
                                    <img src={IconComponent} alt={fileType} /> {/* Usa la imagen directamente */}
                                </div>
                                <div className='title'>
                                    <span className='name'>{document.name}</span>
                                    <span className='type'>{fileType}</span> {/* Muestra el tipo de archivo */}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </StyledAttachments>
    );
}

export default Attachments;
