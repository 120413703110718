
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@nextui-org/react';
import TextEditor from '../../../components/TextEditor/TextEditor'
import Button from '../../../components/Button/Button'
import InputSelect from '../../../components/Select/inputSelect';
import toast from '../../../components/Toast/ToastTypes';
import CreateExample, { example } from './templates';

import { editQuotation, getQuoteData } from '../../../services/quotations.services';
import  {getQuotation } from '../../../services/quotation.services'

const StyledDiv = styled('div', {
  bgColor: '$neutral_50',
  borderRadius: '8px',
  border: '1px solid grey',
})

export default function OfferRequirements({ currentQuote }) {
  const [offerRequirements, setOfferRequirements] = useState('')
  const [readOnly, setReadOnly] = useState(true)
  const token = useSelector(state => state.user.token)
  const [fillData, setFillData] = useState()

  const handleSubmit = () => {
    setReadOnly(true)
    editQuotation({ terms: offerRequirements }, currentQuote.id).then(async res => {
      setReadOnly(true)
      toast('success', 'Requisitos actualizados')
    }).catch(err => console.log('err :', err))
  }

  useEffect(() => {
    const asyncTasks = [getQuotation(currentQuote.radication,token), getQuoteData(token, currentQuote.id) ]
    Promise.all(asyncTasks).then (async res => {
      const RFQData = await res[0].json()
      const liquidationData = await res[1].json()
      console.log('ALL THE DATA :',{
        ...RFQData,
        ...liquidationData,
      })
      setFillData({
        ...RFQData,
        ...liquidationData,
      })
      setReadOnly(true)
      setOfferRequirements(liquidationData.terms || '')
    })
    .catch(err => console.log('err :', err))
  }, [currentQuote])

  return (
    <StyledDiv>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '15px', margin: '10px' }}>
          <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setReadOnly(false)} disabled={!readOnly || !currentQuote} />
          <Button text='Guardar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => handleSubmit()} disabled={readOnly || !currentQuote} />
        </div>
        <div>
          <div style={{ display: 'flex', margin: '10px' }}>
            <label style={{ margin: '10px' }}>Plantillas :</label>
            <InputSelect
              isDisabled={readOnly}
              disableClear={true}
              onChange={(e) => setOfferRequirements(e.value || '')}
              options={[
                { label: 'Ninguno', value: '' },
                { label: 'Ejemplo', value: fillData && CreateExample(fillData)},
              ]}
            />
          </div>
        </div>
      </div>
      <TextEditor state={offerRequirements} setState={setOfferRequirements} readOnly={readOnly} />
    </StyledDiv>
  )
}
