import { styled } from '@nextui-org/react';
import TextEditor from '../../components/TextEditor/TextEditor'
import Button from '../../components/Button/Button'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQuoteData, editQuotation } from '../../services/quotations.services';
import toast from '../../components/Toast/ToastTypes';

const StyledDiv = styled('div', {
    bgColor: '$neutral_50',
    borderRadius: '8px',
    padding: '20px'
    //   border:'1px solid grey',
})

export default function Notes({ currentQuote }) {

    const [notesHTML, setNotesHTML] = useState('')
    const [readOnly, setReadOnly] = useState(true)
    const [quoteData, setQuoteData] = useState({})
    const token = useSelector(state => state.user.token)

    useEffect(() => {
        getQuoteData(token, currentQuote).then(async res => {
            const data = await res.json();
            setReadOnly(true)
            setQuoteData(data)
            setNotesHTML(data.observations)
        }).catch(err => console.log('err :', err))
    }, [currentQuote])

    const handleSubmit = () => {
        const data = quoteData
        data.observations = notesHTML

        data.origin_terminal = 'origenTepm'
        data.destination_terminal = 'destinoTepm'

        editQuotation(data, quoteData.id).then(async res => {
            toast('success', 'Nota modificada con exito')
            setReadOnly(true)
        }).catch(err => console.log('err :', err))
    }

    return (
        <div>
            <StyledDiv>
                <div style={{ display: 'flex', gap: '15px', margin: '20px' }}>
                    <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setReadOnly(false)} disabled={!readOnly || !currentQuote} />
                    <Button text='Guardar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => handleSubmit()} disabled={readOnly || !currentQuote} />
                </div>
                <TextEditor state={notesHTML} setState={setNotesHTML} readOnly={readOnly} />
            </StyledDiv>
        </div>
    )
}