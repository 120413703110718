import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { Controller, useFormContext } from 'react-hook-form';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import Card2 from '../../../../components/Cards/Card2/Card2';
//Constants
import { projectMethods, generalMethods } from '../../../../constants';

const StyledCommerce = styled('div', {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['aside']: {
            color: '$primary_200'
        },
        ['main']: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
        },
        ['.separate']: {
            margin: '2rem 1rem'
        },
        ['.containerIndustries']: {
            display: 'grid',
            gap: '0.5rem',
        }
    }
})

const Commerce = ({shippingMethods, commerceTypes, loadTypes, setDisabledContinue }) => {
    const nameForm = 'commerce'
    const { watch, setValue, getValues, control, formState: { errors } } = useFormContext()

    const [_shippingMethods, _setShippingMethods] = useState([])
    const [selectedShipping, setSelectedShipping] = useState(getValues()[nameForm]?.shippingMethod||'')

    // console.log('aver :',watch())
    // console.log('ERRORS :',errors)

    useEffect(() => {
        setValue(`${nameForm}.shippingMethod`, selectedShipping);
        if (!getValues(`${nameForm}.shippingMethod` ) || !getValues(`${nameForm}.commerceType`)){
            setDisabledContinue(true)
        } else {
            setDisabledContinue(false)
        }
    }, [selectedShipping, watch(`${nameForm}.commerceType`)]);

    const selectShipping = (index) => {
        if (selectedShipping.text !== _shippingMethods[index].text) {
            setSelectedShipping(_shippingMethods[index])
        }
    }

    useEffect(() => {
        if (getValues(`liquidation.loadType`)?.value !== '' && shippingMethods?.length > 0) {
            let methods = []
            if (getValues(`liquidation.loadType`)?.value === loadTypes[0]?.id) {
                methods = generalMethods
            } else if (getValues(`liquidation.loadType`)?.value === loadTypes[1]?.id) {
                methods = projectMethods
            }
            _setShippingMethods(methods)
        }
    }, [])

    return (
        <StyledCommerce>
            <PageTitle>
                Tipo de comercio
            </PageTitle>
            <aside>
                Selecciona que tipo de comercio vamos a transportar.
            </aside>
            <main>
                <Controller
                    control={control}
                    name={`${nameForm}.commerceType`}
                    rules={{required:"Campo obligario"}}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(value) => { onChange(value)}} name={`${nameForm}.commerceType`} value={value} options={commerceTypes} orientation='horizontal'></RadioGroup>
                    )}
                ></Controller>

                <hr className='separate' />

                <div className='d-flex flex-column gap-1'>
                <div className='d-flex flex-column gap-1'>
                <PageTitle>
                    Medios de envío
                </PageTitle>
                {
                    getValues(`liquidation.loadType`)?.value === loadTypes[0]?.id ?
                    <aside>Selecciona una opción.</aside>:
                    getValues(`liquidation.loadType`)?.value === loadTypes[1]?.id &&
                    <aside>Te recordamos que <b>el 90% de viajes son marítimos</b></aside>
                }
                <div className='d-flex gap-1'>
                    {
                        _shippingMethods.map((shippingMethod, i) => {
                            return (<Card2
                                key={i}
                                index={i}
                                icon={shippingMethod.icon}
                                text={shippingMethod.text}
                                textSelected={selectedShipping?.text}
                                handler={selectShipping}>
                            </Card2>)
                        }
                        )
                    }
                </div>
            </div>
                </div>
            </main>
        </StyledCommerce>
    );
}

export default Commerce;
