import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import { useForm, Controller } from 'react-hook-form'
import theme from '../../../theme';

// Components
import Input from '../../../components/Input/Input'
import Summary from '../Summary/Summary';

const Information = ({ user }) => {

    const { handleSubmit, control, reset } = useForm();

    const StyledInformation = styled('div', {
        [`.${theme} &`]: {
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['.inputs']: {
                marginTop: '1rem',
                display: 'grid',
                gridGap: '1rem',
                gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                gridAutoFlow: 'dense',
                width: '70%'
            }
        }
    })
    const StyledForm = styled("form", {
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        width: '100%',
        padding: '2rem',
        gap: '1rem'
    })

    useEffect(() => {
        reset({
            email: user.email,
            phone: user.phone,
            address: user.address,
        })
    }, [user])

    return (
        <StyledInformation className='theme'>
            <Summary user={user}></Summary>

            <StyledForm>
                <span className='bold'>Datos de contacto</span>
                <div className='inputs'>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <Input label="Mail de contacto"  {...field} disabled={true} />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <Input label="Teléfono" {...field} disabled={true} />
                        )}
                    />
                    <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                            <Input label="Dirección" {...field} disabled={true} />
                        )}
                    />
                </div>
            </StyledForm>
        </StyledInformation>
    );
}

export default Information;
