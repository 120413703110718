import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip } from 'recharts';
import { styled } from "@nextui-org/react";
import Icon from '../../Icon/Icon';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

export default function PieRechart({ title, icon, data }) {

    const { t } = useTranslation("Home")

    const StyledDiv = styled("div", {
        position: 'relative',
        backgroundColor: "$neutral_50",
        borderRadius: "8px",
        display: "flex",
    })
    const TitleIconContainer = styled("div", {
        display: 'flex',
        gap: '10px',
        margin: '30px',
        width: '100%',
    })
    const Column = styled("div", {
        display: 'flex',
        flexDirection: 'column',
    })
    const DataContainer = styled("div", {
        display: 'flex',
        gap: '6px',
        flexDirection: 'column',
        justifyContent: 'center',
    })
    const Title = styled("p", {
        color: "$primary_300",
        fontWeight: '800',
    })
    const noData = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #CCC",
        height: "232px",
        padding: "10px",
    };
    const COLORS = ['#EEAE5B', '#AFB7D6', '#F7D7AE', '#303C90', '#292E68'];

    return (
        <StyledDiv>
            <Column>
                <TitleIconContainer>
                    <Icon name={icon} color={theme.colors.secondary_200} />
                    <Title>{title}</Title>
                </TitleIconContainer>
                {data?.length === 0 ? (
                    <div style={noData}>
                        <p>{t("no-data")}</p>
                    </div>
                ) : (
                    <PieChart width={300} height={230} >
                        <Pie
                            data={data}
                            cx={130}
                            cy={100}
                            innerRadius={60}
                            outerRadius={90}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip/>
                    </PieChart>)}
            </Column>
            <DataContainer>
                {
                    data?.map((e, i) => {
                        return (
                            <p key={i} style={{ color: COLORS[i] }}>{e.name} : {e.value}</p>
                        )
                    })
                }
            </DataContainer>
        </StyledDiv>
    );
}
