import React, { useEffect, useState } from "react";
import { Input as Inp, styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from 'react-hook-form';

//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import Input from "../../../../components/Input/Input";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Button from "../../../../components/Button/Button";
import Boat from "../../../../components/Graphics/Boat/Boat";

import { validateAllLoad, validateCurrent, calculateTotalTons, calculateCBM } from "./Functions";

const StyledLoad = styled("div", {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['aside']: {
            color: "$primary_200"
        },
        ['main']: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
        },
        ['.separate']: {
            margin: '0rem 1rem'
        },
        ['.form']: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
            gap: '0.5rem'
        },
        ['.inputContainer']: {
            ['.nextui-input-main-container']: {
                width: '100%',
                ['label']: {
                    color: '$primary_500'
                }
            },
            ['.nextui-input-content--right']: {
                width: 'fit-content',
                padding: '0rem',
                ['.nextui-dropdown-button']: {
                    height: '100%'
                }
            }
        },
        ['.inputDropdown']: {
            borderRadius: '8px',
            backgroundColor: 'white',
            border: 'solid 1px $primary_300',
            transition: 'border 0.25s ease',
            marginRight: 0,
            '&:focus-within': {
                border: `solid 1px $primary_100`,
            },
            '.nextui-input-wrapper': {
                borderRadius: '8px',
                backgroundColor: '$neutral_50'
            },
            '.nextui-input-label--right': {
                borderRadius: '0px 8px 8px 0px',
                backgroundColor: '$neutral_100'
            }
        },
        ['.cardUnit']: {
            padding: '0rem 1.25rem',
            backgroundColor: '$neutral_100',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            color: '$primary_500',
            fontSize: '0.875rem'
        },
        [`.form-container`]: {

            padding: '10px',
            marginBottom: '10px',
        },

        [`.minimized-forms`]: {
            display: "flex",
            flexWrap: "wrap",
        },
        [`.minimized-form-container`]: {
            flexBasis: "calc(100% / 8)",
        },
        ['.load-info']:{
            display:'flex',
            justifyContent:'space-around',
            margin:'15px',
        }
    }
});
const Paginado = styled('div', {
    display: 'flex',
    gap: '10px'
})
const containerSizes = [
    { value: '20 pies', name: '20 pies' },
    { value: '40 pies', name: '40 pies' },
    { value: '40 HC pies', name: '40 HC pies' }
];
const distanceUnits = [
    { key: 'cm', name: 'cm' },
    { key: 'm', name: 'm' },
    { key: 'in', name: 'in' }
];
const weightUnits = [
    { key: 'kg', name: 'kg' },
    { key: 'lb', name: 'lb' }
];
const insuranceOptions = [
    { value: true, name: 'Sí, ya cuento.' },
    { value: false, name: 'No, aún no cuento.' }
];

const nameForm = 'load'

function Load({ activeStep, setDisabledContinue, load, setLoad, loadType, loadTypes,shippingMethod, shippingMethods }) {

    const { register, control, getValues, setValue, watch } = useFormContext();

    // const shippingMethod = getValues("commerce.shippingMethod.value") Pendiente

    console.log('shippingMethod :',shippingMethod)
    console.log('shippingMethods :',shippingMethods)

    const values = getValues()[nameForm];
    const [totalTons, setTotalTons] = useState(0);
    const [totalCBM, setTotalCBM] = useState(0);
    const [disableAddLoad, setDisableAddLoad] = useState(true)
    const [expandedForm, setExpandedForm] = useState(0);
    const [loadCount, setLoadCount] = useState(Object.keys(load).length || 1);

    const handleChangeDropdown = (value, name, i) => {
        setValue(name, value[0])
        if (name === `${nameForm}[${i}].unitWeightItem`) {
            setValue(`${nameForm}[${i}].totalWeightUnit`, value[0])
        }

        if (name === `${nameForm}[${i}].longUnit` || name === `${nameForm}[${i}].widthUnit` || name === `${nameForm}[${i}].heightUnit`) {
            setValue(`${nameForm}[${i}].longUnit`, value[0])
            setValue(`${nameForm}[${i}].widthUnit`, value[0])
            setValue(`${nameForm}[${i}].heightUnit`, value[0])
        }
    }

    const formValues = watch();
    useEffect(() => {
        const values = getValues();
        setTotalTons(calculateTotalTons(values))
        setTotalCBM(calculateCBM(values))
        if (activeStep === 4) {
            if (validateAllLoad(values)) {
                setDisabledContinue(false);
            } else {
                setDisabledContinue(true);
            }
        }
        if (validateCurrent(getValues(), expandedForm)) {
            setDisableAddLoad(false)
        } else { setDisableAddLoad(true)}

        if (values.load[expandedForm]?.amount && values.load[expandedForm]?.unitWeight &&
            values.load[expandedForm].totalWeight !== values.load[expandedForm].amount * values.load[expandedForm].unitWeight
            ){
            const totalW = values.load[expandedForm].amount * values.load[expandedForm].unitWeight
            setValue(`${nameForm}[${expandedForm}].totalWeight`, totalW)
        }
        
    }, [formValues]);

    // useEffect(() => {
    //     setValue(`${nameForm}`, load)
    //     return () => {
    //         setLoad(getValues()[nameForm])
    //     }
    // }, [])

    useEffect(() => {
        if (!getValues(`${nameForm}[${expandedForm}].insurance`)){
            setValue(`${nameForm}[${expandedForm}].insurance`, false)
        }
    }, [expandedForm])

    const handleAddLoad = () => {
        const values = getValues()
        if (validateCurrent(values, expandedForm) || loadCount === 0) {
            setLoad(values)
            setLoadCount(loadCount + 1);
            setExpandedForm(loadCount);
        }
    };

    const handleEditLoad = (index) => {
        setExpandedForm(index);
    };
    const handleDeleteLoad = (index) => {
        setLoadCount(loadCount - 1);
        const values = getValues();
        const newLoadData = [...values.load];
        newLoadData.splice(index, 1);
        setValue("load", newLoadData);
    };


    return (
        <StyledLoad>
            <PageTitle>
                Información de tu carga
            </PageTitle>
            {
                loadType === loadTypes[0]?.id ?
                    <aside>Ingresa información del envío de tu carga general.</aside>
                    : loadType === loadTypes[1]?.id &&
                    <aside>Ingresa información del envío de tu carga proyecto.</aside>
            }
            <div className="form-wrapper">
                <div className="expanded-form">
                    {Array.from({ length: loadCount }, (_, i) => (
                        (expandedForm === i) ? <main key={i}>
                            <div className="form-container">
                                <>
                                    {
                                        shippingMethod === shippingMethods[1]?.id &&
                                        shippingMethod === 2 &&
                                        <div style={{ margin: '20px 0' }}>
                                            <Controller
                                                control={control}
                                                name={`${nameForm}[${i}].containerSize`}
                                                render={({ field: { onChange, value } }) => (
                                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}[${i}].containerSize`} value={value} label='Selecciona un contenedor' options={containerSizes} required></RadioGroup>
                                                )}
                                            ></Controller>
                                        </div>
                                    }
                                    <div className="form">
                                        <Controller
                                            control={control}
                                            name={`${nameForm}[${i}].loadName`}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    onChange={event => {
                                                        field.onChange(event);
                                                    }}
                                                    id='loadNameInput'
                                                    label='Nombre de la carga'
                                                    placeholder='Introduce nombre'
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`${nameForm}[${i}].loadValue`}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    onChange={event => {
                                                        field.onChange(event);
                                                    }}
                                                    label='Valor de la carga USD'
                                                    placeholder='$ Introducir valor'
                                                    type='number'
                                                    required
                                                />
                                            )}
                                        />
                                        {shippingMethod !== 2 && <div className='inputContainer'>
                                            <Inp
                                                {...register(`${nameForm}[${i}].long`)}
                                                className="inputDropdown"
                                                label="Largo"
                                                placeholder="Introducir"
                                                type={'number'}
                                                animated={false}
                                                contentRight={
                                                    <Dropdown onChange={(value) => handleChangeDropdown(value, `${nameForm}[${i}].longUnit`, i)} name={`${nameForm}[${i}].longUnit`} options={distanceUnits} value={watch(`load.${expandedForm}.longUnit`)} bgColor='neutral_100' />
                                                }
                                            />
                                        </div>}
                                        {shippingMethod !== 2 && <div className='inputContainer'>
                                            <Inp
                                                {...register(`${nameForm}[${i}].width`)}
                                                className="inputDropdown"
                                                label="Ancho"
                                                placeholder="Introducir"
                                                type={'number'}
                                                animated={false}
                                                contentRight={
                                                    <Dropdown onChange={(value) => handleChangeDropdown(value, `${nameForm}[${i}].widthUnit`, i)} name={`${nameForm}[${i}].widthUnit`} options={distanceUnits} value={watch(`load.${expandedForm}.widthUnit`)} bgColor='neutral_100' />
                                                }
                                            />
                                        </div>}
                                        {shippingMethod !== 2 && <div className='inputContainer'>
                                            <Inp
                                                {...register(`${nameForm}[${i}].height`)}
                                                className="inputDropdown"
                                                label="Alto"
                                                placeholder="Introducir"
                                                type={'number'}
                                                animated={false}
                                                contentRight={
                                                    <Dropdown onChange={(value) => handleChangeDropdown(value, `${nameForm}[${i}].heightUnit`, i)} name={`${nameForm}[${i}].heightUnit`} options={distanceUnits} value={watch(`load.${expandedForm}.heightUnit`)} bgColor='neutral_100' />
                                                }
                                            />
                                        </div>}
                                        <div className='inputContainer'>
                                            <Controller
                                                control={control}
                                                name={`${nameForm}[${i}].amount`}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        label='Cantidad'
                                                        placeholder='$ Introducir cantidad'
                                                        type='number'
                                                        required
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='inputContainer'>
                                            <Inp
                                                {...register(`${nameForm}[${i}].unitWeight`)}
                                                className="inputDropdown"
                                                label="Peso por pieza"
                                                placeholder="Introducir"
                                                type={'number'}
                                                animated={false}
                                                contentRight={
                                                    <Dropdown onChange={(value) => handleChangeDropdown(value, `${nameForm}[${i}].unitWeightItem`, i)} name={`${nameForm}[${i}].unitWeightItem`} options={weightUnits} bgColor='neutral_100' />
                                                }
                                            />
                                        </div>
                                        <div className='inputContainer'>
                                            <Inp
                                                {...register(`${nameForm}[${i}].totalWeight`)}
                                                className="inputDropdown"
                                                label="Peso total"
                                                placeholder="Introducir"
                                                type={'number'}
                                                animated={false}
                                                /*  disabled
                                                 css={{ 'input:disabled': { color: 'inherit' } }} */
                                                contentRight={<div className="cardUnit">{watch(`${nameForm}[${i}].totalWeightUnit`)}</div>}
                                            />
                                        </div>
                                    </div>
                                    <hr className="separate" />
                                    <Controller
                                        control={control}
                                        className="w-50"
                                        name={`${nameForm}[${i}].insurance`}
                                        render={({ field }) => (
                                            <RadioGroup
                                                name={`${nameForm}.insurance`}
                                                {...field} // Esto garantiza que el valor del campo sea el valor actual en el estado del formulario
                                                label='¿Ya cuentas con un seguro?'
                                                options={insuranceOptions}
                                                required
                                            />
                                        )}
                                    />
                                </>
                            </div>
                        </main> : null
                    )
                    )}
                </div>
                <Button handler={handleAddLoad} text={'Añadir otra carga'} bold outline width="100%" disabled={disableAddLoad} />
            </div>
            <div className="load-info">
                <p>Toneladas totales: {totalTons?.toFixed(2)}</p>
                <p>Cargas totales: {loadCount}</p>
                <p>cbm : {totalCBM.toFixed(3)}</p>
            </div>
            {loadCount > 1 ?
                <Paginado>
                    {
                        Array.from({ length: loadCount }, (_, i) => (
                            <Button text={values[i]?.loadName || 'no name'} outline handler={() => handleEditLoad(i)}></Button>
                        ))
                    }
                </Paginado> : null}
            {shippingMethod === 2 ?
                <Boat loadCount={loadCount} expandedForm={expandedForm} handleEditLoad={handleEditLoad} handleDeleteLoad={handleDeleteLoad} /> :
                null}

        </StyledLoad>
    );
}
export default Load;