import React, { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ViewsHandler from '../ViewsHandler/ViewsHandler'
import Button from '../Button/Button'
import Icon from '../Icon/Icon';
import themeColors from '../../theme';
import { Input, Loading } from '@nextui-org/react';
import { calculateCO2 } from '../../services/CO2.services';
import FormCO2 from '../FormCO2/FormCO2';
import { connect } from 'react-redux'
import { emittedAction, showTreeAction } from '../../store/CO2';
import Dropdown from '../Dropdown/Dropdown';

const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: themeColors.colors.primary_300.value,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: themeColors.colors.primary_300.value,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: themeColors.colors.neutral_200.value,
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
    color: themeColors.colors.neutral_200.value,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: themeColors.colors.primary_300.value,
    }),
    '& .QontoStepIcon-completedIcon': {
        color: themeColors.colors.primary_300.value,
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    '.stepActive': {
        backgroundColor: themeColors.colors.primary_300.value,
        display: 'flex',
        borderRadius: '1rem',
        padding: '0.4rem',
    }
}));

const StyledSteps = styled('div')(() => ({
    backgroundColor: themeColors.colors.neutral_50.value,
    paddingTop: '2rem',
    borderRadius: '8px',
    '.contentContainer > div > div': {
        overflow: 'visible !important'
    },
    '.contentContainer > div': {
        width: '100%'
    },
    '.contentContainer': {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'visible !important',
        overflowX: 'clip !important',
        '.content': {
            width: '100%',
            height: '100%',
            color: themeColors.colors.primary_500.value,
            display: 'flex',
            position: 'relative',
            padding: '0rem 2rem',
            h3: {
                color: themeColors.colors.primary_300.value,
            },
            '.form': {
                display: 'flex',
                alignItems: 'center',
                width: '100%'
            },
            '.inputWeight': {
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                padding: '1rem 0rem',
                ['.nextui-input-content--right']: {
                    width: 'fit-content',
                    padding: '0rem',
                    ['.nextui-dropdown-button']: {
                        height: '100%'
                    }
                }
            },
            label: {
                color: themeColors.colors.primary_500.value,
            },
            '.o_input': {
                borderRadius: '8px',
                backgroundColor: 'white',
                border: 'solid 1px $primary_300',
                transition: 'border 0.25s ease',
                marginRight: 0,
                '&:focus-within': {
                    border: `solid 1px ${themeColors.colors.primary_100.value}`,
                },
                '.nextui-input-wrapper': {
                    borderRadius: '8px',
                    backgroundColor: themeColors.colors.neutral_50.value
                },
                '.nextui-input-label--right': {
                    borderRadius: '0px 8px 8px 0px',
                    backgroundColor: themeColors.colors.neutral_100.value
                }
            },
            '.iconSummary': {
                backgroundColor: themeColors.colors.primary_300.value,
                display: 'flex',
                padding: '0.7rem',
                borderRadius: '8px 0px 0px 8px'
            },
            '.originSummary, .destinationSummary': {
                padding: '0.5rem',
                width: '25%',
                textAlign: 'justify',
                wordBreak: 'break-word',
                backgroundColor: themeColors.colors.primary_50.value
            },
            '.destinationSummary': {
                borderRadius: '0px 8px 8px 0px'
            },
            '.arrowSummary': {
                padding: '0rem 0.5rem',
                backgroundColor: themeColors.colors.primary_50.value
            },
            '.rowSummary': {
                display: 'flex',
                justifyContent: 'center',
                margin: '0.5rem 0rem',
            },
            '.contentSummary': {
                overflowY: 'scroll',
                height: '290px',
                '&::-webkit-scrollbar': {
                    width: '5px'
                },
                '&::-webkit-scrollbar-track': {
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#5e6eab',
                    borderRadius: '50px'
                }
            },
            '.totalInfo': {
                backgroundColor: themeColors.colors.primary_300.value,
                padding: '1rem',
                margin: '1rem',
                textAlign: 'center',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '195px',
                h3: {
                    color: themeColors.colors.neutral_50.value,
                    marginBottom: '0rem'
                },
                '.fontWeightThin': {
                    fontWeight: '100'
                }
            }
        },
    },
    '.footer': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
    },
    '.error': {
        border: 'solid 1px red'
    },
    '.manageButtons div, .manageButtons section': {
        gap: '1rem'
    }
}));


function Steps({ emittedAction, showTreeAction }) {
    const theme = useTheme();
    const sendMethods = [
        {
            icon: 'Terrestre',
            text: 'Terrestre',
            logicalName: 'ground'
        },
        {
            icon: 'Maritimo',
            text: 'Marítimo',
            logicalName: 'ocean'
        },
        {
            icon: 'Aereo',
            text: 'Aéreo',
            logicalName: 'air'
        }
    ]
    const optionsWeightUnit = [
        {
            key: 'Ton',
            name: 'Ton'
        },
        {
            key: 'Kg',
            name: 'Kg'
        }
    ]
    const [activeStep, setActiveStep] = React.useState(0);
    const [co2Emitido, setCo2Emitido] = useState(0)

    const [data, setData] = useState([])

    const [ttw, setTtw] = useState(0)
    const [distance, setDistance] = useState(0)
    const [loadingCO2, setLoadingCO2] = useState(false)
    const [errors, setErrors] = useState({})
    const [weight, setWeight] = useState('')
    const [weightUnit, setWeightUnit] = useState(optionsWeightUnit[0].name)
    const [originLoading, setOriginLoading] = useState(false)
    const [destinationLoading, setDestinationLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [dataShipping, setDataShipping] = useState([
        {},
        {
            sendMethod: sendMethods[0].text,
            iconSendMethod: sendMethods[0].icon,
            logicalSendMethod: sendMethods[0].logicalName,
            origin: '',
            originLocation: { lat: 0, lng: 0 },
            destination: '',
            destinationLocation: { lat: 0, lng: 0 },
        },
        {}
    ]);

    const handleChange = (e) => {
        setWeight(e.target.value)
    }

    const handleWeightUnitChange = (selected) => {
        setWeightUnit(selected[0])
    }

    const handleNext = () => {
        if (activeStep < dataShipping.length - 1 && validate())
            setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep > 0 && validate())
            setActiveStep(activeStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const calculate = async () => {
        setButtonLoading(true)
        if (validate()) {
            let formData = []
            dataShipping.forEach((element, i) => {
                if (i !== 0 && i !== dataShipping.length - 1) {
                    formData.push({
                        weight: weight,
                        weightUnit: weightUnit,
                        sendMethod: element.logicalSendMethod,
                        origin: element.origin,
                        destination: element.destination
                    })
                }
            });

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setLoadingCO2(true)

            const data = await calculateCO2(formData)
            setData(data)
            let totalDistance = 0;
            let totalTTW = 0;
            let totalCo2 = 0;

            data.forEach(e => {
                totalDistance += e.distance;
                totalTTW += e.TTW;
                totalCo2 += e.CO2;
            });
            setTtw(totalTTW)
            setCo2Emitido(totalCo2)
            setDistance(totalDistance)
            emittedAction(data[0].CO2)
            showTreeAction(true)
            setLoadingCO2(false)
        }
        setButtonLoading(false)
    }
    const validateWeight = (weight, errors) => {
        if (weight === '') {
            errors.weight = 'Requerido';
        } else if (weight < 0.1) {
            errors.weight = 'Mínimo 0.1';
        } else if (weight > 100000) {
            errors.weight = 'Máximo 100000';
        }
    };

    const validateLocation = (location, name, activeStep, errors) => {
        if (location === '') {
            errors[name + activeStep] = 'Requerido';
        }
    };

    const validate = (name = 'all') => {
        const _errors = {};

        if (activeStep === 0) {
            validateWeight(weight, _errors);
        } else {
            if (name === 'origin' || name === 'all') {
                validateLocation(dataShipping[activeStep].origin, 'origin', activeStep, _errors);
            }
            if (name === 'destination' || name === 'all') {
                validateLocation(dataShipping[activeStep].destination, 'destination', activeStep, _errors);
            }
        }

        setErrors(_errors);

        return Object.keys(_errors).length === 0;
    };


    const createNewStepData = (activeStep, sendMethods, dataShipping) => {
        return {
            sendMethod: sendMethods[0].text,
            iconSendMethod: sendMethods[0].icon,
            logicalSendMethod: sendMethods[0].logicalName,
            origin: dataShipping[activeStep].destination,
            originLocation: dataShipping[activeStep].destinationLocation,
            destination: '',
            destinationLocation: { lat: 0, lng: 0 },
        };
    };

    const addStep = async () => {
        setButtonLoading(true);
        if (validate()) {
            const data = createNewStepData(activeStep, sendMethods, dataShipping);
            const dataShippingClone = structuredClone(dataShipping);
            dataShippingClone.splice(activeStep + 1, 0, data);
            setDataShipping(dataShippingClone);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        setButtonLoading(false);
    };


    const removeStep = () => {
        setButtonLoading(true)
        const dataShippingClone = structuredClone(dataShipping)
        dataShippingClone.splice(activeStep, 1)
        setDataShipping(dataShippingClone)

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setButtonLoading(false)
    }

    const getOnlyDataShipping = () => {
        const dataShippingClone = structuredClone(dataShipping)
        dataShippingClone.shift()
        dataShippingClone.pop()
        return dataShippingClone
    }

    const renderIcon = (active, iconName) => {
        return (
            <div className={active ? 'stepActive' : 'd-flex'}>
                <Icon name={iconName} size='20' color={active ? 'white' : undefined}></Icon>
            </div>
        );
    };

    const QontoStepIcon = (props, element, i) => {
        const { active } = props;
        const isEdgeStep = i === 0 || i === dataShipping.length - 1;
        const iconName = isEdgeStep ? 'Circle' : element.iconSendMethod;

        return (
            <QontoStepIconRoot ownerState={{ active }}>
                {renderIcon(active, iconName)}
            </QontoStepIconRoot>
        );
    };


    useEffect(() => {
        showTreeAction(false)
    }, []);

    return (
        <StyledSteps>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {
                    dataShipping.map((element, i) => (
                        <Step key={i}>
                            <StepLabel StepIconComponent={(props) => QontoStepIcon(props, element, i)}></StepLabel>
                        </Step>
                    ))
                }
            </Stepper>

            <ViewsHandler
                activeView={activeStep}
                index={activeStep}
                onChangeIndex={handleStepChange}
                className={'contentContainer'}
                disabled={true}
                enableMouseEvents
            >
                {
                    dataShipping.map((step, index) => (
                        index === 0 ?
                            <div className='contentContainer'>
                                <div key={index} className='content flex-column'>
                                    <h3 className='text-center'>Calculadora de CO<sub>2</sub></h3>
                                    Para calcular la huella de carbono en un proceso logístico, es fundamental considerar el origen y destino, el modo de transporte y el peso de la carga. <br>
                                    </br>A continuación, se detalla cada uno de estos factores:
                                    <br></br><b>Origen y Destino:</b> Indica la ubicación exacta donde se produce el producto o se inicia el proceso logístico, incluyendo la ciudad y el país si es posible.
                                    Además, debes señalar la ubicación exacta donde se entregará el producto o finalizará el proceso logístico.
                                    <br></br><b>Modo de transporte:</b> Es importante seleccionar el método de transporte adecuado para el lugar de partida y destino.
                                    <br></br>Ten en cuenta que cada medio de transporte emite diferentes cantidades de CO2. <br></br>Por ejemplo, los aviones emiten más CO2 por kilómetro recorrido que los camiones. <br></br>Debes indicar el método de transporte que se utilizará en el proceso logístico.
                                    <br></br><b>Peso de la carga:</b> El peso de la carga es otro factor que influye en la huella de carbono.
                                    El transporte de cargas más pesadas requiere más energía y, por lo tanto, emite más CO2. Es importante que indiques el peso de la carga transportada para que la calculadora de huella de carbono pueda proporcionar un resultado más preciso.
                                    <br></br>
                                    Al ingresar esta información en la calculadora de huella de carbono, podrás obtener un resultado que te permitirá identificar oportunidades para reducir la huella de carbono en el proceso logístico. Así, podrás tomar decisiones más sostenibles y responsables con el medio ambiente.
                                    <div className='inputWeight'>
                                        <Input
                                            label="Peso de la carga"
                                            name="weight"
                                            placeholder="Ingresar peso"
                                            className={`o_input ${errors.weight ? 'error' : ''}`}
                                            animated={false}
                                            onChange={handleChange}
                                            contentRight={<Dropdown options={optionsWeightUnit} bgColor='neutral_100' onChange={handleWeightUnitChange} />}
                                            type={'number'}
                                            width={250}
                                            helperText={errors.weight ? errors.weight : ''}
                                        />
                                    </div>
                                </div>
                            </div> : index === dataShipping.length - 1 ?
                                <div className='contentContainer'>
                                    <div key={index} className='content flex-column'>
                                        <h3 className='text-center'>Resumen</h3>
                                        <div className='contentSummary flex-1'>
                                            {
                                                getOnlyDataShipping().map((element, i) => {
                                                    return <div key={i} className='rowSummary'>
                                                        <div className='iconSummary d-flex align-items-center'>
                                                            <Icon name={element.iconSendMethod} color={'white'} size={35}></Icon>
                                                        </div>
                                                        <div className='originSummary d-flex align-items-center'>
                                                            {element.origin}
                                                        </div>
                                                        <div className='arrowSummary d-flex align-items-center'>
                                                            <Icon name='SimpleArrowRight'></Icon>
                                                        </div>
                                                        <div className='destinationSummary d-flex align-items-center'>
                                                            {element.destination}
                                                        </div>
                                                        <div className='destinationSummary d-flex align-items-center'>
                                                            {data[i]?.distance} km
                                                        </div>
                                                        <div className='destinationSummary d-flex align-items-center'>
                                                            {data[i]?.CO2} kg CO2
                                                        </div>
                                                        <div className='destinationSummary d-flex align-items-center'>
                                                            {data[i]?.TTW} kg TTW
                                                        </div>

                                                        {/*   <div className='arrowSummary d-flex align-items-center'>
                                                        <Icon name='SimpleArrowRight'></Icon>
                                                    </div>
                                                    <div className='distanceSummary d-flex align-items-center'>
                                                        {element.distance+"km"}
                                                    </div> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <div className='totalInfo'>
                                                {
                                                    loadingCO2 ?
                                                        <Loading color={'white'}></Loading>
                                                        :
                                                        <>
                                                            <h3>Peso Carga</h3>
                                                            <h3 className='fontWeightThin'>{new Intl.NumberFormat('es-MX').format(weight)} {weightUnit}</h3>
                                                        </>
                                                }
                                            </div>
                                            <div className='totalInfo'>
                                                {
                                                    loadingCO2 ?
                                                        <Loading color={'white'}></Loading>
                                                        :
                                                        <>
                                                            <h3>Distancia Recorrida</h3>
                                                            <h3 className='fontWeightThin'>{new Intl.NumberFormat('es-MX').format(distance)} Km</h3>
                                                        </>
                                                }
                                            </div>
                                            <div className='totalInfo'>
                                                {
                                                    loadingCO2 ?
                                                        <Loading color={'white'}></Loading>
                                                        :
                                                        <>
                                                            <h3>CO<sub>2</sub> Generado</h3>
                                                            <h3 className='fontWeightThin'>{new Intl.NumberFormat('es-MX').format(co2Emitido)} Kg</h3>
                                                        </>
                                                }
                                            </div>
                                            <div className='totalInfo'>
                                                {
                                                    loadingCO2 ?
                                                        <Loading color={'white'}></Loading>
                                                        :
                                                        <>
                                                            <h3>TTW Generado</h3>
                                                            <h3 className='fontWeightThin'>{new Intl.NumberFormat('es-MX').format(ttw)} Kg</h3>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='contentContainer'>
                                    <div key={index} className='content'>
                                        <div className='form'>
                                            <FormCO2 index={index} activeStep={activeStep} dataShipping={dataShipping} setDataShipping={setDataShipping} sendMethods={sendMethods} errors={errors} validate={validate} originLoading={originLoading} setOriginLoading={setOriginLoading} destinationLoading={destinationLoading} setDestinationLoading={setDestinationLoading}></FormCO2>
                                        </div>
                                    </div>
                                </div>
                    ))
                }
            </ViewsHandler>

            <div className='footer'>
                {
                    activeStep > 0 ?
                        <Button handler={handleBack} text={'Atras'} startIcon={{ name: 'ArrowLeft', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading} outline></Button>
                        : <div></div>
                }
                <div className='manageButtons d-flex'>
                    {
                        activeStep === dataShipping.length - 2 ?
                            <div className='d-flex'>
                                {
                                    dataShipping.length > 3 ?
                                        <Button handler={removeStep} text={'Eliminar'} endIcon={{ name: 'Close', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading} outline></Button>
                                        : ''
                                }
                                <Button handler={addStep} text={'Añadir'} bgColor={'primary_300'} textColor={'neutral_50'} endIcon={{ name: 'Plus', color: 'white', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading}></Button>
                                <Button handler={calculate} text={'Calcular'} bgColor={'primary_300'} textColor={'neutral_50'} endIcon={{ name: 'CheckSquare', color: 'white', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading}></Button>
                            </div>
                            : activeStep < dataShipping.length - 1 ?
                                <section className='d-flex'>
                                    {
                                        activeStep > 0 ?
                                            <>
                                                <Button handler={removeStep} text={'Eliminar'} endIcon={{ name: 'Close', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading} outline></Button>
                                                <Button handler={addStep} text={'Añadir'} bgColor={'primary_300'} textColor={'neutral_50'} endIcon={{ name: 'Plus', color: 'white', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading}></Button>
                                            </>
                                            : ''
                                    }
                                    <Button handler={handleNext} text={'Siguiente'} endIcon={{ name: 'ArrowRight', size: 14 }} disabled={originLoading || destinationLoading || buttonLoading} outline></Button>
                                </section>
                                : <div></div>

                    }
                </div>
            </div>
        </StyledSteps>
    );
}

const mapState = ({ co2: { co2emitted, showTreeChart }, }) => {
    return { co2emitted, showTreeChart }
}

export default connect(mapState, { emittedAction, showTreeAction })(Steps)