import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { URL } from '../../../constants';
import { useSelector } from "react-redux";
import { bgColorStates, textColorStates } from '../../../constants';
import { Loading } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

// Components
import Table from '../../../components/Table/Table';

import { useTranslation } from "react-i18next";

const TableServices = ({ filterType, filterSearch }) => {
    const navigate = useNavigate()
    const token = useSelector((state) => state.user.token);
    const { data, loading } = useFetch(URL + 'service/', { 'Authorization': 'Token ' + token });
    const [items, setItems] = useState([])
    const downloadQuotation = (item) => {

    }

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Carga', uid: 'load', type: 'text' },
        { name: 'Fecha', uid: 'date', type: 'date' },
        { name: 'Calificación', uid: 'rate', type: 'rate' },
        { name: 'Asesor', uid: 'advisor', type: 'text' },
        { name: 'Estado', uid: 'state', type: 'badge' }
    ]

    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (item) => navigate(`/services/detail/chat/?radication=${item.idRadication}&tab=chat`)
        },
        {
            name: 'Descargar',
            icon: 'Download',
            handle: downloadQuotation
        }
    ]

    const getListAdvisors = (users) => {
        let list = [];
        users.forEach(user => { if (user.role.id === 3) { list.push(` ${user.name} ${user.last_name}`) } })

        return list.toLocaleString()
    }

    useEffect(() => {
        if (loading) {
            return; // No hacer nada si está cargando
        }

        let dataTable = [];

        if (data?.services?.length > 0) {
            dataTable = data.services
                .filter(service => service.state === 'Entrega final')
                .filter(service => {
                    if (filterType === "Seleccionar") {
                        return true;
                    } else if (service.state !== 'Cotizado') {
                        if (filterType === "Carga general") {
                            return service.loads[0].load_type === 'Carga General';
                        } else if (filterType === "Carga proyecto") {
                            return service.loads[0].load_type === 'Carga Proyecto';
                        }
                    }
                    return false;
                })
                .filter(service => {
                    if (filterSearch === '') {
                        return true;
                    } else {
                        return service.radication.toString().startsWith(filterSearch);
                    }
                })
                .map(service => ({
                    id: service.id,
                    idRadication: service.radication,
                    load: service.loads.length > 0 ? service.loads[0].load_type : '',
                    date: new Date(service.created_at),
                    advisor: getListAdvisors(service.users),
                    state: {
                        name: service.state,
                        bgColor: bgColorStates[service.state],
                        textColor: textColorStates[service.state]
                    }
                }));
        } else {
            dataTable = data;
        }

        setItems(dataTable);
    }, [loading, filterType, filterSearch]);

    return (
        <div className='h-100'>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items.length > 0 ?
                        <Table columns={columns} items={items} actions={actions}></Table>
                        :
                        <div className='d-flex justify-content-center'>No hay servicios Finalizados</div>
            }
        </div>
    );
}

export default TableServices;