import React, {useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table'
import { getWaitingOp } from '../../services/quotations.services';
import { getPreliquidations } from '../../services/preliquidation.services';
import { getOffers } from '../../services/quotations.services';
import { getBusinessTerms } from '../../services/businessTerms.services';
import format from 'date-fns/format';
import { Loading } from '@nextui-org/react';
import { getUsers } from '../../services/user.services';

export default function PendingTable({setRadication, setShowPending}) {
    const token = useSelector(state=>state.user.token)
    const [items, setItems] = useState([])

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Cliente', uid: 'client', type: 'text' },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Incoterm', uid: 'incoterm', type: 'text' },
        { name: 'Costo', uid: 'value', type: 'text' },
    ];

    const actions = [
        {
            name: 'Iniciar Operacion',
            icon: 'Tracking',
            handle: (item) => { 
                setRadication(item.idRadication);
                setShowPending(false);
            }
        },
    ]

    useEffect(()=>{
        const asyncTasks = [getWaitingOp(token),  getPreliquidations(token), getBusinessTerms(token), getUsers(token)]
        Promise.all(asyncTasks).then(async res => {
            const waitingOpData = await res[0].json();
            const preliquidationsData = await res[1].json();
            const incotermsData = await res[2].json();
            const usersData = await res[3].json();

            waitingOpData.forEach((op,i) => {
                let offerCost = 0;
                op.preliquidation_ids.forEach( id => {
                    const preliquidation = preliquidationsData.find(prel => {
                        return prel.id === id
                    })
                    const prelCost = preliquidation.sale_rate.rate_number * preliquidation.unit / preliquidation.sale_rate.trm
                    offerCost +=prelCost
                })
                waitingOpData[i].cost = offerCost;
            })
            
            const tableData = waitingOpData.map(op => {
                return ({
                    id: op.id.toString(),
                    idRadication: op.radication,
                    client:usersData.find(user => user.id === op.client)?.name + ' ' + usersData.find(user => user.id === op.client)?.last_name,
                    incoterm:incotermsData.find(term => term.id===op.incoterm)?.name,
                    origin: op.shipping.origin_boarding_terminal,
                    destination: op.shipping.destination_boarding_terminal,
                    value: '$' + op.cost.toFixed(2)
                });
            })
            setItems(tableData)
        }).catch(err => console.log('error :',err))
    },[])

    return (
        <>
            {items.length?
            <Table columns={columns} items={items} actions={actions} excel={false} searchBar={false}></Table>:
            <Loading/>
            }
        </>
    )
}
