import React from 'react'
import { Modal, styled, Checkbox, Radio } from '@nextui-org/react';

import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import { useForm, Controller } from 'react-hook-form'

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500'
})
const InputRow = styled("div", {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '10px',
    ['input']: {
        minWidth: '140px !important'
    }
})
const Line = styled("div", {
    width: '90%',
    height: '1px',
    background: 'grey',
    margin: '10px'
})

export default function PriceListModal({ open, setOpen }) {

    const { handleSubmit, control } = useForm();

    const onSubmit = (data) => {
        console.log('formdata :', data);
    };

    const CustomInputSelect = React.forwardRef((props, ref) => (
        <InputSelect ref={ref} {...props} />
    ));

    const CustomInput = React.forwardRef((props, ref) => (
        <Input ref={ref} {...props} />
    ));

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Cotizacion <b>val 919</b></h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <InputRow>
                        <Controller
                            name='get-from'
                            control={control}
                            render={({ field }) => (
                                <Radio.Group defaultValue="price-list" size="xs" {...field}>
                                    <Radio value="price-list"><p style={{ color: '#292E68' }}>Desde lista de precios</p></Radio>
                                    <Radio value="quotes"><p style={{ color: '#292E68' }}>Desde cotizacion</p></Radio>
                                </Radio.Group>
                            )}
                        />
                    </InputRow>
                    <InputRow>
                        <Controller
                            name='price-list'
                            control={control}
                            render={({ field }) => (
                                <CustomInputSelect label='Lista de precios :' {...field} />
                            )}
                        />
                    </InputRow>
                    <p>Seleccione los servicios que desea agregar a la cotizacion:</p>
                    <Controller
                        name='select-all'
                        control={control}
                        render={({ field }) => (
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Checkbox {...field} />
                                <span>Seleccionar todo</span>
                            </div>
                        )}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button text='Guardar cambios' type="submit" bold bgColor={'primary_300'} textColor='neutral_50' />
                </Modal.Footer>
            </form>
        </StyledFormModal>
    );
}
