import React from 'react'
import { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewsHandler from '../../components/ViewsHandler/ViewsHandler';
import { Collapse } from '@nextui-org/react';
import { styled } from '@nextui-org/react';
import QuotationsTable from './QuotationsTable';

import { URL } from '../../constants';
import QuoteForm from './QuoteForm/QuoteForm';
import PreLiquidation from './PreLiquidation/PreLiquidation';
import OfferRequirements from './OfferRequirements/OfferRequirements';
import VersionControl from './VersionControl/VersionControl';
import Notes from './Notes';
import Instructions from './Instructions/Instructions';

import { getClients, getSolutions, getSubTypes, getOffices, getReasons, getStatuses } from '../../services/quotations.services';
import { getPreliquidations } from '../../services/preliquidation.services';
import PricingDashboard from './PricingDashboard/PricingDashboard';

const StyledContainer = styled('div', {
    display: 'grid',

    marginBottom: '50px',
    ['header']: {
        backgroundColor: '$neutral_50',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.title']: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
        fontFamily: 'inherit',
        color: '$primary_300',
        textTransform: 'none'
    },
    ['.Mui-selected']: {
        fontWeight: '600',
        color: '$primary_300 !important'
    },
    ['.MuiTabs-indicator']: {
        backgroundColor: '$secondary_300'
    }
})

const tabs = [
    {
        id: 'form',
        value: 'form',
        label: 'Formulario de cotización',
    },
    {
        id: 'pre-liquidation',
        value: 'pre-liquidation',
        label: 'Preliquidacion',
    },
    {
        id: 'offer requirements',
        value: 'offer requirements',
        label: 'Condiciones de la oferta',
    },
    {
        id: 'version-control',
        value: 'version-control',
        label: 'Control de versiones',
    },
    {
        id: 'notes',
        value: 'notes',
        label: 'Notas',
    },
    {
        id: 'instructions',
        value: 'instructions',
        label: 'Instrucciones de embarque',
    },
    {
        id: 'dashboard',
        value: 'dashboard',
        label: 'Dashboard',
    },
]

const StyledCollapse = styled(Collapse, {
    [".nextui-collapse-title"]: {
        color: '$primary_300',
    }
})

export default function ManageQuotes() {

    const [tabValue, setTabValue] = useState('form')
    const [tabId, setTabId] = useState(0);

    const [currentQuote, setCurrentQuote] = useState('')

    const [showTable, setShowTable] = useState(false)

    const [items, setItems] = useState([])
    const token = useSelector(state => state.user.token)
    const { data, loading } = useFetch(URL + 'quotation/quotations/', { 'Authorization': 'Token ' + token });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
    };


    const getData = () => {
        const asyncTasks = [
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getClients(token),
            getStatuses(token),
            getReasons(token),
            getPreliquidations(token),
        ];
        Promise.all(asyncTasks)
            .then(async responses => {
                const subtypesData = await responses[0].json();
                const servicesData = await responses[1].json();
                const officesData = await responses[2].json();
                const clientsData = await responses[3].json();
                const statusesData = await responses[4].json();
                const reasonsData = await responses[5].json();
                const preliquidationsData = await responses[6].json();

                const formatData = await data?.map(quote => {

                    const preliquidations = preliquidationsData.filter(prel => quote.preliquidation_ids?.includes(prel.id))
                    let totalCost = 0;
                    let totalProfit = 0;
                    //console.log('preliquidations :',preliquidations)
                    preliquidations.forEach(prel => {
                        totalCost += prel.sale_rate.rate_number * prel.unit / prel.sale_rate.trm
                        totalProfit += prel.unit * (prel.sale_rate?.rate_number / prel.sale_rate?.trm - prel.purchase_rate?.rate_number / prel.purchase_rate?.trm)
                    })

                    return {
                        id: quote.id,
                        radication: quote.radication,
                        client: clientsData.find(e => e.id === quote.client)?.name,
                        service: servicesData.find(e => e.id === quote.service)?.name,
                        subtype: subtypesData.find(e => e.id === quote.subtype)?.name,
                        office: officesData.find(e => e.id === quote.office)?.name,
                        reason: reasonsData.find(e => e.id === quote.reason)?.name,
                        cost:'$' + totalCost.toFixed(2),
                        profit:'$' + totalProfit.toFixed(2),
                        status: statusesData.find(e => e.id === quote.status)?.name,
                        created_at:quote.created_at,
                    };
                });

                const uniqueData = []

                formatData.forEach((item => {
                    const sameRadication = formatData.filter(quote => quote.radication === item.radication)
                    sameRadication.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

                    if (!uniqueData.find(item=> item.radication === sameRadication[0].radication)){
                        sameRadication[0].versions = sameRadication.length
                        uniqueData.push(sameRadication[0])
                    }
                }))
                //Pone en la tabla solo la primera version creada de cada quotation
                setItems(uniqueData);
            })
            .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
        getData()
    }, [loading, data]);
    //console.log("currentQuote :",currentQuote)
    return (
        <StyledContainer>
            <header>
                <StyledCollapse orientation="vertical" title='Ver cotizaciones' expanded={showTable} onClick={() => setShowTable(true)}>
                    <QuotationsTable setCurrentQuote={setCurrentQuote} setShowTable={setShowTable} items={items} loading={loading} />
                </StyledCollapse>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    {
                        tabs.map((tab, i) =>
                            <Tab key={i} value={tab.value} label={tab.label} />
                        )
                    }
                </Tabs>
            </header>
            <div style={{ padding: '30px' }}>
                {
                    currentQuote?
                    <p style={{margin:'10px', fontSize:'16px'}}>Cotizacion seleccionada : {currentQuote?.radication} (ID : {currentQuote.id})</p>:
                    <p style={{margin:'10px', fontSize:'16px'}}>No hay cotizacion seleccionada</p>
                }
                <ViewsHandler
                    activeView={tabId}
                >
                    <QuoteForm currentQuote={currentQuote.id} setCurrentQuote={setCurrentQuote} items={items} setItems={setItems} />
                    <PreLiquidation currentQuote={currentQuote.id} />
                    <OfferRequirements currentQuote={currentQuote} />
                    <VersionControl currentQuote={currentQuote} setCurrentQuote={setCurrentQuote}/>
                    <Notes currentQuote={currentQuote.id} />
                    <Instructions currentQuote={currentQuote.id} />
                    <PricingDashboard/>
                </ViewsHandler>
            </div>
        </StyledContainer>
    )
}
