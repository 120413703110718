import { styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import React, { useState, useEffect } from 'react';

//Libreria Traduccion
import { useTranslation } from "react-i18next";

export default function AddTicket() {

    const ModalContainer = styled('div', {
        bgColor: '$neutral_50',
        width: '500px',
        boxShadow: "$elevation_1",
        padding: '32px',
        borderRadius: '8px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    })

    const Container = styled('div', {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        div: {
            display: 'flex',
            flexDirection: 'column',
        }
    })
    const Top = styled('div', {
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'space-between'
    })
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        const currentDate = new Date();

        // Usando opciones de idioma español para formatear la fecha
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = currentDate.toLocaleDateString('es-ES', options).replace(',', ' |');

        setCurrentDateTime(formattedDate);
    }, []);


    return (
        <ModalContainer>
            <Container>
                <Top>
                    <h3>Crear ticket</h3>
                    <Button text='X' />
                </Top>
                <div>
                    <h4>Fecha</h4>
                    <p>{currentDateTime}</p>
                </div>
                <div>
                    <h4>Asunto</h4>
                    <Input />
                </div>
                <div>
                    <h4>Detalle</h4>
                    <Input />
                </div>
                <Button text='Crear Ticket' bgColor='secondary_300' textColor='neutral_50' />
            </Container>
        </ModalContainer>
    )
}
