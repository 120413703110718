import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useUserRoles from '../../../../hooks/useUserRoles';
import { getQuotation } from '../../../../services/quotation.services';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewsHandler from '../../../../components/ViewsHandler/ViewsHandler';
import { ROLES } from '../../../../constants';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Chat from './Chat/Chat';
import Participants from './Participants/Participants';
import Attachments from './Attachments/Attachments';
import ServiceDetails from './Details/ServiceDetails';
import { useTranslation } from "react-i18next";
import TrackingLogs from '../../../OperationsTracking/Events/TrackingLogs';
import { getOperations } from '../../../../services/operations.services';


const StyledServiceChat = styled('div', {
    [`.${theme} &`]: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        ['header']: {
            backgroundColor: '$neutral_50',
            padding: '1rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['.title']: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }
        },
        ['.content']: {
            display: 'flex',
            height: 'calc(100vh - 198px)',
            ['& > div']: {
                width: '100%'
            }
        },
        ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
            fontFamily: 'inherit',
            color: '$primary_300',
            textTransform: 'none'
        },
        ['.Mui-selected']: {
            fontWeight: '600'
        },
        ['.MuiTabs-indicator']: {
            backgroundColor: '$secondary_300'
        }
    }
})

const ServiceChat = () => {
    const userRoles = useUserRoles()
    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }

    let tabs = [
        { value: 'chat', label: 'Conversación' },
        { value: 'attachments', label: 'Adjuntos' },
        { value: 'participants', label: 'Participantes' },
        { value: 'logs', label: 'Registros de operación' },
    ]
    if (havePermissions([ROLES.Admin, ROLES.Co2, ROLES.User])) {
        tabs.push({ value: 'details', label: 'Sostenibilidad' })
    }

    const navigate = useNavigate()
    const token = useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const radication = searchParams.get('radication')
    const [currentOperation, setCurrentOperation] = useState()
    const [service, setService] = useState({})
    const [tabId, setTabId] = useState(tabs.findIndex(tab => tab.value === searchParams.get('tab')));
    const [tabValue, setTabValue] = useState(searchParams.get('tab'))

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        navigate(`/services/detail/chat/?radication=${radication}&tab=${newValue}`)
    };

    const handleChangeIndex = (index) => {
        setTabId(index);
    };

    useEffect(() => {
        const savedService = localStorage.getItem('service');
        if (savedService) {
            setService(JSON.parse(savedService));
        }
        getQuotation(radication, token).then(async res => {
            const data = await res.json();
            setService(data);
            // Guarda el estado en el almacenamiento local cada vez que se actualiza
            localStorage.setItem('service', JSON.stringify(data));
        })
        getOperations({token}).then(async res => {
            const operationsData = await res.json();
            const currentOp = operationsData.find(operation=>operation.radication === radication)
            setCurrentOperation(currentOp.id)
        })
    }, []);

    return (
        <StyledServiceChat>
            <header>
                <div className='title'>
                    <PageTitle>
                        Servicio # {radication}
                    </PageTitle>
                    <p><b>Estado: </b>{service?.state}</p>
                </div>

                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    {
                        tabs.map((tab, i) =>
                            <Tab key={i} value={tab.value} label={tab.label} />
                        )
                    }
                </Tabs>
            </header>

            <div className='content'>
                <ViewsHandler
                    activeView={tabId}
                >
                    <Chat radication={radication} />
                    <Attachments radication={radication}></Attachments>
                    <Participants service={service} setService={setService}></Participants>
                    <TrackingLogs currentOperation={currentOperation} />
                    {havePermissions([ROLES.Admin, ROLES.Co2, ROLES.User]) && <ServiceDetails service={service} />}
                </ViewsHandler>
            </div>
        </StyledServiceChat>
    );
}

export default ServiceChat;
