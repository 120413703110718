import React, { useEffect, useState } from 'react';
import Button from "../../components/Button/Button";
import { styled, Avatar } from '@nextui-org/react';
import { URL } from '../../constants';
import Input from "../../components/Input/Input";
import { Textarea } from '@nextui-org/react';
import styles from "./valAI.module.scss";
import PageTitle from '../../components/PageTitle/PageTitle';
import { useSelector } from 'react-redux';
//Libreria Traduccion
import { useTranslation } from "react-i18next";
import { getUserAuth } from '../../services/auth.services';
import Icon from '../../components/Icon/Icon';

const StyledServices = styled('article', {
  padding: '3rem 2rem',
  display: "flex",
  height: "100%",
  flexDirection: "column",
  gap: '1.5rem',
  ['.header']: {
    display: 'flex',
    gap: '1.5rem'
  },
})

const mockChat = [
  { question: "¿Cómo está el clima hoy?", answer: "Lo siento, necesitaría tu ubicación para responder a eso." },
  {
    question: "¿Qué debo hacer si mi paquete no llega a tiempo?",
    answer: "Si tu paquete no llega en el tiempo estimado, comunícate con nuestro servicio de atención al cliente. Estaremos encantados de investigar y resolver cualquier problema relacionado con la entrega."
  },
  {
    question: "¿Cuál es el tiempo estimado de entrega?",
    answer: "El tiempo de entrega depende de la ubicación y el tipo de servicio seleccionado. Por lo general, intentamos entregar en [X] días hábiles."
  },
];


const ValAI = () => {
  const [input, setInput] = useState('');
  // const [messages, setMessages] = useState([
  //   { question: "¿Cómo está el clima hoy?", answer: "Lo siento, necesitaría tu ubicación para responder a eso." },
  // ]);
  const [messages, setMessages] = useState(mockChat);
  const [loading, setLoading] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const questionLimit = 3;

  const handleInput = (event) => setInput(event.target.value);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    if (questionCount >= questionLimit) {
      return;
    }
    addQuestionToMessages(input);
    setInput('');
    setLoading(true);
    const answer = await fetchAnswerFromBackend(input);
    updateLastMessageWithAnswer(answer);
    setLoading(false);
    setQuestionCount(questionCount + 1);
  };

  const fetchAnswerFromBackend = async (question) => {
    const response = await fetch(`${URL}api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ input: question })
    });

    const data = await response.json();
    return data.response;
  };
  // const fetchAnswerFromBackend = async (question) => {
  //   alert("we")
  // };

  const addQuestionToMessages = (question) => {
    setMessages(prev => [...prev, { question }]);
  };

  const updateLastMessageWithAnswer = (answer) => {
    setMessages(prev => {
      const updatedMessages = [...prev];
      updatedMessages[updatedMessages.length - 1].answer = answer;
      return updatedMessages;
    });
  };

  return (
    <div className={styles.container}>
      <MessageForm input={input} handleInput={handleInput} handleSubmit={handleSubmit} />
      <MessageList messages={messages} loading={loading} />
      <p style={{ margin: '15px', color: 'grey' }}>Preguntas realizadas: {questionCount} de {questionLimit}</p>
    </div>
  );
};

const MessageForm = ({ input, handleInput, handleSubmit }) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit()
    }
  };
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <StyledServices>
        <PageTitle>
          VAL GPT
        </PageTitle>
      </StyledServices>
      <Textarea
        variant='bordered'
        aria-label='preguntar'
        placeholder='Preguntar'
        onKeyDown={handleKeyDown}
        startIcon={{ name: 'Search' }}
        value={input}
        onChange={handleInput}
      />
      <div className={styles.buttonWrapper}>
        <Button
          type="submit"
          className={styles.btnContainer}
          text={'Enviar'}
          textColor={'neutral_50'}
          bgColor={'primary_400'}
        />
      </div>
    </form>
  )
};

const MessageList = ({ messages, loading }) => {
  const token = useSelector(state => state.user.token)
  const [user, setUser] = useState({})
  useEffect(() => {
    getUserAuth(token)
      .then(({ user }) => {
        const userAuth = {
          name: user.first_name,
          lastName: user.last_name
        }
        setUser(userAuth)
      });
  }, []);

  return (
    <ul className={styles.messages}>
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          <li className={`${styles.message} ${styles.question}`}>
            {user?.name && <Avatar text={`${user?.name[0]?.toUpperCase()}${user?.lastName[0]?.toUpperCase()}`} size='sm'></Avatar>}
            {message.question}
          </li>
          {message.answer && (
            <li className={`${styles.message} ${styles.answer}`}>
              <div style={{ width: '30px !important' }}><Icon name='Technology' color='white' size='30px' /></div> {message.answer}
            </li>
          )}
        </React.Fragment>
      ))}
      {loading && <li className={styles.thinking}>Pensando...</li>}
    </ul>
  )
};

export default ValAI;
