import React, { useState, useRef, useEffect } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from "react-i18next";
//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import InputSelect from "../../../../components/Select/inputSelect";
import GeneralCard from "./GeneralCard";
import ProjectCard from "./ProjectCard";

const StyledLiquidation = styled('div', {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        ['aside']: {
            color: "$primary_200"
        },
        ['.separate']: {
            margin: '1rem 1rem',
            backgroundColor: '$neutral_100',
        }
    }
})


function Liquidation({loadTypes, setDisabledContinue}) {

    const nameForm = 'liquidation'

    const { control, getValues, watch, setValue, formState:{errors} } = useFormContext();

    useEffect(()=>{
        setDisabledContinue(true)
        if (getValues(`${nameForm}.loadType`)){
            if (typeof(watch(`${nameForm}.loadType`)) ==='string'){
                const defaultValue = loadTypes.find(type => type.name === watch(`${nameForm}.loadType`))
                console.log('defaultValue :',defaultValue)
                setValue(`${nameForm}.loadType`,{
                    label:defaultValue.name,
                    value:defaultValue.id,
                })
            }
            setDisabledContinue(false)
        } else {
            setDisabledContinue(true)
        }
    },[watch(`${nameForm}.loadType`)])

    // useEffect(()=>{
    //     console.log('aaaaaaaaaa :',watch(`${nameForm}.loadType`))
    //     if (watch(`${nameForm}.loadType`)){
    //         console.log('loadtype :',watch(`${nameForm}.loadType`))
    //         const defaultValue = loadTypes.find(type => type.name === watch(`${nameForm}.loadType`))
    //         console.log('defaultValue :',defaultValue)
    //         // setValue(`${nameForm}.loadType`,defaultValue.value)
    //     }
    // },[])

    return (
        <StyledLiquidation>
            <div className="d-flex flex-column gap-1">
                <PageTitle>
                    Tipo de liquidación
                </PageTitle>
                <aside>
                    Inicia con una liquidación nueva y selecciona el tipo de carga que necesites.
                </aside>
                <main>
                    <Controller
                        control={control}
                        name={`${nameForm}.loadType`}
                        rules={{required:"Este campo es obligatorio"}}
                        render={({ field }) => (
                            <InputSelect 
                                {...field}
                                name={`${nameForm}.loadType`} 
                                label='Selecciona tipo de carga'
                                options={loadTypes.map(type => {
                                    return {
                                        label:type.name,
                                        value:type.id,
                                    }
                                })}
                            />
                        )}
                    ></Controller>
                </main>
            </div>
            {
                getValues(`${nameForm}.loadType`) !== "" && (
                    <div>
                        {
                            watch(`${nameForm}.loadType.value`) === loadTypes[0]?.id ? (
                                <GeneralCard />
                            ) : (
                                <ProjectCard />
                            )
                        }
                    </div>
                )
            }
        </StyledLiquidation>
    );
}
export default Liquidation;