import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input as Inp, styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from 'react-hook-form';
import PlacesAutocomplete from "react-places-autocomplete"
//Components
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Input from '../../../../components/Input/Input';
import InputSelect from '../../../../components/Select/inputSelect';
import Icon from '../../../../components/Icon/Icon';

import { getAirportsByCountry, getPortsByCountry } from '../../../../services/countries.services';



const StyledSend = styled('div', {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['aside']: {
            color: '$primary_200'
        },
        ['h5']: {
            marginBottom: '0rem'
        },
        ['.separate']: {
            margin: '0rem 1rem'
        },
        ['.form']: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
            gap: '0.5rem',
        },
        ['.o_input']: {
            borderRadius: '8px',
            backgroundColor: 'white',
            border: 'solid 1px $primary_300',
            transition: 'border 0.25s ease',
            color: 'blue',
            marginRight: 0,
            ['&:focus-within']: {
                border: 'solid 1px $primary_100',
            },
            ['.nextui-input-wrapper']: {
                borderRadius: '8px',
                backgroundColor: '$neutral_50'
            },
            ['.nextui-input-label--right']: {
                borderRadius: '0px 8px 8px 0px',
                backgroundColor: '$neutral_100'
            },
            ['.nextui-input-label--left']: {
                borderRadius: '8px 0px 0px 8px'
            }
        },
        ['.autocomplete-dropdown-container-top, .autocomplete-dropdown-container-bottom']: {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            ['.suggestion']: {
                border: 'solid 1px $neutral_100',
                padding: '0.4rem',
                fontSize: '0.9rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            ['.suggestion:first-child']: {
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
            },
            ['.suggestion:last-child']: {
                borderBottomRightRadius: '8px',
                borderBottomLeftRadius: '8px'
            },
        },
        ['.autocomplete-dropdown-container-top']: {
            bottom: '100%'
        }
    }
})

const StyledLabel = styled('div', {
    [`.${theme} &`]: {
        color: `$primary_500`,
        ['span']: {
            color: '#ff676c'
        }
    }
})

const Send = ({ countries, loadTypes, setDisabledContinue, shippingMethods }) => {

    const token = useSelector(state => state.user.token);

    const { control, getValues, setValue, watch } = useFormContext();

    const [_countries, _setCountries] = useState([])

    const [originBoardings, setOriginBoardings] = useState([])
    const [destinationBoardings, setDestinationBoardings] = useState([])

    const [_shippingMethods, _setShippingMethods] = useState([])
    const [selectedOriginCountry, setSelectedOriginCountry] = useState({})
    const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(getValues('send.destinationCountry'))
    const [selectedOriginTerminal, setSelectedOriginTerminal] = useState(getValues('send.originBoardingTerminal') || {})
    const [selectedDestinationTerminal, setSelectedDestinationTerminal] = useState(getValues('send.destinationBoardingTerminal') || {})
    const [originCountryISO, setOriginCountryISO] = useState("");
    const [destinationCountryISO, setDestinationCountryISO] = useState("");
    const nameForm = 'send'

    const incoterm = getValues('negotiation.businessTerm')

    const originAddressTerms = [1,2]
    const destinationAddressTerms = [7,17]

    const requireOriginAddress = originAddressTerms.includes(incoterm)
    const requireDestinationAddress = destinationAddressTerms.includes(incoterm)

    const getBoardings = async (token, shippingMethod, country) => {
        let res = []
        switch (shippingMethod) {
            case 1: {        //air
                res = await getAirportsByCountry(token, country)
                break;
            }
            case 2: {        //ocean
                res = await getPortsByCountry(token, country)
                break;
            }
            default:
        }
        return res
    }

    useEffect(() => {
        getBoardings(token, getValues('commerce.shippingMethod.value'), selectedOriginCountry?.label).then(async res => {
            let aux = await res.json()
            setOriginBoardings(aux)
            console.log("aux :",aux)
            setValue('send.originBoardingTerminal', "")
            setSelectedOriginTerminal({
                label:"",
                value:"",
            })
        })
    }, [selectedOriginCountry])

    useEffect(() => {
        getBoardings(token, getValues("commerce.shippingMethod.value"), selectedDestinationCountry?.label).then(async res => {
            let aux = await res.json()
            setDestinationBoardings(aux)
            setValue('send.destinationBoardingTerminal', "")
            setSelectedDestinationTerminal({
                label:"",
                value:""
            })
        })
    }, [selectedDestinationCountry])

    useEffect(() => {
        const data = countries.map(country => {
            return {
                value: country.id,
                label: country.name,
                iso: country.iso
            }
        })
        if (getValues('send.originCountry')) {
            const formatedOriginCountry = data.find(country => 
                country.value == getValues('send.originCountry')
            )
            const formatedDestinationCountry = data.find(country => 
                country.value == getValues('send.destinationCountry')
            )        
            setSelectedOriginCountry(formatedOriginCountry)
            setSelectedDestinationCountry(formatedDestinationCountry)

            // setSelectedDestinationTerminal(getValues('send.destinationBoardingTerminal'))
        }
        _setCountries(data)
    }, [countries]);

    // useEffect(() => {
    //     setValue(`${nameForm}.originBoardingTerminal`, '')
    // }, [selectedOriginCountry])

    // useEffect(() => {
    //     setValue(`${nameForm}.destinationBoardingTerminal`, '')
    // }, [selectedDestinationCountry])
    
    useEffect(() => {
        //VALIDACIONES
        const sendFormValues = getValues('send')
        if (
            (!sendFormValues?.deliveryAddress && requireDestinationAddress) ||
            !sendFormValues?.destinationCountry ||
            (!sendFormValues?.destinationBoardingTerminal) ||
            !sendFormValues?.originCountry ||
            (!sendFormValues?.pickUpAddress && requireOriginAddress) ||
            (!sendFormValues?.originBoardingTerminal)
            ) {
            setDisabledContinue(true);
        } else {
            setDisabledContinue(false);
        }
    }, [watch()]);

    return (
        <StyledSend>
            <main className='d-flex flex-column gap-1'>
                <PageTitle>
                    Información de envío
                </PageTitle>
                {
                    getValues("liquidation.loadType") === loadTypes[0]?.id ?
                        <aside>Ingresa la información del envío de tu carga general.</aside>
                        : getValues("liquidation.loadType") === loadTypes[1]?.id &&
                        <aside>Ingresa la información del envío de tu carga proyecto.</aside>
                }
                <h5>1 - Datos de origen</h5>
                <div className='form'>
                    <Controller
                        control={control}
                        name={`${nameForm}.originCountry`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name } }) => (
                            <InputSelect
                                options={_countries?.map(e => { return { label: e.label, value: e.value, iso: e.iso } })}
                                onChange={(value) => {
                                    onChange(value.value)
                                    setSelectedOriginCountry(value);
                                    setOriginCountryISO(value.iso)
                                }}
                                value={selectedOriginCountry}
                                label={<StyledLabel>País de origen <span>*</span></StyledLabel>}
                            />
                        )}
                    />
                    <Controller
                        name={`${nameForm}.pickUpAddress`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                onChange={event => {
                                    field.onChange(event);
                                }}
                                label='Direccion de Recoleccion'
                                placeholder='Introduce dirección'
                                required={requireOriginAddress}
                            />
                        )}
                    />
                    
                    <Controller
                        rules={{ required: true }}
                        control={control}
                        name={`${nameForm}.originBoardingTerminal`}
                        render={({ field: { onChange, value } }) => (
                             (getValues("commerce.shippingMethod.value") === 1 || getValues("commerce.shippingMethod.value") === 2) ?
                                <InputSelect
                                    options={originBoardings.map(e => {
                                        return {
                                            label: getValues("commerce.shippingMethod.value") === 1?
                                            `${e.iata_code} - ${e.name} - ${e.city} - ${e.country}` :
                                            `${e.unlocs[0]} - ${e.name} - ${e.province} - ${e.country}`
                                            ,
                                            value: e.name,
                                        }
                                    })}
                                    value={selectedOriginTerminal}
                                    onChange={(value) => { onChange(value.value); setSelectedOriginTerminal(value); }}
                                    label={<StyledLabel>Embarque <span>*</span></StyledLabel>}
                                /> :
                                <PlacesAutocomplete
                                    value={
                                        `${getValues('commerce.shippingMethod') === shippingMethods[0]?.id ?
                                            'Aeropuerto ' :
                                            getValues("commerce.shippingMethod") === shippingMethods[1]?.id ?
                                                'Puerto ' :
                                                ''}` + value
                                    }
                                    searchOptions={{
                                        componentRestrictions: { country: originCountryISO }
                                    }}
                                    onChange={(value) => { onChange(value); }}
                                    onSelect={(value) => { onChange(value) }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                        <div className='p-relative'>
                                            {(getValues("negotiation.businessTerm") !== getValues("negotiation.businessTerm")[3]?.id && getValues("negotiation.businessTerm") !== getValues("negotiation.businessTerm")[4]?.id) &&
                                                <Inp
                                                    rules={{ required: true }}
                                                    label={<StyledLabel>Embarque <span>*</span></StyledLabel>}
                                                    name={`${nameForm}.originBoardingTerminal`}
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Desde'
                                                    fullWidth={'100%'}
                                                    value={value}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />}
                                            <div className="autocomplete-dropdown-container-bottom">
                                                {
                                                    suggestions.map((suggestion, i) => {
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                key={i}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className: 'suggestion',
                                                                    style,
                                                                })}
                                                            >
                                                                <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                        )}
                    ></Controller>
                    <Controller
                        name={`${nameForm}.pickUpComments`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                onChange={event => {
                                    field.onChange(event);
                                }}
                                label='Comentario adicional'
                                placeholder='Introduce'
                            />
                        )}
                    />
                </div>

                <hr className='separate' />

                <h5>2 - Datos de destino</h5>
                <div className='form'>
                    <Controller
                        control={control}
                        name={`${nameForm}.destinationCountry`}
                        render={({ field: { onChange, value, name } }) => (
                            // <Select onChange={(value) => { onChange(value); saveData(name, value); }} name={name} value={value} label='País de destino' options={_countries} required></Select>
                            <InputSelect
                                label={<StyledLabel>País de destino <span>*</span></StyledLabel>}
                                options={_countries?.map(e => {
                                    return {
                                        label: e.label,
                                        value: e.value,
                                        iso: e.iso
                                    }
                                })}
                                value={selectedDestinationCountry}
                                onChange={(value) => {
                                    onChange(value.value);
                                    setSelectedDestinationCountry(value);
                                    setDestinationCountryISO(value.iso)
                                }}
                            />
                        )}
                    ></Controller>
                    <Controller
                        name={`${nameForm}.deliveryAddress`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                onChange={event => {
                                    field.onChange(event);
                                }}
                                label='Direccion de Entrega'
                                placeholder='Introduce dirección'
                                required={requireDestinationAddress}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`${nameForm}.destinationBoardingTerminal`}
                        render={({ field: { onChange, value } }) => (
                            (getValues("commerce.shippingMethod.value") === 1 || getValues("commerce.shippingMethod.value") === 2) ?
                                <InputSelect
                                    value={selectedDestinationTerminal}
                                    options={destinationBoardings.map(e => {
                                        return {
                                            label: getValues("commerce.shippingMethod.value") === 1?
                                            `${e.iata_code} - ${e.name} - ${e.city} - ${e.country}` :
                                            `${e.unlocs[0]} - ${e.name} - ${e.province} - ${e.country}`
                                            ,
                                            value: e.name,
                                        }
                                    })}
                                    onChange={(value) => { onChange(value.value); setSelectedDestinationTerminal(value) }}
                                    label={<StyledLabel>Desembarque <span>*</span></StyledLabel>}
                                /> :
                                <PlacesAutocomplete
                                    value={
                                        `${getValues("commerce.shippingMethod") === shippingMethods[0]?.id ?
                                            'Aeropuerto ' :
                                            getValues("commerce.shippingMethod") === shippingMethods[1]?.id ?
                                                'Puerto ' :
                                                ''}` + value
                                    }
                                    onChange={(value) => { onChange(value); }}
                                    onSelect={(value) => { onChange(value)}}
                                    searchOptions={{
                                        componentRestrictions: { country: destinationCountryISO }
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                        <div className='p-relative'>
                                            <Inp
                                                label={<StyledLabel>Desembarque <span>*</span></StyledLabel>}
                                                name={`${nameForm}.destinationBoardingTerminal`}
                                                className={`o_input`}
                                                animated={false}
                                                placeholder='Hasta'
                                                fullWidth={'100%'}
                                                value={value}
                                                onBlur={getInputProps().onBlur}
                                                onChange={getInputProps().onChange}
                                                onKeyDown={getInputProps().onKeyDown}
                                            />
                                            <div className="autocomplete-dropdown-container-top">
                                                {
                                                    suggestions.map((suggestion, i) => {
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                key={i}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className: 'suggestion',
                                                                    style,
                                                                })}
                                                            >
                                                                <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                        )}
                    ></Controller>
                    <Controller
                        name={`${nameForm}.deliveryComments`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                onChange={event => {
                                    field.onChange(event);
                                }}
                                label='Comentario adicional'
                                placeholder='Introduce'
                            />
                        )}
                    />
                </div>
            </main>
        </StyledSend>
    );
}

export default Send;