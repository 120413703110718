import React, { useState } from 'react';
import theme from '../../../theme';
import { Collapse, Button, Input, styled } from '@nextui-org/react';

//Libreria Traduccion
import { useTranslation } from "react-i18next";

const StyledFAQ = styled('div', {
    [`.${theme} &`]: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.content']: {
            display: 'flex',
            gap: '1rem',

            ['.faq']: {
                flex: 1,
                marginTop: '1rem',
                display: 'grid',
                gridGap: '1rem',
                gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                gridAutoFlow: 'dense',
                width: '100%',  // Toma todo el ancho disponible
            }
        },
        ['.card']: {
            backgroundColor: '$neutral_50',
            color: '$primary_300',
            borderRadius: '8px',
            width: '100%',
            padding: '0rem',
            gap: '1rem'
        },
    }
});


const FAQ = () => {
    const faqs = [
        {
            category: "Account Management",
            question: "¿Cómo puedo actualizar mi dirección de correo electrónico?",
            answer: "Para actualizar tu dirección de correo electrónico, accede a la sección 'Mi perfil' y haz clic en 'Editar correo electrónico'. Asegúrate de verificar cualquier cambio en tu dirección de correo electrónico a través del enlace que te enviaremos."
        },
        {
            category: "Service Info",
            question: "¿Qué debo hacer si mi servicio no llega a tiempo?",
            answer: "Si tu servicio no llega en el tiempo prometido, por favor contacta a nuestro equipo de soporte. Proporciona los detalles del servicio y te ayudaremos a resolver el problema lo antes posible."
        },
        {
            category: "Billing",
            question: "¿Cómo puedo obtener una factura de mi compra?",
            answer: "Las facturas se generan automáticamente después de cada compra. Puedes descargarlas desde la sección 'Historial de compras' en tu cuenta. Si encuentras algún problema o necesitas ayuda adicional, no dudes en contactarnos."
        },
        {
            category: "Technical Issues",
            question: "Estoy teniendo problemas para acceder a mi cuenta, ¿qué debo hacer?",
            answer: "Si tienes problemas para acceder a tu cuenta, intenta restablecer tu contraseña primero. Si el problema persiste, contacta a nuestro equipo de soporte técnico proporcionando detalles sobre el problema que estás experimentando y nos aseguraremos de ayudarte."
        },
    ]
    const [openIndex, setOpenIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const filteredFaqs = faqs.filter(faq =>
        (faq.question.toLowerCase().includes(searchTerm.toLowerCase()) || faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
        && (selectedCategory === 'All' || faq.category === selectedCategory)
    );

    return (
        <StyledFAQ className='theme'>
            <div className='faq'>
                {faqs.map((faq, i) => (
                    <div key={i} className='card'>
                        <Collapse in={i === openIndex} title={<h3>{faq.question}</h3>} shadow onClick={() => setOpenIndex(i === openIndex ? null : i)}>
                            {faq.answer}
                        </Collapse>
                    </div>
                ))}
            </div>
        </StyledFAQ>
    );
}
export default FAQ;
