import { URL } from "../constants"

export const getCountries = async (token) => {
    const response = await fetch(`${URL}countries/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const GetShipmentsByCountryOrigin = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}shipments-by-country-origin/${startDate}/${endDate}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const GetShipmentsByCountryDestination = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}shipments-by-country-destination/${startDate}/${endDate}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getAirportsByCountry = async (token, countryName) => {
    const response = await fetch(`${URL}airports/?country=${countryName}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getPortsByCountry = async (token, country) => {
    const response = await fetch(`${URL}ports/?country=${country}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getPorts = async (token) => {
    const response = await fetch(`${URL}allports/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getAirports = async (token) => {
    const response = await fetch(`${URL}allaeropuertos/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}