import { URL } from "../constants"

export const getPreliquidations = async (token, id) => {
    const getURL = id ? `${URL}preliquidation/preliquidations/${id}/` : `${URL}preliquidation/preliquidations/`

    const response = await fetch(getURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const postLiquidation = async (data) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editLiquidation = async (data, id) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/${id}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteLiquidation = async (id) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (response.status === 204 || response.status === 200) {
        return true;
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getServiceGroups = async (token) => {
    const response = await fetch(`${URL}preliquidation/service-groups/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getServices = async (token, serviceGroupId) => {
    const servicesURL = serviceGroupId ? `${URL}preliquidation/services/${serviceGroupId}/` : `${URL}preliquidation/services/`
    const response = await fetch(servicesURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}


export const getServiceVariations = async (token, serviceId) => {
    const servicesVariationsURL = serviceId ? `${URL}preliquidation/service-variations/${serviceId}/` : `${URL}preliquidation/service-variations/`
    const response = await fetch(servicesVariationsURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getProviderTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/provider-types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getProviders = async (token, providerTypeId) => {
    const providersURL = providerTypeId ? `${URL}preliquidation/providers/${providerTypeId}/` : `${URL}preliquidation/providers/`
    const response = await fetch(providersURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getAppliesPer = async (token) => {
    const response = await fetch(`${URL}preliquidation/applies-per/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRateTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/rate-types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRates = async (token) => {      //Rate number, Minimum rate, Currency, Trm, Rate type
    const response = await fetch(`${URL}preliquidation/rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSaleRates = async (token) => {
    const response = await fetch(`${URL}preliquidation/sale-rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getPurchaseRates = async (token) => {
    const response = await fetch(`${URL}preliquidation/purchase-rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
