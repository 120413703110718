import React from 'react'
import { Modal, styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';

import { useForm, Controller } from 'react-hook-form'

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500'
})
const InputRow = styled("div", {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '10px',
    ['input']: {
        minWidth: '140px !important'
    }
})
const Line = styled("div", {
    width: '90%',
    height: '1px',
    background: 'grey',
    margin: '10px'
})

const CustomInputSelect = React.forwardRef((props, ref) => (
    <InputSelect ref={ref} {...props} />
));

const CustomInput = React.forwardRef((props, ref) => (
    <Input ref={ref} {...props} />
));

export default function PreliquidationCharacteristicsModal({ open, setOpen }) {

    const { handleSubmit, control } = useForm();

    const onSubmit = (data) => {
        console.log('formdata :', data);
    };

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Cotizacion <b>val 919</b></h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div style={{ display: 'flex', gap: '15px', margin: '15px' }}>
                        <Button text='Nuevo' bold bgColor={'primary_300'} textColor='neutral_50' />
                        <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' />
                    </div>
                    <h3>Valores</h3>
                    <InputRow>
                        <Controller
                            name="fob"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="FOB :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="cif"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Unidad :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="containers-amount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Cantidad de contenedores :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="free-days"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Dias libres :" type={'number'} {...field} />
                            )}
                        />
                    </InputRow>
                    <Line />
                    <h3>Consolidado</h3>
                    <Controller
                        name="shipping-method"
                        control={control}
                        render={({ field }) => (
                            <CustomInputSelect label="MODALIDAD :" options={[{ label: 'Aerea' }, { label: 'Maritima' }, { label: 'Terrestre' }]} {...field} />
                        )}
                    />
                    <InputRow>
                        <Controller
                            name="pieces-amount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Cantidad de piezas :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="gross-weight"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Cantidad de piezas :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="weight-unit"
                            control={control}
                            render={({ field }) => (
                                <CustomInputSelect label="Unidad :" options={[{ label: 'Ton' }, { label: 'Kg' }, { label: 'Lb' }]} {...field} />
                            )}
                        />
                    </InputRow>
                    <InputRow>
                        <Controller
                            name="long"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Largo :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="height"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Alto :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="width"
                            control={control}
                            render={({ field }) => (
                                <CustomInput label="Ancho :" type={'number'} {...field} />
                            )}
                        />
                        <Controller
                            name="measures-unit"
                            control={control}
                            render={({ field }) => (
                                <CustomInputSelect label="Unidad :" options={[{ label: 'cm' }, { label: 'm' }, { label: 'in' }, { label: 'ft' }]} {...field} />
                            )}
                        />
                    </InputRow>
                </Modal.Body>
                <Modal.Footer>
                    <Button text='Guardar cambios' bold bgColor={'primary_300'} type='submit' textColor='neutral_50' />
                </Modal.Footer>
            </form>
        </StyledFormModal>
    );
}
