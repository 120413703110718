import React from 'react';
import { Loading, Collapse, styled } from '@nextui-org/react';
import { useTranslation } from "react-i18next";

import PageTitle from '../../../../components/PageTitle/PageTitle';
import SectionLiquidation from './SectionLiquidation/SectionLiquidation';
import SectionInfo from './SectionInfo/SectionInfo';
import SectionList from './SectionList/SectionList';


const StyledInfo = styled("section", {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
})

const StyledTitle = styled("div", {
    fontSize: '17px',
})

const InfoLiquidation = ({ detail, radication, loadTypes }) => {
    return (
        <StyledInfo>
            {
                Object.keys(detail).length === 0 ?
                    <Loading></Loading>
                    :
                    <>
                        <PageTitle>
                            {
                                /* detail?.initial[0]?.info === loadTypes[0]?.name ?
                                    `Liquidación #${radication}`
                                    : detail?.initial[0]?.info === loadTypes[1]?.name && */
                                `Pre - Liquidación #${radication}`
                            }
                        </PageTitle>

                        <SectionLiquidation data={detail.initial} separate></SectionLiquidation>
                        {/* Commerce */}
                        <Collapse title={<StyledTitle>1 - Comercio</StyledTitle>}>
                            <SectionLiquidation data={detail.commerce} separate></SectionLiquidation>
                        </Collapse>

                        {/* Source data */}
                        <Collapse title={<StyledTitle>2 - Datos de origen</StyledTitle>}>
                            <SectionLiquidation data={detail.sourceData} separate></SectionLiquidation>
                        </Collapse>

                        {/* Destination data */}
                        <Collapse title={<StyledTitle>3 - Datos de destino</StyledTitle>}>
                            <SectionLiquidation data={detail.destinationData} separate></SectionLiquidation>
                        </Collapse>

                        {/* Loading information */}
                        <Collapse title={<StyledTitle>4 - Información de carga</StyledTitle>}>
                            <SectionLiquidation data={detail.loadingInformation} separate></SectionLiquidation>
                        </Collapse>

                        {/* Additional information */}
                        <Collapse title={<StyledTitle>5 - Información de adicional</StyledTitle>}>
                            <SectionLiquidation data={detail.additionalInformation} separate></SectionLiquidation>
                        </Collapse>

                        {/* Relevant Information */}
                        {/* <Collapse title={<StyledTitle>Relevant Information</StyledTitle>}> */}
                            {/* <SectionInfo data={detail.relevantInformation} separate></SectionInfo> */}
                        {/* </Collapse> */}

                        {/* Departures and Itineraries */}
                        {/* <Collapse title={<StyledTitle>Salidas e itinerarios</StyledTitle>}> */}
                            {/* <SectionList subtitle={'Salidas e itinerarios'} data={detail.departuresItineraries}></SectionList> */}
                        {/* </Collapse> */}
                    </>
            }
        </StyledInfo>
    );
}

export default InfoLiquidation;
