import React, { useState } from 'react'
import { styled } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import Card2 from '../../../components/Cards/Card2/Card2'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Icon from '../../../components/Icon/Icon'
import Button from '../../../components/Button/Button'
import CarForm from './CarForm'
import BusForm from './BusForm'
import HotelForm from './HotelForm'
import AirFreight from './AirFreight'
import RoadFreightForm from './RoadFreightForm'
import ShipFreightForm from './ShipFreightForm'
import ViewsHandler from '../../../components/ViewsHandler/ViewsHandler'
import InputFileCard from '../../../components/InputFileCard/InputFileCard'
import {Radio} from '@nextui-org/react'
import * as XLSX from 'xlsx';

const Container = styled("div", {
    background: '$neutral_50',
    margin: '40px',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
})

const Cards = styled("div", {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '20px',
})

const ExcelButton = styled("button", {
    display: 'flex',
    margin:'30px',
    background: '$excel',
    color: 'white',
    gap: '5px',
    borderRadius: '8px',
    padding: '10px',
    transition: '300ms',
    ['&:hover']: {
        cursor: 'pointer',
        background: '#2c8059',
    }
})

const types = [
    {index: 0,value: 'car',name: 'Auto',icon: 'Car'},
    {index: 1,value: 'bus',name: 'Bus',icon: 'Bus',},
    {index: 2,value: 'hotel',name: 'Estadia en hotel',icon: 'Hotel',},
    {index: 3,value: 'air',name: 'Air Freight',icon: 'Aereo', },
    {index: 4,value: 'Ship',name: 'Ship Freight',icon: 'Maritimo', },
    {index: 5,value: 'Road',name: 'Road Freight',icon: 'Terrestre', },
]

export default function Calculate() {
    const navigate = useNavigate()

    const [selectedType, setSelectedType] = useState()
    const [method, setMethod] = useState("form")
    const [file, setFile] = useState()

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        console.log('selectedFile :',selectedFile)
    };
    const handleUpload = async () => {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            console.log(workbook);
          };
          reader.readAsArrayBuffer(file);
        }
      };

    return (
        <Container>
            <div style={{margin:'10px'}}>
                <Button handler={()=>navigate('/co2/persons')} text="Volver ←" outline/>
            </div>
            <PageTitle>Calcular emisiones de CO2</PageTitle>
            <div style={{margin:'15px'}}>
            <Radio.Group label="Metodo" size='sm' value={method} onChange={(value)=>setMethod(value)} orientation="horizontal">
                <Radio value="form">Llenar formulario</Radio>
                <Radio value="excel">Importar archivo </Radio>
            </Radio.Group>
            </div>
            {
                method==='excel'?
                <div>
                    <InputFileCard label='Sube un archivo .xls o .xlsx'
                        name='co2 xls'
                        startIcon={{ name: 'Upload', color: '#FFF' }}
                        width={'100%'}
                        aspectRatio={'6/1'} 
                        onChange={(e) => handleFileChange(e)}
                        accept='.xls,.xlsx'
                    />
                    <ExcelButton onClick={()=>handleUpload()}>Importar excel <Icon name='Excel' size='26' /></ExcelButton>
                </div>:
                <div>
                    <Cards>
                    {
                        types.map((type) =>
                            <Card2
                                key={type.index}
                                index={type.index}
                                icon={type.icon}
                                text={type.name}
                                textSelected={selectedType?.name}
                                handler={() => setSelectedType(type)}
                            />
                        )
                    }
                    </Cards>
                    <ViewsHandler activeView={selectedType?.index}>
                        <CarForm />
                        <BusForm />
                        <HotelForm />
                        <AirFreight />
                        <ShipFreightForm />
                        <RoadFreightForm />
                    </ViewsHandler>
                </div>
            }
        </Container>
    )
}
