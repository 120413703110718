import React, {useState} from 'react'
import { styled } from '@nextui-org/react'
import { usePDF } from 'react-to-pdf';
import ColoredPieChart from '../../components/Graphics/ColoredPieChart'
import ComposedBarChart from '../../components/Graphics/ComposedChart/ComposedChart'
import DashboardHorizontalBarChart from '../../components/Graphics/HorizontalChart/DashboardHorizontalChart'
import Table from '../../components/Table/Table'
import Button from '../../components/Button/Button';

const colors = ['#8295ec', '#3e60f7 ','#001b91']

const Container = styled("div",{
  display:'grid',
  gridTemplateColumns:'repeat(4, 1fr)',
  gap:'20px',
})

const SmallChart = styled("div", {
    background:'white',
    borderRadius:'8px',
    margin: 'auto',
    padding:'20px',
    width: '100%',
    height:'400px',
})
const MediumChart = styled("div", {
    background:'white',
    borderRadius:'8px',
    maxH: '400px',
    width: '100%',
    gridColumn: 'span 2',
    fontSize: '12px',
})
const dataOperations = [
  { name: 'IMPO', value: 400 },
  { name: 'EXPO', value: 300 },
  { name: 'OTROS', value: 300 },
];
const dataMethod = [
  { name: 'Aéreo', value: 400 },
  { name: 'Marítimo', value: 300 },
  { name: 'OTROS', value: 200 },
];

const dataSolicitudes = [
    {name: 'Enero',solicitudes: 590,},
    {name: 'Febrero',solicitudes: 868,},
    {name: 'Marzo',solicitudes: 1397,},
    {name: 'Abril',solicitudes: 1480,},
    {name: 'Mayo',solicitudes: 1520,},
];
const dataImpo = [
    {name: 'Enero',impos: 5,},
    {name: 'Febrero',impos: 8,},
    {name: 'Marzo',impos: 13,},
    {name: 'Abril',impos: 14,},
    {name: 'Mayo',impos: 15,},
];
const dataExpo = [
    {name: 'Enero',expos: 5,},
    {name: 'Febrero',expos: 8,},
    {name: 'Marzo',expos: 13,},
    {name: 'Abril',expos: 14,},
    {name: 'Mayo',expos: 10,},
];

const columns = [
    {name:'Cliente', uid:'client', type:'text'},
    {name:'Embarques', uid:'shipments', type:'textCenter'},
    {name:'Porcentaje', uid:'percentage', type:'textCenter'},
]
const items = [
    {
        key:1,
        client:'Rodatech',
        shipments:37,
        percentage:'9.30%'
    },
    {
        key:2,
        client:'Labcare',
        shipments:37,
        percentage:'9.30%'
    },
    {
        key:3,
        client:'Tarson',
        shipments:37,
        percentage:'9.30%'
    },
    {
        key:4,
        client:'Proelectrica',
        shipments:37,
        percentage:'9.30%'
    },
    {
        key:5,
        client:'Soenergy',
        shipments:37,
        percentage:'9.30%'
    },
]
const dataHitRate = [
    {name: 'Enero', hitRate: 33,},
    {name: 'Febrero', hitRate: 40,},
    {name: 'Marzo', hitRate: 40,},
    {name: 'Abril', hitRate: 56,},
    {name: 'Mayo', hitRate: 43,},
    {name: 'Junio', hitRate: 55,},
    {name: 'Julio', hitRate: 30,},
    {name: 'Septiembre', hitRate: 37,},
    {name: 'Octubre', hitRate: 49,},
    {name: 'Noviembre', hitRate: 16,},
]

export default function OperationsDashboard() {

    const [loadingPdf, setLoadingPdf] = useState(false)

    const { toPDF, targetRef } = usePDF({filename: 'operations_dashboard.pdf'});

    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }

    return (
    <>
        <div style={{margin:'20px'}}>
            <Button text={loadingPdf?'Cargando...':'Descargar pdf'} disabled={loadingPdf} handler={() => downloadPDF()} outline/>
        </div>
        <Container ref={targetRef}>
            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>Solicitudes por mes</p>
                <ComposedBarChart barDataKey={"solicitudes"} data={dataSolicitudes}/>
            </MediumChart>

            <SmallChart>
                <p style={{ margin: '15px', color: 'grey' }}>Operaciones</p>
                <ColoredPieChart colors={colors} data={dataOperations} radius={90} legend={true}/>
            </SmallChart>
            <SmallChart>
                <p style={{ margin: '15px', color: 'grey' }}>Modalidad</p>
                <ColoredPieChart colors={colors} data={dataMethod} radius={90} legend={true}/>
            </SmallChart>

            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>IMPORTACIONES</p>
                <ComposedBarChart barDataKey={"impos"} data={dataImpo}/>
            </MediumChart>
            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>EXPORTACIONES</p>
                <ComposedBarChart barDataKey={"expos"} data={dataExpo}/>
            </MediumChart>
            <MediumChart>
                <DashboardHorizontalBarChart width={600} color={'#3e60f7'} dataKey={"hitRate"} data={dataHitRate} />
            </MediumChart>
            <MediumChart>
                <Table columns={columns} items={items} excel={false} searchBar={false}/>
            </MediumChart>
        </Container>
    </>    
  )
}
