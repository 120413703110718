import React, {useEffect,useState} from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import { usePDF } from 'react-to-pdf';
import { styled, Tooltip } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import logo from "../../../assets/img/val-big.png"
//services
import { getPreliquidations, getServiceGroups, getServices, getServiceVariations, getProviderTypes, getProviders, getAppliesPer, getRateTypes, getRates, getSaleRates, getPurchaseRates, postLiquidation, editLiquidation, deleteLiquidation } from '../../../services/preliquidation.services';
import { getQuotation } from '../../../services/quotation.services';

const Container = styled("div",{
    boxShadow:'$elevation_2',
    width:'90%',
    padding:'40px 90px',
    margin:'30px',
    borderRadius:'8px',
    background:'$neutral_50',
    ['.prel-info']:{
        display:'flex',
        justifyContent:'space-between'
    },
    ['.load']:{
        display:'flex',
        flexDirection:'column',
        border:'2px solid $primary_500',
        padding:'5px',
        borderRadius:'8px',
    }
})

const Line = styled("div",{
    background:'$primary_500',
    height:'2px',
    width:'45%',
    margin:'0',
    borderRadius:'5px'
})
const Circle = styled("div",{
    position:'relative',
    bottom:'4px',
    height:'12px',
    width:'12px',
    border:'3px solid $primary_500',
    borderRadius:'50%',
})



export default function PreliquidationDetails() {
    const token=useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const { toPDF, targetRef } = usePDF({filename: 'preliquidation.pdf'})


    const [loadingPdf, setLoadingPdf] = useState(false)
    const [preliquidationId, setPreliquidationId] = useState()
    const [radication, setRadication] = useState()
    const [RFQ,setRFQ] = useState()
    const [preliquidationData, setPreliquidationData] = useState({})

    useEffect(()=>{
        setPreliquidationId(searchParams.get('preliquidation'))
        setRadication(searchParams.get('radication'))
    },[])

    useEffect(() => {
        const asyncTasks = [
            getServiceGroups(token),
            getServices(token),
            getServiceVariations(token),
            getProviderTypes(token),
            getProviders(token),
            getAppliesPer(token),
            getRateTypes(token),
            getRates(token),
            getSaleRates(token),
            getPurchaseRates(token),
        ];
        if (radication){
            asyncTasks.push(getQuotation(radication, token))
        }
        Promise.all(asyncTasks)
            .then(async responses => {
                const serviceGroupsData = await responses[0].json();
                const servicesData = await responses[1].json();
                const serviceVariationsData = await responses[2].json();
                const providerTypesData = await responses[3].json();
                const providersData = await responses[4].json();
                const appliesPerData = await responses[5].json();
                const rateTypesData = await responses[6].json();
                const ratesData = await responses[7].json();
                const saleRatesData = await responses[8].json();
                const purchaseRatesData = await responses[9].json();

                if (radication){
                    const RFQData = await responses[10].json(); setRFQ(RFQData)
                }
                if (preliquidationId) {
                    getPreliquidations(token, preliquidationId).then(async res => {
                        let data = await res.json()

                        const prelData = {
                            "applies": appliesPerData.find(e => e.id === data.applies_per),
                            "notes": data.notes || '',
                            "provider": providersData.find(provider => provider.id === data.provider),
                            "provider-type": providerTypesData.find(providerType => providerType.id === data.provider_type),
                            "purchase-currency": { label: data.purchase_rate.currency, value: data.purchase_rate.currency },
                            "purchase-min": data.purchase_rate.minimum_rate,
                            "purchase-price": data.purchase_rate.rate_number,
                            "purchase-rate-type": rateTypesData.find(rateType => rateType.id === data.purchase_rate.rate_type),
                            "purchase-trm": data.purchase_rate.trm,
                            "sale-currency": { label: data.sale_rate.currency, value: data.sale_rate.currency },
                            "sale-min": data.sale_rate.minimum_rate,
                            "sale-price": data.sale_rate.rate_number,
                            "sale-rate-type": rateTypesData.find(rateType => rateType.id === data.sale_rate.rate_type),
                            "sale-trm": data.sale_rate.trm,
                            "unit": data.unit,
                            "service": servicesData.find(service => service.id === data.service),
                            "service-group": serviceGroupsData.find(serviceGroup => serviceGroup.id === data.service_group),
                            "taxes": { label: data.sale_rate.tax, value: data.sale_rate.tax },
                            "variant": undefined
                        }
                        setPreliquidationData(prelData)
                    })
                }

            })
            .catch(error => console.error('Error:', error));
    }, [radication]);


    let salesTotal = (preliquidationData['sale-price'] / preliquidationData['sale-trm']) * preliquidationData.unit
    let purchaseTotal = (preliquidationData['purchase-price'] / preliquidationData['purchase-trm']) * preliquidationData.unit

    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }

    return (
        <>
        <div style={{margin:'30px 0px 0 30px'}}>
        <Button handler={()=>downloadPDF()} text={loadingPdf?'Cargando...':'Descargar'} endIcon={{ name: 'Download' }} outline disabled={loadingPdf}/>
        </div>
        <Container ref={targetRef}>
            {preliquidationData && 
            <>  
                <div className='prel-info'>
                    <div>
                        <h3>Detalles de preliquidación {preliquidationId}</h3>
                        <p>Aplica por: {preliquidationData.applies?.name}</p>
                        <p>Notas: {preliquidationData.notes}</p>
                        <p>Proveedor: {preliquidationData.provider?.name}</p>
                        <p>Moneda de Compra: {preliquidationData['purchase-currency']?.label}</p>
                        <p>Compra Mínima: {preliquidationData['purchase-min']}</p>
                        <p>Precio de Compra: {preliquidationData['purchase-price']}</p>
                        <p>Tipo de Tasa de Compra: {preliquidationData['purchase-rate-type']?.type}</p>
                        <p>TRM de Compra: {preliquidationData['purchase-trm']}</p>
                        <p>Moneda de Venta: {preliquidationData['sale-currency']?.label}</p>
                        <p>Venta Mínima: {preliquidationData['sale-min']}</p>
                        <p>Precio de Venta: {preliquidationData['sale-price']}</p>
                        <p>Tipo de Tasa de Venta: {preliquidationData['sale-rate-type']?.type}</p>
                        <p>TRM de Venta: {preliquidationData['sale-trm']}</p>
                        <p>Unidad: {preliquidationData.unit}</p>
                        <p>Servicio: {preliquidationData.service?.name}</p>
                        <p>Grupo de Servicio: {preliquidationData['service-group']?.name}</p>
                        <p>Impuestos: {preliquidationData.taxes?.label}</p>
                        <p></p>
                        <p>Total de ventas : {salesTotal}</p>
                        <p>Total de compras : {purchaseTotal}</p>
                        <p>Profit : {salesTotal - purchaseTotal}</p>
                    </div>
                    <img src={logo} height="500px" alt="" />
                </div>
                <div style={{display:'flex', justifyContent:'space-around', margin:'30px 0'}}>
                    <Line/>
                    <Circle/>
                    <Line/>
                </div>
                <div>
                    <h3>Detalles de cotización {radication}</h3>
                    <strong>General</strong>
                    <p>Tipo de operacion : {RFQ?.business_type}</p>
                    <p>Industria : {RFQ?.industry}</p>
                    <p>Incoterm: {RFQ?.business_terms}</p>
                    <strong>Envio</strong>
                    <p>Metodo de envio: {RFQ?.shipping.shipping_method}</p>
                    <p>Origen : {`${RFQ?.shipping.pickup_address} ,${RFQ?.shipping.origin_boarding_terminal}, ${RFQ?.shipping.origin_country}`}</p>
                    <p>Destino : {`${RFQ?.shipping.delivery_address} ,${RFQ?.shipping.destination_boarding_terminal}, ${RFQ?.shipping.destination_country}`}</p>

                    <strong>Cargas :</strong>
                    <div style={{display:'flex', flexWrap:'wrap', gap:'25px', margin:'10px'}}>
                        {
                            RFQ?.loads.map((load, index) => {
                                return (
                                    <div className='load' key={index}>
                                        <strong>Carga  {index + 1} :</strong>
                                        <span>{`Nombre : ${load?.name}`}</span>
                                        <span>{`Valor : ${load.value} USD`}</span>
                                        <span>{`Cantidad : ${load.amount}`}</span>
                                        <span>{`Medidas : ${load.long}${load.long_unit} x ${load.width}${load.width_unit} x ${load.height}${load.height_unit}`}</span>
                                        <Tooltip content="peso x cantidad" color={"primary"}>
                                            <span>{`Peso total : ${load.total_weight} ${load.total_weight_unit}`}</span>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <strong>Adicional : </strong>
                    <p>Requiere control de temperatura : {RFQ?.options.temperature_control === 1 ? "Si" : "No"}</p>
                    <p>Requiere Data logger : {RFQ?.options.data_logger === 1 ? "Si" : "No"}</p>
                    <p>La carga es remontable : {RFQ?.options.remountable === 1 ? "Si" : "No"}</p>
                    <p>Requiere posicion arancelaria : {RFQ?.options.arancelary_position === 1 ? "Si" : "No"}</p>
                    <p>Especificaciones adicionales : {RFQ?.options.additional_specifications || "Ninguno"}</p>
                </div>
            </>
            }
        </Container>
        </>
    )
}
