import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { URL } from '../../../constants';
import { useSelector } from "react-redux";
import { Loading } from '@nextui-org/react';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal/ConfirmationModal';
import DataModal from '../../../components/Modal/DataModal/DataModal';
import moment from 'moment';
import Table from '../../../components/Table/Table';

const TableServices = () => {
    const token = useSelector((state) => state.user.token);
    const headers = {
        "Content-Type": "application/json",
        'Authorization': 'Token ' + token
    }
    const [modalStyle, setModalStyle] = useState({ display: "none" });
    const [modal, setModal] = useState(null);
    const { data, loading } = useFetch(URL + 'user/', headers);
    const roles = useFetch(`${URL}roles/`, headers);
    const [items, setItems] = useState([])
    //console.log('users :', data)
    const closeModal = () => {
        setModal(null);
        setModalStyle({ display: "none" });
    };

    const style = {
        display: "block",
        backgroundColor: "rgba(0, 0, 0, .7)",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        zIndex: "300"
    }

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'Nombre', uid: 'name', type: 'text' },
        { name: 'Tipo Documento', uid: 'document_type', type: 'text' },
        { name: 'Documento', uid: 'document', type: 'text' },
        { name: 'Email', uid: 'email', type: 'text' },
        { name: 'Telefono', uid: 'phone', type: 'text' },
        { name: 'Industria', uid: 'industry', type: 'text' },
        { name: 'Empresa', uid: 'company', type: 'text' },
        { name: 'Ultimo Inicio', uid: 'last_login', type: 'text' },
        { name: 'Rol', uid: 'role', type: 'object' },
    ];

    const rolModal = userData => {
        const userRoles = roles.data.filter(rol => rol.id != userData.role_id);
        setModalStyle(style);
        setModal(
            <DataModal
                data={userRoles}
                titleText="Seleccione el nuevo rol del usuario"
                buttonText="Cambiar rol"
                close={closeModal}
                id={userData.id}
                endpoint={`user/${userData.id}`}
                methodType="PATCH"
                headers={headers}
            />
        )
    };
    const deleteUserModal = userData => {
        setModalStyle(style);
        setModal(
            <ConfirmationModal
                titleText="Eliminar usuario"
                buttonText="Eliminar"
                contentText="¿Esta seguro que desea eliminar este usuario?"
                close={closeModal}
                id={userData.id}
                endpoint={`user/${userData.id}`}
                methodType="DELETE"
                headers={headers}
            />
        );
    }

    const actions = [
        {
            name: 'Cambiar rol',
            icon: 'Edit',
            type: 'button',
            handle: rolModal
        },
        {
            name: 'Eliminar',
            icon: 'Trash',
            type: 'button',
            handle: deleteUserModal
        }
    ];


    useEffect(() => {
        if (!loading) {
            let dataTable;
            if (data.length > 0) {
                dataTable = data.map((user) => {
                    return {
                        id: user.id,
                        name: user.name,
                        document_type: user.document_type_id,
                        document: user.document,
                        email: user.email,
                        phone: user.phone,
                        industry: user.industry,
                        company: "co",
                        last_login: user.last_login
                            ? moment(user.last_login).format("YYYY-MM-DD HH:mm:ss")
                            : 'Sin inicio',
                        role: user.role ? user.role : 'Sin rol',
                    }
                });
            }
            setItems(dataTable);
        }
    }, [loading]);

    return (
        <div className='h-100'>
            {loading ? (
                <div className='d-flex justify-content-center'>
                    <Loading></Loading>
                </div>
            ) : items.length > 0 ? (
                <Table columns={columns} items={items} actions={actions}></Table>
            ) : (
                <div className='d-flex justify-content-center'>No hay</div>
            )}
            <div style={modalStyle}>{modal}</div>
        </div>

    );
}

export default TableServices;