import React, { useState } from 'react';
import { styled, Card } from '@nextui-org/react';
import theme from '../../../theme';
import { Modal } from '@mui/material';

// Components
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Ticket from './Ticket';
import Questions from './Questions';
import AddTicket from './AddTicket';

//Libreria Traduccion
import { useTranslation } from "react-i18next";


const StyledAllTickets = styled('article', {
    [`.${theme} &`]: {
        position: 'relative',
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            margin: '20px 0',
            display: 'flex',
            gap: '20rem'
        },
    }
})
const Deck = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(440px, 1fr))',
    gap: '20px',
})

const ManageView = ({ selectedSection }) => {
    const [filterSearch, setFilterSearch] = useState('')
    const [modalOpen, setModalOpen] = useState(false)


    const [tickets, setTickets] = useState([
        { id: 1, subject: 'Problema con el envío', date: '2022/11/01', status: 'open', detail: "Realice una liquidación general maritima, pero me hacen falta algunos documentos. ¿Cuánto plazo tengo para enviarlos?" },
        { id: 2, subject: 'Consulta sobre el pago', date: '2022/11/02', status: 'resolved', detail: "Realice una liquidación general maritima, pero me hacen falta algunos documentos. ¿Cuánto plazo tengo para enviarlos?" },
        { id: 3, subject: 'Error en la página', date: '2022/11/03', status: 'open', detail: "Realice una liquidación general maritima, pero me hacen falta algunos documentos. ¿Cuánto plazo tengo para enviarlos?" },
    ])

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterSearch(value)
    }

    const handleSectionChange = (section) => {
        setSelectedSection(section)
    }

    return (
        <StyledAllTickets>

            {selectedSection !== 'questions' && (
                <div className="header">
                    <Input placeholder='Buscar tickets' startIcon={{ name: 'Search' }} onChange={handleChange} fullWidth></Input>
                    <Button text='Nuevo ticket' size='lg' outline bold handler={() => setModalOpen(true)} />
                    {/* <Button endIcon={{ name: 'Plus' }} outline bold>Nuevo ticket</Button> */}
                </div>
            )}

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <AddTicket />
            </Modal>
            <Deck>
                {selectedSection === 'all' && (
                    tickets.map(ticket => (
                        <Ticket id={ticket.id} key={ticket.id} subject={ticket.subject} detail={ticket.detail} date={ticket.date} status={ticket.status} />
                    ))
                )}
                {selectedSection === 'open' && (
                    tickets.filter(ticket => ticket.status === 'open').map(ticket => (
                        <Ticket id={ticket.id} key={ticket.id} subject={ticket.subject} detail={ticket.detail} date={ticket.date} status={ticket.status} />
                    ))
                )}
                {selectedSection === 'resolved' && (
                    tickets.filter(ticket => ticket.status === 'resolved').map(ticket => (
                        <Ticket id={ticket.id} key={ticket.id} subject={ticket.subject} detail={ticket.detail} date={ticket.date} status={ticket.status} />
                    ))
                )}
                {
                    selectedSection === 'questions' && <Questions />
                }
            </Deck>

        </StyledAllTickets>
    );
}

export default ManageView;