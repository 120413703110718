import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import { usePDF } from 'react-to-pdf';
import ColoredPieChart from '../../../components/Graphics/ColoredPieChart'
import ComposedBarChart from '../../../components/Graphics/ComposedChart/ComposedChart'
import DashboardHorizontalBarChart from '../../../components/Graphics/HorizontalChart/DashboardHorizontalChart'
import Button from '../../../components/Button/Button';
import {getQuotesByRange} from '../../../services/quotations.services'
import { useSelector } from "react-redux";
const colors = ['#8295ec', '#3e60f7 ','#001b91']

const Container = styled("div",{
  display:'grid',
  gridTemplateColumns:'repeat(4, 1fr)',
  gap:'20px',
})

const SmallChart = styled("div", {
    background:'white',
    borderRadius:'8px',
    margin: 'auto',
    padding:'20px',
    width: '100%',
    height:'400px',
})
const MediumChart = styled("div", {
    background:'white',
    borderRadius:'8px',
    maxH: '400px',
    width: '100%',
    gridColumn: 'span 2',
    fontSize: '12px',
})
const dataOperations = [
  { name: 'IMPO', value: 400 },
  { name: 'EXPO', value: 300 },
  { name: 'OTROS', value: 300 },
];
const dataMethod = [
  { name: 'Aéreo', value: 400 },
  { name: 'Marítimo', value: 300 },
  { name: 'OTROS', value: 200 },
];

let dataSolicitudes = [
    {name: 'Enero',solicitudes: 590,},
    {name: 'Febrero',solicitudes: 868,},
    {name: 'Marzo',solicitudes: 1397,},
    {name: 'Abril',solicitudes: 1480,},
    {name: 'Mayo',solicitudes: 1520,},
];
const dataImpo = [
    {name: 'Enero',impos: 5,},
    {name: 'Febrero',impos: 8,},
    {name: 'Marzo',impos: 13,},
    {name: 'Abril',impos: 14,},
    {name: 'Mayo',impos: 15,},
];
const dataExpo = [
    {name: 'Enero',expos: 5,},
    {name: 'Febrero',expos: 8,},
    {name: 'Marzo',expos: 13,},
    {name: 'Abril',expos: 14,},
    {name: 'Mayo',expos: 10,},
];
const dataHitRate = [
    {name: 'Enero', hitRate: 33,},
    {name: 'Febrero', hitRate: 40,},
    {name: 'Marzo', hitRate: 40,},
    {name: 'Abril', hitRate: 56,},
    {name: 'Mayo', hitRate: 43,},
    {name: 'Junio', hitRate: 55,},
    {name: 'Julio', hitRate: 30,},
    {name: 'Septiembre', hitRate: 37,},
    {name: 'Octubre', hitRate: 49,},
    {name: 'Noviembre', hitRate: 16,},
]


export default function PricingDashboard() {
    const [loadingPdf, setLoadingPdf] = useState(false)
    const user = useSelector(state => state.user)
    const { toPDF, targetRef } = usePDF({filename: 'pricing_dashboard.pdf'});

    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }
    useEffect(()=>{
        getQuotesByRange(user.token,'2023-01-01','2024-03-31').then(async res=>{
            let meses=await res.json()
            dataSolicitudes=meses
        })
    })
    return (
    <>
        <div style={{margin:'20px'}}>
            <Button text={loadingPdf?'Cargando...':'Descargar pdf'} disabled={loadingPdf} handler={() => downloadPDF()} outline/>
        </div>
        <Container ref={targetRef}>
            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>Solicitudes por mes</p>
                <ComposedBarChart barDataKey={"solicitudes"} data={dataSolicitudes}/>
            </MediumChart>

            <SmallChart>
                <p style={{ margin: '15px', color: 'grey' }}>Liquidaciones</p>
                <ColoredPieChart colors={colors} data={dataOperations} radius={90} legend={true}/>
            </SmallChart>
            <SmallChart>
                <p style={{ margin: '15px', color: 'grey' }}>Modalidad</p>
                <ColoredPieChart colors={colors} data={dataMethod} radius={90} legend={true}/>
            </SmallChart>

            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>IMPORTACIONES</p>
                <ComposedBarChart barDataKey={"impos"} data={dataImpo}/>
            </MediumChart>
            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>EXPORTACIONES</p>
                <ComposedBarChart barDataKey={"expos"} data={dataExpo}/>
            </MediumChart>
            <MediumChart>
                <p style={{ margin: '15px', color: 'grey' }}>HIT RATE</p>
                <DashboardHorizontalBarChart width={600} color={'#3e60f7'} dataKey={"hitRate"} data={dataHitRate} />
            </MediumChart>
        </Container>
    </>
)}