import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import Circle from './Circle'

export default function Movements({data}) {
    
    const [movements,setMovements] = useState()

    useEffect(()=>{
        if (data.movements){
            setMovements({
                RFQ:true,
                quotation: data.movements.quotation,
                operation: data.movements.quotation,
            })
        }
    },[data])


    return (
        movements && <div style={{display:'flex', gap:'15px',margin:'10px 30px'}}>
            <Circle 
                state={"completed"}
                tooltip="Peticion de cotizacion realizada con exito"
                firstCircle={true}
            />
            <Circle
                state={movements.quotation? "completed": "started"}
                tooltip={movements.quotation? 'Cotizacion : La cotizacion se realizo con exito': 'Cotizacion : La cotizacion esta en proceso'}
            />
            <Circle
                state={movements.quotation? movements.operation? "completed": "started" : "not-started"}
                tooltip={movements.quotation? movements.operation? "Operacion : La operacion se inicializo con exito": "El inicio de la operacion esta en proceso" : "Esperando cotizacion"}
            />
        </div>
    )
}
