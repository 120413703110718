
import { URL } from "../constants";

export const getOperations = async ({ token, id }) => {
    const getURL = id ? `${URL}operations/operations/${id}/` : `${URL}operations/operations/`
    const response = await fetch(getURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}


export const postOperation = async (data) => {
    const response = await fetch(`${URL}operations/operations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status !== 201) {
        throw error
    }
    return response;
}

export const deleteOperation = async (id) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}

export const getOperationTypes = async (token) => {
    const response = await fetch(`${URL}operations/operation_types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRoles = async (token) => {
    const response = await fetch(`${URL}roles/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getActivities = async (token) => {
    const response = await fetch(`${URL}operations/activities/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOperationActivities = async (token) => {
    const response = await fetch(`${URL}operations/operation_activities/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getStatuses = async (token) => {
    const response = await fetch(`${URL}operations/statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOperationStatuses = async (token) => {
    const response = await fetch(`${URL}operations/operation_statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSubStatuses = async (token) => {
    const response = await fetch(`${URL}operations/operation_sub_statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const setSubState = async (token, operationId, subStateId, date, notes) => {
    const data = {
        operation: operationId,
        operation_sub_status: subStateId,
        notes,
        date,
    }
    console.log("data :",data)
    const response = await fetch(`${URL}operations/operation_sub_statuses_change/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    return response;
}

export const editSubState = async (token, relationId, subStateId) => {
    const data = {
        operation_sub_status: subStateId,
    }
    const response = await fetch(`${URL}operations/operation_sub_statuses_change/${relationId}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return response;
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getSubStatusesOperations = async (token) => {
    const response = await fetch(`${URL}operations/operation_sub_statuses_change/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getAgents = async (token) => {
    const response = await fetch(`${URL}operations/agents/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getCoordinators = async (token) => {
    const response = await fetch(`${URL}operations/coordinators/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getLogEntries = async (token) => {
    const response = await fetch(`${URL}operations/log_entries/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const postLogEntries = async (data) => {
    const response = await fetch(`${URL}operations/log_entries/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editLogEntry = async (data, id) => {
    const response = await fetch(`${URL}operations/log_entries/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteLogEntry = async (id) => {
    const response = await fetch(`${URL}operations/log_entries/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
    return response;
}

export const addOperationActivity = async ({ data, id }) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editOperationActivity = async ({ data, id }) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteActivity = async (id) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}


export const getEmails = async (token, radication) => {

    const response = await fetch(`${URL}operations/email-records/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSubject = async (token, radication) => {
    const response = await fetch(`${URL}operations/generate-subject/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const sendEmail = async (data, token) => {
    const response = await fetch(`${URL}operations/send-email/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return response;
    } else {
        throw new Error("Ha habido un error");
    }
}
export const getDOs = async (token) => {
    const response = await fetch(`${URL}quotation/delivery-order/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const deleteDO = async (id) => {
    const response = await fetch(`${URL}quotation/delivery-order/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}