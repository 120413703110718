import React, { useState, useEffect, useRef } from 'react';
import { Input, Grid, styled, Loading } from "@nextui-org/react";
import theme from "../../theme";
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress } from "react-places-autocomplete"
import { getLatLng } from '../../services/map.services';

// Components
import Card2 from '../Cards/Card2/Card2';
import Button from '../Button/Button'
import Icon from '../Icon/Icon';

const StyledFormCO2 = styled('div', {
    [`.${theme} &`]: {
        backgroundColor: "$neutral_50",
        borderRadius: "8px",
        width: '100%',
        height: '100%',
        padding: '0rem 0.5rem',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        h3: {
            color: '$primary_300'
        }
    },
    ['.o_input']: {
        borderRadius: '8px',
        backgroundColor: 'white',
        border: 'solid 1px $primary_300',
        transition: 'border 0.25s ease',
        color: 'blue',
        marginRight: 0,
        ['&:focus-within']: {
            border: 'solid 1px $primary_100',
        },
        ['.nextui-input-wrapper']: {
            borderRadius: '8px',
            backgroundColor: '$neutral_50'
        },
        ['.nextui-input-label--right']: {
            borderRadius: '0px 8px 8px 0px',
            backgroundColor: '$neutral_100'
        },
        ['.nextui-input-label--left']: {
            borderRadius: '8px 0px 0px 8px'
        }
    },
    ['.error']: {
        border: 'solid 1px red'
    },
    ['label']: {
        marginBottom: '0.375rem',
        fontSize: '0.9rem',
        color: '$primary_500'
    },
    ['.contentSendMethod']: {
        display: 'flex',
        justifyContent: 'center',
        gap: '2rem',
        margin: '1.5rem 0rem'
    },
    ['.nextButton']: {
        position: 'absolute',
        left: '0px',
        bottom: '-55px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    ['.autocomplete-dropdown-container']: {
        position: 'absolute',
        width: '100%',
        ['.suggestion']: {
            border: 'solid 1px $neutral_100',
            padding: '0.4rem',
            fontSize: '0.9rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        ['.suggestion:first-child']: {
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
        },
        ['.suggestion:last-child']: {
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px'
        },
    },
    ['iframe']: {
        width: '100%',
        height: '100%'
    },
    ['.editOrigin, .editDestination']: {
        padding: '0rem 0rem 0rem 0.25rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ['& > div > p']: {
            paddingRight: '1rem'
        }
    },
});

const FormCO2 = ({ index = 0, activeStep, dataShipping, setDataShipping, sendMethods, errors, validate, originLoading, setOriginLoading, destinationLoading, setDestinationLoading }) => {
    const [originSave, setOriginSave] = useState(false)
    const [destinationSave, setDestinationSave] = useState(false)
    const [firstSlip, setFirstSlip] = useState(false)
    const [firstChangeMethod, setFirstChangeMethod] = useState(false)
    const originInputRef = useRef(null);
    const destinationInputRef = useRef(null);

    const handleChangeAutocomplete = async (value, name, select = false) => {
        const dataShippingClone = structuredClone(dataShipping)
        if (value === 'right') name === 'origin' ? value = originInputRef.current.value : value = destinationInputRef.current.value
        // Synchronize destination with the following origin
        if (name === 'origin') {
            dataShippingClone[index].origin = value
            if (dataShipping[index - 1].hasOwnProperty('destination')) dataShippingClone[index - 1].destination = value
        } else if (name === 'destination') {
            dataShippingClone[index].destination = value
            if (dataShipping[index + 1].hasOwnProperty('origin')) dataShippingClone[index + 1].origin = value
        }

        if (select && validate(name)) {
            // Update location on map
            if (name === 'origin') {
                setOriginLoading(true)
                const originData = await geocodeByAddress(value)

                const originCity = originData[0].address_components?.find(
                    component => component.types.includes('locality')
                );

                const originCountry = !originCity
                    ? originData[0].address_components?.find(
                        component => component.types.includes('country')
                    )
                    : null;

                const originAddress = originCity
                    ? originCity.long_name
                    : originCountry
                        ? originCountry.long_name
                        : 'No disponible';

                const originLocation = await getLatLng(originAddress)

                dataShippingClone[index].originLocation = originLocation.location
                initMap({ origin: originLocation.location })
                setOriginSave(true)
                setOriginLoading(false)
            } else if (name === 'destination') {
                setDestinationLoading(true)

                const destinationData = await geocodeByAddress(value)

                const destinationCity = destinationData[0].address_components?.find(
                    component => component.types.includes('locality')
                );

                const destinationCountry = !destinationCity
                    ? destinationData[0].address_components?.find(
                        component => component.types.includes('country')
                    )
                    : null;

                const destinationAddress = destinationCity
                    ? destinationCity.long_name
                    : destinationCountry
                        ? destinationCountry.long_name
                        : 'No disponible';

                const destinationLocation = await getLatLng(destinationAddress)

                dataShippingClone[index].destinationLocation = destinationLocation.location

                initMap({ destination: destinationLocation.location })
                setDestinationSave(true)
                setDestinationLoading(false)
            }
        }
        setDataShipping(dataShippingClone)
    }

    const setMethod = (key) => {
        const dataShippingClone = structuredClone(dataShipping)
        dataShippingClone[index].sendMethod = sendMethods[key].text;
        dataShippingClone[index].iconSendMethod = sendMethods[key].icon;
        dataShippingClone[index].logicalSendMethod = sendMethods[key].logicalName;
        setDataShipping(dataShippingClone);
    }

    // var map
    const initMap = (value) => {
        var mapOptions = {
            zoom: 4.6,
            center: { lat: 4.570868, lng: -74.297333 }
        }

        var map = new google.maps.Map(document.getElementById('map' + index), mapOptions)

        var triangleCoords = [
            value?.origin ? value.origin : dataShipping[index].originLocation,
            value?.destination ? value.destination : dataShipping[index].destinationLocation,
        ];

        if (triangleCoords[0].lat !== 0 && triangleCoords[0].lng !== 0) {
            new google.maps.Marker({
                position: triangleCoords[0],
                map: map,
                label: 'A'
            });
        }
        if (triangleCoords[1].lat !== 0 && triangleCoords[1].lng !== 0) {
            new google.maps.Marker({
                position: triangleCoords[1],
                map: map,
                label: 'B'
            });
        }


        var bermudaTriangle = new google.maps.Polygon({
            paths: triangleCoords,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            geodesic: true,
        });

        bermudaTriangle.setMap(map);

        let vertices = bermudaTriangle.getPath();
        for (let i = 0; i < vertices.getLength(); i++) {
            let vertex = vertices.getAt(i);
        }

    }

    useEffect(() => {
        if (firstChangeMethod) {
            const dataShippingClone = structuredClone(dataShipping)
            dataShippingClone[index].origin = ''
            if (!originSave) originInputRef.current.value = ''
            dataShippingClone[index].originLocation = { lat: 0, lng: 0 }
            dataShippingClone[index].destination = ''
            if (!destinationSave) destinationInputRef.current.value = ''
            dataShippingClone[index].destinationLocation = { lat: 0, lng: 0 }
            setOriginSave(false)
            setDestinationSave(false)
            const location = { lat: 0, lng: 0 }
            initMap({ origin: location, destination: location })
            setDataShipping(dataShippingClone)
        } else {
            setFirstChangeMethod(true)
        }
    }, [dataShipping[index].sendMethod]);

    useEffect(() => {
        if (!originSave) originInputRef.current.value = dataShipping[index].origin
        if (!destinationSave) destinationInputRef.current.value = dataShipping[index].destination
    }, [originSave, destinationSave]);

    useEffect(() => {
        if (dataShipping[index].destination === '') {
            setDestinationSave(false)
        }

        if (index !== activeStep && firstSlip) {
            setOriginSave(true)
            setDestinationSave(true)
        }
        setFirstSlip(true)
    }, [activeStep]);

    useEffect(() => {
        if (index > 1) {
            initMap({ origin: dataShipping[index].originLocation })
            setOriginSave(true)
        } else {
            initMap()
        }
    }, []);

    return (
        <StyledFormCO2>
            <div className='w-50'>
                <h3 className='text-center'>Método de envío</h3>
                <div className='contentSendMethod'>
                    {
                        sendMethods.map((element, i) =>
                            <Card2 key={i} index={i} icon={element.icon} text={element.text} textSelected={dataShipping[index].sendMethod} handler={setMethod}></Card2>
                        )
                    }
                </div>
                <Grid.Container className='d-flex justify-content-center' gap={4}>
                    <Grid className='w-50'>
                        {
                            originSave ?
                                <div className='editOrigin'>
                                    <label>Origen</label>
                                    <div className='d-flex align-items-center flex-1 break-word'>
                                        <p>{dataShipping[index].origin}</p>
                                        <Button handler={() => setOriginSave(!originSave)} startIcon={{ name: 'Edit', size: '20' }} size={'sm'} outline></Button>
                                    </div>
                                </div>
                                :
                                <PlacesAutocomplete
                                    value={
                                        `${dataShipping[index].logicalSendMethod === 'air' ?
                                            'Aeropuerto ' :
                                            dataShipping[index].logicalSendMethod === 'ocean' ?
                                                'Puerto ' :
                                                ''}` + dataShipping[index].origin
                                    }
                                    onChange={(e) => handleChangeAutocomplete(e, 'origin')}
                                    onSelect={(e) => handleChangeAutocomplete(e, 'origin', true)}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                        <div className='p-relative'>
                                            <Input
                                                id={`origin` + index}
                                                label="Origen"
                                                name="origin"
                                                className={`o_input ${errors['origin' + index] ? 'error' : ''}`}
                                                animated={false}
                                                width={'100%'}
                                                helperText={errors['origin' + index] ? errors['origin' + index] : ''}
                                                placeholder='Desde'
                                                disabled={originLoading || destinationLoading}
                                                ref={originInputRef}
                                                onBlur={getInputProps().onBlur}
                                                onChange={getInputProps().onChange}
                                                onKeyDown={getInputProps().onKeyDown}
                                                contentRightStyling={true}
                                                contentRight={originLoading ? <Loading size='xs'></Loading> : <Icon name='Send'></Icon>}
                                                contentClickable={true}
                                                onContentClick={(e) => handleChangeAutocomplete(e, 'origin', true)}
                                                labelLeft={
                                                    dataShipping[index].logicalSendMethod === 'air' ?
                                                        'Aeropuerto' :
                                                        dataShipping[index].logicalSendMethod === 'ocean' ?
                                                            'Puerto'
                                                            : ''
                                                }
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {suggestions.map((suggestion, i) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            key={i}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className: 'suggestion',
                                                                style,
                                                            })}
                                                        >
                                                            <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                        }
                    </Grid>
                    <Grid className='w-50'>
                        {
                            destinationSave ?
                                <div className='editDestination'>
                                    <label>Destino</label>
                                    <div className='d-flex align-items-center flex-1 break-word'>
                                        <p>{dataShipping[index].destination}</p>
                                        <Button handler={() => setDestinationSave(!destinationSave)} startIcon={{ name: 'Edit', size: '20' }} size={'sm'} outline></Button>
                                    </div>
                                </div>
                                :
                                <PlacesAutocomplete
                                    value={
                                        `${dataShipping[index].logicalSendMethod === 'air' ?
                                            'Aeropuerto ' :
                                            dataShipping[index].logicalSendMethod === 'ocean' ?
                                                'Puerto ' :
                                                ''}` + dataShipping[index].destination
                                    }
                                    onChange={(e) => handleChangeAutocomplete(e, 'destination')}
                                    onSelect={(e) => handleChangeAutocomplete(e, 'destination', true)}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                        <div className='p-relative'>
                                            <Input
                                                id={`destination` + index}
                                                label="Destino"
                                                name="destination"
                                                className={`o_input ${errors['destination' + index] ? 'error' : ''}`}
                                                helperText={errors['destination' + index] ? errors['destination' + index] : ''}
                                                animated={false}
                                                width={'100%'}
                                                placeholder='Hasta'
                                                disabled={originLoading || destinationLoading}
                                                ref={destinationInputRef}
                                                onBlur={getInputProps().onBlur}
                                                onChange={getInputProps().onChange}
                                                onKeyDown={getInputProps().onKeyDown}
                                                contentRightStyling={true}
                                                contentRight={destinationLoading ? <Loading size='xs'></Loading> : <Icon name='Send'></Icon>}
                                                contentClickable={true}
                                                onContentClick={(e) => handleChangeAutocomplete(e, 'destination', true)}
                                                labelLeft={
                                                    dataShipping[index].logicalSendMethod === 'air' ?
                                                        'Aeropuerto' :
                                                        dataShipping[index].logicalSendMethod === 'ocean' ?
                                                            'Puerto'
                                                            : ''
                                                }
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {suggestions.map((suggestion, i) => {
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            key={i}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className: 'suggestion',
                                                                style,
                                                            })}
                                                        >
                                                            <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                        }
                    </Grid>
                </Grid.Container>
            </div>
            <div className='w-50 h-100'>
                <div id={'map' + index} className='w-100 h-100 border-rounded'></div>
            </div>
        </StyledFormCO2>
    );
}

export default FormCO2;