import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { styled } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

export default function DashboardHorizontalBarChart({ dataKey, data, width, height, color }) {


    const { t } = useTranslation("Home")
    const filteredData = data?.filter(e => e[dataKey] !== 0)

    return (
        <ResponsiveContainer width="100%" height="100%">
            <div>
                <BarChart
                    layout="vertical" // establece el diseño en vertical
                    width={width || 700}
                    height={height || 400}
                    data={filteredData}
                    margin={{
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 30,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={dataKey} stackId="a" fill={color || "#fd7c5c"} barSize={10} radius={8}/>
                </BarChart>
            </div>
        </ResponsiveContainer>
    );
}