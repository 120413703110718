import { URL } from "../constants"

export const sendQuotation = async (data, token) => {
    const response = await fetch(`${URL}service/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const editQuotation = async (data, token) => {
    const response = await fetch(`${URL}service/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const setServiceUser = async (token, userId, serviceId) => {
    const data = {
        user:userId,
        service:serviceId,
    }
    console.log("data :",data)
    const response = await fetch(`${URL}userservice/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const deleteService = async (token, radication) => {
    const response = await fetch(`${URL}services/radication/${radication}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status === 204 || response.status === 200) {
        return true;
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const approveLiquidation = async (data, token) => {
    const response = await fetch(`${URL}services/approve/${data}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getQuotation = async (data, token) => {
    const response = await fetch(`${URL}services/radication/${data}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getQuotations = async (token) => {
    const response = await fetch(`${URL}service/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const sendCodeParticipant = async (data, token) => {
    const response = await fetch(`${URL}invitation/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            email: data.email,
            radication: data.radication
        })
    })
    return response;
}

