import React, { useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../theme";
import styles from "./Quotation.module.scss";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
// Components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Input from "../../../components/Input/Input";
import TableQuotation from "./TableQuotation/TableQuotation";

const StyledCotization = styled("section", {
  [`.${theme} &`]: {
    backgroundColor: "$primary_50",
    height: "100%",
    padding: "3rem 2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    flexGrow: 1,
    ['.header']: {
      display: 'flex',
      gap: '1.5rem'
    },
  },
});

function Quotation({ hideMainSidebar }) {

  const [filterType, setFilterType] = useState("Seleccionar");
  const [filterSearch, setFilterSearch] = useState('')

  const options = [
    { key: "Seleccionar", name: "Seleccionar" },
    { key: "Carga general", name: "Carga general" },
    { key: "Carga proyecto", name: "Carga proyecto" }
  ];

  const handleClick = () => {
    hideMainSidebar();
  };

  const handleChange = (value) => {
    setFilterSearch(value)
  }
  const debouncedSearch = debounce((value) => {
    handleChange(value)
  }, 300);

  return (
    <StyledCotization>
      <PageTitle>
        Cotizaciones
      </PageTitle>

      <div className="header">
        <Input placeholder='Buscar' startIcon={{ name: 'Search' }} onChange={(e) => debouncedSearch(e.target.value)} fullWidth></Input>
        <Dropdown options={options} className={styles.dropdown} s onChange={data => setFilterType(data[0])} outline></Dropdown>
        <Link to="/new-quotation">
          <Button className={styles.btnContainer} text={'Nueva Cotizacion'} textColor={'neutral_50'} bgColor={'primary_400'}
            onClick={() => handleClick()}></Button>
        </Link>
      </div>

      <div className="flex-1 overflow-h">
        <TableQuotation filterType={filterType} filterSearch={filterSearch}></TableQuotation>
      </div>
    </StyledCotization>
  );

}
export default Quotation;