import React, { useEffect, useState } from "react";
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import Icon from '../../components/Icon/Icon';
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import PageTitle from '../../components/PageTitle/PageTitle';
import calculatorImage from '../../assets/tools/calculator.jpg'
import "./calculator.css";

// Import traduccion
import { useTranslation } from "react-i18next";

const StyledCalculator = styled('article', {
  [`.${theme} &`]: {
    padding: '3rem 2rem',
    margin: '3rem',
    backgroundColor: '$neutral_50',
    display: "flex",
    flexDirection: "column",
    borderRadius: '8px',
    ['div']: {
      display: 'flex',
      margin: '15px',
      gap: '20px',
    },
    ['input']: {
      borderRadius: "8px",
      height: '40px',
      padding: '5px',
      border: "1px solid $primary_300",
      transition: "border 0.25s ease",
      [".nextui-input-wrapper"]: {
        borderRadius: "6px",
        backgroundColor: "$neutral_50",
      },
      ["label"]: {
        color: "$primary_500",
      },
      ["& + .nextui-input-helper-text-container .nextui-input-helper-text"]: {
        fontSize: "11px",
      },
    }
  }
})

const TableContainer = styled('div', {
  padding: '3rem 2rem',
  margin: '3rem',
  backgroundColor: '$neutral_50',
  display: "flex",
  flexDirection: "column",
  borderRadius: '8px',
})
const InfoContainer = styled("div", {
  // backgroundColor:'red',
  display: "flex",
  justifyContent: 'center',
  margin: 'auto',
  flexDirection: "column",
  padding: '2rem',
  ["div"]: {
    justifyContent: 'center',
    display: 'flex',
    gap: '30px',
    margin: '10px',
  }
})

/* The above code is a React component called `LoadForm` that represents a form for adding or editing a
load. It includes input fields for the load's name, dimensions (length, width, height), unit of
measurement (meters or centimeters), weight, and amount. The form also calculates and displays the
total weight based on the weight and amount inputs. The form can be submitted to add a new load or
update an existing load. */
function LoadForm(props) {
  const { t } = useTranslation("Calculator");
  const [name, setName] = React.useState('');
  const [unit, setUnit] = useState('m');  // Single unit for all three dimensions
  const [length, setLength] = React.useState('');
  const [width, setWidth] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [weightinput, setWeightinput] = React.useState('');
  const [totalWeightForm, setTotalWeightForm] = React.useState(0);
  const [editingLoad, setEditingLoad] = useState(null);
  // useEffect to update totalWeightForm when weightinput or amount changes
  React.useEffect(() => {
    setTotalWeightForm(weightinput * amount);
  }, [weightinput, amount]);
  useEffect(() => {
    if (editingLoad) {
      setName(editingLoad.name);
      setLength(editingLoad.length);
      setWidth(editingLoad.width);
      setHeight(editingLoad.height);
      setAmount(editingLoad.amount);
      setWeightinput(editingLoad.weight);
    }
  }, [editingLoad]);
  // Function to convert cm to meters if needed
  const convertToMeter = (value, unit) => (unit === 'cm' ? value / 100 : value);
  // Function to handle form submission
  const handleSubmit = (e) => {
    const lengthValue = parseFloat(length);
    const widthValue = parseFloat(width);
    const heightValue = parseFloat(height);
    e.preventDefault();

    // Convert dimensions to meters if needed

    const lengthInMeters = convertToMeter(parseFloat(length), unit);
    const widthInMeters = convertToMeter(parseFloat(width), unit);
    const heightInMeters = convertToMeter(parseFloat(height), unit);
    // Calculate cubic meters and weight
    const cubicMeters = lengthInMeters * widthInMeters * heightInMeters;
    // Calcular metros cúbicos y peso considerando la cantidad
    const cubicMetersPerItem = lengthInMeters * widthInMeters * heightInMeters;
    const totalCubicMeters = cubicMetersPerItem * amount; // Aquí multiplicamos por la cantidad
    const weight = weightinput * amount;

    const id = new Date().getTime();
    props.onAddLoad({
      id: id,
      name: name,
      dimensions: `${lengthValue}${unit} x ${widthValue}${unit} x ${heightValue}${unit}`,
      cubicMeters: totalCubicMeters, // Usamos totalCubicMeters en lugar de cubicMeters
      amount,
      weight
    });
    // Clear the form fields after submission
    setName('');
    setLength('');
    setWidth('');
    setHeight('');
    setAmount('');
    setWeightinput('');
  };
  return (

    <StyledCalculator>
      <PageTitle>
        {t("loadcalculator")}
      </PageTitle>
      <form onSubmit={handleSubmit}>
        <div>
          <input className="medium-input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nombre de la Carga" required={true} />
          {/* <Icon name="Largo" color="#EB9D3A" /> */}
        </div>
        <div>
          <input className="small-input" pattern="^\d*(\.\d{0,2})?$" title="Por favor, ingrese un número válido. Puede incluir hasta dos decimales." value={length} onChange={(e) => setLength(e.target.value)} placeholder="Largo" required />
          <input className="small-input" pattern="^\d*(\.\d{0,2})?$" title="Por favor, ingrese un número válido. Puede incluir hasta dos decimales." value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Ancho" required />
          <input className="small-input" pattern="^\d*(\.\d{0,2})?$" title="Por favor, ingrese un número válido. Puede incluir hasta dos decimales." value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Alto" required />
          <select value={unit} onChange={(e) => setUnit(e.target.value)} style={{ margin: '10px' }}>
            <option value="m">m</option>
            <option value="cm">cm</option>
          </select>
        </div>
        <div>
          <input className="small-input" value={weightinput} onChange={(e) => setWeightinput(e.target.value)} placeholder="Peso (kg)" required />
          <input className="small-input" pattern="^\d*(\.\d{0,2})?$"
            title="Por favor, ingrese un número válido. Puede incluir hasta dos decimales." value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Cantidad" required />
          <input className="small-input" value={totalWeightForm} placeholder="Peso Total (kg)" readOnly />
        </div>
        <Button type="submit" bgColor='primary_300' textColor='white' margin={0} text={editingLoad ? 'Actualizar Carga' : 'Añadir Carga'} />
      </form>

    </StyledCalculator>
  );
}
/* The above code is defining a React functional component called `LoadList`. This component takes in a
`props` object as an argument. */
function LoadList(props) {
  const { t } = useTranslation("Calculator");

  return (

    <TableContainer>
      <table className="calculator">
        <thead>
          <tr>
            <th className="calculator">{t("loadname")}</th>
            <th className="calculator">{t("mcubic")}</th>
            <th className="calculator">{t("amount")}</th>
            <th className="calculator">{t("weight")}</th>
          </tr>
        </thead>
        <tbody>
          {props.loads.map((load, index) => (
            <tr key={load.id}>
              <td className="calculator">
                {load.name} - <span className="smaller-dimensions">{load.dimensions}</span>
              </td>
              <td className="calculator formula"> {/* Estilizado para parecerse a una fórmula matemática */}
                <span>{load.cubicMeters} m³</span>

                <span className="equals smaller-dimensions">=</span>
                <span className="dimension smaller-dimensions">({load.dimensions.split(" x ").join(" × ")})m³</span>
                <span className="times smaller-dimensions">×</span>
                <span className="dimension smaller-dimensions">{load.amount}</span>
              </td>
              <td className="calculator">{load.amount} cargas</td>
              <td className="calculator">{load.weight} kg</td>
              <td className="calculator">
                <Icon className="IconCalculator" name="Trash" onClick={() => props.onDeleteLoad(load.id)} />
                <Icon className="IconCalculator" name="Edit" onClick={() => props.onEditLoad(load.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
}
/**
 * The Calculator function is a React component that calculates the total cubic meters, total weight,
 * and recommends a container type based on the loads entered.
 * @returns a JSX element, which represents the UI of the Calculator component. The returned JSX
 * element contains a LoadForm component, a LoadList component (if there are loads), and an
 * InfoContainer component. The InfoContainer component displays the total cubic meters, total amount,
 * total weight, and recommended container type based on the loads entered.
 */
function Calculator() {
  const { t } = useTranslation("Calculator");

  const [totalcubicmeters, setTotalcubicmeters] = React.useState(0); // initialize with 0
  const [totalweight, setTotalweight] = React.useState(0);

  const handleDeleteLoad = (id) => {
    const updatedLoads = loads.filter(load => load.id !== id);
    setLoads(updatedLoads);
  };

  function determineContainer(cubicMeters, weight) {
    const weightInTonnes = weight / 1000; // Convertir kilogramos a toneladas.

    // Especificaciones de contenedores
    const containers = [
      { type: 'Avión', maxCubicMeters: 2, maxWeightInTonnes: 0.2 },
      { type: 'Pallet', maxCubicMeters: 10, maxWeightInTonnes: 1 },
      { type: '20', maxCubicMeters: 33, maxWeightInTonnes: 28 },
      { type: '20HC', maxCubicMeters: 35, maxWeightInTonnes: 26 },
      { type: '40', maxCubicMeters: 67, maxWeightInTonnes: 29 },
      { type: '40HC', maxCubicMeters: 76, maxWeightInTonnes: 28 },
      { type: '45', maxCubicMeters: 85, maxWeightInTonnes: 29 },
      { type: '45HC', maxCubicMeters: 90, maxWeightInTonnes: 30 },
      // Se pueden agregar otros contenedores aquí en el futuro
    ];

    // Determinar el tipo de contenedor
    for (const container of containers) {
      if (cubicMeters <= container.maxCubicMeters && weightInTonnes <= container.maxWeightInTonnes) {
        return container.type;
      }
    }

    return 'Ningún contenedor disponible';
  }

  const containerType = determineContainer(totalcubicmeters, totalweight);
  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;
  const [loads, setLoads] = React.useState([]);
  const handleAddLoad = (load) => {
    setLoads((prevLoads) => [...prevLoads, load]);
  };
  const totalAmount = loads.reduce((acc, load) => acc + load.amount, 0);
  const totalCubicMetersRounded = roundToTwoDecimals(loads.reduce((acc, load) => acc + load.cubicMeters, 0)); // Esta línea ya debe calcular correctamente, pero ahora lo hará con la nueva lógica de cálculo en el formulario.
  const totalWeight = roundToTwoDecimals(loads.reduce((acc, load) => acc + load.weight, 0));
  useEffect(() => {
    // Update the state when the loads change
    setTotalcubicmeters(totalCubicMetersRounded);
    setTotalweight(totalWeight);
  }, [loads]);
  if (!loads.length) {
    return <LoadForm onAddLoad={handleAddLoad} />;
  }
  return (
    <div>
      <LoadForm onAddLoad={handleAddLoad} />
      {loads.length > 0 && <LoadList loads={loads} onDeleteLoad={handleDeleteLoad} />}
      <InfoContainer>
        <div>
          <p>Metros Cúbicos Totales: <span className="value">{totalCubicMetersRounded} m³</span></p>
          <p>Cantidad Total: <span className="value">{Number(totalAmount).toString()}</span></p>
          <p>Peso Total: <span className="value">{totalWeight} kg</span></p>
        </div>
        <div>
          <p>Tipo de contenedor recomendado: <span className="value">{containerType}</span></p>
        </div>
      </InfoContainer>
    </div>
  );
}

export default Calculator;
