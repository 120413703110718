import React, { useEffect, useState } from 'react'
import { getProviders, getProviderTypes } from '../../../services/preliquidation.services'
import Table from '../../../components/Table/Table'
import { useSelector } from 'react-redux'

export default function ProvidersTable() {  
    const token = useSelector(state=>state.user.token)
    const [items, setItems] = useState([])
    const columns = [
        {name:'ID', uid:'id', type:'text'},
        {name:'Nombre', uid:'name', type:'text'},
        {name:'email', uid:'mail', type:'text'},
        {name:'Tipo de proveedor', uid:'provider_type', type:'text'},
    ]

    useEffect(()=>{
        Promise.all([getProviders(token), getProviderTypes(token)]).then(async res=>{
            const providersData = await res[0].json()
            const providerTypesData = await res[1].json()
            //console.log('providersData :',providersData)
            const tableData = providersData.map(provider => {
                return {
                    ...provider,
                    provider_type:providerTypesData.find(type => type.id === provider.provider_type)?.name,
                }
            })
            setItems(tableData)
        })
    },[])
   // console.log("items :",items)
    return (
        <Table columns={columns} items={items}/>
    )
}
