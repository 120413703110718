import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePDF } from 'react-to-pdf';
import { Loading, styled, Tooltip } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import logo from "../../assets/img/val-big.png"
//services
import { getQuoteData, getSubTypes, getSolutions, getOffices, getClients, getQuoteLanguages, getStatuses, getReasons, postQuotation, deleteQuotation, editQuotation, finishQuotation } from '../../services/quotations.services';
import { getBusinessTerms } from '../../services/businessTerms.services';
import { getQuotation } from '../../services/quotation.services';

//components
import Button from '../../components/Button/Button';

const Container = styled("div", {
    boxShadow: '$elevation_2',
    width: '90%',
    padding: '40px 90px',
    margin: '30px',
    borderRadius: '8px',
    background: '$neutral_50',
    ['.prel-info']: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ['.load']: {
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid $primary_500',
        padding: '5px',
        borderRadius: '8px',
    }
})

const Line = styled("div", {
    background: '$primary_500',
    height: '2px',
    width: '45%',
    margin: '0',
    borderRadius: '5px'
})
const Circle = styled("div", {
    position: 'relative',
    bottom: '4px',
    height: '12px',
    width: '12px',
    border: '3px solid $primary_500',
    borderRadius: '50%',
})


const PrelInfoContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
});

const LogoImage = styled("img", {
    marginBottom: '10px',
    height: '100px',
})

const Title = styled("h2", {
    textAlign: 'center',
});

const StyledTable = styled("table", {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
    border: '1px solid black',
});

const TableCell = styled("td", {
    border: '1px solid black',
    padding: '8px',
});

export default function QuoteDetails() {
    const token = useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const { toPDF, targetRef } = usePDF({ filename: 'quotation.pdf' })

    const [loadingPdf, setLoadingPdf] = useState(false)
    const [loading, setLoading] = useState(false)
    const [quoteData, setQuoteData] = useState()
    const [quoteId, setQuoteId] = useState()
    const [radication, setRadication] = useState()
    const [RFQ, setRFQ] = useState()

    useEffect(() => {
        setQuoteId(searchParams.get('id'))
        setRadication(searchParams.get('radication'))
    }, [])

    useEffect(() => {
        const asyncTasks = [
            getQuoteData(token, quoteId),
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getBusinessTerms(token),
            getClients(token),
            getQuoteLanguages(token),
            getStatuses(token),
            getReasons(token),
            getQuotation(radication, token)
        ];
        setLoading(true)
        if (quoteId && radication) {
            Promise.all(asyncTasks).then(async responses => {
                const liquidationData = await responses[0].json();
                const subtypesData = await responses[1].json();
                const servicesData = await responses[2].json();
                const officesData = await responses[3].json();
                const incotermsData = await responses[4].json();
                const clientsData = await responses[5].json();
                const quoteLanguagesData = await responses[6].json();
                const statusesData = await responses[7].json();
                const reasonsData = await responses[8].json();
                if (radication) {
                    const RFQData = await responses[9].json(); setRFQ(RFQData)
                }
                setLoading(false)
                const formattedData = {
                    subtype: subtypesData.find(e => e.id === liquidationData.subtype),
                    service: servicesData.find(e => e.id === liquidationData.service),
                    office: officesData.find(e => e.id === liquidationData.office),
                    client: clientsData.find(e => e.id === liquidationData.client),
                    origin: liquidationData?.shipping?.origin_boarding_terminal,
                    destination: liquidationData?.shipping?.destination_boarding_terminal,
                    reason: reasonsData.find(e => e.id === liquidationData.reason),
                    incoterm: incotermsData.find(e => e.id === liquidationData.incoterm),
                    language: quoteLanguagesData.find(e => e.id === liquidationData.language),
                    status: statusesData.find(e => e.id === liquidationData.status),
                    notes: liquidationData.observations,
                }
                setQuoteData(formattedData);
            })
                .catch(error => console.error('Error:', error));
        }
    }, [radication, quoteId]);

    console.log('quoteData :', quoteData)
    console.log('RFQ :', RFQ)

    function downloadPDF() {
        setLoadingPdf(true)
        toPDF().then(() => {
            setLoadingPdf(false)
        }).catch((err) => {
            console.log("error al descargar pdf :", err)
            setLoadingPdf(false)
        })
    }

    return (
        <>
            <div style={{ margin: '30px 0px 0 30px' }}>
                <Button handler={() => downloadPDF()} text={loadingPdf ? 'Cargando...' : 'Descargar'} endIcon={{ name: 'Download' }} outline disabled={loadingPdf} />
            </div>
            <Container ref={targetRef}>
                {loading ? <Loading size='xl' /> :
                    <>
                        <div className='prel-info' style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={logo} height="100px" alt="" style={{ marginBottom: '10px' }} />
                                <h2 style={{ textAlign: 'center' }}>Detalles de la liquidación {radication}</h2>
                            </div>


                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Tipo:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.subtype?.name}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Razón:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.reason?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Servicio:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.service?.name}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Incoterm:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.incoterm?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Oficina:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.office?.name}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Idioma:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.language?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Cliente:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.client?.name}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Estado:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.status?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Origen:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.origin}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>Destino:</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.destination}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>Notas:</td>
                                            <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>{quoteData?.notes}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '30px 0' }}>
                            <Line />
                            <Circle />
                            <Line />
                        </div>
                        {
                            <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left', border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Carga</th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Nombre</th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Valor</th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Cantidad</th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Medidas</th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Peso total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RFQ?.loads.map((load, index) => (
                                        <tr key={index} style={{ border: '1px solid black' }}>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>{`Carga ${index + 1}`}</td>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>{load?.name}</td>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>{`${load.value} USD`}</td>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>{load.amount}</td>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>{`${load.long}${load.long_unit} x ${load.width}${load.width_unit} x ${load.height}${load.height_unit}`}</td>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                                                <Tooltip content="peso x cantidad" color={"primary"}>
                                                    {`${load.total_weight} ${load.total_weight_unit}`}
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                        }
                        {RFQ?.length > 2 ? 'No hay RFQ relacionada a esta liquidación (!)' : <div>
                            <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left', border: '1px solid black' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                                            <h3>Detalles de cotización {radication}</h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                                            <strong>General</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Tipo de operacion:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{RFQ?.business_type}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Industria:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{RFQ?.industry}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Incoterm:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{RFQ?.business_terms}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                                            <strong>Envio</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Metodo de envio:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{RFQ?.shipping.shipping_method}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Origen:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{`${RFQ?.shipping.pickup_address} ,${RFQ?.shipping.origin_boarding_terminal}, ${RFQ?.shipping.origin_country}`}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>Destino:</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{`${RFQ?.shipping.delivery_address} ,${RFQ?.shipping.destination_boarding_terminal}, ${RFQ?.shipping.destination_country}`}</td>
                                    </tr>
                                </tbody>
                            </table>


                            <br /><br />
                            <strong>Adicional : </strong>
                            <p>Requiere control de temperatura : {RFQ?.options.temperature_control === 1 ? "Si" : "No"}</p>
                            <p>Requiere Data logger : {RFQ?.options.data_logger === 1 ? "Si" : "No"}</p>
                            <p>La carga es remontable : {RFQ?.options.remountable === 1 ? "Si" : "No"}</p>
                            <p>Requiere posicion arancelaria : {RFQ?.options.arancelary_position === 1 ? "Si" : "No"}</p>
                            <p>Especificaciones adicionales : {RFQ?.options.additional_specifications || "Ninguno"}</p>
                        </div>}
                    </>}
            </Container>
        </>
    )
}

